<div class="container-authenticated container-savebar" [ngClass]="{'container-authenticated-ios': isIos}">
    <h1>Platform Defaults</h1>
    <mat-divider></mat-divider>
    <br />
    <div class="platform-default-contents">
        <form [formGroup]="platformDefaultsForm">
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                    <h4>Deposit</h4>
                    <mat-divider></mat-divider>
                    <br />
                    <div fxLayout="row" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="50%">
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Deposit Minimum</mat-label>
                                <input matInput placeholder="5" formControlName="depositMinimumPercentage" currencyMask
                                    [options]="{ precision : 0, align: 'left', prefix: '', suffix: ' %' , inputMode: 'NATURAL'}" />
                                <mat-hint>Deposit minimum percentage.</mat-hint>
                                <mat-error
                                    *ngIf="platformDefaultsForm.controls['depositMinimumPercentage'].invalid && (platformDefaultsForm.controls['depositMinimumPercentage'].dirty || platformDefaultsForm.controls['depositMinimumPercentage'].touched)">
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['depositMinimumPercentage'].errors?.['required']">Deposit
                                        minimum is
                                        required.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['depositMinimumPercentage'].errors?.['min']">
                                        Deposit Minimum minimum value is 0 %.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['depositMinimumPercentage'].errors?.['min']">
                                        Deposit Minimum maximum value is 100 %.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%">
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Deposit Maximum</mat-label>
                                <input matInput placeholder="5" formControlName="depositMaximumPercentage" currencyMask
                                    [options]="{ precision : 0, align: 'left', prefix: '', suffix: ' %' , inputMode: 'NATURAL'}" />
                                <mat-hint>Deposit maximum percentage.</mat-hint>
                                <mat-error
                                    *ngIf="platformDefaultsForm.controls['depositMaximumPercentage'].invalid && (platformDefaultsForm.controls['depositMaximumPercentage'].dirty || platformDefaultsForm.controls['depositMaximumPercentage'].touched)">
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['depositMaximumPercentage'].errors?.['required']">Deposit
                                        minimum is
                                        required.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['depositMaximumPercentage'].errors?.['min']">
                                        Deposit Maximum minimum value is 0 %.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['depositMaximumPercentage'].errors?.['min']">
                                        Deposit Maximum maximum value is 100 %.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <br />
                    <h4>Minimum Show Length</h4>
                    <mat-divider></mat-divider>
                    <br />
                    <input type="hidden" formControlName="showLengthMinutesMinimum" />
                    <div class="ddl-group" fxLayout="row" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="50%">
                            <mat-form-field class="no-wrapper"
                                [ngClass]="{'ng-invalid mat-form-field-invalid': platformDefaultsForm.controls['showLengthMinutesMinimum'].invalid && (platformDefaultsForm.controls['showLengthMinutesMinimum'].dirty || platformDefaultsForm.controls['showLengthMinutesMinimum'].touched)}"
                                appearance="outline" color="primary">
                                <mat-label>Hour(s)</mat-label>
                                <mat-select [(value)]="minimumShowLengthHour"
                                    (selectionChange)="updateMinimumShowLengthInput()">
                                    <mat-option
                                        *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
                                        [value]="hour">
                                        {{hour}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%">
                            <mat-form-field class="no-wrapper"
                                [ngClass]="{'ng-invalid mat-form-field-invalid': platformDefaultsForm.controls['showLengthMinutesMinimum'].invalid && (platformDefaultsForm.controls['showLengthMinutesMinimum'].dirty || platformDefaultsForm.controls['showLengthMinutesMinimum'].touched)}"
                                appearance="outline" color="primary">
                                <mat-label>Minutes</mat-label>
                                <mat-select [(value)]="minimumShowLengthMinute"
                                    (selectionChange)="updateMinimumShowLengthInput()">
                                    <mat-option *ngFor="let minute of [0,15,30,45]" [value]="minute">
                                        {{minute}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-error class="form-group-error"
                        *ngIf="platformDefaultsForm.controls['showLengthMinutesMinimum'].invalid && (platformDefaultsForm.controls['showLengthMinutesMinimum'].dirty || platformDefaultsForm.controls['showLengthMinutesMinimum'].touched)">
                        <ng-container
                            *ngIf="platformDefaultsForm.controls['showLengthMinutesMinimum'].errors?.['required']">
                            Minimum Show Length is required.
                        </ng-container>
                        <ng-container *ngIf="platformDefaultsForm.controls['showLengthMinutesMinimum'].errors?.['min']">
                            Minimum Show Length is 15 minutes.
                        </ng-container>
                    </mat-error>
                    <br />
                    <h4>Maximum Show Length</h4>
                    <mat-divider></mat-divider>
                    <br />
                    <input type="hidden" formControlName="showLengthMinutesMaximum" />
                    <div class="ddl-group" fxLayout="row" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="50%">
                            <mat-form-field class="no-wrapper"
                                [ngClass]="{'ng-invalid mat-form-field-invalid': platformDefaultsForm.controls['showLengthMinutesMaximum'].invalid && (platformDefaultsForm.controls['showLengthMinutesMaximum'].dirty || platformDefaultsForm.controls['showLengthMinutesMaximum'].touched)}"
                                appearance="outline" color="primary">
                                <mat-label>Hour(s)</mat-label>
                                <mat-select [(value)]="maximumShowLengthHour"
                                    (selectionChange)="updateMaximumShowLengthInput()">
                                    <mat-option
                                        *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
                                        [value]="hour">
                                        {{hour}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%">
                            <mat-form-field class="no-wrapper"
                                [ngClass]="{'ng-invalid mat-form-field-invalid': platformDefaultsForm.controls['showLengthMinutesMaximum'].invalid && (platformDefaultsForm.controls['showLengthMinutesMaximum'].dirty || platformDefaultsForm.controls['showLengthMinutesMaximum'].touched)}"
                                appearance="outline" color="primary">
                                <mat-label>Minutes</mat-label>
                                <mat-select [(value)]="maximumShowLengthMinute"
                                    (selectionChange)="updateMaximumShowLengthInput()">
                                    <mat-option *ngFor="let minute of [0,15,30,45]" [value]="minute">
                                        {{minute}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <mat-error class="form-group-error"
                        *ngIf="platformDefaultsForm.controls['showLengthMinutesMaximum'].invalid && (platformDefaultsForm.controls['showLengthMinutesMaximum'].dirty || platformDefaultsForm.controls['showLengthMinutesMaximum'].touched)">
                        <ng-container
                            *ngIf="platformDefaultsForm.controls['showLengthMinutesMaximum'].errors?.['required']">
                            Maximum Show Length is required.
                        </ng-container>
                        <ng-container *ngIf="platformDefaultsForm.controls['showLengthMinutesMaximum'].errors?.['min']">
                            Maximum Show Length minimum is 15 minutes.
                        </ng-container>
                    </mat-error>                    
                </div>
                <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                    <h4>Tags</h4>
                    <mat-divider></mat-divider>
                    <br />
                    <mat-form-field appearance="outline" color="primary" floatLabel="always">
                        <mat-label>Custom Prompt</mat-label>
                        <input matInput placeholder="Try 'Storytime' to see what you get!"
                            formControlName="customTagFieldPromptText" />
                        <mat-hint>Custom prompt for the add/edit tags area for users.</mat-hint>
                    </mat-form-field>
                    <br />
                    <br />
                    <h4>Event Defaults</h4>
                    <mat-divider></mat-divider>
                    <br />
                    <div fxLayout="row" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Grace Window</mat-label>
                                <input matInput placeholder="5" formControlName="eventGraceWindowMinutes" type="number" />
                                <mat-hint>Grace window in minutes.</mat-hint>
                                <mat-error
                                    *ngIf="platformDefaultsForm.controls['eventGraceWindowMinutes'].invalid && (platformDefaultsForm.controls['eventGraceWindowMinutes'].dirty || platformDefaultsForm.controls['eventGraceWindowMinutes'].touched)">
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['eventGraceWindowMinutes'].errors?.['required']">Grace
                                        window is
                                        required.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['eventGraceWindowMinutes'].errors?.['min']">
                                        Minimum Grace Window is 0 minutes.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <br />
                    <h4>Toon Balloon Company Settings</h4>
                    <mat-divider></mat-divider>
                    <br />
                    <div fxLayout="row" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Commission Percentage</mat-label>
                                <input matInput placeholder="0" formControlName="commissionPercentage" placeholder="35"
                                    currencyMask
                                    [options]="{ precision : 0, align: 'left', prefix: '', suffix: ' %' , inputMode: 'NATURAL'}" />
                                <mat-hint>The percentage of the total show cost that Toon Balloon takes.</mat-hint>
                                <mat-error
                                    *ngIf="platformDefaultsForm.controls['commissionPercentage'].invalid && (platformDefaultsForm.controls['commissionPercentage'].dirty || platformDefaultsForm.controls['commissionPercentage'].touched)">
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['commissionPercentage'].errors?.['required']">Commission
                                        Percentage
                                        is
                                        required.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['commissionPercentage'].errors?.['min']">
                                        Minimum Commission Percentage is 0 %.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="platformDefaultsForm.controls['commissionPercentage'].errors?.['max']">
                                        Maximum Commission Percentage is 100 %.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </form>
        <div class="bottom-bar">
            <mat-divider></mat-divider>
            <button class="icon-button" mat-raised-button color="primary" type="button"
                [disabled]="!platformDefaultsForm.valid || platformDefaultsSaving" (click)="savePlatformDefaults()">
                <mat-icon>save_alt</mat-icon> Save
            </button>
        </div>
    </div>
</div>