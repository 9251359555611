import { Injectable } from '@angular/core';
import { BehaviorSubject, first, from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';

//environments
import { environment } from 'src/environments/environment';

// services
import { HttpBaseService } from 'src/app/global/services/http-base/http-base.service';
import { TokenService } from '../token/token.service';
import { UserService } from 'src/app/profile/services/user/user.service';
import { CognitoService } from '../cognito/cognito.service';
import { StripeService } from 'src/app/global/services/stripe/stripe.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpBaseService<any> {

  authenticatedSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // subject with boolean value to tell is user is authenticated
  authenticated$: Observable<boolean> = this.authenticatedSubject$.asObservable(); // authenticated subject observable

  authenticatedUserSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // subject that contains the authenticated AWS cognito user object
  authenticatedUser$: Observable<any> = this.authenticatedUserSubject$.asObservable(); // authenticated user subject observable

  authenticatedUserDataSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // subject that contains the authenticated users data from their profile
  authenticatedUserData$: Observable<any> = this.authenticatedUserDataSubject$.asObservable(); // authenticated user data subject observable

  authenticatedUserRoleSubject$: BehaviorSubject<Array<string>> = new BehaviorSubject<any>(null); // subject that contains the authenticated users roles
  authenticatedUserRole$: Observable<Array<string>> = this.authenticatedUserRoleSubject$.asObservable(); // authenticated user roles subject observable

  signUpStatusSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // subject that contains the sign up status of the current authenticated user
  signUpStatus$: Observable<any> = this.signUpStatusSubject$.asObservable(); // sign up status subject observable

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService,
    private _stripeService: StripeService,
    private userService: UserService,
    private cognitoService: CognitoService,
    private location: Location,
    private router: Router,
    private afa: AngularFireAuth
  ) {
    super(`${environment.nodeServerUrl}/auth/`, _http, _tokenService);
  }

  /**
   * @description check if the current user is authenticated
   */
  authCheck() {

    const location = this.location.path();

    return from(this._tokenService.checkToken().then(async () => {
      await this.cognitoService.getCognitoUser().then(async cognitoUser => {
        if (cognitoUser) {
          await this.getCognitoUserSession(cognitoUser).then(async session => {
            if (session) {
              await this.cognitoService.getCognitoSessionValid(session).then(async isValid => {
                this.authenticatedSubject$.next(isValid);
                if (isValid) {
                  await this.setSessionValues(session, cognitoUser);

                  this.createFirebaseToken(cognitoUser.username).subscribe(async fbt => {
                    await this.afa.signInWithCustomToken(fbt.data);

                    // let timer: Observable<number> = interval(60000); // run every 60 seconds
                    let stripeAccountId: string;
                    let that = this;
                    // get the users stripe account id from the database

                    this.authenticatedUserData$.pipe(first()).subscribe(user => {
                      if (user) {
                        console.log('stripeAccountId: ', stripeAccountId);
                        stripeAccountId = user.stripeAccountId;

                        that._stripeService.getStripeAccountStatus(stripeAccountId).subscribe(accountStatus => {
                          console.log('accountStatus: ', accountStatus);
                          that._stripeService.stripeAccountStatusSubject$.next(accountStatus);
                        });
                      }
                    });
                  });
                  // if (location !== '/sign-in') {
                  //   await this.router.navigate([location]);
                  // }
                } else {
                  this.clearAuthSubjects();
                }
              }).catch(err => {
                if (err) {
                  console.log('session valid error: ', err);
                  this.clearAuthSubjects();
                }
              });
            }
          }).catch(err => {
            if (err) {
              console.log('session error: ', err);
              this.clearAuthSubjects();
            }
          });
        }
      }).catch(err => {
        if (err) {
          console.log('get cognito user error: ', err);
          this.clearAuthSubjects();
        }
      })
    })
    );
  }

  /**
   * @description converts the callback of the cognito user session into a promise
   * @param cognitoUser { any } the actual cognito user object
   * @returns Promise<any> with the calback values
   */
  private getCognitoUserSession(cognitoUser: any) {
    return new Promise<any>((resolve, reject) => {
      cognitoUser.getSession((err: any, session: any) => {
        resolve(session);
        reject(err);
      });
    });
  }

  /**
   * @description 1. sets the token subject value with the current valid token from the cognito user session
   *              2. sets the authenticated user subject with the current cognito user
   * @param session { any } // the current cognito user session
   * @param cognitoUser { any } // the current cognito user
   */
  private async setSessionValues(session: any, cognitoUser: any) {

    await this.cognitoService.getCognitoUserJwtToken(session).then(token => {
      if (token) {
        this._tokenService.tokenSubject$.next(token);
      }
    }).catch(err => {
      if (err) {
        console.log('jwt token error: ', err);
      }
    });

    if (session) {
      await this.cognitoService.getCognitoUserAttributes(cognitoUser).then(async user => {
        if (user) {
          // console.log(user);
          this.authenticatedUserSubject$.next(user);
          await this.getUserData();
        }
      }).catch(err => {
        if (err) {
          console.log('user error: ', err);
          this.clearAuthSubjects();
        }
      });
    }
  }

  /**
   * @description gets the sign up status of the current authenticated user and sets the subject
   */
  async getSignUpStatus() {
    const signUpStatus = (await this.userService.getSignUpStatus());
    this.signUpStatusSubject$.next(signUpStatus.data);
  }

  /**
   * @description 1. gets the user profile data of the current authenticated user and sets the subject
   *              2. gets the user roles of the current authenticated user and sets the subject
   */
  async getUserData() {

    await this.getSignUpStatus();

    const getUserData = (await this.userService.getUser());
    this.authenticatedUserDataSubject$.next(getUserData.data);
    this.authenticatedUserRoleSubject$.next(getUserData.data.roleNames);
  }

  /**
   * @description creates a custom firebase auth token with the unique cognito user id
   * @param uid { string } the unique id of the auth user
   * @returns { string } JWT firebase token
   */
  createFirebaseToken(uid: string) {
    return this.post(uid, 'createFirebaseToken');
  }

  /**
   * @description sends the data to the API to create a new user account
   * @param newAccount { any } the form data from the create account form 
   * @returns { Observable<any> } // returns the created user object
   */
  createAccount(newAccount: any) {
    return this.post(newAccount, 'register');
  }

  /**
   * @description sign in a user with provided username and password
   * @param email { string }
   * @param password { string }
   * @returns { Promise<any> } // cognito user session
   */
  signIn(email: string, password: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.cognitoService.setCognitoSetAuthAttributes(email, password).then(async (data: any) => {
        if (data) {
          await this.cognitoService.authCognitoUser(data.cognitoUser, data.authenticationDetails).then(async session => {
            if (session) {
              resolve(session);
            }
          }).catch(err => {
            if (err) {
              console.log('authCognitoUser error: ', err);
              this.clearAuthSubjects();
              reject(err);
            }
          });
        }
      }).catch(err => {
        if (err) {
          console.log('cognitoSignIn error: ', err);
          this.clearAuthSubjects();
          reject(err);
        }
      });
    });
  }

  /**
   * @description signs out the current authenticated user
   * @returns { Promise<any> } // string with message of success or failure
   */
  signOut(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.cognitoService.getCognitoUser().then(cognitoUser => {
        if (!cognitoUser) {
          reject('No user!');
        }
        cognitoUser.signOut(() => {
          this.clearAuthSubjects();
          resolve('SUCCESS');
        });
      });
    });
  }

  /**
   * @description clears all the subjects related to the authenticated user and navigates to sign in
   */
  private clearAuthSubjects() {
    this.authenticatedSubject$.next(false);
    this.authenticatedUserSubject$.next(null);
    this.authenticatedUserRoleSubject$.next(null);
    this.authenticatedUserDataSubject$.next(null);
    this.signUpStatusSubject$.next(null);
    this._tokenService.tokenSubject$.next(null);
    this._tokenService.validSubject$.next(false);
    this._stripeService.stripeAccountStatusSubject$.next(null);
    this.router.navigate(['/sign-in']);
  }

  /**
   * @description checks the AWS cognito user and sends them a verification code via email to reset their password
   * @param email { string }
   * @returns { Promise<any> } // either { result: result, email: email } or an error object
   */
  forgotPassword(email?: string): Promise<any> {

    return new Promise(async (resolve, reject) => {

      let cognitoUser: any;

      if (email) {
        await this.cognitoService.getCognitoUser(email).then(user => {
          cognitoUser = user;
        }).catch(err => {
          if (err) {
            console.log('getCognitoUser error: ', err);
            reject('No user!');
          }
        });
      } else {
        await this.cognitoService.getCognitoUser().then(user => {
          cognitoUser = user;
        }).catch(err => {
          if (err) {
            console.log('getCognitoUser error: ', err);
            reject('No user!');
          }
        });

        await this.cognitoService.getCognitoUserSession(cognitoUser).then(session => {
          email = session.idToken.payload.email;
        }).catch(err => {
          if (err) {
            console.log('getCognitoUserSession error: ', err);
            reject('No session!');
          }
        });
      }

      await this.cognitoService.forgotPasswordCognitoUser(cognitoUser).then(result => {
        if (result) {
          let response = { result: result, email: email };
          resolve(response);
        }
      }).catch(err => {
        if (err) {
          console.log('forgotPasswordCognitoUser error: ', err);
          reject(err);
        }
      });
    });
  }

  /**
   * @description resets a AWS cognito users password if provided the correct data
   * @param verificationCode { string }
   * @param newPassword { string }
   * @param email { string }
   * @returns { Promise<any> } success or failure objects
   */
  resetPassword(verificationCode: string, newPassword: string, email?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let cognitoUser: any;

      if (email) {
        await this.cognitoService.getCognitoUser(email).then(user => {
          cognitoUser = user;
        }).catch(err => {
          if (err) {
            console.log('getCognitoUser error: ', err);
            reject('No user!');
          }
        });
      } else {
        await this.cognitoService.getCognitoUser().then(user => {
          cognitoUser = user;
        }).catch(err => {
          if (err) {
            console.log('getCognitoUser error: ', err);
            reject('No user!');
          }
        });
      }

      await this.cognitoService.confirmPasswordCognitoUser(cognitoUser, verificationCode, newPassword).then(result => {
        if (result) {
          resolve(result);
        }
      }).catch(err => {
        if (err) {
          console.log('confirmPasswordCognitoUser error: ', err);
          reject(`Confirm failed! ${err}`);
        }
      });
    });
  }

}
