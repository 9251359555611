import { Component, Input, OnInit } from '@angular/core';

// services
import { ImageService } from 'src/app/global/services/image/image.service';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit {

  @Input() profileData: any;

  croppedImage: any = '';  // the base 64 cropped image

  constructor(
    private imageService: ImageService
  ) { }

  ngOnInit(): void {
    this.croppedImage = this.profileData.profileImageUrl ? this.imageService.formatImage(this.profileData.profileImageUrl)['640w'] : ''; 
  }

}
