import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// components
import { CreateAccountComponent } from './auth/components/create-account/create-account.component';
import { DeleteAccountComponent } from './auth/components/delete-account/delete-account.component';
import { ForgotPasswordComponent } from './auth/components/forgot-password/forgot-password.component';
import { RegisterComponent } from './profile/components/register/register.component';
import { ResetPasswordComponent } from './auth/components/reset-password/reset-password.component';
import { SignInComponent } from './auth/components/sign-in/sign-in.component';
import { BookingsComponent } from './event/components/bookings/bookings.component';
import { BrowseComponent } from './event/components/browse/browse.component';
import { ScheduleComponent } from './event/components/schedule/schedule.component';
import { ShowsComponent } from './event/components/shows/shows.component';
import { EditProfileComponent } from './profile/components/edit-profile/edit-profile.component';
import { ArtistProfileComponent } from './profile/components/artist-profile/artist-profile.component';
import { EventComponent } from './event/components/event/event.component';
import { StreamComponent } from './event/components/stream/stream.component';
import { UserListComponent } from './admin/components/user-list/user-list.component';
import { TagListComponent } from './admin/components/tag-list/tag-list.component';
import { PlatformMediaComponent } from './admin/components/platform-media/platform-media.component';
import { PlatformDefaultsComponent } from './admin/components/platform-defaults/platform-defaults.component';
import { AccessDeniedComponent } from './auth/components/access-denied/access-denied.component';

// guards
import { AuthGuard } from './auth/guards/auth/auth.guard';
import { NotAuthGuard } from './auth/guards/not-auth/not-auth.guard';
import { RegisterGuard } from './profile/guards/register/register.guard';

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent, canActivate: [NotAuthGuard] },
  { path: 'create-account', component: CreateAccountComponent },
  { path: 'delete-account', component: DeleteAccountComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UserListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'tags', component: TagListComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'media', component: PlatformMediaComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'settings', component: PlatformDefaultsComponent, canActivate: [AuthGuard], data: { role: 'Admin' } },
  { path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuard], resolve: { registerGuard: RegisterGuard } },
  { path: 'edit-profile/:id', component: EditProfileComponent, canActivate: [AuthGuard], data: { role: 'Admin' }, resolve: { registerGuard: RegisterGuard } },
  { path: 'artist/:id', component: ArtistProfileComponent, canActivate: [AuthGuard], resolve: { registerGuard: RegisterGuard } },
  { path: 'event/:id', component: EventComponent, canActivate: [AuthGuard], resolve: { registerGuard: RegisterGuard } },
  { path: 'event/:id/stream', component: StreamComponent, canActivate: [AuthGuard], resolve: { registerGuard: RegisterGuard } },
  { path: 'shows', component: ShowsComponent, canActivate: [AuthGuard], data: { role: 'Artist' }, resolve: { registerGuard: RegisterGuard } },
  { path: 'bookings', component: BookingsComponent, canActivate: [AuthGuard], resolve: { registerGuard: RegisterGuard } },
  { path: 'schedule/:id', component: ScheduleComponent, canActivate: [AuthGuard], resolve: { registerGuard: RegisterGuard } },
  { path: 'browse', component: BrowseComponent, canActivate: [AuthGuard], resolve: { registerGuard: RegisterGuard } },
  { path: '', component: BrowseComponent, canActivate: [AuthGuard], resolve: { registerGuard: RegisterGuard } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
