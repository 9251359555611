import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenService } from 'src/app/auth/services/token/token.service';
import { GlobalConstants } from 'src/app/global/constants/global';
import { HttpBaseService } from 'src/app/global/services/http-base/http-base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformMediaService extends HttpBaseService<any> {

  platformMediaDefaultSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // subject that contains the default platform media
  platformMediaDefault$: Observable<any> = this.platformMediaDefaultSubject$.asObservable(); // default platform media subject observable

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/platformmedia/`, _http, _tokenService);
  }

  /**
   * @description gets the entire platform media list for all media types
   * @returns { Observable<any> } platform media list
   */
  getPlatformMedia(): Observable<any> {
    return this.get('');
  }

  /**
   * @description gets the default platform media list for all media types
   * @returns { void }
   */
  getDefaultPlatformMedia(): void {
    this.get('default').subscribe(res => {
      if(res && res.data){
        this.platformMediaDefaultSubject$.next(res.data);
      }      
    });
  }

  /**
   * @description gets the media type lookup list
   * @returns { Observable<any> } media type lookup list
   */
  getMediaTypeLookups(): Observable<any> {
    return this.get('mediatypelookups');
  }

  /**
   * @description creates platform media record in the database
   * @param tagData { any }
   * @returns { Observable<any> } platform media data result
   */
  createPlatformMedia(mediaData: any): Observable<any> {
    return this.post(mediaData);
  }

  /**
   * @description deletes the platform media in the database
   * @param account { any }
   * @returns { Observable<any> } platform media delete result
   */
  deleteMedia(media: any, modifiedBy: string): Observable<any> {
    return this.patch(media.id, { statusLookupId: GlobalConstants.deletedStatus.id, mediaDefault: false, modifiedBy: modifiedBy });
  }

  /**
   * @description sets platform media to default in the database
   * @param account { any }
   * @returns { Observable<any> } platform media default result
   */
  defaultMedia(media: any, modifiedBy: string): Observable<any> {
    return this.patch(media.id, { mediaTypeLookupId: media.mediaTypeLookupId, mediaDefault: true, modifiedBy: modifiedBy });
  }

}
