
// modules
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

// services
import { SongService } from '../../services/song/song.service';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';

// components
import { SongFormComponent } from '../song-form/song-form.component';
import { VideoPlayerComponent } from 'src/app/global/components/video-player/video-player.component';
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

@Component({
  selector: 'app-song-list',
  templateUrl: './song-list.component.html',
  styleUrls: ['./song-list.component.scss']
})
export class SongListComponent implements OnInit, OnDestroy {

  @Input() editable: boolean = false; // flag that tells if the songlist is editable or not
  @Input() modifiedBy: string; // the authenticated user
  @Input() userSongListData: any; // song list for the current user

  deleteSongDialogRef: any; // element reference to delete song dialog
  deleteSongSaving: boolean = false; // flag to tell if in the act of deleting song data

  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private songService: SongService,
    private authService: AuthService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    // console.log(this.userSongListData);
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description opens the song dialog with dong data based on mode and set up the close event
   * @param mode { string }
   * @param song { any }
   */
  openSongDialog(mode: string, song?: any): void {

    let songData: any;

    if (song) {
      songData = { mode: mode, modifiedBy: this.modifiedBy, song: song }
    } else {
      songData = { mode: mode, modifiedBy: this.modifiedBy, song: { userId: this.userSongListData.userId } }
    }

    const songFormDialogRef = this.dialog.open(SongFormComponent, {
      width: '500px',
      data: songData,
    });

    this.subscriptions.add(
      songFormDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
        //If the result is nothing they click off the dialog and we have no save or edit
        if (!result) {
          return;
        }
        else if (result.event === 'add') {
          this.userSongListData.songList.push(result.data);
        } else if (result.event === 'edit') {
          let songToReplace = this.userSongListData.songList.find(song => song.id === result.data.id);
          Object.assign(songToReplace, result.data);
        }
      })
    );
  }

  /**
   * @description opens the delete song dialog and sets up the close event
   * @param deleteTemplateRef { any }
   */
  deleteSongDialog(deleteTemplateRef: any): void {
    this.deleteSongDialogRef = this.dialog.open(deleteTemplateRef);

    this.subscriptions.add(
      this.deleteSongDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description marks a song as deleted in the database
   * @param deletesong { any }
   */
  deleteSong(deletesong: any) {
    this.deleteSongSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('deleted song:', deletesong);

    this.subscriptions.add(
      this.songService.deleteUserSong(deletesong).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.userSongListData.songList = this.userSongListData.songList.filter(song => song.id !== deletesong.id);
            this.deleteSongDialogRef.close({ event: 'delete', data: res });
            this.deleteSongDialogRef = null;
            this.deleteSongSaving = false;
            this.authService.getUserData(); // this updates the user data subject so that the most recent data is always displayed
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            this.deleteSongSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description launches the dialog with the embed to play the video
   * @param song { any } 
   */
  launchEmbed(song: any): void {
    this.dialog.open(VideoPlayerComponent, {
      panelClass: 'video-dialog',
      data: song,
    });
  }

}
