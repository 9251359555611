import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { map, Observable, startWith, Subscription } from 'rxjs';

// services
import { PlatformDefaultsService } from 'src/app/admin/services/platform-defaults/platform-defaults.service';
import { SpotifyService } from 'src/app/global/services/spotify/spotify.service';
import { TagLookupService } from 'src/app/global/services/tag-lookup/tag-lookup.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>; // search input element reference

  filteredTags: Observable<string[]>; // an observable that contains the string array of tag names
  tags: string[] = []; // the tags selected
  allTags: string[] = []; // all the tags that are available to select

  selectedDate: Date;

  platformDefaults: any; // object with the current platform defaults
  filterObject: { searchText?: string, dateFilter?: string, price?: string, distance?: string }; // the filters that are selected

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  // creates the filter form
  filterForm: FormGroup = this.formBuilder.group({
    searchText: [''],
    dateFilter: [''],
    price: [''],
    distance: [''],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private platformDefaultsService: PlatformDefaultsService,
    private spotifyService: SpotifyService,
    private tagLookupService: TagLookupService,
    public dialogRef: MatDialogRef<FilterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    // filters the values in the list of available tags based on what was typed in the input
    this.filteredTags = this.filterForm.controls['searchText'].valueChanges.pipe(
      startWith(null),
      map((tags: [] | null) => (tags && tags.length > 0 ? this.filterTags(tags) : this.allTags.slice())),
    );

    //Get spotify genres    
    this.subscriptions.add(
      this.spotifyService.genres()
        .subscribe(genres => {
          this.addToTags(genres);
        })
    );

    //Get tagLookups
    this.subscriptions.add(
      this.tagLookupService.tagLookups()
        .subscribe(tagLookups => {
          this.addToTags(tagLookups.map((x: any) => { return x.name }));
        })
    );

  }

  ngOnInit(): void {

    this.filterObject = this.data;

    if (this.filterObject) {

      this.selectedDate = new Date(this.data.dateFilter);

      this.filterForm.controls['searchText'].setValue(this.filterObject.searchText ? this.filterObject.searchText : '');
      this.filterForm.controls['dateFilter'].setValue(this.filterObject.dateFilter ? this.filterObject.dateFilter : '');
      this.filterForm.controls['price'].setValue(this.filterObject.price ? this.filterObject.price : '');
      this.filterForm.controls['distance'].setValue(this.filterObject.distance ? this.filterObject.distance : '');
    }

    //Get platform defaults
    this.subscriptions.add(
      this.platformDefaultsService.getPlatformDefaults()
        .subscribe(platformDefaults => {
          this.platformDefaults = platformDefaults.data;
        })
    );

  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description fires when close button is pressed
   */
  close() {
    this.dialogRef.close({ event: 'close' });
  }

  /**
   * @description fires when the filter button is pressed
   */
  filter() {
    this.filterObject = this.filterForm.value;
    this.dialogRef.close({ event: 'filter', data: this.filterObject });
  }

  /**
 * @description adds a selected tag to the list
 * @param array { string[] } the selected tags 
 */
  addToTags(array) {
    //Create a set to get unique values and then combine with previous tags
    this.allTags = Array.from(new Set(this.allTags.concat(array)));

    //Setup autocomplete filter
    if (array && array.length > 0) {
      this.filteredTags = this.filterForm.controls['searchText'].valueChanges.pipe(
        startWith(null),
        map((filter: string | null) => (filter && filter.length > 0 ? this.filterTags(this.tags, filter) : this.allTags.slice())),
      );
    }
  }

  /**
   * @description filters tags based on the text in the input
   * @param tags { string[] }
   * @param value { string }
   * @returns { string[] } the filtered list of tags
   */
  private filterTags(tags: string[], value?: string): string[] {
    return this.allTags.filter(tag => !tags.includes(tag) && (!value || tag.toLowerCase().includes(value.toLowerCase())));
  }

  /**
   * @description removes a selected tag from the list
   * @param tag { string }
   */
  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.filterForm.controls['searchText'].setValue(this.tags);
    }
  }

  /**
   * @description set the selected tag
   * @param event { MatAutocompleteSelectedEvent }
   */
  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    //Need the control set value to allow filter to work correctly
    this.filterForm.controls['searchText'].setValue('');
    this.searchInput.nativeElement.value = '';
    this.filterForm.controls['searchText'].setValue(this.tags);
  }

  // /**
  //  * @description the search method that fires after a user stops typing
  //  * @param text { string } the test that the user enters
  //  */
  // search(text: string) {
  //   console.log('search: ', text);
  //   this.filterObject.searchText = text;
  // }

  /**
   * @description this is the event that fired when the date is selected
   * @param dateSelected { moment } the date selected
   */
  dateChanged(dateSelected: moment.Moment) {
    console.log('date: ', dateSelected);
    this.filterForm.controls['dateFilter'].setValue(dateSelected ? dateSelected.format('MM/DD/yyyy') : '');

    console.log('date filter: ', this.filterObject.dateFilter);
  }

  // /**
  //  * @description the price method that fires after a user stops typing
  //  * @param text { string } the test that the user enters
  //  */
  // price(text: string) {
  //   console.log('search: ', text);
  //   this.filterObject.price = text;
  // }

  // /**
  //  * @description the distance method that fires after a user stops typing
  //  * @param text { string } the test that the user enters
  //  */
  // distance(text: string) {
  //   console.log('search: ', text);
  //   this.filterObject.distance = text;
  // }

}
