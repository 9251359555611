import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss']
})
export class SuccessMessageComponent implements OnInit {

  constructor(
    public sbRef: MatSnackBarRef<SuccessMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: string
  ) { }

  ngOnInit(): void {
  }

}
