import { AbstractControl } from '@angular/forms';
export class PasswordValidation {

    /**
     * @description makes sure the password has all the required params in the regex
     * @param control {AbstractControl}
     * @returns { [key: string]: any } | null
     */
    static passwordRequirements(control: AbstractControl): { [key: string]: any } {
        // tslint:disable-next-line:max-line-length
        let regex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,}$/g
        if (!regex.test(control.value)) {
            return { 'passwordRequirements': true };
        }
        return null;
    }

    /**
     * @description checks the password and verifyPassword fields to make sure they match exactly
     * @param AC {AbstractControl}
     * @returns { [key: string]: any } | null
     */
    static matchPassword(AC: AbstractControl): { [key: string]: any } {
        const password = AC.get('password').value; // to get value in input tag
        const verifyPassword = AC.get('verifyPassword').value; // to get value in input tag
        if (password !== verifyPassword) {
            // console.log('false');
            AC.get('password').setErrors({ matchPassword: true });
            AC.get('verifyPassword').setErrors({ matchPassword: true });
        } else {
            // console.log('true');            
            AC.get('password').setErrors({ matchPassword: null });
            AC.get('verifyPassword').setErrors({ matchPassword: null });
            AC.get('password').updateValueAndValidity({onlySelf: true});
            AC.get('verifyPassword').updateValueAndValidity({onlySelf: true});
            return null;
        }
    }
}