<app-navbar *ngIf="(authService.authenticated$ | async) || (!(authService.authenticated$ | async) && (location.path() === '/browse' || location.path().includes('/artist/')))"></app-navbar>
<mat-sidenav-container autosize>
    <mat-sidenav
        *ngIf="!isNative && (authService.authenticated$ | async) && (authService.signUpStatus$ | async) && location.path() !== '/register'"
        #sidenav fixedInViewport="true" mode="side" opened="{{ isExpanded }}">
        <section [class.sidenav]="isExpanded">
            <div class="toggle" (click)="toggleMenu()">
                <mat-icon>
                    {{ isExpanded ? "keyboard_backspace" : "dehaze" }}
                </mat-icon>
            </div>
            <mat-list class="nav">
                <ng-container *ngIf="this.roles?.includes('Artist') && this.user">
                    <ng-container *ngIf="this.stripeService.stripeAccountStatus$ | async as stripeStatus">
                        <ng-container *ngIf="!stripeStatus.setup; else default">
                            <a mat-list-item routerLinkActive="active-link"
                                class="hover" [stripeConnectButton]="{ stripeAccountId: user.stripeAccountId, linkType: 'account_onboarding' }">
                                <mat-icon mat-list-icon matBadge="setup" matBadgeSize="small" matBadgePosition="before" matBadgeColor="accent" [matTooltip]="!isExpanded ? 'Payments' : null"
                                    matTooltipPosition="right">payments</mat-icon>
                                <p matLine *ngIf="isExpanded">Payments</p>
                            </a>
                        </ng-container>
                        <ng-template #default>
                            <a mat-list-item routerLinkActive="active-link"
                                class="hover" [stripeConnectButton]="{ stripeAccountId: user.stripeAccountId, linkType: 'login' }">
                                <mat-icon mat-list-icon matBadge="{{ !stripeStatus.charges ? 'attn' :  !stripeStatus.verify ? 'verify' : '' }}" matBadgeSize="small" matBadgePosition="before" matBadgeColor="{{ !stripeStatus.charges ? 'warn' :  !stripeStatus.verify ? 'verify' : 'primary' }}" [matTooltip]="!isExpanded ? 'Payments' : null"
                                    matTooltipPosition="right">payments</mat-icon>
                                <p matLine *ngIf="isExpanded">Payments</p>
                            </a>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let route of routeLinks">
                    <a *ngIf="!route.hide" mat-list-item routerLinkActive="active-link" class="hover"
                        routerLink="{{ route.link }}">
                        <mat-icon mat-list-icon [matTooltip]="!isExpanded ? route.name : null"
                            matTooltipPosition="right">
                            {{ route.icon }}</mat-icon>
                        <p matLine *ngIf="isExpanded">{{ route.name }}</p>
                    </a>
                </ng-container>
            </mat-list>
        </section>
    </mat-sidenav>
    <mat-sidenav-content [style.margin-left.px]="expandedWidth"
        *ngIf="noUserDataPages.includes(path.split('?')[0]) || (authService.signUpStatus$ | async) || ((noUserDataPages.includes('/artist') || noUserDataPages.includes('/browse') ) && !(authService.authenticated$ | async))">
        <!-- Check if the route is in the noUserDataPages routes array | Check the sign up status | Check if the route is the artist route and unauthenticated -->
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<div *ngIf="loaderService.loader$ | async" class="loader-container">
    <div *ngIf="loaderService.theme$ | async as theme" class="loader center-align text-center" [ngClass]="theme">
        <mat-spinner color="primary" strokeWidth="3" diameter="80" class="center-align"></mat-spinner>
        <h3 class="center-align">Loading</h3>
    </div>
</div>