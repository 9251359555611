import { AfterContentChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-settings-form',
  templateUrl: './show-settings-form.component.html',
  styleUrls: ['./show-settings-form.component.scss']
})
export class ShowSettingsFormComponent implements OnInit, AfterContentChecked, OnDestroy {

  @Input() userId: number; // user id that the show settings are for
  @Input() modifiedBy: string; // current authenticated user 
  @Input() showRequirements: any; // current show requirements data
  @Input() platformDefaults: any; // current platform defaults data

  @Output() showRequirementsFormOutput = new EventEmitter(); // emits the show requirements form data
  @Output() showRequirementsFormValidOutput = new EventEmitter(); // emits the show requirements form validation status

  leadBookingTimeDay: number = 0; // number of days for lead booking calculation
  leadBookingTimeHour: number = 0; // number of hours for lead booking calculation
  leadBookingTimeMinute: number = 0; // number of minutes for lead booking calculation

  minimumShowLengthHour: number = 0; // number of hours for minimum show length calculation
  minimumShowLengthMinute: number = 0; // number of minutes for minimum show length calculation

  maximumShowLengthHour: number = 0; // number of hours for maximum show length calculation
  maximumShowLengthMinute: number = 0; // number of minutes for maximum show length calculation

  // creates the show settings form
  showSettingsForm: FormGroup = this.formBuilder.group({
    userId: [''],
    leadBookingTime: [0, [Validators.required, Validators.min(15)]],
    minimumShowLength: [0, [Validators.required]],
    maximumShowLength: [0, [Validators.required]],
    pricePerHour: [0, [Validators.required, Validators.min(5)]],
    depositPercentage: [0, [Validators.required]],
    modifiedBy: ['']
  });;

  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {    

    if (this.showSettingsForm) {

      // if there are show requirements fill the values in the form group
      if (this.showRequirements) {

        this.showSettingsForm.controls['userId'].setValue(this.showRequirements.userId ? this.showRequirements.userId : this.userId);
        this.showSettingsForm.controls['leadBookingTime'].setValue(this.showRequirements.leadBookingTime ? this.showRequirements.leadBookingTime : 0);
        this.showSettingsForm.controls['minimumShowLength'].setValue(this.showRequirements.minimumShowLength ? this.showRequirements.minimumShowLength : 0);
        this.showSettingsForm.controls['maximumShowLength'].setValue(this.showRequirements.maximumShowLength ? this.showRequirements.maximumShowLength : 0);
        this.showSettingsForm.controls['pricePerHour'].setValue(this.showRequirements.pricePerHour ? this.showRequirements.pricePerHour : 0);
        this.showSettingsForm.controls['depositPercentage'].setValue(this.showRequirements.depositPercentage ? this.showRequirements.depositPercentage : 0);
        this.showSettingsForm.controls['modifiedBy'].setValue(this.modifiedBy);

        this.setGroupControlValues();
      }

      this.subscriptions.add(
        this.showSettingsForm.valueChanges.subscribe(
          (result) => {
            console.log(result);
            console.log(this.showSettingsForm.status);
            this.showRequirementsFormValidOutput.emit(this.showSettingsForm.status === 'VALID' ? true : false);
            if (this.showSettingsForm.status === 'VALID') {
              this.showRequirementsFormOutput.emit(result);
            }
          }
        )
      );

      this.showRequirementsFormValidOutput.emit(this.showSettingsForm.status === 'VALID' ? true : false);
    }
  }

  ngAfterContentChecked(){
    if (this.showSettingsForm) {

      if (this.platformDefaults) {
        this.showSettingsForm.controls['minimumShowLength'].addValidators([Validators.min(this.platformDefaults.showLengthMinutesMinimum), Validators.max(this.platformDefaults.showLengthMinutesMaximum)]);
        this.showSettingsForm.controls['maximumShowLength'].addValidators([Validators.min(this.platformDefaults.showLengthMinutesMinimum), Validators.max(this.platformDefaults.showLengthMinutesMaximum)]);
        this.showSettingsForm.controls['depositPercentage'].addValidators([Validators.min(this.platformDefaults.depositMinimumPercentage), Validators.max(this.platformDefaults.depositMaximumPercentage)]);
      }
    }
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description makes the calcualtions and sets the values in the hidden inputs for the grouped drop down lists
   */
  private setGroupControlValues() {
    let leadBookingTimeObject = this.timeConvert(this.showSettingsForm.controls['leadBookingTime'].value);

    this.leadBookingTimeDay = leadBookingTimeObject.days;
    this.leadBookingTimeHour = leadBookingTimeObject.hours;
    this.leadBookingTimeMinute = leadBookingTimeObject.minutes;

    let minimumShowLengthObject = this.timeConvert(this.showSettingsForm.controls['minimumShowLength'].value);

    this.minimumShowLengthHour = minimumShowLengthObject.hours;
    this.minimumShowLengthMinute = minimumShowLengthObject.minutes;

    let maximumShowLengthObject = this.timeConvert(this.showSettingsForm.controls['maximumShowLength'].value);

    this.maximumShowLengthHour = maximumShowLengthObject.hours;
    this.maximumShowLengthMinute = maximumShowLengthObject.minutes;

  }

  /**
   * @description updates the lead booking time hidden input
   */
  updateLeadBookingTimeInput() {
    this.showSettingsForm.controls['leadBookingTime'].markAsTouched();
    this.showSettingsForm.controls['leadBookingTime'].setValue((((this.leadBookingTimeDay * 24) + this.leadBookingTimeHour) * 60) + this.leadBookingTimeMinute);
  }

  /**
   * @description updates the minimum show length hidden input
   */
  updateMinimumShowLengthInput() {
    this.showSettingsForm.controls['minimumShowLength'].markAsTouched();
    this.showSettingsForm.controls['minimumShowLength'].setValue((this.minimumShowLengthHour * 60) + this.minimumShowLengthMinute);
  }

  /**
  * @description updates the maximum show length hidden input
  */
  updateMaximumShowLengthInput() {
    this.showSettingsForm.controls['maximumShowLength'].markAsTouched();
    this.showSettingsForm.controls['maximumShowLength'].setValue((this.maximumShowLengthHour * 60) + this.maximumShowLengthMinute);
  }

  /**
   * @description formats the deposit percentage label value
   * @param value { number }
   * @returns { string }
   */
  formatLabel(value: number) {
    return value + '%';
  }

  /**
   * @description converts timestamp into days, hours, minutes
   * @param time { number }
   * @returns { days: number, hours: number, minutes: number }
   */
  private timeConvert(time: number) {

    let d: number = Number((time / (24 * 60)).toFixed(0));
    let h: number = Number(((time % (24 * 60)) / 60).toFixed(0));
    let m: number = Number(((time % (24 * 60)) % 60).toFixed(0));

    return { days: d, hours: h, minutes: m };
  }

}
