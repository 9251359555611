import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { filter } from 'rxjs';

// services
import { ImageService } from 'src/app/global/services/image/image.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit, AfterViewInit {

  @ViewChild('scroller') scroller: CdkVirtualScrollViewport;

  @Input() type: string;
  @Input() status: string;
  @Input() events: any;
  @Input() scrollParams: any;

  @Output() VirtualScrollOutput = new EventEmitter(); // emits the virtual scroll length, offset and bottom reached params

  constructor(
    private imageService: ImageService,
    private ngZone: NgZone
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.scroller.elementScrolled().pipe(
      filter(() => {
        if (this.scroller.measureScrollOffset('bottom') === 0 && !this.scrollParams.bottomReached) {
          return true;
        } else {
          return false;
        }
      })
    ).subscribe(() => {
      this.ngZone.run(() => {
        this.scrollParams.offsetLength = this.scrollParams.offsetLength + this.scrollParams.limitLength;
        this.VirtualScrollOutput.emit({
          limitLength: this.scrollParams.limitLength,
          offsetLength: this.scrollParams.offsetLength,
          bottomReached: this.scrollParams.bottomReached
        });        
      });
    });
  }

  /**
   * @description gets the profile image in the correct size from S3
   * @param profileImageUrl 
   * @returns { string } image URL
   */
  getProfileImage(profileImageUrl: string) {
    return profileImageUrl ? this.imageService.formatImage(profileImageUrl)['80x80'] : '';
  }

}
