import { Injectable } from '@angular/core';
import { AsyncValidatorFn } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, first, switchMap } from 'rxjs';
import { StripeService } from '../services/stripe/stripe.service';

@Injectable()
export class StripeCouponValidator {

    constructor(
        private stripeService: StripeService,
    ) { }

    /**
     * @description Validates stripe coupon
     * @param userId 
     * @returns { [key: string]: any } whether the form control has an error of 'coupon' or null
     */
    coupon(userId: number): AsyncValidatorFn {

        return control => control.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(value => this.stripeService.getStripeCoupon(value)),
            map(
                (res: any) => {
                    console.log(res);
                    // check if the coupon is not valid and if it matches the stripe user that it belongs to
                    if (res) {
                        if ((res && res.validCoupon === false) || (!res.valid || res.metadata.userId !== String(userId))) {
                            return { 'coupon': true };
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }
            ),
            first()
        );
    }
}