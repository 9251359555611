<mat-divider></mat-divider>
<div mat-subheader>
    <span>{{mediaTypeLookup.name}}</span>
    <span class="spacer"></span>
    <button *ngIf="mediaTypeLookup.media && mediaTypeLookup.media.length > 0" class="icon-button" mat-raised-button color="primary"
        (click)="openDialog(mediaTypeLookup)"><mat-icon>add_photo_alternate</mat-icon> Add</button>
</div>
<div class="media-container" *ngIf="mediaTypeLookup.media && mediaTypeLookup.media.length > 0; else empty">
    <div class="item" *ngFor="let item of mediaTypeLookup.media">
        <mat-card class="mat-elevation-z6">
            <div class="content-container">
                <img mat-card-image src="{{ imageBaseUrl + item.mediaUrl }}" alt="Logo Image">
            </div>
            <mat-card-actions>
                <span class="default-media" [ngClass]="{ 'primary-bg':item.mediaDefault }">{{ item.mediaDefault ? 'Default' : ''  }}</span>
                <span class="spacer"></span>
                <button mat-icon-button [matMenuTriggerFor]="itemOptions" matTooltip="Options">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #itemOptions class="matMenu">                    
                    <button mat-menu-item *ngIf="!item.mediaDefault"
                    (click)="defaultMediaDialog(defaultMediaTemplate)">
                        <mat-icon>done</mat-icon>
                        <span>Make Default</span>
                    </button>
                    <button mat-menu-item (click)="deleteMediaDialog(deleteTemplate)">
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                    </button>
                </mat-menu>                
            </mat-card-actions>
        </mat-card>
        <ng-template #deleteTemplate>
            <h1 mat-dialog-title>Delete Media</h1>
            <div mat-dialog-content>
                <p>You are about to delete a media item for "{{ mediaTypeLookup.name }}".</p>
                <span>Are you sure?</span>
            </div>
            <div mat-dialog-actions>
                <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                    Cancel</button>
                <button class="icon-button" mat-button (click)="deleteMedia(item)" cdkFocusInitial
                    [disabled]="deleteMediaSaving"><mat-icon>delete</mat-icon>
                    Delete</button>
            </div>
        </ng-template>

        <ng-template #defaultMediaTemplate>
            <h1 mat-dialog-title>Suspend User</h1>
            <div mat-dialog-content>
                <p>You are about to make this media the default for "{{ mediaTypeLookup.name }}".</p>
                <span>Are you sure?</span>
            </div>
            <div mat-dialog-actions>
                <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                    Cancel</button>
                <button class="icon-button" mat-button (click)="defaultMedia(item)" cdkFocusInitial
                    [disabled]="defaultMediaSaving"><mat-icon>done</mat-icon>
                    Make Default</button>
            </div>
        </ng-template>
    </div>
</div>
<ng-template #empty>
    <div class="no-images">
        <span>
            <mat-icon mat-list-icon class="empty-image-list">photo_library</mat-icon>
            <h4>No Alternate {{ mediaTypeLookup.name }}'s!</h4>
            <button class="icon-button" mat-raised-button color="primary"
                (click)="openDialog(mediaTypeLookup)"><mat-icon>add_photo_alternate</mat-icon>
                Add</button>
            <br />
        </span>
    </div>
</ng-template>
<br/>