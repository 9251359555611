<form [formGroup]="tagForm">
    <h1 mat-dialog-title>{{data.mode | titlecase }} Tag</h1>
    <div mat-dialog-content>
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Tag Name</mat-label>
            <input matInput placeholder="Stairway to Heaven" formControlName="name" type="text">
            <mat-hint>Name of the tag.</mat-hint>
            <mat-error
                *ngIf="tagForm.controls['name'].invalid && (tagForm.controls['name'].dirty || tagForm.controls['name'].touched)">
                <ng-container *ngIf="tagForm.controls['title'].errors?.['required']">Tag name is
                    required.
                </ng-container>
            </mat-error>
        </mat-form-field>
        <br />
        <br />
    </div>
    <div mat-dialog-actions>
        <button class="icon-button" mat-button (click)="onCancelClick()">
            <mat-icon>close</mat-icon> Cancel
        </button>
        <button class="icon-button" mat-button [disabled]="!tagForm.valid || tagSaving" (click)="saveTag()"
            cdkFocusInitial>
            <mat-icon>save_alt</mat-icon> Save
        </button>
    </div>
</form>
