import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

// capacitor
import { Capacitor } from '@capacitor/core';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { UserService } from 'src/app/profile/services/user/user.service';
import { ImageService } from 'src/app/global/services/image/image.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';
import { SuccessMessageComponent } from 'src/app/global/components/success-message/success-message.component';

// constants
import { GlobalConstants } from 'src/app/global/constants/global';

// validators
import { PasswordValidation } from 'src/app/auth/validators/password';
import { UserFilterComponent } from '../user-filter/user-filter.component';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  @ViewChild('scroller') scroller: CdkVirtualScrollViewport;

  filters: Array<string> = []; // list of filters selected
  filterData: { searchText?: string, roles?: Array<string>, status?: Array<string> };

  isIos: boolean = false // flag to tell if device is ios;
  userListData: any; // user list
  user: any; // the authenticated user

  deleteUserDialogRef: any; // element reference to delete user dialog
  deleteUserSaving: boolean = false; // flag to tell if in the act of deleting user data

  suspendUserDialogRef: any; // element reference to suspend user dialog
  suspendUserSaving: boolean = false; // flag to tell if in the act of suspending user data

  restoreUserDialogRef: any; // element reference to restore user dialog
  restoreUserSaving: boolean = false; // flag to tell if in the act of restoring user data

  resetPasswordDialogRef: any; // element reference to reset password dialog
  resetPasswordSaving: boolean = false; // flag to tell if in the act of reseting password data

  manageRolesDialogRef: any; // element reference to manage roles dialog
  manageRolesSaving: boolean = false; // flag to tell if in the act of managing role data

  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template

  hide: boolean = true; // flag to tell input to show or hide password

  // creates the form group
  resetPasswordForm: FormGroup = this.formBuilder.group({
    password: ['', [Validators.required, PasswordValidation.passwordRequirements]],
    verifyPassword: ['', [Validators.required, PasswordValidation.passwordRequirements]]
  }, {
    validators: PasswordValidation.matchPassword // match password validation method
  });

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private imageService: ImageService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    // check if device is ios and set flag
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.globalConstants = GlobalConstants;
  }

  ngOnInit(): void {

    // show the loader
    this.loaderService.loaderSubject$.next(true);

    this.subscriptions.add(
      this.userService.filter$.subscribe(filter => {
        this.filters = [];
        if (filter) {
          if (filter.searchText != '') this.filters.push(filter.searchText);
          if (filter.roles.length > 0) {
            for (let role of filter.roles) {
              this.filters.push(role);
            }
          }
          if (filter.status.length > 0) {
            for (let status of filter.status) {
              this.filters.push(status);
            }
          }

          this.filterData = filter;
        }
        this.getUserList();
      })
    );


    // set the modifiedBy var to the authenticated users email
    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe((user: any) => {
        if (user) {
          this.user = user;
        }
      })
    );
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description opens the filter dialog
   */
  openFilterDialog() {
    const filterDialogRef = this.dialog.open(UserFilterComponent, {
      width: '500px',
      data: { filter: this.filterData, roleNames: this.user.roleNames }
    });

    this.subscriptions.add(
      filterDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
        // If the result is nothing they click off the dialog and we have no save or edit
        if (!result) {
          return;
        }
        else if (result.event === 'filter') {
          this.userService.filterSubject$.next(result.data);
        }
      })
    );
  }

  /**
  * @description clears the filters to show the whole list
  */
  clearFilters() {
    // this.resetBrowseArtists();
    this.userService.filterSubject$.next({ searchText: '', roles: [], status: [] });
  }

  /**
   * @description gets the image path from the image service
   * @param imagePath { string }
   * @returns { any }
   */
  getImage(imagePath: string) {
    return this.imageService.formatImage(imagePath)['80x80'];
  }

  /**
   * @description gets a list of all users from the database
   */
  getUserList() {
    // get the authenticated users profile data
    this.subscriptions.add(
      this.userService.getUserList(this.filterData).subscribe(
        {
          next: (res) => {
            console.log(res);
            if (!res) return; // stop if there is no result

            this.userListData = res.data; // set raw user list data

            // hide the loader
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            // hide the loader
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description updates the role in the user data to reflect the value of the checkbox
   * @param event { any } the value of the checkbox
   * @param role { any } the role thats being changed
   */
  updateRole(event: any, role: any) {
    role.checked = event.checked;
  }

  /**
  * @description opens the delete user dialog and sets up the close event
  * @param deleteTemplateRef { any }
  */
  deleteUserDialog(deleteTemplateRef: any): void {
    this.deleteUserDialogRef = this.dialog.open(deleteTemplateRef);

    this.subscriptions.add(
      this.deleteUserDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description opens the suspend user dialog and sets up the close event
   * @param suspendTemplateRef { any }
   */
  suspendUserDialog(suspendUserTemplateRef: any): void {
    this.suspendUserDialogRef = this.dialog.open(suspendUserTemplateRef);

    this.subscriptions.add(
      this.suspendUserDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description opens the restore user dialog and sets up the close event
   * @param suspendTemplateRef { any }
   */
  restoreUserDialog(restoreTemplateRef: any): void {
    this.restoreUserDialogRef = this.dialog.open(restoreTemplateRef);

    this.subscriptions.add(
      this.restoreUserDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description opens the restore reset password dialog and sets up the close event
   * @param resetPasswordTemplateRef { any }
   */
  resetPasswordDialog(resetPasswordTemplateRef: any): void {
    this.resetPasswordDialogRef = this.dialog.open(resetPasswordTemplateRef);

    this.subscriptions.add(
      this.resetPasswordDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description opens the restore manage roles dialog and sets up the close event
   * @param resetPasswordTemplateRef { any }
   */
  manageRolesDialog(manageRolesTemplateRef: any): void {
    this.manageRolesDialogRef = this.dialog.open(manageRolesTemplateRef);

    this.subscriptions.add(
      this.manageRolesDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description marks a user as deleted in the database
   * @param deleteuser { any }
   */
  deleteUser(deleteuser: any) {
    this.deleteUserSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('deleted user:', deleteuser);

    this.subscriptions.add(
      // this.userService.deleteUser(deleteuser, this.user.email).subscribe(
      this.userService.deleteUser(deleteuser).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.userListData = this.userListData.filter(user => user.id !== deleteuser.id);
            this.deleteUserDialogRef.close({ event: 'delete', data: res });
            this.deleteUserDialogRef = null;
            this.deleteUserSaving = false;
            //this.authService.getUserData(); // this updates the user data subject so that the most recent data is always displayed
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'User has been deleted!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.deleteUserSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description marks a user as suspended in the database
   * @param suspenduser { any }
   */
  suspendUser(suspenduser: any) {
    this.suspendUserSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('suspend user:', suspenduser);

    this.subscriptions.add(
      this.userService.suspendUser(suspenduser, this.user.email).subscribe(
        {
          next: (res) => {
            console.log(res);
            // update the user record in the list with the updated data
            this.userListData = this.userListData.map(u => u.id !== res.data.id ? u : res.data);
            this.suspendUserDialogRef.close({ event: 'suspend', data: res });
            this.suspendUserDialogRef = null;
            this.suspendUserSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'User has been suspended!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.suspendUserSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description marks a user as restored in the database
   * @param restoreuser { any }
   */
  restoreUser(restoreuser: any) {
    this.restoreUserSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('restore user:', restoreuser);

    this.subscriptions.add(
      this.userService.restoreUser(restoreuser, this.user.email).subscribe(
        {
          next: (res) => {
            console.log(res);
            // update the user record in the list with the updated data
            this.userListData = this.userListData.map(u => u.id !== res.data.id ? u : res.data);
            this.restoreUserDialogRef.close({ event: 'restore', data: res });
            this.restoreUserDialogRef = null;
            this.restoreUserSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'User has been restored!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.restoreUserSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description resets a users password in the database
   * @param user { any }
   */
  resetPassword(user: any) {
    this.resetPasswordSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('user:', user);

    let data = {
      id: user.id,
      email: user.email,
      password: this.resetPasswordForm.controls['password'].value
    };

    this.subscriptions.add(
      this.userService.resetPassword(data).subscribe(
        {
          next: (res) => {
            console.log(res);
            // update the user record in the list with the updated data
            this.resetPasswordDialogRef.close({ event: 'reset password', data: res });
            this.resetPasswordDialogRef = null;
            this.resetPasswordSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Password has been reset!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.resetPasswordSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description updates a users roles in the database
   * @param user { any }
   */
  manageRoles(user: any) {
    this.manageRolesSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('user:', user);

    let data = { id: user.id, roles: user.roles, email: this.user.email };

    console.log('data: ', data);

    this.subscriptions.add(
      this.userService.updateRoles(data).subscribe(
        {
          next: (res) => {
            console.log(res);
            // update the users roles in the list with the updated data
            this.userListData = this.userListData.map(u => u.id !== res.data.id ? u : res.data);
            this.manageRolesDialogRef.close({ event: 'manage roles', data: res });
            this.manageRolesDialogRef = null;
            this.manageRolesSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Roles have been updated!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.manageRolesSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

}
