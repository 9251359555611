import { Injectable } from "@angular/core";
import { Chromecast } from '@caprockapps/capacitor-chromecast';

// helpers
import { StreamControlsHelper } from "./streamControls.helper";

@Injectable()
export class StreamCastHelper {

    eventData: any;
    user: any;
    streamId: string;

    constructor(
        private streamControlsHelper: StreamControlsHelper
    ) {
    }

    /**
     * @description initalize the Chromecast API
     */
    async initializeCastApi(controlBarElement: any) {
        await Chromecast.initialize({ appId: 'A5FA8135' });
        //await this.setupChromeCastListeners(controlBarElement);
    };

    async setupChromeCastListeners(controlBarElement: any) {
        await Chromecast.addListener('SESSION_STARTED', async (res: any, ...args: any[]) => {
            console.log('Listener SESSION_STARTED args: ', args, ' res: ', res);
            this.streamControlsHelper.controlBarCast(res.isConnected, controlBarElement);
            await this.sendEventData(this.eventData, this.user, this.streamId);
        });
        await Chromecast.addListener('SESSION_ENDED', (res: any, ...args: any[]) => {
            console.log('Listener SESSION_ENDED args: ', args, ' res: ', res);
            this.streamControlsHelper.controlBarCast(res.isConnected, controlBarElement);
        });
        await Chromecast.addListener('SESSION_START_FAILED', (res: any, ...args: any[]) => {
            console.log('Listener SESSION_START_FAILED args: ', args, ' res: ', res);
            this.streamControlsHelper.controlBarCast(res.isConnected, controlBarElement);
        });
        await Chromecast.addListener('SESSION_RESUMED', (res: any, ...args: any[]) => {
            console.log('Listener SESSION_RESUMED args: ', args, ' res: ', res);
            this.streamControlsHelper.controlBarCast(res.isConnected, controlBarElement);
        });
        await Chromecast.addListener('RECEIVER_MESSAGE', async (res: any, ...args: any[]) => {
            console.log('Listener RECEIVER_MESSAGE args: ', args, ' res: ', res);
            if (res && JSON.parse(res[Object.keys(res)[0]].message)) {
                const msg = JSON.parse(res[Object.keys(res)[0]].message);
                if (msg && msg.type === 'toonballoon' && msg.receiver) {
                    if (msg.receiver === 'streamId') {
                        //Attempt to send eventData when the receiver requests it
                        await this.sendEventData(this.eventData, this.user, this.streamId);
                    }
                }
            }
        });
    }

    async setCastHelperData(eventData: any = null, user: any = null, streamId: string = null) {
        this.eventData = eventData ?? this.eventData;
        this.user = user ?? this.user;
        this.streamId = streamId ?? this.streamId;
    }

    /**
     * @description this starts the chromecast
     */
    async initiateCast() {
        await Chromecast.requestSession();
    };

    /**
     * @description send a message to the chromecast device
     * @param message { string }
     */
    async sendMessage(message) {
        await Chromecast.sendMessage({ namespace: 'urn:x-cast:com.example.cast.mynamespace', message: JSON.stringify(message) });
    }

    // /**
    //  * @description sends the stream Id to the cast device
    //  */
    async sendStreamId(streamId: string) {
        this.sendMessage({ "type": "toonballoon", "caster": streamId });
    }

    /**
     * @description send the event data to the cast device
     */
    async sendEventData(eventData: any, user: any, streamId: string) {

        this.sendMessage({ "type": "toonballoon", "eventData": eventData, "user": user, "streamId": streamId });
    }

}