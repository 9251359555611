// modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// services
import { TokenService } from 'src/app/auth/services/token/token.service';
import { HttpBaseService } from 'src/app/global/services/http-base/http-base.service';

// environments
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SongService extends HttpBaseService<any> {

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/song`, _http, _tokenService);
   }

  /**
   * @description creates a user song record in the database
   * @param songData { any }
   * @returns { Observable<any> } user song data result
   */
  createUserSong(songData: any): Observable<any> {
    return this.post(songData);
  }

  /**
   * @description marks a user song record in the database as deleted
   * @param songData { any }
   * @returns { Observable<any> } user song data result
   */
  deleteUserSong(songData: any): Observable<any> {
    return this.put(songData, '/delete');
  }
}
