import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

// services
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { EventService } from 'src/app/event/services/event/event.service';
import { ImageService } from 'src/app/global/services/image/image.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

// constants
import { GlobalConstants } from 'src/app/global/constants/global';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {

  @Input() eventId?: number;
  @Input() user?: any;
  @Input() modifiedBy?: string;
  @Input() eventStatusLookupId?: number;
  @Input() comments: any;  

  // creates the profile form
  commentForm: FormGroup = this.formBuilder.group({
    eventId: [''],
    userId: [''],
    modifiedBy: [''],
    statusLookupId: [''],
    comment: ['', [Validators.required]]
  });

  addCommentDialogRef: any; // element reference to add comment dialog
  commentSaving: boolean = false; // flag to disable save button to prevent multiple clicks

  globalConstants: any;

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    public dialog: MatDialog,
    public formBuilder: UntypedFormBuilder,
    private imageService: ImageService,
    private loaderService: LoaderService,
    private eventService: EventService,
    private snackBar: MatSnackBar
  ) { 
    this.globalConstants = GlobalConstants;
  }

  ngOnInit(): void {
    this.commentForm.controls['eventId'].setValue(this.eventId);    
    this.commentForm.controls['modifiedBy'].setValue(this.modifiedBy);
    this.commentForm.controls['statusLookupId'].setValue(1);

    if(this.user){
      this.commentForm.controls['userId'].setValue(this.user.id);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description opens the add comment dialog and sets up the close event
   * @param addCommentDialogRef { any }
   */
  addCommentDialog(addCommentTemplateRef: any): void {
    this.addCommentDialogRef = this.dialog.open(addCommentTemplateRef, { width: '500px' });

    this.subscriptions.add(
      this.addCommentDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
        result.data.__user__ = this.user
        this.comments.push(result.data);
      })
    );
  }

  saveComment() {

    this.commentSaving = true;
    this.loaderService.loaderSubject$.next(true);

    this.subscriptions.add(
      this.eventService.createComment(this.commentForm.value).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.addCommentDialogRef.close({ data: res.data });
            this.loaderService.loaderSubject$.next(false);            
          },
          error: (e) => {
            console.error(e);
            this.commentSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );

  };

  getImage(imagePath: string){
    return this.imageService.formatImage(imagePath)['80x80'];
  }

}
