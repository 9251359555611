import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

// services
import { StripeService } from 'src/app/global/services/stripe/stripe.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';

// components
import { DiscountCodeFormComponent } from '../discount-code-form/discount-code-form.component';
import { AuthService } from 'src/app/auth/services/auth/auth.service';

@Component({
  selector: 'app-discount-codes',
  templateUrl: './discount-codes.component.html',
  styleUrls: ['./discount-codes.component.scss']
})
export class DiscountCodesComponent implements OnInit {

  discountCodeData: any = []; // discount code list for the current user

  deleteDiscountCodeDialogRef: any; // element reference to delete discount code dialog
  deleteDiscountCodeSaving: boolean = false; // flag to tell if in the act of deleting a discount code

  currentUserId: string;

  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private stripeService: StripeService,
    private loaderService: LoaderService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

    // gets the current logged in user
    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe(user => {
        if (user) {
          this.currentUserId = String(user.id);
        }
      })
    );

    // set the modifiedBy var to the authenticated users email
    this.subscriptions.add(
      this.stripeService.getStripeAccountDiscountCodes('').subscribe(discountCodes => {
        if (discountCodes && discountCodes.data && discountCodes.data.length > 0) {
          this.discountCodeData = discountCodes.data.filter(discountCode => discountCode.metadata.userId === this.currentUserId);
        }
      })
    );
  }

  /**
   * @description opens the discount code dialog with data based on mode and set up the close event
   * @param mode { string }
   * @param discountCode { any }
   */
  openDiscountCodeDialog(mode: string, discountCode?: any): void {

    let discountCodeData: any;

    if (discountCode) {
      discountCode.userId = this.currentUserId;
    } else {
      discountCode = {
        userId: this.currentUserId
      };
    }

    discountCodeData = { mode: mode, discountCode: discountCode };

    const discountCodeFormDialogRef = this.dialog.open(DiscountCodeFormComponent, {
      width: '500px',
      data: discountCodeData,
    });

    this.subscriptions.add(
      discountCodeFormDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
        // If the result is nothing they click off the dialog and we have no save or edit
        if (!result) {
          return;
        }
        else if (result.event === 'add') {
          this.discountCodeData.push(result.data);
        } else if (result.event === 'edit') {
          let discountCodeToReplace = this.discountCodeData.find(discountCode => discountCode.id === result.data.id);
          Object.assign(discountCodeToReplace, result.data);
        }
      })
    );
  }

  /**
   * @description opens the delete discount code dialog and sets up the close event
   * @param deleteTemplateRef { any }
   */
  deleteDiscountCodeDialog(deleteTemplateRef: any): void {
    this.deleteDiscountCodeDialogRef = this.dialog.open(deleteTemplateRef);

    this.subscriptions.add(
      this.deleteDiscountCodeDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description marks a song as deleted in the database
   * @param deletesong { any }
   */
  deleteDiscountCode(id: string) {
    this.deleteDiscountCodeSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('deleted discount code:', id);

    this.subscriptions.add(
      this.stripeService.deleteStripeAccountDiscountCode(id).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.discountCodeData = this.discountCodeData.filter(discountCode => discountCode.id !== id);
            this.deleteDiscountCodeDialogRef.close({ event: 'delete', data: res });
            this.deleteDiscountCodeDialogRef = null;
            this.deleteDiscountCodeSaving = false;
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            this.deleteDiscountCodeSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

}
