import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';

// environment
import { environment } from 'src/environments/environment';

// services
import { TokenService } from 'src/app/auth/services/token/token.service';
import { HttpBaseService } from '../http-base/http-base.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService extends HttpBaseService<any> {

  stripeAccountStatusSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // subject with boolean value to tell is users stripe account status
  stripeAccountStatus$: Observable<any> = this.stripeAccountStatusSubject$.asObservable(); // stripe account status subject observable

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService,
    private afd: AngularFireDatabase
  ) {
    super(`${environment.nodeServerUrl}/stripe/`, _http, _tokenService);
  }

  ngOnDestroy(): void {
    // unsubscribes all base service subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description gets the stripe connect url for the current user.
   * @returns { string } containing the url
   */
  getStripeConnectUrl(stripeAccountId: string, linkType: string): string {
    return `${environment.nodeServerUrl}/stripe/accountlink?id=${stripeAccountId}&linkType=${linkType}`;
  }

  /**
   * @description gets the stripe account status for the current user.
   * @param stripeAccountId { string }
   * @returns { Observable<any> } containing object with status
   */
  getStripeAccountStatus(stripeAccountId: string): Observable<any> {

    this.subscriptions.add(
      this.get('accountstatus', { id: stripeAccountId }).subscribe(res => {
        console.log('accountStatus: ', res);
        this.afd.database.ref('accountStatus/' + stripeAccountId).set(res);
      })
    );

    return this.afd.object(`accountStatus/${stripeAccountId}`).valueChanges().pipe(
      switchMap(res => {
        let stripeStatus: { setup: boolean, charges: boolean, verify: boolean } = { setup: true, charges: true, verify: true };
        if (res) {
          let stripeAccountStatus: any = res;

          // return new Promise((resolve, reject) => {
          if (stripeAccountStatus) {
            stripeStatus.setup = stripeAccountStatus.details_submitted;

            if (!stripeAccountStatus.charges_enabled ||
              (stripeAccountStatus.requirements &&
                (stripeAccountStatus.requirements?.currently_due?.length > 0 ||
                  stripeAccountStatus.requirements?.past_due?.length > 0
                )
              )
            ) {
              stripeStatus.charges = false;
            }

            // if (stripeAccountStatus.requirements && stripeAccountStatus.requirements.pending_verification.length > 0) {
            //   stripeStatus.verify = false;
            // }

            //     resolve(stripeStatus);
            //   } else {
            //     reject({ error: 'No stripe account status.' });
            //   }
            // });
          }
        }
        return of(stripeStatus);
      })
    );
  }

  /**
   * @description gets the stripe account discount code list for the current user.
   * @param stripeAccountId { string }
   * @returns { Observable<any> } containing object with discount codes
   */
  getStripeAccountDiscountCodes(stripeAccountId: string): Observable<any> {

    return this.get('discountcodes', { id: stripeAccountId });

  }

  /**
   * @description gets the stripe coupon.
   * @param couponCode { string }
   * @returns { Observable<any> } containing object with coupon
   */
  getStripeCoupon(couponCode: string): Observable<any> {
    if (couponCode && couponCode !== '') {
      return this.get('coupon', { couponcode: couponCode });
    } else {
      return of(null);
    }

  }

  /**
   * @description creates the stripe account discount code.
   * @param discountCode { any }
   * @returns { Observable<any> } containing object with discount code
   */
  createStripeAccountDiscountCode(discountCode: any): Observable<any> {
    return this.post({ discountCode: discountCode }, 'creatediscountcode');
  }

  /**
   * @description deletes a stripe account discount code.
   * @param id { string } the stripe coupon id
   * @returns { Observable<any> } containing object with discount code delete result
   */
  deleteStripeAccountDiscountCode(id: string): Observable<any> {
    return this.put({ id: id }, 'deletediscountcode');
  }

  /**
   * @description creates the stripe checkout session.
   * @param data { any }
   * @returns { Observable<any> } containing object with checkout session
   */
  createStripeCheckoutSession(data: any): Observable<any> {
    return this.post(data, 'createcheckoutsession');
  }

}
