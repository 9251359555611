import { Directive, EventEmitter, HostListener, Input, NgZone, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// capacitor
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Capacitor } from '@capacitor/core';

// services
import { StripeService } from '../../services/stripe/stripe.service';
import { EventService } from 'src/app/event/services/event/event.service';
import { LoaderService } from '../../services/loader/loader.service';

// constants
import { GlobalConstants } from '../../constants/global';

@Directive({
  selector: '[stripeCheckoutButton]'
})
export class StripeCheckoutButtonDirective {

  @Output() checkoutSavingEvent: EventEmitter<boolean> = new EventEmitter(); // emits the checkout event
  @Input() stripeCheckoutButton: any; // the stripe checkout button element

  isNative: boolean = false; // flag to tell if native app

  constructor(
    private iab: InAppBrowser,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private stripeService: StripeService,
    private eventService: EventService
  ) {
    this.isNative = Capacitor.isNativePlatform(); // sets the native flag based on if a native app or not
  }

  /**
   * @description listens to the click event of the element it is added to and then gets the stripe checkout url from the service and navigates to it.
   */
  @HostListener('click') onClick() {
    // build link and navigate to it
    console.log('Stripe checkout button clicked.');
    this.createEvent();
    // this.checkout()
  }

  /**
   * @description creates and runs the stripe checkout event
   */
  private createEvent() {

    this.loaderService.loaderSubject$.next(true);

    this.checkoutSavingEvent.emit(true);

    let eventData: any = {
      bookedUserId: this.stripeCheckoutButton.bookedUserId,
      bookingUserId: this.stripeCheckoutButton.bookingUserId,
      totalPrice: this.stripeCheckoutButton.totalPrice,
      depositAmount: this.stripeCheckoutButton.depositAmount,
      dateTimeStart: this.stripeCheckoutButton.eventStartTime.toISOString(),
      dateTimeEnd: this.stripeCheckoutButton.eventEndTime.toISOString(),
      dayOfTheWeekLookupId: this.stripeCheckoutButton.dayOfTheWeekLookupId,
      modifiedBy: this.stripeCheckoutButton.modifiedBy,
      statusLookupId: GlobalConstants.activeStatus.id
    }

    if (this.stripeCheckoutButton.discountedPrice > 0) {
      eventData.totalPrice = this.stripeCheckoutButton.discountedPrice;
    }

    this.eventService.createEvent(eventData).subscribe(
      {
        next: (res: any) => {
          console.log(res);
          if (!res || !res.data || !res.data.id) return; // stop if there is no result

          // create the event ststus history for the new event    
          this.createEventStatusHistory(res.data.id);

        },
        error: (e) => {
          console.error(e);
          this.checkoutSavingEvent.emit(false);
          this.loaderService.loaderSubject$.next(false);
        },
        complete: () => console.info('Event creation complete')
      }
    );
  }

  /**
   * @description creates and updates the stripe event status history
   * @param eventId { any }
   */
  private createEventStatusHistory(eventId: any) {

    let eventStatusHistoryData = {
      userId: this.stripeCheckoutButton.bookingUserId,
      statusLookupId: GlobalConstants.activeStatus.id,
      modifiedBy: this.stripeCheckoutButton.modifiedBy,
      eventStatusLookupId: GlobalConstants.pendingEventStatus.id,
      eventId: eventId
    }

    this.eventService.createEventStatusHistory(eventStatusHistoryData).subscribe(
      {
        next: (res: any) => {
          console.log(res);
          if (!res || !res.data || !res.data.id) return; // stop if there is no result

          // Go to checkout here        
          this.checkout(eventId)

        },
        error: (e) => {
          console.error(e);
          this.checkoutSavingEvent.emit(false);
          this.loaderService.loaderSubject$.next(false);
        },
        complete: () => console.info('Event status history creation complete')
      }
    );
  }

  /**
   * @description starts the stripe checkout for the event id sent in
   * @param eventId { number }
   */
  private checkout(eventId: number) {

    let checkoutData: any = {
      amount: this.stripeCheckoutButton.totalPrice * 100,
      customerId: this.stripeCheckoutButton.customerId,
      stripeAccountId: this.stripeCheckoutButton.stripeAccountId,
      backUrl: this.stripeCheckoutButton.backUrl,
      successUrl: this.stripeCheckoutButton.successUrl,
      eventId: eventId
    }

    if (this.stripeCheckoutButton.couponCode) {
      checkoutData.couponCode = this.stripeCheckoutButton.couponCode;
    }

    this.stripeService.createStripeCheckoutSession(checkoutData).subscribe(
      {
        next: (res) => {
          console.log(res);
          if (!res) return; // stop if there is no result

          // Go to checkout here
          console.log('Stripe checkout Url: ', res.url);

          // check if it running in a native platform
          if (this.isNative) {

            const browser = this.iab.create(res.url, '_blank', 'location=no,fullscreen=no,toolbar=no');

            browser.on('loadstart').subscribe(event => {
              console.log(event);
              // browser.executeScript({code: "alert('loadstart! I am an alert box!!')"});
              if (event && event.url) {
                if (event.url.includes('http://localhost:4200') || event.url.includes('https://dev.toonballoon.com') || event.url.includes('https://app.toonballoon.com')) {
                  if (event.url.includes('bookings')) {
                    this.ngZone.run(() => {
                      // Bring event back inside Angular's zone                     
                      this.router.navigate([`../../bookings`], { relativeTo: this.route });
                    });
                  }
                  browser.close();
                  this.ngZone.run(() => {
                    // Bring event back inside Angular's zone 
                    this.loaderService.loaderSubject$.next(false);
                  });
                }
              }
            });
          } else {
            location.href = res.url;
          }

        },
        error: (e) => {
          console.error(e);
          this.checkoutSavingEvent.emit(false);
          this.loaderService.loaderSubject$.next(false);
        },
        complete: () => console.info('checkout complete')
      }
    );
  }

}
