import { AfterViewInit, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { filter, first, Subscription } from 'rxjs';

// environments
import { environment } from 'src/environments/environment';

// capacitor
import { Capacitor } from '@capacitor/core';

// services
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { UserService } from 'src/app/profile/services/user/user.service';
import { BrowseService } from '../../services/browse/browse.service';

// components
import { FilterComponent } from '../filter/filter.component';

// pipes
import { CurrencyPipe } from '@angular/common';


@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss'],
})
export class BrowseComponent implements OnInit, AfterViewInit {

  @ViewChild('scroller') scroller: CdkVirtualScrollViewport;

  filters: Array<string> = []; // list of filters selected
  filterData: any;
  isIos: boolean = false; // flag to tell if device is ios;
  artists: Array<any> = []; // the list of artists to show  
  imageBaseUrl: string; // the base url for the listing images

  limitLength: number = 20; // how many items to pull at once
  offsetLength: number = 0; // how many items to skip
  bottomReached: boolean = false; // tells the infinite scroll to stop

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy  
  private scrollerSubscription: Subscription;  // parent subscription list that contains all subscriptions to make cleanup easy  
  private previousBrowseParams: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    private browseService: BrowseService,
    private userService: UserService,
    public dialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    private ngZone: NgZone
  ) {
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.imageBaseUrl = environment.imageBaseUrl;
  }

  ngOnInit(): void {

    this.subscriptions.add(
      this.browseService.filter$.subscribe(filter => {
        this.filters = [];
        if (filter) {
          if (filter.searchText != '') this.filters.push(filter.searchText);
          if (filter.dateFilter != '') this.filters.push(filter.dateFilter);
          if (filter.price != '') this.filters.push(this.currencyPipe.transform(filter.price, 'USD') + '/hr');
          if (filter.distance != '') this.filters.push(filter.distance + 'mi');

          this.filterData = filter;
        }

        this.browseArtists();
      })
    );


  }

  ngAfterViewInit(): void {
    this.bindScroller();
  }


  unbindScroller(): void {
    if (this.scrollerSubscription) this.scrollerSubscription.unsubscribe();
  }
  bindScroller(): void {
    this.unbindScroller();
    this.scrollerSubscription = this.scroller.elementScrolled().pipe(
      filter(() => {        
        if (this.scroller.measureScrollOffset('bottom') === 0 && !this.bottomReached) {          
          return true;
        } else {
          return false;
        }
      })
    ).subscribe(() => {
      this.ngZone.run(() => {
        this.browseArtists();
      });
    });
  }

  ngOnDestoy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description resets the list to a fresh state
   */
  private resetBrowseArtists() {
    this.artists = [];
    this.filterData = null;
    this.offsetLength = 0;
    this.bottomReached = false;
    this.ngZone.run(() => {
      this.scroller.scrollTo({
        top: 0
      });
    });
  }

  /**
   * @description navigates to the schedule event page
   * @param artistId { number } the artist id that the user is wanting to schedule
   */
  scheduleEvent(artistId: number) {
    console.log('schedule artist: ', artistId);
    this.router.navigate([`../schedule`, artistId], { relativeTo: this.route });
  }

  /**
   * @description clears the filters to show the whole list
   */
  clearFilters() {
    this.resetBrowseArtists();
    this.browseService.filterSubject$.next({ searchText: '', dateFilter: '', price: '', distance: '' });
  }

  /**
   * @description opens the filter dialog
   */
  openFilterDialog() {
    const filterDialogRef = this.dialog.open(FilterComponent, {
      width: '500px',
      data: this.filterData
    });

    filterDialogRef.afterClosed().pipe(first()).subscribe(result => {
      console.log('The dialog was closed:', result);
      // If the result is nothing they click off the dialog and we have no save or edit
      if (!result) {
        return;
      }
      else if (result.event === 'filter') {
        this.resetBrowseArtists();
        this.browseService.filterSubject$.next(result.data);
      }
    });
  }

  /**
   * @description this sets the list of the artists that the user can browse
   */
  private async browseArtists() {

    // show the loader
    this.loaderService.loaderSubject$.next(true);

    let userId = await this.userService.getUserId(); // get the current user id
    //If we haven't changed params don't run the next request
    if (JSON.stringify({ userId: userId, limitLength: this.limitLength, offsetLength: this.offsetLength, filter: this.filterData }) === JSON.stringify(this.previousBrowseParams)) return;
    this.previousBrowseParams = { userId: userId, limitLength: this.limitLength, offsetLength: this.offsetLength, filter: this.filterData };
    this.subscriptions.add(
      this.browseService.getBrowseArtists(userId, this.limitLength, this.offsetLength).pipe(first()).subscribe(artists => {

        if (artists && artists.data && artists.data && artists.data.length > 0) {
          this.offsetLength = this.offsetLength + artists.data.length;
          console.log('Artists: ', artists.data);
          //Concat with filter for existing items
          this.artists = this.artists.concat(artists.data.filter(x => !this.artists.some(y => y.id === x.id)));
          this.loaderService.loaderSubject$.next(false);

        }
        else {
          this.bottomReached = true;
          this.loaderService.loaderSubject$.next(false);
        }
      })
    );

  }

}
