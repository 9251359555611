<mat-list>
    <div mat-subheader>
        <span>Comments</span>
        <button *ngIf="comments && comments.length > 0 && user && eventId && modifiedBy" [disabled]="(eventStatusLookupId !== globalConstants.liveEventStatus.id && eventStatusLookupId !== globalConstants.completeEventStatus.id)"
            class="icon-button right-button" mat-button type="button" (click)="addCommentDialog(addCommentTemplate)">
            <mat-icon>add_comment</mat-icon> Add
        </button>
    </div>
    <mat-divider></mat-divider>
    <ng-container *ngIf="comments && comments.length > 0; else nocomments">
        <mat-card class="comment-card" *ngFor="let comment of comments">
            <mat-card-header>
                <div mat-card-avatar class="header-image"
                    [ngStyle]="{'background-image':  'url(' + getImage(comment.__user__.profileImageUrl) + ')'}"></div>
                <mat-card-title>{{comment.__user__.displayName}}</mat-card-title>
                <mat-card-subtitle class="comment-date">{{ comment.updatedAt | date: 'medium' }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                {{comment.comment}}
            </mat-card-content>
        </mat-card>
    </ng-container>
    <ng-template #nocomments>
        <div class="no-comments">
            <span *ngIf="user && eventId && modifiedBy; else emptyComments">
                <mat-icon mat-list-icon class="empty-comments">speaker_notes_off</mat-icon>
                <h3>Add comments after the event starts.</h3>
                <button [disabled]="(eventStatusLookupId !== globalConstants.liveEventStatus.id && eventStatusLookupId !== globalConstants.completeEventStatus.id)" class="icon-button" mat-raised-button color="primary"
                    (click)="addCommentDialog(addCommentTemplate)">
                    <mat-icon>add_comment</mat-icon> Add
                </button>
            </span>
            <ng-template #emptyComments>
                <span>
                    <mat-icon mat-list-icon class="empty-comments">speaker_notes_off</mat-icon>
                    <h3>No comments yet.</h3>
                </span>
            </ng-template>
        </div>
    </ng-template>
</mat-list>

<ng-template #addCommentTemplate>
    <form [formGroup]="commentForm">
        <h1 mat-dialog-title>Add Comment</h1>
        <div mat-dialog-content>
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Comment</mat-label>
                <input type="hidden" formControlName="eventId" />
                <input type="hidden" formControlName="userId" />
                <input type="hidden" formControlName="modifiedBy" />
                <input type="hidden" formControlName="statusLookupId" />
                <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" placeholder=""
                    formControlName="comment" type="text"></textarea>
                <mat-hint>Give this artist some feedback.</mat-hint>
                <mat-error
                    *ngIf="commentForm.controls['comment'].invalid && (commentForm.controls['comment'].dirty || commentForm.controls['comment'].touched)">
                    <ng-container *ngIf="commentForm.controls['comment'].errors?.['required']">Comment is
                        required.
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button class="icon-button" mat-button mat-dialog-close>
                <mat-icon>close</mat-icon>Close
            </button>
            <button class="icon-button" mat-button (click)="saveComment()" cdkFocusInitial
                [disabled]="!commentForm.valid || commentSaving">
                <mat-icon>add_comment</mat-icon> Post Comment
            </button>
        </div>
    </form>
</ng-template>