// modules
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatestWith, map, Observable } from 'rxjs';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private location: Location,
    private router: Router
  ) { }

  /**
   * @description checks if user is authenticated before allowing them to navigate to the requested route
   * @param route { ActivatedRouteSnapshot }
   * @param state { RouterStateSnapshot }
   * @returns { Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree }
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.authCheck().pipe(
      combineLatestWith(this.authService.authenticated$, this.authService.authenticatedUserRole$),
      map(([check, auth, roles]) => {
        let returnValue: boolean = true;

        let routeUrlString = route && route.url.length > 0 && route.url[0] ? route.url[0].toString() : '';
        // if user is not authenticated and if the path is not sign-in route to sign in else continue
        if (!auth && routeUrlString !== 'sign-in' && routeUrlString !== 'browse' && routeUrlString !== 'artist') {   
            returnValue = false;
            this.router.navigate(['/sign-in']);
        } else {
          // if authenticated check the roles to make sure user has access if not route to access denied else continue
          if (route.data.role && !roles.includes(route.data.role)) {
            returnValue = false;
            this.router.navigate(['/access-denied'])
          }
        }
        return returnValue;
      })
    )
  }
}
