import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

// environment
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/global/constants/global';

// components
import { ImageCropperComponent } from 'src/app/global/components/image-cropper/image-cropper.component';
import { SuccessMessageComponent } from 'src/app/global/components/success-message/success-message.component';
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

// servcies
import { PlatformMediaService } from '../../services/platform-media/platform-media.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnDestroy {

  @Input() mediaTypeLookup: any; // the media type you are creating
  @Input() user: any; // the user creating the media

  deleteMediaDialogRef: any; // element reference to delete media dialog
  deleteMediaSaving: boolean = false; // flag to tell if in the act of deleting media data

  defaultMediaDialogRef: any; // element reference to default media dialog
  defaultMediaSaving: boolean = false; // flag to tell if in the act of changing default media data

  imageBaseUrl: string; // the base url for the listing images
  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    public dialog: MatDialog,
    private loaderService: LoaderService,
    private snackBar: MatSnackBar,
    private platformMediaService: PlatformMediaService
  ) {
    this.globalConstants = GlobalConstants;
    this.imageBaseUrl = environment.imageBaseUrl;
  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description create a media item that the platform uses as a image on a page
   * @param image { string }
   */
  private createPlatformMedia(image: string) {
    let media = {
      userId: this.user.id,
      mediaType: this.mediaTypeLookup.type,
      statusLookipId: this.globalConstants.activeStatus.id,
      modifiedBy: this.user.email,
      mediaUrl: image,
      mediaTypeLookupId: this.mediaTypeLookup.id,
      mediaDefault: false
    };
    this.subscriptions.add(
      this.platformMediaService.createPlatformMedia(media).subscribe(res => {
        console.log('media added: ', res.data);
        this.mediaTypeLookup.media.push(res.data);
      })
    );
  }

  /**
   * @description opens the image cropper dialog popup
   */
  openDialog(mediaTypeLookup: any) {
    const dialogRef = this.dialog.open(ImageCropperComponent, { data: { mode: mediaTypeLookup.type, aspectRatio: (mediaTypeLookup.width / mediaTypeLookup.height), width: mediaTypeLookup.width, height: mediaTypeLookup.height } });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result.event === 'done') {
          console.log(`Dialog result: ${result.data}`);
          this.createPlatformMedia(result.data);
        }
      })
    );
  }

  /**
  * @description opens the delete media dialog and sets up the close event
  * @param deleteTemplateRef { any }
  */
  deleteMediaDialog(deleteTemplateRef: any): void {
    this.deleteMediaDialogRef = this.dialog.open(deleteTemplateRef);

    this.subscriptions.add(
      this.deleteMediaDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
  * @description opens the default media dialog and sets up the close event
  * @param defaultMediaTemplateRef { any }
  */
  defaultMediaDialog(defaultMediaTemplateRef: any): void {
    this.defaultMediaDialogRef = this.dialog.open(defaultMediaTemplateRef);

    this.subscriptions.add(
      this.defaultMediaDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description marks a user as deleted in the database
   * @param deletemedia { any }
   */
  deleteMedia(deletemedia: any) {
    this.deleteMediaSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('deleted user:', deletemedia);

    this.subscriptions.add(
      this.platformMediaService.deleteMedia(deletemedia, this.user.email).subscribe(
        {
          next: (res) => {
            console.log(res);
            // this.userListData = this.userListData.filter(user => user.id !== deletemedia.id);
            this.deleteMediaDialogRef.close({ event: 'delete', data: res });
            this.deleteMediaDialogRef = null;
            this.deleteMediaSaving = false;
            //this.authService.getUserData(); // this updates the user data subject so that the most recent data is always displayed
            this.mediaTypeLookup.media.pop(deletemedia);
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Media deleted!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.deleteMediaSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
  * @description marks a user as suspended in the database
  * @param defaultmedia { any }
  */
  defaultMedia(defaultmedia: any) {
    this.defaultMediaSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('suspend user:', defaultmedia);

    this.subscriptions.add(
      this.platformMediaService.defaultMedia(defaultmedia, this.user.email).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.defaultMediaDialogRef.close({ event: 'suspend', data: res });
            this.defaultMediaDialogRef = null;
            this.defaultMediaSaving = false;           

            // remove the flag from old items
            for (let item of this.mediaTypeLookup.media) {
              item.mediaDefault = false;
            }

            // add flag to new item
            defaultmedia.mediaDefault = true;

            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Default media changed!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.defaultMediaSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

}
