<form [formGroup]="profileForm">
    <input type="hidden" formControlName="email">
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
        <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
            <div class="profile-image-container">
                <img *ngIf="croppedImage && croppedImage !== ''" class="profile-image" [src]="croppedImage" />
                <button *ngIf="!croppedImage || croppedImage === ''" class="upload-photo-button" mat-raised-button
                    color="primary" (click)="openDialog()">Upload Photo</button>
                <button *ngIf="croppedImage && croppedImage !== ''" class="edit-photo-button" mat-mini-fab color="primary" (click)="openDialog()">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
        <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">            
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Display Name</mat-label>
                <input matInput placeholder="Rockstars" formControlName="displayName" type="text">
                <mat-hint>The name you want others to see</mat-hint>
                <mat-error
                    *ngIf="profileForm.controls['displayName'].invalid && (profileForm.controls['displayName'].dirty || profileForm.controls['displayName'].touched)">
                    <ng-container *ngIf="profileForm.controls['displayName'].errors?.['required']">Display name is
                        required.
                    </ng-container>
                </mat-error>
            </mat-form-field>
            <br />
            <br />
            <ng-container *ngIf="!userProfile; else zip">
                <mat-form-field appearance="outline" color="primary">
                    <mat-label>Bio</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" placeholder=""
                        formControlName="bio" type="text"></textarea>
                    <mat-hint>Tell everyone a little about what you play and why you love music.</mat-hint>
                    <mat-error
                        *ngIf="profileForm.controls['bio'].invalid && (profileForm.controls['bio'].dirty || profileForm.controls['bio'].touched)">
                        <ng-container *ngIf="profileForm.controls['bio'].errors?.['required']">Bio is
                            required.
                        </ng-container>
                    </mat-error>
                </mat-form-field>
                <br />
                <br />
            </ng-container>
            <ng-template #zip>
                <mat-form-field appearance="outline" color="primary">
                    <mat-label>Zip Code</mat-label>
                    <input matInput placeholder="90210" formControlName="zipCode" type="number">
                    <mat-hint>Helps users search and support local artists.</mat-hint>
                    <mat-error
                        *ngIf="profileForm.controls['zipCode'].invalid && (profileForm.controls['zipCode'].dirty || profileForm.controls['zipCode'].touched)">
                        <ng-container *ngIf="profileForm.controls['zipCode'].errors?.['minLength']">Must be 5 digits.
                        </ng-container>
                        <ng-container *ngIf="profileForm.controls['zipCode'].errors?.['maxLength']">Must be 5 digits.
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </ng-template>
        </div>
        <div fxFlex="0 1 100%">
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Tags</mat-label>
                <mat-chip-list ngDefaultControl #tagList formControlName="tagNames">
                    <mat-chip *ngFor="let tag of tags" (removed)="removeTag(tag)">
                        {{tag}}
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip>
                    <input placeholder="New Tag..." #tagsInput [matAutocomplete]="auto" [matChipInputFor]="tagList"
                        formControlName="tagsInput">
                </mat-chip-list>
                <mat-hint>Genres, Categories, Artists etc... {{ platformDefaults && platformDefaults.customTagFieldPromptText ? platformDefaults.customTagFieldPromptText : '' }}</mat-hint>
                <mat-error
                    *ngIf="profileForm.controls['tagNames'].invalid && (profileForm.controls['tagNames'].dirty || profileForm.controls['tagNames'].touched)">
                    <ng-container *ngIf="profileForm.controls['tagNames'].errors?.['required']">At least one tag is
                        required.
                    </ng-container>
                </mat-error>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
                    <ng-container *ngFor="let tag of filteredTags | async">
                        <mat-option *ngIf="!tags.includes(tag)" [value]="tag">
                            {{tag}}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <ng-container *ngIf="!userProfile">
            <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                <mat-form-field appearance="outline" color="primary">
                    <mat-label>Zip Code</mat-label>
                    <input matInput placeholder="90210" formControlName="zipCode" type="number">
                    <mat-hint>Helps users search and support local artists.</mat-hint>
                    <mat-error
                        *ngIf="profileForm.controls['zipCode'].invalid && (profileForm.controls['zipCode'].dirty || profileForm.controls['zipCode'].touched)">
                        <ng-container *ngIf="profileForm.controls['zipCode'].errors?.['minLength']">Must be 5 digits.
                        </ng-container>
                        <ng-container *ngIf="profileForm.controls['zipCode'].errors?.['maxLength']">Must be 5 digits.
                        </ng-container>
                    </mat-error>
                </mat-form-field>
                <br />
                <br />
                <mat-form-field appearance="outline" color="primary">
                    <mat-label>Venmo QR Code</mat-label>
                    <input matInput #venmoURL
                        placeholder="https://venmo.com/code?user_id=0123456789"
                        formControlName="venmoURL" type="url">
                    <button (click)="scanBarcode()" *ngIf="isNative" mat-icon-button matSuffix>
                        <mat-icon>qr_code_scanner</mat-icon>
                    </button>
                    <mat-hint>
                        <ng-container *ngIf="isNative; else webHint">
                            Scan QR code or enter QR Url.
                        </ng-container>
                        <ng-template #webHint>
                            Enter QR Url.
                        </ng-template>
                    </mat-hint>
                    <mat-error
                        *ngIf="profileForm.controls['venmoURL'].invalid && (profileForm.controls['venmoURL'].dirty || profileForm.controls['venmoURL'].touched)">
                        <ng-container *ngIf="profileForm.controls['venmoURL'].errors?.['venmoUrl']">
                            Please enter a valid Venmo url.
                        </ng-container>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                <mat-card>
                    <div class="venmoQrPosition mat-form-field-appearance-outline mat-form-field-outline">
                        <span class="venmoQrPositionText">QR Code Screen Position</span>
                        <button mat-button (click)="setvenmoQrCodeScreenPosition('topLeft')"
                            [ngClass]="profileForm.controls['venmoQrCodeScreenPosition'].value === 'topLeft' ? 'mat-raised-button mat-primary' : 'mat-stroked-button mat-primary'"
                            class="top-left">
                            <mat-icon>qr_code_2</mat-icon>
                        </button>
                        <button mat-button (click)="setvenmoQrCodeScreenPosition('topRight')"
                            [ngClass]="profileForm.controls['venmoQrCodeScreenPosition'].value === 'topRight' ? 'mat-raised-button mat-primary' : 'mat-stroked-button mat-primary'"
                            class="top-right">
                            <mat-icon>qr_code_2</mat-icon>
                        </button>
                        <button mat-button (click)="setvenmoQrCodeScreenPosition('bottomLeft')"
                            [ngClass]="profileForm.controls['venmoQrCodeScreenPosition'].value === 'bottomLeft' ? 'mat-raised-button mat-primary' : 'mat-stroked-button mat-primary'"
                            class="bottom-left">
                            <mat-icon>qr_code_2</mat-icon>
                        </button>
                        <button mat-button (click)="setvenmoQrCodeScreenPosition('bottomRight')"
                            [ngClass]="profileForm.controls['venmoQrCodeScreenPosition'].value === 'bottomRight' ? 'mat-raised-button mat-primary' : 'mat-stroked-button mat-primary'"
                            class="bottom-right">
                            <mat-icon>qr_code_2</mat-icon>
                        </button>
                    </div>
                </mat-card>
            </div>
        </ng-container>
    </div>
    <br />
    <br />
</form>