<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="0px grid" fxLayoutAlign="flex-start">
    <div *ngIf="!authenticated" fxFlex="0 1 40%" fxFlex.lt-md="100%" fxHide="true"
        fxHide.gt-sm="false" class="reset-password-image" [ngStyle]="{ 'background': 'url('+ resetPasswordImage +') center center / cover no-repeat' }">
    </div>
    <div fxFlex="0 1 60%" fxFlex.lt-md="100%">
        <div [ngClass]="!authenticated ? 'container' : 'container-authenticated'">
            <div [ngClass]="{ 'center-form': !authenticated }">
                <form [formGroup]="resetPasswordForm">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div *ngIf="!authenticated" fxFlex="0 1 20%" fxFlex.lt-md="0 1 25%" fxFlex.lt-sm="100%">
                        </div>
                        <div fxFlex="0 1 60%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                            <img *ngIf="!authenticated" class="image-logo" [src]="resetPasswordLogo" />
                            <h1>Reset Password</h1>
                            <mat-divider></mat-divider>
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Verification Code</mat-label>
                                <input matInput placeholder="Enter your code." formControlName="code" type="text">
                                <mat-hint>The verification code sent to your email.
                                </mat-hint>
                                <mat-error
                                    *ngIf="resetPasswordForm.controls['code'].invalid && (resetPasswordForm.controls['code'].dirty || resetPasswordForm.controls['code'].touched)">
                                    <ng-container *ngIf="resetPasswordForm.controls['code'].errors?.['required']">
                                        Varification Code is
                                        required.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Password</mat-label>
                                <input matInput placeholder="Enter your password." formControlName="password"
                                    [type]="hide ? 'password' : 'text'">
                                <button type="button" tabindex="-1" mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint>Minimum(6 characters, 1 special, 1 uppercase, 1 lowercase, 1 number).
                                </mat-hint>
                                <mat-error
                                    *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['password'].touched)">
                                    <ng-container *ngIf="resetPasswordForm.controls['password'].errors?.['required']">
                                        Password is
                                        required.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="resetPasswordForm.controls['password'].errors?.['passwordRequirements']">
                                        Password must be 6 characters, containing a special, uppercase, lowercase and
                                        number.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="resetPasswordForm.controls['password'].errors?.['matchPassword']">
                                        Password and verify password do not match.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Verify Password</mat-label>
                                <input matInput placeholder="Verify your password." formControlName="verifyPassword"
                                    [type]="hide ? 'password' : 'text'">
                                <button type="button" tabindex="-1" mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint>Reenter the password.</mat-hint>
                                <mat-error
                                    *ngIf="resetPasswordForm.controls['verifyPassword'].invalid && (resetPasswordForm.controls['verifyPassword'].dirty || resetPasswordForm.controls['verifyPassword'].touched)">
                                    <ng-container
                                        *ngIf="resetPasswordForm.controls['verifyPassword'].errors?.['required']">
                                        Verify password is
                                        required.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="resetPasswordForm.controls['verifyPassword'].errors?.['passwordRequirements']">
                                        Password must be 6 characters, containing a special, uppercase, lowercase and
                                        number.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="resetPasswordForm.controls['verifyPassword'].errors?.['matchPassword']">
                                        Password and verify password do not match.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <div class="text-center">
                                <button class="icon-button" mat-raised-button color="primary" [disabled]="!resetPasswordForm.valid || resetPasswordSaving"
                                    (click)="resetPassword()"><mat-icon>lock_reset</mat-icon> Reset
                                    Password</button>
                                    <br />
                                <br />
                                <button class="icon-button" *ngIf="!authenticated" mat-button color="light" routerLink="/sign-in"><mat-icon>login</mat-icon> Sign In</button>    
                            </div>
                        </div>
                        <div *ngIf="!authenticated" fxFlex="0 1 20%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>