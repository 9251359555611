import { Directive, HostListener, Input } from '@angular/core';

// capacitor
import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

// services
import { StripeService } from '../../services/stripe/stripe.service';

@Directive({
  selector: '[stripeConnectButton]'
})
export class StripeConnectButtonDirective {

  @Input() stripeConnectButton: any; // the stripe connect button element

  isNative: boolean = false; // flag to tell if native app

  constructor(
    private iab: InAppBrowser,
    private stripeService: StripeService
  ) {
    this.isNative = Capacitor.isNativePlatform(); // sets the native flag based on if a native app or not
  }

  /**
   * @description listens to the click event of the element it is added to and then gets the stripe connect url from the service and navigates to it.
   */
  @HostListener('click') onClick() {
    // build link and navigate to it
    console.log('Stripe connect button clicked.');
    let url = this.stripeService.getStripeConnectUrl(this.stripeConnectButton.stripeAccountId, this.stripeConnectButton.linkType);
    console.log('Stripe connect Url: ', url);

    // check if app is native and then open in in app browser or web browser based on the answer
    if (this.isNative) {

      let browserOptions: any = `location=yes,fullscreen=no,closebuttoncolor=#fafafa,closebuttoncaption=X Close Stripe,hidenavigationbuttons=yes,hideurlbar=yes,toolbarcolor=#65bbc4,toolbarposition=top`;

      if(Capacitor.getPlatform() === 'ios'){
        browserOptions = `location=no,fullscreen=no,closebuttoncolor=#fafafa,closebuttoncaption=X Close Stripe,hidenavigationbuttons=yes,hideurlbar=yes,toolbarcolor=#65bbc4,toolbarposition=top,lefttoright=yes`;
      }

      const browser = this.iab.create(url, '_blank', browserOptions);

      browser.on('loadstart').subscribe(event => {
        console.log(event);
        // browser.executeScript({code: "alert('loadstart! I am an alert box!!')"});
        if (event && event.url) {
          if (event.url.includes('http://localhost:4200') || event.url.includes('https://dev.toonballoon.com') || event.url.includes('https://app.toonballoon.com')) {
            browser.close();
          }
        }
      });
    } else {
      window.open(url, '_blank');
      // location.href = url;
    }
  }

}
