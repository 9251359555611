import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  isNative: boolean = false; // flag to tell if native app
  loading: boolean = false; // flag to show loader while loading

  imageChangedEvent: any = ''; // event that tells is the image has changed
  croppedImage: string = ''; // the cropped image base 64 string
  

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.isNative = Capacitor.isNativePlatform(); // sets the native flag based on if a native app or not
  }

  ngOnInit(): void {
  }

  /**
   * @description trigger the hidden file input to open the camera on native devices
   */
  openCamera(){
    document.getElementById('cameraInput').click();
  }

  /**
   * @description trigger the hidden file input to open the file system or omage gallery on native devices
   */
  openGallery(){
    document.getElementById('galleryInput').click();
  }

  /**
   * @description the method to set the image change event
   * @param event { any } the image change event
   */
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.loading = true;
  }

  /**
   * @description sets the cropped image to the base 64 string
   * @param event { ImageCroppedEvent }
   */
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {    
    // show cropper
    this.loading = false;
    console.log('image loaded: ', image);
  }

  /**
   * @description fires when the cropper is ready to crop
   */
  cropperReady() {
    // cropper ready
    this.loading = false;
  }

  /**
   * @description fires when the load image fails
   */
  loadImageFailed() {
    // show message
    this.loading = false;
  }

  /**
   * @description fires when close button is pressed
   */
  close() {
    this.dialogRef.close({ event: 'close' });
  }

  /**
   * @description fires when the done button is pressed
   */
  done() {
    this.dialogRef.close({ event: 'done', data: this.croppedImage });
  }

}
