import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// services
import { TokenService } from 'src/app/auth/services/token/token.service';
import { HttpBaseService } from 'src/app/global/services/http-base/http-base.service';

// environments
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShowRequirementService extends HttpBaseService<any> {

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/showrequirements`, _http, _tokenService);
   }

   /**
    * @description creates a new show requirements object in the database for a user
    * @param showRequirementsData { any }
    * @returns { Observable<any> } show requirements data return object
    */
  createShowRequirements(showRequirementsData: any): Observable<any> {
    return this.post(showRequirementsData);
  }
}
