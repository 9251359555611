<form [formGroup]="songForm">
    <h1 mat-dialog-title>{{data.mode | titlecase }} Song</h1>
    <div mat-dialog-content>
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Song Title</mat-label>
            <input matInput placeholder="Stairway to Heaven" formControlName="title" type="text"
                [matAutocomplete]="autoTracks">
            <mat-hint>Known title of the song.</mat-hint>
            <mat-error
                *ngIf="songForm.controls['title'].invalid && (songForm.controls['title'].dirty || songForm.controls['title'].touched)">
                <ng-container *ngIf="songForm.controls['title'].errors?.['required']">Song title is
                    required.
                </ng-container>
            </mat-error>
            <mat-autocomplete #autoTracks="matAutocomplete">
                <ng-container *ngIf="!isLoading;else loading">
                    <mat-option *ngFor="let track of filteredTracks" [value]="track.name">
                        {{track.name}}
                    </mat-option>
                </ng-container>
                <ng-template #loading>
                    <mat-option class="is-loading">Loading...</mat-option>
                </ng-template>
            </mat-autocomplete>
        </mat-form-field>
        <br />
        <br />
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Artist</mat-label>
            <input matInput placeholder="Led Zeppelin" formControlName="artist" [matAutocomplete]="autoArtist"
                type="text">
            <mat-hint>Original artist of the song.</mat-hint>
            <mat-error
                *ngIf="songForm.controls['artist'].invalid && (songForm.controls['artist'].dirty || songForm.controls['artist'].touched)">
                <ng-container *ngIf="songForm.controls['artist'].errors?.['required']">Artist is
                    required.
                </ng-container>
            </mat-error>
            <mat-autocomplete #autoArtist="matAutocomplete">
                <ng-container *ngIf="!isLoading;else loading">
                    <mat-option *ngFor="let artist of filteredArtists" [value]="artist.name">
                        {{artist.name}}
                    </mat-option>
                </ng-container>
                <ng-template #loading>
                    <mat-option class="is-loading">Loading...</mat-option>
                </ng-template>
            </mat-autocomplete>
        </mat-form-field>
        <br />
        <br />
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Genre</mat-label>
            <input matInput placeholder="Classic Rock" formControlName="genre" [matAutocomplete]="autoGenre"
                type="text">
            <mat-hint>Known title of the song.</mat-hint>
            <mat-error
                *ngIf="songForm.controls['genre'].invalid && (songForm.controls['genre'].dirty || songForm.controls['genre'].touched)">
                <ng-container *ngIf="songForm.controls['genre'].errors?.['required']">Genre is
                    required.
                </ng-container>
            </mat-error>
            <mat-autocomplete #autoGenre="matAutocomplete">
                <mat-option *ngFor="let option of filteredGenres | async" [value]="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <br />
        <br />
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Media URL</mat-label>
            <input matInput placeholder="https://youtu.be/QkF3oxziUI4" formControlName="mediaUrl" type="url">
            <mat-hint>You Tube, Vimeo, Dailymotion.</mat-hint>
            <mat-error
                *ngIf="songForm.controls['mediaUrl'].invalid && (songForm.controls['mediaUrl'].dirty || songForm.controls['mediaUrl'].touched)">
                <!-- <ng-container *ngIf="songForm.controls['mediaUrl'].errors?.['required']">Song title is
                    required.
                </ng-container> -->
            </mat-error>
        </mat-form-field>
        <br />
        <br />
    </div>
    <div mat-dialog-actions>
        <button class="icon-button" mat-button (click)="onCancelClick()">
            <mat-icon>close</mat-icon> Cancel
        </button>
        <button class="icon-button" mat-button [disabled]="!songForm.valid || songSaving" (click)="saveSong()"
            cdkFocusInitial>
            <mat-icon>save_alt</mat-icon> Save
        </button>
    </div>
</form>