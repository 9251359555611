// modules
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

// validators
import { DateValidator } from 'src/app/global/validators/date';
import { PasswordValidation } from '../../validators/password';

// services
import { AuthService } from '../../services/auth/auth.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { PlatformMediaService } from 'src/app/admin/services/platform-media/platform-media.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

// environments
import { GlobalConstants } from 'src/app/global/constants/global';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit, OnDestroy {

  imageBaseUrl: string; // the base url for the images
  createAccountImage: string; // object with the default create account image
  createAccountLogo: string; // object with the default create account logo
  createAccountSaving: boolean = false; // flag that disables save button while saving to prevent multiple clicks
  hide: boolean = true; // flag to tell input to show or hide password
  private deadMan: any = moment().subtract(120, 'years'); // date of birth max limit
  private eighteenYearsAgo: any = moment().subtract(18, 'years'); // date of birth min limit

  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template
  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  // creates the form group
  createAccountForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    dateOfBirth: ['', [Validators.required, DateValidator.usDate, DateValidator.minDate(this.deadMan), DateValidator.maxDate(this.eighteenYearsAgo)]],
    password: ['', [Validators.required, PasswordValidation.passwordRequirements]],
    verifyPassword: ['', [Validators.required, PasswordValidation.passwordRequirements]],
    roleNames: [[]]
  }, {
    validators: PasswordValidation.matchPassword // match password validation method
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private platformMediaService: PlatformMediaService,
    private loaderService: LoaderService,
    private snackBar: MatSnackBar
  ) {

    this.globalConstants = GlobalConstants;
    this.imageBaseUrl = environment.imageBaseUrl;

    let theme = localStorage.getItem('theme');

    this.subscriptions.add(
      this.platformMediaService.platformMediaDefault$.subscribe(defaults => {
        if (defaults && defaults && defaults.length > 0) {

          let image = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.resetPasswordImageType.id);
          let logo = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.mainLogoType.id);

          if (image && image.length > 0 && image[0].mediaUrl && this.imageBaseUrl) {
            this.createAccountImage = this.imageBaseUrl + image[0].mediaUrl;
          } else {
            this.createAccountImage = "assets/default-images/create-account.webp";
          }

          if (logo && logo.length > 0 && logo[0].mediaUrl && this.imageBaseUrl) {
            this.createAccountLogo = this.imageBaseUrl + logo[0].mediaUrl;
          } else {
            if (theme === 'light') {
              this.createAccountLogo = "assets/logos/toonballoon_logo_dark.webp";
            } else {
              this.createAccountLogo = "assets/logos/toonballoon_logo_light.webp";
            }
          }
        } else {
          this.createAccountImage = "assets/default-images/create-account.webp";
          if (theme === 'light') {
            this.createAccountLogo = "assets/logos/toonballoon_logo_dark.webp";
          } else {
            this.createAccountLogo = "assets/logos/toonballoon_logo_light.webp";
          }
        }
      })
    );

  }

  ngOnInit(): void {
    this.setRoles({}); // sets the roles to an empty object
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description add roles to the role object based on role selected
   * @param role { string }
   */
  setRoles(role: any) {
    if (role.value === 'artist') {
      this.createAccountForm.controls['roleNames'].setValue(['User', 'Artist']);
    } else {
      this.createAccountForm.controls['roleNames'].setValue(['User']);
    }
  }

  /**
   * @description creates the new user account with form values provided
   */
  createAccount() {
    this.createAccountSaving = true;
    this.loaderService.loaderSubject$.next(true);
    let form = this.createAccountForm.value;
    console.log('create-account', form);
    this.subscriptions.add(
      this.authService.createAccount(form).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.authService.signIn(form.email, form.password).then(() => {
              this.router.navigate([`../register`], { relativeTo: this.route });
            }, error => {
              this.router.navigate([`../sign-in`], { relativeTo: this.route });
              this.loaderService.loaderSubject$.next(false);
              this.snackBar.openFromComponent(ErrorMessageComponent, {
                data: error.message,
                duration: 10000,
                panelClass: ['error-snackbar']
              });
            });
          },
          error: (e) => {
            console.log('create account error:', e);
            this.createAccountSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

}
