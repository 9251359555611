import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// capacitor
import { Capacitor } from '@capacitor/core';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { PlatformMediaService } from '../../services/platform-media/platform-media.service';

@Component({
  selector: 'app-platform-media',
  templateUrl: './platform-media.component.html',
  styleUrls: ['./platform-media.component.scss']
})
export class PlatformMediaComponent implements OnInit, OnDestroy {

  isIos: boolean = false // flag to tell if device is ios;
  user: any; // the authenticated user

  platformMediaTypes: any = []; // list of media types

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private platformMediaService: PlatformMediaService
  ) {
    // check if device is ios and set flag
    this.isIos = Capacitor.getPlatform() === 'ios';
  }

  ngOnInit(): void {

    // show the loader
    this.loaderService.loaderSubject$.next(true);

    // set the modifiedBy var to the authenticated users email
    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe((user: any) => {
        if (user) {
          this.user = user;
          this.getMediaTypeLookups();         
        }
      })
    );   
    
  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description gets the media types from the lookup table in the database
   */
  getMediaTypeLookups(){
    this.subscriptions.add(
      this.platformMediaService.getMediaTypeLookups().subscribe(res => {
        this.platformMediaTypes = res.data;
        this.getPlatformMedia();        
      })
    );
  }

  /**
   * @description gets the media items from the database
   */
  getPlatformMedia(){
    this.subscriptions.add(
      this.platformMediaService.getPlatformMedia().subscribe(res => {
         for(let type of this.platformMediaTypes){
            type.media = res.data.filter(x => x.mediaTypeLookupId === type.id);
         }
         this.loaderService.loaderSubject$.next(false);         
      })
    );
  }

}
