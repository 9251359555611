<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="0px grid" fxLayoutAlign="flex-start">
    <div fxFlex="0 1 40%" fxFlex.lt-md="100%" fxHide="true" fxHide.gt-sm="false" class="delete-account-image" [ngStyle]="{ 'background': 'url('+ deleteAccountImage +') center center / cover no-repeat' }">        
    </div>
    <div fxFlex="0 1 60%" fxFlex.lt-md="100%">
        <div class="container">
            <div class="center-form">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="0 1 20%" fxFlex.lt-md="0 1 25%" fxFlex.lt-sm="100%">
                        </div>
                        <div fxFlex="0 1 60%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                            <img class="image-logo" [src]="deleteAccountLogo" />
                            <h1>Instructions to Delete Account</h1>
                            <mat-divider></mat-divider>                            
                            <br />
                            <ol class="instructions-list">
                                <li>If you forgot your password you can reset it by clicking the "Forgot Password" link at the bottom of this page. Otherwise skip to step 2.</li>
                                <li>Log into your account by clicking the "Sign In" link at the bottom of this page.</li>
                                <li>Once authenticated click the <mat-icon>more_vert</mat-icon> menu button in the top right corner of the screen.</li>
                                <li>Click the "Delete Account" menu item.</li>
                                <li>An alert will show warning you that you will "permanently delete your account and all its identifying information". Click "Yes, Delete" to proceed.</li>
                                <li>You will be signed out of your account and all identifying information will be deleted (username, user demographics and profile images). If you have made a purchase on your account that info will be retained but bound to a "Deleted User" record that contains no identifying information.</li>
                            </ol>
                            <div class="text-center">
                                <button class="icon-button" mat-button color="light" routerLink="/sign-in"><mat-icon>login</mat-icon> Sign In</button>
                                <br />
                                <br />
                                <button class="icon-button" mat-button color="light" routerLink="/forgot-password"><mat-icon>password</mat-icon> Forgot Password</button>
                            </div>
                        </div>
                        <div fxFlex="0 1 20%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
