import { AfterViewInit, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { UserService } from '../../../profile/services/user/user.service';

// components
import { ErrorMessageComponent } from '../error-message/error-message.component';

@Component({
  selector: 'app-complete-profile-prompt',
  templateUrl: './complete-profile-prompt.component.html',
  styleUrls: ['./complete-profile-prompt.component.scss']
})
export class CompleteProfilePromptComponent implements AfterViewInit, OnDestroy {

  @ViewChild('completeProfilePromptStepper') completeProfilePromptStepper: MatStepper; // gets the stepper element

  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public sbRef: MatSnackBarRef<ErrorMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngAfterViewInit(): void {

    // navigates the user to their current step based off the user sign up status
    if (this.data && this.completeProfilePromptStepper) {
      if (this.data.detailsComplete) {
        this.completeProfilePromptStepper.next();
        if (this.data.availabilityComplete) {
          this.completeProfilePromptStepper.next();
          if (this.data.pricingComplete) {
            this.completeProfilePromptStepper.next();
            if (this.data.songlistComplete) {
              this.completeProfilePromptStepper.next();
            }
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description navigates to the register page and closes the status stepper 
   */
  continue() {
    this.router.navigate([`../register`], { relativeTo: this.route });
    this.sbRef.dismiss();
  }

  /**
   * @description closes the status stepper and saves flag in user profile to not show again
   */
  dontShowAgain() {
    this.subscriptions.add(
      this.userService.updateUser({ id: this.data.id, showSignUpPrompt: false }).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.sbRef.dismiss();
            this.authService.getUserData();
          },
          error: (e) => {
            console.error(e);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => {
            console.info('complete');
          }
        }
      )
    );
  }

}
