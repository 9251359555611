//Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, first } from 'rxjs';

//Environment
import { environment } from 'src/environments/environment';

//Services
import { TokenService } from 'src/app/auth/services/token/token.service';
import { HttpBaseService } from '../http-base/http-base.service';

@Injectable({
  providedIn: 'root'
})
export class TagLookupService extends HttpBaseService<any> {

  private tagLookupsInstance: string[] = []; // tags string array property
  private tagLookupsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]); // subject that contains the tags string array
  private tagLookupsObservable: Observable<string[]> = this.tagLookupsSubject.asObservable(); // tags subject observable

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/`, _http, _tokenService);
  }

  ngOnDestroy(): void {
    // unsubscribes all base service subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description tags pull only pulls once
   * @returns { Observable<string[]> } of tags
   */
  tagLookups(): Observable<string[]> {
    if (!this.tagLookupsInstance || this.tagLookupsInstance.length < 1) {
      this.subscriptions.add(
        this.get('taglookup')
          .pipe(first())
          .subscribe(response => {
            if (response && response.data) {
              this.tagLookupsInstance = response.data;
              this.tagLookupsSubject.next(this.tagLookupsInstance);
            }
          })
      );
    }
    return this.tagLookupsObservable;
  }

}
