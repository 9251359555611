import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

// capacitor
import { Capacitor } from '@capacitor/core';

// servcies
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { PlatformDefaultsService } from '../../services/platform-defaults/platform-defaults.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';
import { SuccessMessageComponent } from 'src/app/global/components/success-message/success-message.component';

// constants
import { GlobalConstants } from 'src/app/global/constants/global';

@Component({
  selector: 'app-platform-defaults',
  templateUrl: './platform-defaults.component.html',
  styleUrls: ['./platform-defaults.component.scss']
})
export class PlatformDefaultsComponent implements OnInit {

  user: any// the authenticated user
  isIos: boolean = false // flag to tell if device is ios;

  platformDefaultsSaving: boolean = false; // flag to tell if in the act of saving platform defaults data

  minimumShowLengthHour: number = 0; // number of hours for minimum show length calculation
  minimumShowLengthMinute: number = 0; // number of minutes for minimum show length calculation

  maximumShowLengthHour: number = 0; // number of hours for maximum show length calculation
  maximumShowLengthMinute: number = 0; // number of minutes for maximum show length calculation

  // creates the show settings form
  platformDefaultsForm: FormGroup = this.formBuilder.group({
    depositMinimumPercentage: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    depositMaximumPercentage: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    showLengthMinutesMinimum: [0, [Validators.required, Validators.min(15)]],
    showLengthMinutesMaximum: [0, [Validators.required, Validators.min(15)]],
    eventGraceWindowMinutes: [0, [Validators.required, Validators.min(0)]],
    commissionPercentage: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
    customTagFieldPromptText: ['']
  });

  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private authService: AuthService,
    private platformDefaultsService: PlatformDefaultsService,
    private snackBar: MatSnackBar
  ) {
    // check if device is ios and set flag
    this.isIos = Capacitor.getPlatform() === 'ios';
  }

  ngOnInit(): void {

    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe({
        next: (res) => {
          if (res) {
            this.user = res;
          }
        },
        error: (e) => {
          console.error(e);
          // hide the loader
          this.loaderService.loaderSubject$.next(false);
          this.snackBar.openFromComponent(ErrorMessageComponent, {
            data: e,
            duration: 10000,
            panelClass: ['error-snackbar']
          });
        },
        complete: () => console.info('complete')
      })
    );

    this.subscriptions.add(
      this.platformDefaultsService.getPlatformDefaults().subscribe({
        next: (res) => {
          if (res && res.data) {
            // bind pulled values to form fields
            this.platformDefaultsForm.controls['depositMinimumPercentage'].setValue(res.data.depositMinimumPercentage ? res.data.depositMinimumPercentage : 0);
            this.platformDefaultsForm.controls['depositMaximumPercentage'].setValue(res.data.depositMaximumPercentage ? res.data.depositMaximumPercentage : 0);
            this.platformDefaultsForm.controls['showLengthMinutesMinimum'].setValue(res.data.showLengthMinutesMinimum ? res.data.showLengthMinutesMinimum : 0);
            this.platformDefaultsForm.controls['showLengthMinutesMaximum'].setValue(res.data.showLengthMinutesMaximum ? res.data.showLengthMinutesMaximum : 0);
            this.platformDefaultsForm.controls['eventGraceWindowMinutes'].setValue(res.data.eventGraceWindowMinutes ? res.data.eventGraceWindowMinutes : 0);
            this.platformDefaultsForm.controls['commissionPercentage'].setValue(res.data.commissionPercentage ? res.data.commissionPercentage : 0);
            this.platformDefaultsForm.controls['customTagFieldPromptText'].setValue(res.data.customTagFieldPromptText ? res.data.customTagFieldPromptText : '');

            this.setGroupControlValues();
          }
          this.loaderService.loaderSubject$.next(false);
        },
        error: (e) => {
          console.error(e);
          // hide the loader
          this.loaderService.loaderSubject$.next(false);
          this.snackBar.openFromComponent(ErrorMessageComponent, {
            data: e,
            duration: 10000,
            panelClass: ['error-snackbar']
          });
        },
        complete: () => console.info('complete')
      })
    );
  }

  /**
   * @description makes the calcualtions and sets the values in the hidden inputs for the grouped drop down lists
   */
  private setGroupControlValues() {    

    let minimumShowLengthObject = this.timeConvert(this.platformDefaultsForm.controls['showLengthMinutesMinimum'].value);

    this.minimumShowLengthHour = minimumShowLengthObject.hours;
    this.minimumShowLengthMinute = minimumShowLengthObject.minutes;

    let maximumShowLengthObject = this.timeConvert(this.platformDefaultsForm.controls['showLengthMinutesMaximum'].value);

    this.maximumShowLengthHour = maximumShowLengthObject.hours;
    this.maximumShowLengthMinute = maximumShowLengthObject.minutes;

  }

  /**
   * @description updates the minimum show length hidden input
   */
  updateMinimumShowLengthInput() {
    this.platformDefaultsForm.controls['showLengthMinutesMinimum'].markAsTouched();
    this.platformDefaultsForm.controls['showLengthMinutesMinimum'].setValue((this.minimumShowLengthHour * 60) + this.minimumShowLengthMinute);
  }

  /**
  * @description updates the maximum show length hidden input
  */
  updateMaximumShowLengthInput() {
    this.platformDefaultsForm.controls['showLengthMinutesMaximum'].markAsTouched();
    this.platformDefaultsForm.controls['showLengthMinutesMaximum'].setValue((this.maximumShowLengthHour * 60) + this.maximumShowLengthMinute);
  }

  /**
   * @description converts timestamp into days, hours, minutes
   * @param time { number }
   * @returns { days: number, hours: number, minutes: number }
   */
  private timeConvert(time: number) {

    let d: number = Number((time / (24 * 60)).toFixed(0));
    let h: number = Number(((time % (24 * 60)) / 60).toFixed(0));
    let m: number = Number(((time % (24 * 60)) % 60).toFixed(0));

    return { days: d, hours: h, minutes: m };
  }

  /**
   * @description Saves the values in platform default fields
   */
  savePlatformDefaults(){
    this.platformDefaultsSaving = true;
    this.loaderService.loaderSubject$.next(true);
    let data: any = this.platformDefaultsForm.value;
    data.statusLookupId = GlobalConstants.activeStatus.id;
    data.modifiedBy = this.user.email;
    data.userId = this.user.id;
    console.log('platform defaults: ', data);

    this.platformDefaultsService.createPlatformDefaults(data).subscribe(
      {
        next: (res: any) => {
          console.log(res);
          console.log(res);
          this.snackBar.openFromComponent(SuccessMessageComponent, {
            data: 'You have successfuly set the platform defaults.',
            duration: 5000,
            panelClass: ['success-snackbar']
          });
          this.loaderService.loaderSubject$.next(false);
          this.platformDefaultsSaving = false;
        },
        error: (e) => {
          console.error(e);
          this.snackBar.openFromComponent(ErrorMessageComponent, {
            data: e,
            duration: 10000,
            panelClass: ['error-snackbar']
          });
          this.loaderService.loaderSubject$.next(false);
          this.platformDefaultsSaving = false;
        },
        complete: () => console.info('Platform defaults creation complete')
      }
    );    

  };

}
