<mat-list class="discount-code-list">
    <div mat-subheader>
        <span>My Discount Codes</span>
        <span class="spacer"></span>
        <button *ngIf="discountCodeData && discountCodeData.length > 0" class="icon-button" mat-raised-button
            color="primary" (click)="openDiscountCodeDialog('add')">
            <mat-icon>new_label</mat-icon> Add
        </button>
    </div>
    <ng-container *ngIf="discountCodeData && discountCodeData.length > 0; else nodiscountcodes">
        <ng-container *ngFor="let discountCode of discountCodeData">
            <mat-divider></mat-divider>
            <mat-list-item>
                <h3 matLine>{{discountCode.id}} <mat-chip *ngIf="!discountCode.valid" color="warn" selected>Invalid</mat-chip></h3>
                <p matLine class="small-text">{{ discountCode.percent_off + '% off' }} {{discountCode.duration_in_months ? 'for ' + discountCode.duration_in_months + (discountCode.duration_in_months > 1 ? ' months' : ' month') : discountCode.duration }} | redeemed {{ discountCode.times_redeemed }} times | created {{ (discountCode.created*1000) | date }}</p>
                <div>
                    <div class="mobile-buttons">
                        <button mat-icon-button [matMenuTriggerFor]="discountCodeOptions" matTooltip="Options">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #discountCodeOptions class="matMenu">
                            <button class="menuButtonToggle" mat-menu-item
                                (click)="deleteDiscountCodeDialog(deleteTemplate)">
                                <mat-icon>delete</mat-icon>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </div>
                    <div class="desktop-buttons">
                        <button class="icon-button" mat-button (click)="deleteDiscountCodeDialog(deleteTemplate)">
                            <mat-icon>delete</mat-icon> Delete
                        </button>
                    </div>

                    <ng-template #deleteTemplate>
                        <h1 mat-dialog-title>Delete Discount Code</h1>
                        <div mat-dialog-content>
                            <p>You are about to delete "{{ discountCode.id }}" from your available discount codes list.
                            </p>
                            <span>Are you sure?</span>
                        </div>
                        <div mat-dialog-actions>
                            <button class="icon-button" mat-button mat-dialog-close>
                                <mat-icon>close</mat-icon> Cancel
                            </button>
                            <button class="icon-button" mat-button (click)="deleteDiscountCode(discountCode.id)"
                                cdkFocusInitial [disabled]="deleteDiscountCodeSaving">
                                <mat-icon>delete</mat-icon> Delete
                            </button>
                        </div>
                    </ng-template>
                </div>
            </mat-list-item>
        </ng-container>
    </ng-container>
    <ng-template #nodiscountcodes>
        <div class="no-discount-codes">
            <span class="center-align">
                <mat-icon mat-list-icon class="empty-discount-codes">discount</mat-icon>
                <h2>You have no discount codes!</h2>
                <button class="icon-button" mat-raised-button color="primary" (click)="openDiscountCodeDialog('add')">
                    <mat-icon>new_label</mat-icon> Add
                </button>
            </span>
        </div>
    </ng-template>
</mat-list>