import { AbstractControl } from '@angular/forms';
export class VenmoValidation {

    /**
     * @description makes sure the venmo url has all the required params in the regex
     * @param control {AbstractControl}
     * @returns { [key: string]: any } | null
     */
    static venmoUrl(control: AbstractControl): { [key: string]: any } {
        // tslint:disable-next-line:max-line-length
        let regex = /^https:\/\/venmo\.com\/code\?user_id=[0-9]+(.+)+$/g
        let regex2 = /^https:\/\/venmo\.com\/u\/([A-Za-z0-9]+([-A-Za-z0-9]+)+)$/g

        if (control.value && (!regex.test(control.value) && !regex2.test(control.value))) {
            return { 'venmoUrl': true };
        }
        return null;
    }

}