<div class="mat-simple-snackbar">
    <div class="mat-simple-snack-bar-content">
        <h3>
            <mat-icon class="message-icon">check_circle</mat-icon> Success
        </h3>
        <span>
            {{ data }}
        </span>
    </div>
    <div class="mat-simple-snackbar-action">
        <button mat-icon-button (click)="sbRef.dismiss()">
            <mat-icon class="icon-black">cancel</mat-icon>
        </button>
    </div>
</div>
