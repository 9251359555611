import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
export class TimeValidation {

    /**
     * @description makes sure the time has all the required params in the regex
     * @param AC {AbstractControl}
     * @returns { (AC: AbstractControl) => [key: string]: any } | null
     */
    static time(inputControlName: string, ddlControlName: string): (AC: AbstractControl) => { [key: string]: any } {
        return (AC: AbstractControl): { [key: string]: any } | null => {

            const inputControl = AC.get(inputControlName);
            const ddlControl = AC.get(ddlControlName);

            // tslint:disable-next-line:max-line-length
            let regex = /^([1-9]|0[1-9]|1[0-2]):([0,3]0|[1,4]5) ([AaPp][Mm])$/g;
            if (inputControl && ddlControl) {               

                if (inputControl.value && ddlControl.value && inputControl.value !== '') {
                    if (!regex.test(inputControl.value + ' ' + ddlControl.value)) {
                        // console.log('false');
                        inputControl.setErrors(Object.assign({ time: true }, inputControl.errors));
                        ddlControl.setErrors(Object.assign({ time: true }, ddlControl.errors));
                        inputControl.markAsTouched({ onlySelf: true })
                        ddlControl.markAsTouched({ onlySelf: true })
                    } else {
                        // console.log('true');            
                        inputControl.setErrors(Object.assign({ time: null }, inputControl.errors));
                        ddlControl.setErrors(Object.assign({ time: null }, ddlControl.errors));
                        inputControl.updateValueAndValidity({ onlySelf: true });
                        ddlControl.updateValueAndValidity({ onlySelf: true });
                    }
                }
                // else {
                //     inputControl.setErrors(Object.assign({ time: null }, inputControl.errors));
                //     ddlControl.setErrors(Object.assign({ time: null }, ddlControl.errors));
                //     inputControl.updateValueAndValidity({ onlySelf: true });
                //     ddlControl.updateValueAndValidity({ onlySelf: true });
                // }
            }
            return null;
        }
    }

    /**
     * @description checks the start time and end time fields to make sure start time is before end tme
     * @param AC {AbstractControl}
     * @returns { (AC: AbstractControl) => [key: string]: any } | null
     */
    static verifyRange(startTimeControlName: string, startTimeDDLControlName: string, endTimeControlName: string, endTimeDDLControlName: string): (AC: AbstractControl) => { [key: string]: any } | null {

        return (AC: AbstractControl): { [key: string]: any } | null => {

            const startTimeControl = AC.get(startTimeControlName);
            const startTimeDDLControl = AC.get(startTimeDDLControlName);
            const endTimeControl = AC.get(endTimeControlName);
            const endTimeDDLControl = AC.get(endTimeDDLControlName);

            const startTimeJoined = startTimeControl.value + ' ' + startTimeDDLControl.value;
            const endTimeJoined = endTimeControl.value + ' ' + endTimeDDLControl.value;

            const startTime = new Date().toDateString() + ' ' + startTimeJoined; // to get value in input tag

            let endTime: any;
            if (endTimeJoined === '12:00 AM') {
                endTime = new Date(new Date().setDate(new Date().getDate() + 1)).toDateString() + ' ' + endTimeJoined; // to get value in input tag plus one day
            } else {
                endTime = new Date().toDateString() + ' ' + endTimeJoined; // to get value in input tag
            }

            let startTimeDate = Date.parse(startTime);
            let endTimeDate = Date.parse(endTime);

            // console.log('startTimeDate: ', startTimeDate);
            // console.log('endTimeDate: ', endTimeDate);

            if (startTimeControl.value !== '' || endTimeControl.value !== '') {
                if (startTimeDate >= endTimeDate) {
                    // console.log('false');
                    startTimeControl.setErrors(Object.assign({ verifyRange: true }, startTimeControl.errors));
                    startTimeDDLControl.setErrors(Object.assign({ verifyRange: true }, startTimeDDLControl.errors));
                    endTimeControl.setErrors(Object.assign({ verifyRange: true }, endTimeControl.errors));
                    endTimeDDLControl.setErrors(Object.assign({ verifyRange: true }, endTimeDDLControl.errors));
                } 
                // else {
                //     console.log('true');
                //     startTimeControl.setErrors(Object.assign({ verifyRange: null }, startTimeControl.errors));
                //     startTimeDDLControl.setErrors(Object.assign({ verifyRange: null }, startTimeDDLControl.errors));
                //     endTimeControl.setErrors(Object.assign({ verifyRange: null }, endTimeControl.errors));
                //     endTimeDDLControl.setErrors(Object.assign({ verifyRange: null }, endTimeDDLControl.errors));
                //     startTimeControl.updateValueAndValidity({ onlySelf: true });
                //     startTimeDDLControl.updateValueAndValidity({ onlySelf: true });
                //     endTimeControl.updateValueAndValidity({ onlySelf: true });
                //     endTimeDDLControl.updateValueAndValidity({ onlySelf: true });
                // }
            }
            else {
                startTimeControl.setErrors(Object.assign({ verifyRange: null }, startTimeControl.errors));
                startTimeDDLControl.setErrors(Object.assign({ verifyRange: null }, startTimeDDLControl.errors));
                endTimeControl.setErrors(Object.assign({ verifyRange: null }, endTimeControl.errors));
                endTimeDDLControl.setErrors(Object.assign({ verifyRange: null }, endTimeDDLControl.errors));
                startTimeControl.updateValueAndValidity({ onlySelf: true });
                startTimeDDLControl.updateValueAndValidity({ onlySelf: true });
                endTimeControl.updateValueAndValidity({ onlySelf: true });
                endTimeDDLControl.updateValueAndValidity({ onlySelf: true });
            }
            return null;
        }
    }

    /**
     * @description checks the start time and end time fields to make sure start time is before end tme
     * @param AC {AbstractControl}
     * @returns { [key: string]: any } | null
     */
    static verifyOverlap(AC: AbstractControl): { [key: string]: any } {

        let dayOfTheWeek = AC as UntypedFormGroup;
        const times: UntypedFormArray = dayOfTheWeek.get('times') as UntypedFormArray;
        let isValid = true;
        if (times.length > 0) {
            for (let time of times.controls) {
                const startTimeControl = time.get('startTime');
                const endTimeControl = time.get('endTime');
                const startTimeDDLControl = time.get('startTimeDDL');
                const endTimeDDLControl = time.get('endTimeDDL');

                startTimeControl.setErrors(Object.assign({ verifyOverlap: null }, startTimeControl.errors))
                endTimeControl.setErrors(Object.assign({ verifyOverlap: null }, endTimeControl.errors));
                startTimeDDLControl.setErrors(Object.assign({ verifyOverlap: null }, startTimeDDLControl.errors))
                endTimeDDLControl.setErrors(Object.assign({ verifyOverlap: null }, endTimeDDLControl.errors));

                if (!startTimeControl.errors['verifyRange']) startTimeControl.updateValueAndValidity({ onlySelf: true });
                if (!endTimeControl.errors['verifyRange']) endTimeControl.updateValueAndValidity({ onlySelf: true });
                if (!startTimeDDLControl.errors['verifyRange']) startTimeDDLControl.updateValueAndValidity({ onlySelf: true });
                if (!endTimeDDLControl.errors['verifyRange']) endTimeDDLControl.updateValueAndValidity({ onlySelf: true });

                if (!startTimeControl.errors && !endTimeControl.errors) {
                    time.updateValueAndValidity({ onlySelf: true });
                    time.parent.updateValueAndValidity({ onlySelf: true });
                }
                for (let x of times.controls) {
                    TimeValidation.compareTimeSlots(time, x);
                    if (time.errors && time.errors['verifyOverlap']) isValid = false;
                }
            }
        }
        return null;
    };

    /**
     * @description compare all time slots for possible conflict
     * @param time { any }
     * @param possibleConflict { any } 
     * @returns { void } 
     */
    static compareTimeSlots(time: any, possibleConflict: any): void {
        const startTimeControl = time.get('startTime');
        const endTimeControl = time.get('endTime');
        const startTimeDDLControl = time.get('startTimeDDL');
        const endTimeDDLControl = time.get('endTimeDDL');

        const startTimeJoined = startTimeControl.value + ' ' + startTimeDDLControl.value;
        const endTimeJoined = endTimeControl.value + ' ' + endTimeDDLControl.value;

        const startTime = new Date().toDateString() + ' ' + startTimeJoined; // to get value in input tag
        let endTime: string;
        if (endTimeJoined === '12:00 AM') {
            endTime = new Date(new Date().setDate(new Date().getDate() + 1)).toDateString() + ' ' + endTimeJoined; // to get value in input tag plus one day
        } else {
            endTime = new Date().toDateString() + ' ' + endTimeJoined; // to get value in input tag
        }

        let startTimeDate = Date.parse(startTime);
        let endTimeDate = Date.parse(endTime);

        // console.log('startTimeDate: ', startTimeDate);
        // console.log('endTimeDate: ', endTimeDate);

        if (possibleConflict === time) return;
        const conflictStartControl = possibleConflict.get('startTime');
        const conflictEndControl = possibleConflict.get('endTime');
        const conflictStartTimeDDLControl = possibleConflict.get('startTimeDDL');
        const conflictEndTimeDDLControl = possibleConflict.get('endTimeDDL');

        let conflictStartTimeJoined = conflictStartControl.value + ' ' + conflictStartTimeDDLControl.value;
        let conflictEndTimeJoined = conflictEndControl.value + ' ' + conflictEndTimeDDLControl.value;

        const conflictStartTime = new Date().toDateString() + ' ' + conflictStartTimeJoined; // to get value in input tag
        const conflictEndTime = new Date().toDateString() + ' ' + conflictEndTimeJoined; // to get value in input tag

        let conflictStartTimeDate = Date.parse(conflictStartTime);
        let conflictEndTimeDate = Date.parse(conflictEndTime);
        if (conflictStartTimeDate === startTimeDate || conflictEndTimeDate === startTimeDate
            || (startTimeDate > conflictStartTimeDate && startTimeDate < conflictEndTimeDate)
            || (conflictStartTimeDate > startTimeDate && conflictStartTimeDate < endTimeDate)) {
            startTimeControl.setErrors(Object.assign({ verifyOverlap: true }, startTimeControl.errors));
            startTimeControl.markAsTouched();
            startTimeDDLControl.setErrors(Object.assign({ verifyOverlap: true }, startTimeDDLControl.errors));
            startTimeDDLControl.markAsTouched();
            // conflictStartControl.setErrors(Object.assign({ verifyOverlap: true }, conflictStartControl.errors));
            // conflictStartControl.markAsTouched();
        }
        if (conflictEndTimeDate === endTimeDate || conflictStartTimeDate === endTimeDate
            || (endTimeDate > conflictStartTimeDate && endTimeDate < conflictEndTimeDate)
            || (conflictEndTimeDate > startTimeDate && conflictEndTimeDate < endTimeDate)) {
            endTimeControl.setErrors(Object.assign({ verifyOverlap: true }, endTimeControl.errors));
            endTimeControl.markAsTouched();
            endTimeDDLControl.setErrors(Object.assign({ verifyOverlap: true }, endTimeDDLControl.errors));
            endTimeDDLControl.markAsTouched();
            // conflictEndControl.setErrors(Object.assign({ verifyOverlap: true }, conflictEndControl.errors));
            // conflictEndControl.markAsTouched();
        }
    }
}
