import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormArray, UntypedFormGroup, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TimeValidation } from '../../validators/time';

@Component({
  selector: 'app-availability-form',
  templateUrl: './availability-form.component.html',
  styleUrls: ['./availability-form.component.scss']
})
export class AvailabilityFormComponent implements OnInit {

  @Input() modifiedBy: string; // authenticated user
  @Input() availabilityNote: any; // users availability note
  @Input() days: any[]; // array of days of the week

  @Output() availabilityFormOutput = new EventEmitter(); // emits the availability form data
  @Output() availabilityFormValidOutput = new EventEmitter(); // emits the availability form validation status

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  timezoneString: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // creates the availability form
  availabilityForm: FormGroup = this.formBuilder.group({
    availabilityNote: [''],
    daysOfTheWeek: new UntypedFormArray([]),
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {

    this.availabilityForm.controls['availabilityNote'].setValue(this.availabilityNote);

    // add form group for each day of the week
    this.days.forEach(day => {
      //Setup the formGroup
      const dayOfTheWeek = (this.formBuilder.group({
        addStartTime: [''],
        addStartTimeDDL: [''],
        addEndTime: [''],
        addEndTimeDDL: [''],
        dayOfTheWeek: [day],
        times: new UntypedFormArray([]),
        isSelected: day.isSelected
      }, {
        validators: [          
          TimeValidation.time('addStartTime', 'addStartTimeDDL'),
          TimeValidation.time('addEndTime', 'addEndTimeDDL'),
          TimeValidation.verifyRange('addStartTime', 'addStartTimeDDL', 'addEndTime', 'addEndTimeDDL'), // verify starttime is before endtime          
          TimeValidation.verifyOverlap
        ] // verify no overlap between time slots   
      }));

      this.daysOfTheWeek.push(
        dayOfTheWeek
      );
      //Go through existing entries and add them
      if (day.times && day.times.length) {
        day.times.forEach(time => {
          this.addTimeSlot(dayOfTheWeek, time);
        });
      }
    });
    // console.log('daysOfTheWeek form array:', this.daysOfTheWeek);
  }

  ngOnDestroy() {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  ngAfterContentInit() {

    //Setup a listener for changes to form values
    this.subscriptions.add(
      this.availabilityForm.valueChanges.subscribe(value => {
        this.formValueChangeFunction(value, this);
      })
    );

    this.formValueChangeFunction(this.availabilityForm.value, this);
  }

  /**
   * @description getter to return the days of the week
   */
  get daysOfTheWeek() { return this.availabilityForm.controls.daysOfTheWeek as UntypedFormArray; }

  /**
   * @description adds a new timeslot with values to the day
   * @param day { any }
   * @param time { any }
   */
  addTimeSlot(day: any, time: any) {

    let startTimeRaw = time?.startTimeString.split(' ');
    let endTimeRaw = time?.endTimeString.split(' ');

    let startTime = startTimeRaw ? startTimeRaw[0] : day.controls['addStartTime'].value;
    let endTime = startTimeRaw ? endTimeRaw[0] : day.controls['addEndTime'].value;
    let startTimeSuffix = startTimeRaw ? startTimeRaw[1] : day.controls['addStartTimeDDL'].value;
    let endTimeSuffix = endTimeRaw ? endTimeRaw[1] : day.controls['addEndTimeDDL'].value;

    const userDayOfTheWeek = (this.formBuilder.group({
      id: [time?.id ?? null],
      startTime: [startTime, [Validators.required]],
      startTimeDDL: [startTimeSuffix],
      endTime: [endTime, [Validators.required]],
      endTimeDDL: [endTimeSuffix],
      modifiedBy: [this.modifiedBy]
    }, {
      validators: [
        TimeValidation.time('startTime', 'startTimeDDL'),
        TimeValidation.time('endTime', 'endTimeDDL'),
        TimeValidation.verifyRange('startTime', 'startTimeDDL', 'endTime', 'endTimeDDL'), // verify starttime is before endtime
      ]
    }));

    //Make sure to push to times directly so validation works
    day.controls['times'].push(
      userDayOfTheWeek
    );

    day.controls['addStartTime'].setValue('');
    day.controls['addEndTime'].setValue('');
    day.controls['addStartTimeDDL'].setValue('AM');
    day.controls['addEndTimeDDL'].setValue('AM');
  }

  /**
   * @description removes timeslots from the times array at provided index
   * @param times { any }
   * @param index { any }
   */
  deleteTimeSlot(times: any, index: any) {
    times.removeAt(index);
  }

  /**
   * @description validates the form on any value changes and emit values
   * @param result { any }
   * @param that { any } the passed in 'this'
   */
  formValueChangeFunction(result, that) {
    const isValid = this.validateAllTimes();
    that.availabilityFormValidOutput.emit(isValid);
    if (isValid) {
      //Emit all nested values from the form to the parent
      that.availabilityFormOutput.emit(this.availabilityForm.getRawValue());
    }
  }

  /**
   * @description validates all the times in the form
   * @returns { boolean } isValid
   */
  validateAllTimes(): boolean {
    this.availabilityForm.errors
    let isValid: boolean = false;
    //Regular loop here so we can break out with return
    for (let i = 0; i < this.daysOfTheWeek.controls.length; i++) {
      const dayOftheWeek: UntypedFormGroup = this.daysOfTheWeek.controls[i] as UntypedFormGroup;
      const times: UntypedFormArray = dayOftheWeek.get('times') as UntypedFormArray;
      let times2 = times.controls;
      //We can check each day of the week and it will check nested child controls. Doesn't seem to work at availabilityForm level yet     
      //if (dayOftheWeek && !dayOftheWeek.valid) return false;
      if (times && !times.valid) return false;
      //Only set true for this day if have some times set
      if (times && times.length > 0) isValid = true;
    };
    return isValid;
  }

}
