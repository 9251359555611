export class GlobalConstants {

    constructor(
    ) { }

    /**
     * @description static object used in scheduling
     */
    public static get days(): Array<any> {
        return [
            { id: 1, name: 'Sunday', isSelected: false },
            { id: 2, name: 'Monday', isSelected: false },
            { id: 3, name: 'Tuesday', isSelected: false },
            { id: 4, name: 'Wednesday', isSelected: false },
            { id: 5, name: 'Thursday', isSelected: false },
            { id: 6, name: 'Friday', isSelected: false },
            { id: 7, name: 'Saturday', isSelected: false }
        ]
    }

    /**
     * @description formatter to give you a time from a date
     */
    public static shortTimeFormat = new Intl.DateTimeFormat("en", {
        timeStyle: "short"
    });

    /**
     *@description the active status object
     */
    public static activeStatus = { id: 1, name: 'Active' };

    /**
     *@description the inactive status object
     */
    public static inactiveStatus = { id: 2, name: 'Active' };

    /**
     *@description the deleted status object
     */
    public static deletedStatus = { id: 3, name: 'Active' };

    /**
     *@description the event history pending status object
     */
    public static pendingEventStatus = { id: 1, name: 'Pending' };

    /**
     *@description the event history unconfirmed status object
     */
    public static unconfirmedEventStatus = { id: 2, name: 'Unconfirmed' };

    /**
     *@description the event history confirmed status object
     */
    public static confirmedEventStatus = { id: 3, name: 'Confirmed' };

    /**
     *@description the event history canceled status object
     */
    public static canceledEventStatus = { id: 4, name: 'Canceled' };

    /**
    *@description the event history complete status object
    */
    public static completeEventStatus = { id: 5, name: 'Complete' };

    /**
     *@description the event history complete status object
     */
    public static liveEventStatus = { id: 6, name: 'Live' };

    /**
   *@description the DesktopNavbarLogoType object
   */
    public static desktopNavbarLogoType = { id: 1, type: 'desktop_navbar_logo' };

    /**
   *@description the MobileNavbarLogoType object
   */
   public static mobileNavbarLogoType = { id: 2, type: 'mobile_navbar_logo' };

   /**
   *@description the MainLogoType object
   */
   public static mainLogoType = { id: 3, type: 'main_logo' };

   /**
   *@description the CreateAccountImageType object
   */
   public static createAccountImageType = { id: 4, type: 'create_account_image' };

   /**
   *@description the ForgotPasswordImageType object
   */
   public static forgotPasswordImageType = { id: 5, type: 'forgot_password_image' };

   /**
   *@description the ResetPasswordImageType object
   */
   public static resetPasswordImageType = { id: 6, type: 'reset_password_image' };

   /**
   *@description the SignInImageType object
   */
   public static signInImageType = { id: 7, type: 'sign_in_image' };
}