// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //// DEV
  cognitoUserPoolId: 'us-west-2_2b46VQebp',
  cognitoAppClientId: '67bgr2q59kob8sjqhedbtspgtu',
  // nodeServerUrl: 'http://127.0.0.1:3000',
  nodeServerUrl: 'https://api-dev.toonballoon.com',
  imageBaseUrl: 'https://dev.toonballoon.com/',
  // stripeReturnUrl: 'http://localhost:4200',
  stripeReturnUrl: 'https://dev.toonballoon.com',
  stripePublicKey: 'pk_test_51LNNSaIO0PdrstG2TFQlto8GM0e5T0N8joeeiMDwq2VZgyN97Z8zjK7K7K5ZCgkgiRQaJTa1r6ZcYuTO3giavBv200cH93oKll',
  firebase: {
    apiKey: "AIzaSyDAOgnODz0mkj8e2jLXGGrPMWO6dvP47lk",
    authDomain: "toon-balloon.firebaseapp.com",
    databaseURL: "https://toon-balloon-default-rtdb.firebaseio.com",
    projectId: "toon-balloon",
    storageBucket: "toon-balloon.appspot.com",
    messagingSenderId: "932255761330",
    appId: "1:932255761330:web:498d586d8d78ac1866daab",
    measurementId: "G-KT9TP9SMKY"
  }

  //// LIVE
  // cognitoUserPoolId: 'us-west-2_Q81iFhluB',
  // cognitoAppClientId: '4dhktvn6fneei93gd8fapj3r4e',
  // nodeServerUrl: 'https://api.toonballoon.com',
  // imageBaseUrl: 'https://app.toonballoon.com/',
  // stripeReturnUrl: 'https://app.toonballoon.com',
  // stripePublicKey: 'pk_live_51LNNSaIO0PdrstG239h06WRNQUz4ksZ2DvszaLhnM8G8LPbym7pVU0eE474RmQD1DYk4FkwSAHRsbZgE8MamWJ1e00b2BDsAxj',
  // firebase: {
  //   apiKey: "AIzaSyDAOgnODz0mkj8e2jLXGGrPMWO6dvP47lk",
  //   authDomain: "toon-balloon.firebaseapp.com",
  //   databaseURL: "https://toon-balloon-live.firebaseio.com",
  //   projectId: "toon-balloon",
  //   storageBucket: "toon-balloon.appspot.com",
  //   messagingSenderId: "932255761330",
  //   appId: "1:932255761330:web:498d586d8d78ac1866daab",
  //   measurementId: "G-KT9TP9SMKY"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
