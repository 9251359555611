import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';

// services
import { TokenService } from 'src/app/auth/services/token/token.service';
import { HttpBaseService } from 'src/app/global/services/http-base/http-base.service';

// environments
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService extends HttpBaseService<any> {

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService,
    private afd: AngularFireDatabase
  ) {
    super(`${environment.nodeServerUrl}/event/`, _http, _tokenService);
  }

  /**
   * @description gets a event by id
   * @param id { number } 
   * @returns { Observable<any> } with event
   */
  getEvent(id: number, userId: number): Observable<any> {

    let eventResult = this.get('', { eventId: id, userId: userId });
    console.log('get single event result:', eventResult);
    return eventResult;

  }

  /**
   * @description gets a event status by id
   * @param id { number } 
   * @returns { Observable<any> } with event status
   */
  getEventStatus(id: number): Observable<any> {

    let eventStatusResult = this.afd.object(`events/${id}`).valueChanges();
    console.log('get single event status result:', eventStatusResult);
    return eventStatusResult;

  }

  /**
   * @description creates a new event
   * @param event { any } 
   * @returns { Observable<any> } with event created
   */
  createEvent(event: any): Observable<any> {

    let eventResult = this.post(event, 'create');
    console.log('create event result:', eventResult);
    return eventResult;

  }

  /**
   * @description cancels an event
   * @param eventId { number } 
   * @returns { Observable<any> } with event canceled
   */
  cancelEvent(userId: number, eventId: number): Observable<any> {

    let eventResult = this.post({ userId: userId, eventId: eventId }, 'cancel');
    console.log('cancel event result:', eventResult);
    return eventResult;

  }

  /**
   * @description completes an event
   * @param eventId { number } 
   * @returns { Observable<any> } with event completed
   */
   completeEvent(userId: number, eventId: number): Observable<any> {

    let eventResult = this.post({ userId: userId, eventId: eventId }, 'complete');
    console.log('complete event result:', eventResult);
    return eventResult;

  }

  /**
   * @description creates a new event status history record
   * @param eventStatusHistory { any } 
   * @returns { Observable<any> } with eventStatusHistory created
   */
  createEventStatusHistory(eventStatusHistory: any): Observable<any> {

    let eventStatusHistoryResult = this.post(eventStatusHistory, 'createstatushistory');
    console.log('create event status history result:', eventStatusHistoryResult);
    return eventStatusHistoryResult;

  }

  /**
   * @description creates a event comment record in the database
   * @param commentData { any }
   * @returns { Observable<any> } event comment data result
   */
   createComment(commentData: any): Observable<any> {
    return this.post(commentData, 'createcomment');
  }

  /**
   * @description gets a users bookings events
   * @param userId { number } 
   * @param limitLength { number } the number of rows to fetch
   * @param offsetLength { number } the number of rows to skip
   * @returns { Observable<any> } with users bookings events
   */
  getBookings(userId: number, limitLength: number, offsetLength: number): Observable<any> {

    let eventResult = this.get('bookings', { userId: userId, limitLength: limitLength, offsetLength: offsetLength });
    console.log('get event result:', eventResult);
    return eventResult;

  }

  /**
   * @description gets a artists shows
   * @param userId { number }
   * @param limitLength { number } the number of rows to fetch
   * @param offsetLength { number } the number of rows to skip 
   * @returns { Observable<any> } with artists shows
   */
  getShows(userId: number, limitLength: number, offsetLength: number): Observable<any> {

    let eventResult = this.get('shows', { userId: userId, limitLength: limitLength, offsetLength: offsetLength });
    console.log('get event result:', eventResult);
    return eventResult;

  }

  /**
   * @description removes the event bridge rule that would no show an event
   * @param userId { number } 
   * @returns { Observable<any> } with artists shows
   */
   removeNoShowEventRule(eventId: number): Observable<any> {
    let eventResult = this.get('removeNoShowEventRule', { id: eventId });
    console.log('removeNoShowEventRule result:', eventResult);
    return eventResult;
  }
}