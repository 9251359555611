<div class="container-authenticated bottom-no-padding" [ngClass]="{'container-authenticated-ios': isIos}">
    <h1 class="inline-header">Manage Users</h1>
    <div class="right-group float-right">
        <button mat-button color="light" class="icon-button" (click)="openFilterDialog()">
            <mat-icon>search</mat-icon>
            <ng-container *ngIf="filters.length > 0; else emptyFilter">
                <ng-container *ngFor="let filter of filters; let i = index"> {{ i !== filters.length - 1 ? (filter === 'Inactive' ? 'Suspended' : filter) + ', '
                    : (filter === 'Inactive' ? 'Suspended' : filter)}}</ng-container>
            </ng-container>
            <ng-template #emptyFilter> Search</ng-template>
        </button>
        <button *ngIf="filters.length > 0" mat-icon-button color="light" matTooltip="Clear Filters"
            (click)="clearFilters()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
    <br class="clear" />
    <mat-divider></mat-divider>
    <mat-list class="user-list">
        <cdk-virtual-scroll-viewport #scroller [itemSize]="88" class="virtual-scroll-viewport">
            <ng-container *ngIf="userListData && userListData.length > 0; else nousers">
                <ng-container *cdkVirtualFor="let user of userListData">
                    <mat-list-item>
                        <img *ngIf="user.profileImageUrl" mat-list-avatar [src]="getImage(user.profileImageUrl)"
                            alt="profile-image">
                        <mat-icon *ngIf="!user.profileImageUrl" mat-list-icon>person</mat-icon>
                        <div mat-line
                            [matBadge]="user.statusLookup.status === 'Inactive' ? 'Suspended' : user.statusLookup.status"
                            matBadgeOverlap="false"
                            matBadgeColor="{{ user.statusLookup.status === 'Active' ? 'accent' : 'warn' }}">{{
                            user.displayName ?
                            user.displayName : 'Incomplete Profile'}}</div>
                        <div mat-line>{{ user.email }}</div>
                        <div mat-line *ngIf="user.__userRoles__ && user.__userRoles__.length > 0">
                            Roles: <ng-container *ngFor="let userrole of user.__userRoles__; let i = index">{{
                                userrole.roleLookup.role }}{{i !== user.__userRoles__.length -1 ? ', ' :
                                ''}}</ng-container>
                        </div>

                        <div class="desktop-buttons">
                            <button class="icon-button" mat-button
                                routerLink="/edit-profile/{{user.id}}"><mat-icon>edit</mat-icon> Edit</button>
                        </div>
                        <button mat-icon-button [matMenuTriggerFor]="userOptions" matTooltip="Options">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #userOptions class="matMenu">
                            <div class="mobile-buttons">
                                <button class="menuButtonToggle" mat-menu-item routerLink="/edit-profile/{{user.id}}">
                                    <mat-icon>edit</mat-icon>
                                    <span>Edit</span>
                                </button>
                            </div>
                            <button class="menuButtonToggle" mat-menu-item
                                (click)="resetPasswordDialog(resetPasswordTemplate)">
                                <mat-icon>password</mat-icon>
                                <span>Reset Password</span>
                            </button>
                            <button class="menuButtonToggle" mat-menu-item
                                (click)="manageRolesDialog(manageRolesTemplate)">
                                <mat-icon>admin_panel_settings</mat-icon>
                                <span>Manage Roles</span>
                            </button>
                            <button *ngIf="user.statusLookupId === globalConstants.activeStatus.id"
                                class="menuButtonToggle" mat-menu-item (click)="suspendUserDialog(suspendTemplate)">
                                <mat-icon>lock</mat-icon>
                                <span>Suspend</span>
                            </button>
                            <button *ngIf="user.statusLookupId === globalConstants.inactiveStatus.id"
                                class="menuButtonToggle" mat-menu-item (click)="restoreUserDialog(restoreTemplate)">
                                <mat-icon>lock_open</mat-icon>
                                <span>Restore</span>
                            </button>
                            <button class="menuButtonToggle" mat-menu-item (click)="deleteUserDialog(deleteTemplate)">
                                <mat-icon>delete</mat-icon>
                                <span>Delete</span>
                            </button>
                        </mat-menu>


                        <ng-template #deleteTemplate>
                            <h1 mat-dialog-title>Delete User</h1>
                            <div mat-dialog-content>
                                <p>You are about to delete the user account for "{{ user.displayName ? user.displayName
                                    :
                                    user.email }}".</p>
                                <span>Are you sure?</span>
                            </div>
                            <div mat-dialog-actions>
                                <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                                    Cancel</button>
                                <button class="icon-button" mat-button (click)="deleteUser(user)" cdkFocusInitial
                                    [disabled]="deleteUserSaving"><mat-icon>delete</mat-icon>
                                    Delete</button>
                            </div>
                        </ng-template>

                        <ng-template #suspendTemplate>
                            <h1 mat-dialog-title>Suspend User</h1>
                            <div mat-dialog-content>
                                <p>You are about to suspend the user account for "{{ user.displayName ? user.displayName
                                    :
                                    user.email }}".</p>
                                <span>Are you sure?</span>
                            </div>
                            <div mat-dialog-actions>
                                <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                                    Cancel</button>
                                <button class="icon-button" mat-button (click)="suspendUser(user)" cdkFocusInitial
                                    [disabled]="suspendUserSaving"><mat-icon>lock</mat-icon>
                                    Suspend</button>
                            </div>
                        </ng-template>

                        <ng-template #restoreTemplate>
                            <h1 mat-dialog-title>Restore User</h1>
                            <div mat-dialog-content>
                                <p>You are about to restore the user account for "{{ user.displayName ? user.displayName
                                    :
                                    user.email }}".</p>
                                <span>Are you sure?</span>
                            </div>
                            <div mat-dialog-actions>
                                <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                                    Cancel</button>
                                <button class="icon-button" mat-button (click)="restoreUser(user)" cdkFocusInitial
                                    [disabled]="restoreUserSaving"><mat-icon>lock_open</mat-icon>
                                    Restore</button>
                            </div>
                        </ng-template>

                        <ng-template #resetPasswordTemplate>
                            <h1 mat-dialog-title>Reset Password</h1>
                            <div mat-dialog-content>
                                <form [formGroup]="resetPasswordForm">
                                    <mat-form-field appearance="outline" color="primary">
                                        <mat-label>Password</mat-label>
                                        <input matInput placeholder="Enter your password." formControlName="password"
                                            [type]="hide ? 'password' : 'text'">
                                        <button type="button" tabindex="-1" mat-icon-button matSuffix
                                            (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                            [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <mat-hint>Minimum(6 characters, 1 special, 1 uppercase, 1 lowercase, 1 number).
                                        </mat-hint>
                                        <mat-error
                                            *ngIf="resetPasswordForm.controls['password'].invalid && (resetPasswordForm.controls['password'].dirty || resetPasswordForm.controls['password'].touched)">
                                            <ng-container
                                                *ngIf="resetPasswordForm.controls['password'].errors?.['required']">
                                                Password is
                                                required.
                                            </ng-container>
                                            <ng-container
                                                *ngIf="resetPasswordForm.controls['password'].errors?.['passwordRequirements']">
                                                Password must be 6 characters, containing a special, uppercase,
                                                lowercase
                                                and
                                                number.
                                            </ng-container>
                                            <ng-container
                                                *ngIf="resetPasswordForm.controls['password'].errors?.['matchPassword']">
                                                Password and verify password do not match.
                                            </ng-container>
                                        </mat-error>
                                    </mat-form-field>
                                    <br />
                                    <br />
                                    <mat-form-field appearance="outline" color="primary">
                                        <mat-label>Verify Password</mat-label>
                                        <input matInput placeholder="Verify your password."
                                            formControlName="verifyPassword" [type]="hide ? 'password' : 'text'">
                                        <button type="button" tabindex="-1" mat-icon-button matSuffix
                                            (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                            [attr.aria-pressed]="hide">
                                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <mat-hint>Reenter the password.</mat-hint>
                                        <mat-error
                                            *ngIf="resetPasswordForm.controls['verifyPassword'].invalid && (resetPasswordForm.controls['verifyPassword'].dirty || resetPasswordForm.controls['verifyPassword'].touched)">
                                            <ng-container
                                                *ngIf="resetPasswordForm.controls['verifyPassword'].errors?.['required']">
                                                Verify password is
                                                required.
                                            </ng-container>
                                            <ng-container
                                                *ngIf="resetPasswordForm.controls['verifyPassword'].errors?.['passwordRequirements']">
                                                Password must be 6 characters, containing a special, uppercase,
                                                lowercase
                                                and
                                                number.
                                            </ng-container>
                                            <ng-container
                                                *ngIf="resetPasswordForm.controls['verifyPassword'].errors?.['matchPassword']">
                                                Password and verify password do not match.
                                            </ng-container>
                                        </mat-error>
                                    </mat-form-field>
                                </form>
                                <br />
                                <p>You are about to reset the password for the user account "{{ user.displayName ?
                                    user.displayName :
                                    user.email }}".</p>
                                <span>Are you sure?</span>
                            </div>
                            <div mat-dialog-actions>
                                <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                                    Cancel</button>
                                <button class="icon-button" mat-button (click)="resetPassword(user)" cdkFocusInitial
                                    [disabled]="resetPasswordSaving || !resetPasswordForm.valid"><mat-icon>password</mat-icon>
                                    Reset Password</button>
                            </div>
                        </ng-template>

                        <ng-template #manageRolesTemplate>
                            <h1 mat-dialog-title>Manage Roles</h1>
                            <div mat-dialog-content>
                                <section class="roles-section">
                                    <span class="roles-list-section">
                                        <ul>
                                            <li *ngFor="let role of user.roles">
                                                <mat-checkbox [checked]="role.checked" color="primary"
                                                    (change)="updateRole($event, role)">
                                                    {{role.role}}
                                                </mat-checkbox>
                                            </li>
                                        </ul>
                                    </span>
                                </section>
                                <br />
                                <p>You are about to change the roles of the user account for "{{ user.displayName ?
                                    user.displayName :
                                    user.email }}".</p>
                                <span>Are you sure?</span>
                            </div>
                            <div mat-dialog-actions>
                                <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                                    Cancel</button>
                                <button class="icon-button" mat-button (click)="manageRoles(user)" cdkFocusInitial
                                    [disabled]="manageRolesSaving"><mat-icon>save_alt</mat-icon>
                                    Save</button>
                            </div>
                        </ng-template>

                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
            </ng-container>
        </cdk-virtual-scroll-viewport>
        <ng-template #nousers>
            <div class="no-users">
                <span class="center-align">
                    <mat-icon mat-list-icon class="empty-user-list">no_accounts</mat-icon>
                    <h2>There are no users in the system!</h2>
                </span>
            </div>
        </ng-template>
    </mat-list>
</div>