// modules
import { Injectable, OnDestroy } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Subscription } from 'rxjs';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterGuard implements OnDestroy, Resolve<any>{

  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description resolves the sign up status object before allowing navigation to route
   * @returns { any }
   */
  resolve(): any {
    let signUpStatusSubscription = this.authService.signUpStatus$.subscribe(
      (res: any) => {
        if (res && !res.detailsComplete) {
          this.router.navigate(['/register']);
        }
        return res;
      }
    );

    this.subscriptions.add(signUpStatusSubscription);

    return signUpStatusSubscription;

  }

}
