import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent implements OnInit {

  roleNames: Array<string>;
  filterObject: { searchText?: string, roles?: Array<string>, status?: Array<string> }; // the filters that are selected

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    public dialogRef: MatDialogRef<UserFilterComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    this.filterObject = this.data.filter;
    this.roleNames = this.data.roleNames;

  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description the search method that fires after a user stops typing
   * @param text { string } the text that the user enters
   */
  search(text: string) {
    console.log('search: ', text);
    this.filterObject.searchText = text;
  }

  /**
   * @description the roles method that fires after a user chooses roles
   * @param roles { any } the roles that the user chooses
   */
  roles(event: any, role: string) {
    console.log('role: ', role);
    if (event.checked) {
      this.filterObject.roles.push(role);
    } else {
      this.filterObject.roles = this.filterObject.roles.filter(item => item !== role);
    }
  }

  /**
   * @description the status method that fires after a user chooses a status
   * @param status { any } the status that the user chooses
   */
  status(event: any, status: string) {
    console.log('status: ', status);
    if (event.checked) {
      this.filterObject.status.push(status);
    } else {
      this.filterObject.status = this.filterObject.status.filter(item => item !== status);
    }
  }

  /**
   * @description fires when close button is pressed
   */
  close() {
    this.dialogRef.close({ event: 'close' });
  }

  /**
   * @description fires when the filter button is pressed
   */
  filter() {
    this.dialogRef.close({ event: 'filter', data: this.filterObject });
  }

}