<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="0px grid" fxLayoutAlign="flex-start">
    <div fxFlex="0 1 40%" fxFlex.lt-md="100%" fxHide="true" fxHide.gt-sm="false" class="forgot-password-image" [ngStyle]="{ 'background': 'url('+ forgotPasswordImage +') center center / cover no-repeat' }">
    </div>
    <div fxFlex="0 1 60%" fxFlex.lt-md="100%">
        <div class="container">
            <div class="center-form">
                <form [formGroup]="forgotPasswordForm">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="0 1 20%" fxFlex.lt-md="0 1 25%" fxFlex.lt-sm="100%">
                        </div>
                        <div fxFlex="0 1 60%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                            <img class="image-logo" [src]="forgotPasswordLogo" />
                            <h1>Forgot Password</h1>
                            <mat-divider></mat-divider>
                            <br />
                            <span>A verification code to reset your password will be sent to the email associated with your account.</span>
                            <br />
                            <br />
                            <mat-divider></mat-divider>
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="Members email." formControlName="email" type="email" (keyup.enter)="forgotPasswordForm.valid && !forgotPasswordSaving ? requestVerificationCode() : null">
                                <mat-hint>The email for your account.</mat-hint>
                                <mat-error
                                    *ngIf="forgotPasswordForm.controls['email'].invalid && (forgotPasswordForm.controls['email'].dirty || forgotPasswordForm.controls['email'].touched)">
                                    <ng-container *ngIf="forgotPasswordForm.controls['email'].errors?.['required']">Email is
                                        required.
                                    </ng-container>
                                    <ng-container *ngIf="forgotPasswordForm.controls['email'].errors?.['email']">Must be a valid
                                        email
                                        address.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <div class="text-center">
                                <button class="icon-button" mat-raised-button color="primary" [disabled]="!forgotPasswordForm.valid || forgotPasswordSaving" (click)="requestVerificationCode()"><mat-icon>pin</mat-icon> Request Verification Code</button>
                                <br />
                                <br />
                                <button class="icon-button" mat-button color="light" routerLink="/sign-in"><mat-icon>login</mat-icon> Sign In</button>
                                <br />
                                <br />
                                <button class="icon-button" mat-button color="light" routerLink="/create-account"><mat-icon>person_add</mat-icon> Create New Account</button>
                            </div>
                        </div>
                        <div fxFlex="0 1 20%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
