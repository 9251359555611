<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="0px grid" fxLayoutAlign="flex-start">
    <div fxFlex="0 1 40%" fxFlex.lt-md="100%" fxHide="true" fxHide.gt-sm="false" class="create-account-image" [ngStyle]="{ 'background': 'url('+ createAccountImage +') center center / cover no-repeat' }">
    </div>
    <div fxFlex="0 1 60%" fxFlex.lt-md="100%">
        <div class="container">
            <div class="horizontal-center-form">
                <form [formGroup]="createAccountForm">
                    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                        <div fxFlex="0 1 20%" fxFlex.lt-md="0 1 25%" fxFlex.lt-sm="100%">
                        </div>
                        <div fxFlex="0 1 60%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                            <img class="image-logo" [src]="createAccountLogo" />
                            <h1>Create New Account</h1>
                            <mat-divider></mat-divider>
                            <br />
                            <mat-button-toggle-group (change)="setRoles($event)" value="user" name="accountType" aria-label="Account Type">
                                <mat-button-toggle value="user">User</mat-button-toggle>
                                <mat-button-toggle value="artist">Artist</mat-button-toggle>
                            </mat-button-toggle-group>
                            <br />
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="Members email." formControlName="email" type="email">
                                <mat-hint>The email for your account.</mat-hint>
                                <mat-error
                                    *ngIf="createAccountForm.controls['email'].invalid && (createAccountForm.controls['email'].dirty || createAccountForm.controls['email'].touched)">
                                    <ng-container *ngIf="createAccountForm.controls['email'].errors?.['required']">Email is
                                        required.
                                    </ng-container>
                                    <ng-container *ngIf="createAccountForm.controls['email'].errors?.['email']">Must be a
                                        valid
                                        email
                                        address.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Date of Birth</mat-label>
                                <input [matDatepicker]="dobpicker" matInput placeholder="Members date of birth."
                                    formControlName="dateOfBirth">
                                <mat-datepicker-toggle tabindex="-1" matSuffix [for]="dobpicker"></mat-datepicker-toggle>
                                <mat-datepicker #dobpicker></mat-datepicker>
                                <mat-hint>Must be 18+ to register.</mat-hint>
                                <mat-error
                                    *ngIf="createAccountForm.controls['email'].invalid && (createAccountForm.controls['dateOfBirth'].dirty || createAccountForm.controls['dateOfBirth'].touched)">
                                    <ng-container *ngIf="createAccountForm.controls['dateOfBirth'].errors?.['required']">Date
                                        of birth is
                                        required.
                                    </ng-container>
                                    <ng-container *ngIf="createAccountForm.controls['dateOfBirth'].errors?.['usDate']">
                                        Must be a valid date.
                                    </ng-container>
                                    <ng-container *ngIf="createAccountForm.controls['dateOfBirth'].errors?.['maxDate']">
                                        You are not 18+ years old.
                                    </ng-container>
                                    <ng-container *ngIf="createAccountForm.controls['dateOfBirth'].errors?.['minDate']">
                                        You must be alive to register.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Password</mat-label>
                                <input matInput placeholder="Enter your password." formControlName="password"
                                    [type]="hide ? 'password' : 'text'">
                                <button type="button" tabindex="-1" mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint>Minimum(6 characters, 1 special, 1 uppercase, 1 lowercase, 1 number).</mat-hint>
                                <mat-error
                                    *ngIf="createAccountForm.controls['password'].invalid && (createAccountForm.controls['password'].dirty || createAccountForm.controls['password'].touched)">
                                    <ng-container *ngIf="createAccountForm.controls['password'].errors?.['required']">
                                        Password is
                                        required.
                                    </ng-container>
                                    <ng-container *ngIf="createAccountForm.controls['password'].errors?.['passwordRequirements']">
                                        Password must be 6 characters, containing a special, uppercase, lowercase and number.
                                    </ng-container>
                                    <ng-container *ngIf="createAccountForm.controls['password'].errors?.['matchPassword']">
                                        Password and verify password do not match.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Verify Password</mat-label>
                                <input matInput placeholder="Verify your password." formControlName="verifyPassword"
                                    [type]="hide ? 'password' : 'text'" (keyup.enter)="createAccountForm.valid && !createAccountSaving ? createAccount() : null">
                                <button type="button" tabindex="-1" mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint>Reenter the password.</mat-hint>
                                <mat-error
                                    *ngIf="createAccountForm.controls['verifyPassword'].invalid && (createAccountForm.controls['verifyPassword'].dirty || createAccountForm.controls['verifyPassword'].touched)">
                                    <ng-container *ngIf="createAccountForm.controls['verifyPassword'].errors?.['required']">
                                        Verify password is
                                        required.
                                    </ng-container>
                                    <ng-container *ngIf="createAccountForm.controls['verifyPassword'].errors?.['passwordRequirements']">
                                        Password must be 6 characters, containing a special, uppercase, lowercase and number.
                                    </ng-container>
                                    <ng-container
                                        *ngIf="createAccountForm.controls['verifyPassword'].errors?.['matchPassword']">
                                        Password and verify password do not match.
                                    </ng-container>
                                </mat-error>
                            </mat-form-field>
                            <br />
                            <br />
                            <div class="text-center">
                                <button class="icon-button" mat-raised-button color="primary" (click)="createAccount()"
                                    [disabled]="!createAccountForm.valid || createAccountSaving"><mat-icon>person_add</mat-icon> Create Account</button>
                                <br />
                                <br />
                                <button class="icon-button" mat-button color="light" routerLink="/sign-in"><mat-icon>login</mat-icon> Sign In</button>                                
                                <br />
                                <br />
                                <button class="icon-button" mat-button color="light" routerLink="/forgot-password"><mat-icon>password</mat-icon> Forgot Password</button>
                            </div>
                        </div>
                        <div fxFlex="0 1 20%" fxFlex.lt-md="0 1 50%" fxFlex.lt-sm="100%">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
