<mat-list class="song-list">
    <div mat-subheader>
        <span>{{ editable ? 'My ' : '' }}Songs</span>
        <span class="spacer"></span>
        <button *ngIf="editable && userSongListData.songList && userSongListData.songList.length > 0" class="icon-button" mat-raised-button
            color="primary" (click)="openSongDialog('add')"><mat-icon>playlist_add</mat-icon> Add</button>
    </div>
    <ng-container *ngIf="userSongListData.songList && userSongListData.songList.length > 0; else nosongs">
        <ng-container *ngFor="let song of userSongListData.songList">
            <mat-divider></mat-divider>
            <mat-list-item>
                <mat-icon *ngIf="song.mediaUrl" mat-list-icon class="play-button" (click)="launchEmbed(song)">
                    play_circle_outline</mat-icon>
                <div mat-line>{{song.title}}</div>
                <div mat-line>{{song.artist}} | {{song.genre}}</div>
                <div *ngIf="editable">
                    <div class="mobile-buttons">
                        <button mat-icon-button [matMenuTriggerFor]="songOptions" matTooltip="Options">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #songOptions class="matMenu">
                            <button class="menuButtonToggle" mat-menu-item (click)="openSongDialog('edit', song)">
                                <mat-icon>edit</mat-icon>
                                <span>Edit</span>
                            </button>
                            <button class="menuButtonToggle" mat-menu-item (click)="deleteSongDialog(deleteTemplate)">
                                <mat-icon>delete</mat-icon>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </div>
                    <div class="desktop-buttons">
                        <button class="icon-button" mat-button (click)="openSongDialog('edit', song)"><mat-icon>edit</mat-icon> Edit</button>
                        <button class="icon-button" mat-button (click)="deleteSongDialog(deleteTemplate)"><mat-icon>delete</mat-icon> Delete</button>
                    </div>

                    <ng-template #deleteTemplate>
                        <h1 mat-dialog-title>Delete Song</h1>
                        <div mat-dialog-content>
                            <p>You are about to delete "{{ song.title }}" from your song list.</p>
                            <span>Are you sure?</span>
                        </div>
                        <div mat-dialog-actions>
                            <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon> Cancel</button>
                            <button class="icon-button" mat-button (click)="deleteSong(song)" cdkFocusInitial
                                [disabled]="deleteSongSaving"><mat-icon>delete</mat-icon> Delete</button>
                        </div>
                    </ng-template>
                </div>
            </mat-list-item>
        </ng-container>
    </ng-container>
    <ng-template #nosongs>
        <div class="no-songs">
            <span class="center-align">
                <mat-icon mat-list-icon class="empty-music-library">library_music</mat-icon>
                <h2>Your song list is empty!</h2>
                <button *ngIf="editable" class="icon-button" mat-raised-button color="primary" (click)="openSongDialog('add')"><mat-icon>playlist_add</mat-icon> Add</button>
            </span>
        </div>
    </ng-template>
</mat-list>