import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/auth/services/token/token.service';
import { GlobalConstants } from 'src/app/global/constants/global';
import { HttpBaseService } from 'src/app/global/services/http-base/http-base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomTagService extends HttpBaseService<any> {

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/taglookup/`, _http, _tokenService);
  }

  /**
  * @description gets the tag list
  * @returns { Observable<any> } tag list
  */
  getTagList(): Observable<any> {
    return this.get('');
  }

  /**
  * @description creates custom tag record in the database
  * @param tagData { any }
  * @returns { Observable<any> } user song data result
  */
  createTag(tagData: any): Observable<any> {
    return this.post(tagData);
  }

  /**
   * @description deletes the tag in the database
   * @param account { any }
   * @returns { Observable<any> } tag delete result
   */
  deleteTag(account: any, modifiedBy): Observable<any> {
    return this.patch(account.id, { statusLookupId: GlobalConstants.deletedStatus.id, modifiedBy: modifiedBy });
  }

  /**
   * @description deactivate the tag in the database
   * @param account { any }
   * @returns { Observable<any> } tag deactivate result
   */
  deactivateTag(account: any, modifiedBy): Observable<any> {
    return this.patch(account.id, { statusLookupId: GlobalConstants.inactiveStatus.id, modifiedBy: modifiedBy });
  }

  /**
   * @description reactivates the tags in the database
   * @param account { any }
   * @returns { Observable<any> } tag reactivate result
   */
  reactivateTag(account: any, modifiedBy): Observable<any> {
    return this.patch(account.id, { statusLookupId: GlobalConstants.activeStatus.id, modifiedBy: modifiedBy });
  }

}
