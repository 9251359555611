<h2 mat-dialog-title>Search</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="filterForm">
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Search</mat-label>
            <input matInput #searchInput [matAutocomplete]="autoTags" placeholder="Rock" type="search"
                formControlName="searchText">
            <mat-autocomplete #autoTags="matAutocomplete">
                <mat-option *ngFor="let option of filteredTags | async" [value]="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete>
            <mat-icon matSuffix>search</mat-icon>
            <mat-hint>Type in a keyword. {{ platformDefaults && platformDefaults.customTagFieldPromptText ?
                platformDefaults.customTagFieldPromptText : '' }}</mat-hint>
        </mat-form-field>
        <br />
        <br />
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Date</mat-label>
            <input [matDatepicker]="datefilterpicker" [value]="selectedDate" matInput placeholder="Date to search." (dateChange)="dateChanged($event.target.value)">
            <mat-datepicker-toggle tabindex="-1" matSuffix [for]="datefilterpicker"></mat-datepicker-toggle>
            <mat-datepicker #datefilterpicker></mat-datepicker>
            <mat-hint>Filter by availability.</mat-hint>
        </mat-form-field>
        <br />
        <br />
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Max Price</mat-label>
            <input matInput placeholder="15" type="number" formControlName="price">
            <mat-icon matSuffix>sell</mat-icon>
            <mat-hint>Max price in dollars per hour.</mat-hint>
        </mat-form-field>
        <br />
        <br />
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Max Distance</mat-label>
            <input matInput placeholder="10" type="number" formControlName="distance">
            <mat-icon matSuffix>place</mat-icon>
            <mat-hint>Max distance in miles.</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="icon-button" mat-button mat-dialog-close (click)="close()"><mat-icon>close</mat-icon> Cancel</button>
    <button class="icon-button" mat-button [mat-dialog-close]="true" cdkFocusInitial
        (click)="filter()"><mat-icon>search</mat-icon> Search</button>
</mat-dialog-actions>