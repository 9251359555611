// modules
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';
import { SuccessMessageComponent } from 'src/app/global/components/success-message/success-message.component';

// services
import { AuthService } from '../../services/auth/auth.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { PlatformMediaService } from 'src/app/admin/services/platform-media/platform-media.service';

// validators
import { PasswordValidation } from '../../validators/password';

// environments
import { GlobalConstants } from 'src/app/global/constants/global';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  imageBaseUrl: string; // the base url for the images
  resetPasswordImage: string; // object with the default reset password image
  resetPasswordLogo: string; // object with the default reset password logo
  authenticated: boolean = false; // flag to tell if user is authenticated
  resetPasswordSaving: boolean = false; // flag that disables save button while saving to prevent multiple clicks
  hide: boolean = true; // flag to tell input to show or hide password

  // creates the form group
  resetPasswordForm: FormGroup = this.formBuilder.group({
    code: ['', [Validators.required]],
    password: ['', [Validators.required, PasswordValidation.passwordRequirements]],
    verifyPassword: ['', [Validators.required, PasswordValidation.passwordRequirements]]
  }, {
    validators: PasswordValidation.matchPassword // match password validation method
  });

  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template
  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    private platformMediaService: PlatformMediaService,
    private loaderService: LoaderService,
    private snackBar: MatSnackBar
  ) { 

    this.globalConstants = GlobalConstants;
    this.imageBaseUrl = environment.imageBaseUrl;

    let theme = localStorage.getItem('theme');

    this.subscriptions.add(
      this.platformMediaService.platformMediaDefault$.subscribe(defaults => {
        if (defaults && defaults && defaults.length > 0) {

          let image = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.resetPasswordImageType.id);
          let logo = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.mainLogoType.id);

          if (image && image.length > 0 && image[0].mediaUrl && this.imageBaseUrl) {
            this.resetPasswordImage = this.imageBaseUrl + image[0].mediaUrl;
          } else {
            this.resetPasswordImage = "assets/default-images/reset-password.webp";
          }

          if (logo && logo.length > 0 && logo[0].mediaUrl && this.imageBaseUrl) {
            this.resetPasswordLogo = this.imageBaseUrl + logo[0].mediaUrl;
          } else {
            if (theme === 'light') {
              this.resetPasswordLogo = "assets/logos/toonballoon_logo_dark.webp";
            } else {
              this.resetPasswordLogo = "assets/logos/toonballoon_logo_light.webp";
            }
          }
        } else {
          this.resetPasswordImage = "assets/default-images/reset-password.webp";
          if (theme === 'light') {
            this.resetPasswordLogo = "assets/logos/toonballoon_logo_dark.webp";
          } else {
            this.resetPasswordLogo = "assets/logos/toonballoon_logo_light.webp";
          }
        }
      })
    );

  }

  ngOnInit(): void {
    // subscribes to the authenticated service subject and sets public variable
    this.subscriptions.add(
      this.authService.authenticated$.subscribe(authenticated => {
        this.authenticated = authenticated;
      })
    );
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description updates the users password with new one proviced in the form
   */
  resetPassword() {
    this.resetPasswordSaving = true;
    this.loaderService.loaderSubject$.next(true);
    let email: string = null;
    if (this.route.snapshot.queryParams && this.route.snapshot.queryParams["email"]) {
      email = this.route.snapshot.queryParams["email"];
    }
    let code = this.resetPasswordForm.controls['code'].value;
    let password = this.resetPasswordForm.controls['password'].value;

    this.authService.resetPassword(code, password, email).then(result => {
      console.log('password reset: ', result);

      if (this.authenticated) {
        this.router.navigate([`../browse`], { relativeTo: this.route });
      } else {
        this.router.navigate([`../sign-in`], { relativeTo: this.route });
      }
      this.loaderService.loaderSubject$.next(false);
      this.snackBar.openFromComponent(SuccessMessageComponent, {
        data: 'Password reset successful!',
        duration: 10000,
        panelClass: ['success-snackbar']
      });
    }).catch(reason => {
      console.log('Failed: ', reason);
      this.resetPasswordSaving = false;
      this.loaderService.loaderSubject$.next(false);
      this.snackBar.openFromComponent(ErrorMessageComponent, {
        data: 'Failed to reset password!',
        duration: 10000,
        panelClass: ['error-snackbar']
      });
    });

  }

}
