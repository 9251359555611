import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// environments
import { environment } from 'src/environments/environment';

// components
import { PlatformMediaService } from 'src/app/admin/services/platform-media/platform-media.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnDestroy {

  imageBaseUrl: string; // the base url for the images
  deleteAccountImage: string; // object with the default delete account image
  deleteAccountLogo: string; // object with the default delete account logo

  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template
  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private platformMediaService: PlatformMediaService,
    private loaderService: LoaderService
  ) { 
    this.imageBaseUrl = environment.imageBaseUrl;

    let theme = localStorage.getItem('theme');
    this.subscriptions.add(
      this.platformMediaService.platformMediaDefault$.subscribe(defaults => {
        if (defaults && defaults && defaults.length > 0) {

          let image = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.signInImageType.id);
          let logo = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.mainLogoType.id);

          if (image && image.length > 0 && image[0].mediaUrl && this.imageBaseUrl) {
            this.deleteAccountImage = this.imageBaseUrl + image[0].mediaUrl;
          } else {
            this.deleteAccountImage = "assets/default-images/delete-account.webp";
          }

          if (logo && logo.length > 0 && logo[0].mediaUrl && this.imageBaseUrl) {
            this.deleteAccountLogo = this.imageBaseUrl + logo[0].mediaUrl;
          } else {
            if (theme === 'light') {
              this.deleteAccountLogo = "assets/logos/toonballoon_logo_dark.webp";
            } else {
              this.deleteAccountLogo = "assets/logos/toonballoon_logo_light.webp";
            }
          }
        } else {
          this.deleteAccountImage = "assets/default-images/delete-account.webp";
          // this.deleteAccountLogo = "assets/logos/toon-balloon-logo.webp";
          if (theme === 'light') {
            this.deleteAccountLogo = "assets/logos/toonballoon_logo_dark.webp";
          } else {
            this.deleteAccountLogo = "assets/logos/toonballoon_logo_light.webp";
          }
        }
      })
    );

    // has to be in a set timeout or the loader throws an error if its the first thing thats called.
    setTimeout(() => {
      this.loaderService.loaderSubject$.next(false);
    }, 100);
  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
