<div class="container-authenticated" [ngClass]="{'container-authenticated-ios': isIos}">
    <h1>Schedule Event</h1>
    <mat-divider></mat-divider>
    <br />
    <div *ngIf="artistData" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid"
        fxLayoutAlign="flex-start">
        <div fxFlex.lt-sm="0 1 100%" fxFlex.lt-md="0 1 50%" fxFlex="0 1 33.33%">
            <app-profile-info [profileData]="artistData"></app-profile-info>
            <!-- <h2>{{ artistData.displayName }}</h2> -->
            <div mat-subheader>
                <span>Event Requirements</span>
            </div>
            <mat-divider></mat-divider>
            <div class="info-text">
                <span>Min Length: {{ artistData.userShowRequirement.minimumShowLength | time }}</span><br />
                <span>Max Length: {{ artistData.userShowRequirement.maximumShowLength | time }}</span><br />
                <span>Price: {{showRequirementsData.pricePerHour | currency}}/hr</span><br />
                <span>Cancel Fee: {{showRequirementsData.depositPercentage}}% of total</span><br />
            </div>
        </div>
        <div fxFlex.lt-sm="0 1 100%" fxFlex.lt-md="0 1 50%" fxFlex="0 1 33.33%">
            <p *ngIf="artistData.availabilityNote">{{ artistData.availabilityNote }}</p>
            <mat-card>
                <mat-calendar [selected]="selected" (selectedChange)="dateSelected($event)" [dateFilter]="dateFilter"
                    [minDate]="today">
                </mat-calendar>
            </mat-card>
            <br />
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Start Time</mat-label>
                <mat-select [(value)]="selectedStartTime" (selectionChange)="selectStartTime()"
                    [disabled]="startTimeDisabled">
                    <mat-option *ngFor="let startTime of startTimes" [value]="startTime">
                        {{ startTime | date: 'shortTime' }}
                    </mat-option>
                </mat-select>
                <mat-hint>Time you want to start the event.</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="outline" color="primary">
                <mat-label>End Time</mat-label>
                <mat-select [(value)]="selectedEndTime" (selectionChange)="selectEndTime()"
                    [disabled]="endTimeDisabled">
                    <mat-option *ngFor="let endTime of endTimes" [value]="endTime">
                        {{ endTime | date: 'shortTime' }}
                    </mat-option>
                </mat-select>
                <mat-hint>Time you want to end the event.</mat-hint>
            </mat-form-field>
        </div>
        <div fxFlex.lt-sm="0 1 100%" fxFlex.lt-md="0 1 100%" fxFlex="0 1 33.33%">
            <h3>Scheduled Event Info</h3>
            <ng-container *ngIf="eventCost">
                <span>Date: {{ selected | date}}</span><br />
                <span>Length: {{ eventCost.length | time }} </span><br />
                <span>Total Price: {{ eventCost.price | currency }}</span><br />
                <ng-container *ngIf="eventCost.discount && eventCost.discount > 0">
                    <span>Discount: {{ eventCost.discount | currency }}</span><br />
                    <span>Discounted Price: {{ eventCost.discountedPrice | currency }}</span><br />
                </ng-container>
                <span>Cancel Fee: {{ eventCost.cancelFee | currency
                    }} <span class="material-icons-outlined info-icon"
                        matTooltip="The portion of the payment that will NOT be refunded if you cancel the event after the artist has confirmed it. However, if the event is canceled before the artist confirms it or the artist cancels or no shows the event, then the total cost will be refunded.">
                        help_outline
                    </span>
                </span>
            </ng-container>
            <br /><br />
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Discount Code</mat-label>
                <input matInput placeholder="MUSIC50OFF" type="text" [formControl]="discountCode">
                <mat-hint>Enter a valid code to get a discount.</mat-hint>
                <mat-error *ngIf="discountCode.invalid && (discountCode.dirty || discountCode.touched)">
                    <ng-container *ngIf="discountCode.errors?.['coupon']">Not a valid code.
                    </ng-container>
                </mat-error>
            </mat-form-field>
            <button [disabled]="checkoutSaving || !checkoutData || checkoutData.amount === 0"
                class="icon-button checkout-button" mat-raised-button color="primary"
                (checkoutSavingEvent)="setCheckoutSaving($event)" [stripeCheckoutButton]="checkoutData">
                <mat-icon>shopping_cart_checkout</mat-icon> Checkout
            </button>
        </div>
    </div>
</div>