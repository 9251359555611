import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

// services
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { StripeService } from 'src/app/global/services/stripe/stripe.service';

@Component({
  selector: 'app-discount-code-form',
  templateUrl: './discount-code-form.component.html',
  styleUrls: ['./discount-code-form.component.scss']
})
export class DiscountCodeFormComponent implements OnInit {

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  isLoading: boolean = false; // flag to show the loader
  discountCodeSaving: boolean = false; // flag to disable save button to prevent multiple clicks
  durationLength: boolean = false; // flag to show or hide the duration months field

  // creates the song form
  discountCodeForm = this.formBuilder.group({
    userId: [this.data.discountCode.userId],
    code: ['', [Validators.required]],
    percentOff: ['', [Validators.required, Validators.min(1), Validators.max(90)]],
    duration: ['', [Validators.required]]
  });

  constructor(
    public dialogRef: MatDialogRef<DiscountCodeFormComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private stripeService: StripeService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
  }

  checkDuration(event: any){
    console.log('durration: ', event.value);
    if(event.value === 'repeating'){      
      this.discountCodeForm.addControl('durationMonths', new UntypedFormControl('', [Validators.required, Validators.min(1)]));
      this.durationLength = true;
    } else {
      this.discountCodeForm.removeControl('durationMonths');
      this.durationLength = false;
    }
  }

  /**
  * @description saves the discount form data for the artist
  * @returns { void }
  */
  saveDiscountCode() {
    this.discountCodeSaving = true;
    this.loaderService.loaderSubject$.next(true);

    console.log('add-discount-code', this.discountCodeForm.value);
    
    this.subscriptions.add(
      this.stripeService.createStripeAccountDiscountCode(this.discountCodeForm.value).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.dialogRef.close({ event: this.data.mode, data: res });
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            this.discountCodeSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description the event that closes the discount code form dialog
   */
  onCancelClick(): void {
    this.dialogRef.close({ event: 'close' });
  }

}
