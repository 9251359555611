import { Injectable } from "@angular/core";
import { Moment } from "moment";
import * as moment from 'moment';

// pipes
import { TimePipe } from 'src/app/global/pipes/time/time.pipe';

@Injectable()
export class ScheduleHelper {

    constructor(
        private timePipe: TimePipe
    ) {
    }

    calculateEventCost(startTime: Moment, endTime: Moment, pricePerHour: number, cancelPercentage: number, discountPercentage?: number): any {

        let eventLength: number = endTime.diff(startTime, 'minutes', true);
        let eventLengthReal: number = (eventLength / 60);

        let eventPricesReal: any = {
            price: eventLengthReal * (pricePerHour * 100),
            discount: 0,
            discountedPrice: 0,
            cancelFee: (eventLengthReal * (pricePerHour * 100)) * (cancelPercentage / 100)
        };

        if (discountPercentage && discountPercentage > 0) {
            eventPricesReal.discount = eventPricesReal.price * (discountPercentage / 100);
            eventPricesReal.discountedPrice = Math.floor(eventPricesReal.price - eventPricesReal.discount);
            eventPricesReal.cancelFee = Math.floor(eventPricesReal.discountedPrice * (cancelPercentage / 100));
        }

        let eventPrices: any = {
            length: eventLength,
            price: (eventPricesReal.price / 100),
            discount: (eventPricesReal.discount / 100),
            discountedPrice: (eventPricesReal.discountedPrice / 100),
            cancelFee: (eventPricesReal.cancelFee / 100)
        };

        return eventPrices;
    }

    /**
     * @description gets the artists available days
     * @param date { moment } the day being checked
     * @param days { Array<any> } the days on the calendar
     * @param events { Array<any> } the artists scheduled events
     * @param showRequirementsData { any } the artists show requirements
     * @returns boolean that tells if the day is available
     */
    isDayAvailable(date: Moment, days: any[], events: any[], showRequirementsData: any): boolean {
        let available: boolean = false;

        const day = date.day();

        // check to see if the user is available on that day of the week
        if (days[day].isSelected) {
            let startTimes = this.availableStartTimes(date, days, events, showRequirementsData);
            if (startTimes.length > 0) {
                available = true;
            }
        }

        return available;
    }

    /**
     * @description round to nearest durtation
     * @param date { Moment }
     * @param duration { moment.Duration }
     * @param method { string }
     * @returns { Moment }
     */
    round(date: Moment, duration: moment.Duration, method: string) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
    }

    /**
     * @description gets the availible start times for a selected start date
     * @param selected { Moment } the selected day
     * @param days { Array<any> } the artists available days
     * @param events { Array<any> } the artists scheduled events
     * @param showRequirementsData { any } the artists show requirements
     * @returns Array<any> of available start times
     */
    availableStartTimes(selected: Moment, days: any[], events: any[], showRequirementsData: any): Array<any> {
        let startTimes: Array<any> = [];

        const day = selected.day();
        //Get the times for the booking user based on the selected day
        let times = days.map(x => {
            if (x.times && x.times.length) {
                return x.times.filter(x => moment(x.startTime).day() === day || moment(x.endTime).day() === day);
            }
        }).filter(x => x && x.length).sort((a, b) => {
            return moment(a.originalStartTime).isBefore(moment(b.originalStartTime)) ? -1 : 1;
        }).flat();
        let filteredEvents = events.filter(x => moment(x.eventStart).format('L') === selected.format('L'));
        let now = moment().add(15, 'minutes');
        let minimumShowLength = this.timePipe.timeConvert(showRequirementsData.minimumShowLength);
        //If we get any time for this day mark it as selected so we know if the future
        if (times && times.length) days[day].isSelected = true;
        for (let time of times) {
            //Set the start and end time retaining any difference in days bewteen the two
            this.setStartAndEndTimeForSelected(time, selected);

            let startTime = moment(time.startTime);
            if (startTime.isBefore(now)) {
                startTime = this.round(now, moment.duration(15, "minutes"), "ceil");
            }

            let endTime = moment(time.endTime);

            // to prevent user from selecting a start time that would violate the artists minimum show length
            endTime = endTime.clone().subtract(minimumShowLength.days, 'days').subtract(minimumShowLength.hours, 'hours').subtract(minimumShowLength.minutes, 'minutes');
            //If the start time and end time doesn't actually get into today skip it
            if (startTime.day() !== day && endTime.isSameOrBefore(selected.clone().startOf('day'))) continue;
            while (startTime.isSameOrBefore(endTime)) {
                //If we get start times prior to the current selected skip them till we get to the current day
                if (startTime.isBefore(selected.clone().startOf('day'))) {
                    startTime = startTime.clone().add(15, 'minutes');
                    continue;
                }
                // check if artist has any booked events
                if (filteredEvents.length > 0) {
                    for (let event of filteredEvents) {
                        let start = moment(event.eventStart);
                        let end = moment(event.eventEnd).add(15, 'minutes'); // add 15 min buffer to end of booked event to prevent booking a event to close together
                        // check to see if start time is between the booked events start time and end time + 15 min if so don't add to list

                        if (!startTimes.find(x => x.isSame(startTime)) && !startTime.isBetween(start, end, undefined, '[]') && !endTime.isBetween(start, end, undefined, '[]')) {
                            startTimes.push(startTime.clone());
                        }

                    }
                } else if (!startTimes.find(x => x.isSame(startTime))) {
                    startTimes.push(startTime.clone());
                }
                let previousStartTime = startTime.clone();
                startTime = startTime.clone().add(15, 'minutes');
                if (previousStartTime.format('L') !== startTime.format('L')) {
                    break;
                }
            }

        }

        //Make sure startTimes are sorted
        return startTimes.sort();
    }

    /**
     * @description gets the availible endtimes for a selected start date and start time
     * @param selected { Moment } the selected day 
     * @param selectedStartTime { Moment } the selected start time
     * @param days { Array<any> } the artists available days
     * @param events { Array<any> } the artists scheduled events
     * @param showRequirementsData { any } the artists show requirements
     * @returns Array<any> of available end times
     */
    availableEndTimes(selected: Moment, selectedStartTime: Moment, days: any[], events: any[], showRequirementsData: any): Array<any> {
        let endTimes: Array<any> = [];

        const day = selected.day();
        //Get the times for the booking user based on the selected day
        let times = days.map(x => {
            if (x.times && x.times.length) {
                return x.times.filter(x => moment(x.endTime).day() === day || moment(x.startTime).day() === day);
            }
        }).filter(x => x && x.length).flat();
        let filteredEvents = events.filter(x => moment(x.eventStart).format('L') === selected.format('L'));

        let minimumShowLength = this.timePipe.timeConvert(showRequirementsData.minimumShowLength);
        let maximumShowLength = this.timePipe.timeConvert(showRequirementsData.maximumShowLength);

        for (let time of times) {

            let endTime = moment(time.endTime);

            let start = moment(time.startTime);
            //Default to endTime
            let end = endTime;
            //If there's a carrover use that instead
            if (time.carryOverTime) {
                end = moment(time.carryOverTime.endTime);
                //In certain cases the end time date has never been updated to selected and might be prior to start. Add a day since its a carryover
                if (end.isBefore(start)) end = moment(start.format('L') + ' ' + end.format('LT')).add(1, 'days');
            }

            if (selectedStartTime.isBetween(start, end, undefined, '[)')) {

                // to prevent user from selecting a start time that would violate the artists minimum show length
                let minEndTime = selectedStartTime.clone().add(minimumShowLength.days, 'days').add(minimumShowLength.hours, 'hours').add(minimumShowLength.minutes, 'minutes');

                // to prevent user from selecting a start time that would violate the artists maximum show length
                let maxEndTime = selectedStartTime.clone().add(maximumShowLength.days, 'days').add(maximumShowLength.hours, 'hours').add(maximumShowLength.minutes, 'minutes');

                // make sure the max end time does not exceed the endtime in the array
                if (maxEndTime.isAfter(endTime)) {
                    maxEndTime = endTime.clone();
                }

                while (minEndTime.isSameOrBefore(maxEndTime)) {
                    // check if artist has any booked events
                    if (filteredEvents.length > 0) {
                        for (let event of filteredEvents) {
                            let start = moment(event.eventStart);
                            let end = moment(event.eventEnd).add(15, 'minutes'); // add 15 min buffer to end of booked event to prevent booking a event to close together
                            // check to see if end time is between the booked events start time and end time + 15 min if so don't add to list 
                            if (!endTimes.find(x => x.isSame(minEndTime)) && !minEndTime.isBetween(start, end, undefined, '[]')) {
                                endTimes.push(minEndTime.clone());
                            }
                        }
                    } else {
                        endTimes.push(minEndTime.clone());
                    }
                    minEndTime = minEndTime.clone().add(15, 'minutes');
                }
            }
        }

        return endTimes.sort();
    }

    /**
     * @description this gets the endtime and adds a day if needed to keep the date/times correct
     * @param time { any }
     * @returns { Moment } of the correct end time
     */
    setStartAndEndTimeForSelected(time: any, selected: any) {
        // combine the date selected and the times to make a correct date time string for the start and end times
        let startTime = moment(selected.format('L') + ' ' + moment(time.originalStartTime).format('LT')).day(moment(time.originalStartTime).day());
        let endTime = moment(selected.format('L') + ' ' + moment(time.originalEndTime).format('LT')).day(moment(time.originalEndTime).day());
        //Handle startTime dates being shifted a week ahead by day set
        while (startTime.clone().startOf('day').isAfter(selected)) startTime.subtract(7, 'days');
        //Make sure endTime is still after startTime
        while (endTime.isSameOrBefore(startTime)) endTime.add(7, 'days');
        // combine the date selected and the times to make a correct date time string for the start and end times
        time.startTime = startTime.toLocaleString();
        time.endTime = endTime.toLocaleString();
    }

}