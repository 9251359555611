// modules
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, first, Subscription } from 'rxjs';

// capacitor
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { StripeService } from '../../services/stripe/stripe.service';
import { LoaderService } from '../../services/loader/loader.service';
import { RoutingStateService } from '../../services/routing-state/routing-state.service';
import { PlatformMediaService } from 'src/app/admin/services/platform-media/platform-media.service';
import { UserService } from 'src/app/profile/services/user/user.service';
import { EventService } from 'src/app/event/services/event/event.service';

// components
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { SuccessMessageComponent } from '../success-message/success-message.component';
import { DeleteAccountWarningComponent } from '../delete-account-warning/delete-account-warning.component';

// environments
import { GlobalConstants } from '../../constants/global';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  adminRouteLinks: any[] = []; // the route links for the admin menu items
  imageBaseUrl: string; // the base url for the images
  desktopLogo: string; // object with the default desktop logo 
  mobileLogo: string; // object with the default mobile logo  
  isAuthenticated: boolean = false; // flag to tell user is authenticated
  isNative: boolean = false; // flag to tell if app is native
  isIos: boolean = false; // flag to tell if app is iOS
  username: string; // current users username
  roles: string[] = []; // string array of current users roles
  scrollPosition: number = 0; // current scroll position
  currentRoute: string = ''; // the current route url path
  screenHeight: number = window.innerHeight; // the current screen height of the device or browser window
  hideNavbar: boolean = false; // flag to hide or show navbar
  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template
  theme: string = 'dark'; // flag to tell if app is native

  // listens to the window scroll event and sets scroll position property
  @HostListener('window:scroll', ['$event']) onScroll($event: Event): void {
    if ($event) {
      this.scrollPosition = window.pageYOffset;
      // console.log(this.scrollPosition);
    }
  }

  // listens to the window resize event and sets screen height property
  @HostListener('window:resize', ['$event'])
  onResize($event: Event): void {
    this.screenHeight = window.innerHeight;
  }

  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private userService: UserService,
    private eventService: EventService,
    private platformMediaService: PlatformMediaService,
    public stripeService: StripeService,
    public loaderService: LoaderService,
    public routingState: RoutingStateService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.globalConstants = GlobalConstants;
    this.imageBaseUrl = environment.imageBaseUrl;

    this.isNative = Capacitor.isNativePlatform(); // set the native flag based on device used
    this.isIos = Capacitor.getPlatform() === 'ios'; // set the ios flag if using ios device

    console.log('location: ', this.location);
    // if native add keyboard event listeners
    if (this.isNative) {
      Keyboard.addListener('keyboardWillShow', info => {
        console.log('keyboard will show:', info);
        this.hideNavbar = true;
        console.log('hide:', this.hideNavbar);
      });

      Keyboard.addListener('keyboardWillHide', () => {
        console.log('keyboard will hide');
        this.hideNavbar = false;
        console.log('hide:', this.hideNavbar);
      });
    }

    this.subscriptions.add(
      this.loaderService.theme$.subscribe(theme => {
        this.theme = theme;
      })
    );

    this.subscriptions.add(
      this.platformMediaService.platformMediaDefault$.subscribe(defaults => {
        if (defaults && defaults && defaults.length > 0) {

          let desktop = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.desktopNavbarLogoType.id);
          let mobile = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.mobileNavbarLogoType.id);

          if (desktop && desktop.length > 0 && desktop[0].mediaUrl && this.imageBaseUrl) {
            this.desktopLogo = this.imageBaseUrl + desktop[0].mediaUrl;
          } else {
            // if (this.theme === 'light') {
            //   this.desktopLogo = "assets/logos/toonballoon_logo_light.webp";
            // } else {
            this.desktopLogo = "assets/logos/toonballoon_logo_dark.webp";
            // }
          }

          if (mobile && mobile.length > 0 && mobile[0].mediaUrl && this.imageBaseUrl) {
            this.mobileLogo = this.imageBaseUrl + mobile[0].mediaUrl;
          } else {
            // if (this.theme === 'light') {
            //   this.mobileLogo = "assets/logos/toonballoon_logo_notext_light.webp";
            // } else {
            this.mobileLogo = "assets/logos/toonballoon_logo_notext_dark.webp";
            // }
          }
        } else {
          // this.desktopLogo = "assets/logos/toonballoon_logo_notext.webp";
          // this.mobileLogo = "assets/logos/toonballoon_logo_light.webp";
          // if (this.theme === 'light') {
          //   this.desktopLogo = "assets/logos/toonballoon_logo_light.webp";
          //   this.mobileLogo = "assets/logos/toonballoon_logo_notext_light.webp";
          // } else {
          this.desktopLogo = "assets/logos/toonballoon_logo_dark.webp";
          this.mobileLogo = "assets/logos/toonballoon_logo_notext_dark.webp";
          // }

        }
      })
    );

  }

  ngOnInit(): void {

    // sets the authenticated flag
    this.subscriptions.add(
      this.authService.authenticated$.subscribe(auth => {
        this.isAuthenticated = auth;
      })
    );

    // sets the user roles property
    this.subscriptions.add(
      this.authService.authenticatedUserRole$.subscribe(roles => {
        this.roles = roles;
        this.setAdminRouteLinks();
      })
    );

    // sets the username property
    this.subscriptions.add(
      this.authService.authenticatedUser$.subscribe(user => {
        if (user) {
          this.username = user.find(res => {
            return res.Name === 'email';
          }).Value;
        }
      })
    );

    // sets the current route url path
    this.subscriptions.add(
      this.router.events.pipe(
        filter((e: any): e is RouterEvent => e instanceof RouterEvent)
      ).subscribe((evt: RouterEvent) => {
        if (evt instanceof NavigationEnd) {
          // console.log(evt.url);
          this.currentRoute = evt.url;
        }
      })
    );
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
  * @description sets all the navigation links for the menu
  */
  private setAdminRouteLinks() {
    this.adminRouteLinks = [
      { link: "/users", name: "Users", icon: "people", hide: !this.roles?.includes('Admin') },
      { link: "/tags", name: "Tags", icon: "style", hide: !this.roles?.includes('Admin') },
      { link: "/media", name: "Media", icon: "collections", hide: !this.roles?.includes('Admin') },
      { link: "/settings", name: "Settings", icon: "settings", hide: !this.roles?.includes('Admin') },
    ];
  }

  /**
   * @description sets the default back functionality
   */
  back() {
    this.loaderService.loaderSubject$.next(true);
    this.routingState.back();
  }

  /**
   * @description changes the theme based on what the user chooses
   */
  changeTheme() {

    let currentTheme = localStorage.getItem('theme');

    if (currentTheme === 'dark') {
      localStorage.setItem('theme', 'light');
      this.loaderService.themeSubject$.next('light');
    } else {
      localStorage.setItem('theme', 'dark');
      this.loaderService.themeSubject$.next('dark');
    }

  }

  /**
   * @description fires the change password process for the current authenticated user
   */
  changePassword() {
    this.authService.forgotPassword().then(result => {
      console.log('requestVerificationCode: ', result);
      this.router.navigate([`../reset-password`], { relativeTo: this.route });
      this.snackBar.openFromComponent(SuccessMessageComponent, {
        data: `Verification code sent to ${result.email}.`,
        duration: 10000,
        panelClass: ['success-snackbar']
      });
    }).catch(reason => {
      console.log('Failed: ', reason);
      this.snackBar.openFromComponent(ErrorMessageComponent, {
        data: 'Failed to send verification code!',
        duration: 10000,
        panelClass: ['error-snackbar']
      });
    });
  }

  deleteAccountDialog(): void {
    this.subscriptions.add(
      this.authService.authenticatedUserData$.pipe(first()).subscribe(user => {

        const dialogRef = this.dialog.open(DeleteAccountWarningComponent, {
          width: '500px',
          data: user,
        });
        this.subscriptions.add(
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            if (result && result.id === user.id) {
              this.deleteAccount(result);
            }
          })
        );
      })
    );
  }

  /**
   * @description fires the delete account process for the current authenticated user
   */
  private deleteAccount(profileData: any) {
    this.loaderService.loaderSubject$.next(true);
    console.log('delete-profile', profileData);

    this.subscriptions.add(
      this.userService.deleteUser(profileData).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'User account delete succeeded!',
              duration: 5000,
              panelClass: ['success-snackbar']
            });
            this.authService.signOut();
          },
          error: (e) => {
            console.error(e);
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: 'User account delete failed!',
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => {
            console.info('complete');
            this.loaderService.loaderSubject$.next(false);
          }
        })
    );
  }

  /**
   * @description signs the current user out
   */
  signOut(): void {
    this.snackBar.dismiss();
    console.log('Sign out');
    this.authService.signOut().then(() => {
      if (!this.isAuthenticated) {
        console.log('signed out!');
      };
    }).catch(reason => {
      console.log('Failed: ', reason);
      this.snackBar.openFromComponent(ErrorMessageComponent, {
        data: 'Failed to sign out!',
        duration: 10000,
        panelClass: ['error-snackbar']
      });
    });
  }

}
