import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: number): string {

    let time = this.timeConvert(value);

    return this.timeFormat(time);
  }

  /**
  * @description converts timestamp into days, hours, minutes
  * @param time { number }
  * @returns { days: number, hours: number, minutes: number }
  */
  public timeConvert(time: number) {

    let d: number = Math.floor(Number(time / (24 * 60)));
    let h: number = Math.floor(Number((time % (24 * 60)) / 60));
    let m: number = Math.floor(Number((time % (24 * 60)) % 60));

    let result = { days: d, hours: h, minutes: m };

    return result;
  }

  /**
 * @description formats timestamp into days, hours, minutes
 * @param time {  days: number, hours: number, minutes: number }
 * @returns { string }
 */
  private timeFormat(time: any) {

    let result = '';

    if (time.days === 0 && time.hours === 0 && time.minutes === 0) {
      result = '0m';
    } else {
      if (time.days > 0) {
        result = result + time.days + 'd ';
      }

      if (time.hours > 0) {
        result = result + time.hours + 'h ';
      }

      if (time.minutes > 0) {
        result = result + time.minutes + 'm';
      }
    }

    return result;
  }

}
