<div class="mat-simple-snackbar">
    <div class="mat-simple-snack-bar-content">
        <h3>
            <mat-icon class="message-icon">rule</mat-icon> Stripe Payment Account Incomplete
        </h3>
        <p>You must complete your Stripe payment account for others to find and book you.</p>        
    </div>
    <div class="mat-simple-snackbar-action">
        <ng-container *ngIf="data.stripeStatus">
            <ng-container *ngIf="!data.stripeStatus.setup; else default">
                <button class="icon-button" mat-button [stripeConnectButton]="{ stripeAccountId: data.stripeAccountId, linkType: 'account_onboarding' }">
                    <mat-icon>navigate_next</mat-icon> Go
                </button>
            </ng-container>
            <ng-template #default>
                <button class="icon-button" mat-button [stripeConnectButton]="{ stripeAccountId: data.stripeAccountId, linkType: 'login' }">
                    <mat-icon>navigate_next</mat-icon> Go
                </button>
            </ng-template>
        </ng-container>
        <button class="icon-button" mat-button (click)="sbRef.dismiss()">
            <mat-icon>close</mat-icon> Dismiss
        </button>       
    </div>
</div>
