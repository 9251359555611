import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, first, interval, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

// capacitor
import { Capacitor } from '@capacitor/core';
import { Camera } from '@capacitor/camera';
import { Microphone } from '@mozartec/capacitor-microphone';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

// services
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { EventService } from '../../services/event/event.service';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { RoutingStateService } from 'src/app/global/services/routing-state/routing-state.service';

// helpers
import { StreamHelper } from '../../helpers/stream.helper';
import { StreamControlsHelper } from '../../helpers/streamControls.helper';
import { StreamCastHelper } from '../../helpers/streamCast.helper';


// window
declare var window: any;

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class StreamComponent implements OnInit, AfterViewInit, OnDestroy {

  // listens to the window resize event and sets qr code width
  @HostListener('window:resize', ['$event'])
  onResize($event: Event): void {
    this.setQrWidth(window.innerWidth);
  }

  // listens to the window back event and disconnects the webrtc connection
  @HostListener('window:onpopstate', ['$event'])
  onBackPressed($event: Event): void {
    if ($event) {
      $event.preventDefault();
      this.back();
      // this.routingState.back();
    }
  }

  qrCodeWidth: number = 50; // the width of the qr code
  isNative: boolean = false; // flag to tell if native app
  isArtist: boolean = false; // flag to tell if user is an artist
  isIos: boolean = false; // flag to tell if device is ios;
  user: any // the authenticated user
  eventId: number; // the event id
  eventData: any; // the raw event data
  controlBarElement: any; // the html element that contians the control bar
  timer: any; // the public countdown timer value that is displayed on the page
  streamId: string; //string id to pass to the chromecast so it knows who the caster is
  backRoute: string; // the route for the back button
  //Params for mrtc connection
  params: any = {};

  videoexists: boolean = false;

  cameraSelected: any;
  micSelected: any;
  speakerSelected: any;

  cameraAllowed: boolean = false; // flag to tell if camera permissions have been granted
  micAllowed: boolean = false;// flag to tell if microphone permissions have been granted

  mediaSettingsDialogRef: any; // element reference to reactivate tag dialog

  micList: Array<any> = [];
  cameraList: Array<any> = [];
  speakerList: Array<any> = [];

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routingState: RoutingStateService,
    private authService: AuthService,
    private eventService: EventService,
    private loaderService: LoaderService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private streamHelper: StreamHelper,
    private streamControlsHelper: StreamControlsHelper,
    private streamCastHelper: StreamCastHelper
  ) {
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.isNative = Capacitor.isNativePlatform(); // sets the native flag based on if a native app or not

    this.eventId = this.route.snapshot.params['id'];
    this.backRoute = this.router.url.replace('/stream', '');

    this.subscriptions.add(
      this.streamHelper.camera$.subscribe(camera => {
        this.cameraSelected = camera;
      })
    );

    this.subscriptions.add(
      this.streamHelper.mic$.subscribe(mic => {
        this.micSelected = mic;
      })
    );

    this.subscriptions.add(
      this.streamHelper.speaker$.subscribe(speaker => {
        this.speakerSelected = speaker;
      })
    );
  }

  async ngOnInit() {

    this.setQrWidth(window.innerWidth);

    this.subscriptions.add(
      this.authService.authenticatedUserData$.pipe(first()).subscribe({
        next: async (res) => {
          if (res) {
            this.user = res;
            await this.checkPermissions(); // check camera and mic permissions            
          }
        },
        error: (e) => {
          console.error(e);
          // hide the loader
          this.loaderService.loaderSubject$.next(false);
          this.snackBar.openFromComponent(ErrorMessageComponent, {
            data: e,
            duration: 10000,
            panelClass: ['error-snackbar']
          });
        },
        complete: () => console.info('complete')
      })
    );

  }

  ngAfterViewInit(): void {
    this.controlBarElement = document.getElementById('controlBar');
    if (!this.isNative) {
      document.addEventListener('fullscreenchange', () => { this.streamControlsHelper.exitHandler(document, this.isNative) }, false);
      document.addEventListener('mozfullscreenchange', () => { this.streamControlsHelper.exitHandler(document, this.isNative) }, false);
      document.addEventListener('MSFullscreenChange', () => { this.streamControlsHelper.exitHandler(document, this.isNative) }, false);
      document.addEventListener('webkitfullscreenchange', () => { this.streamControlsHelper.exitHandler(document, this.isNative) }, false);
    }

    let that = this;

    let observer = new MutationObserver((mutations) => {
      mutations.forEach(function (mutation) {
        mutation.addedNodes.forEach((addedNode: any) => {
          // Do things to `addedNode` here
          if (addedNode?.media?.classList?.contains('remote-video')) {
            that.videoexists = true;
          }
        });
        mutation.removedNodes.forEach((removedNode: any) => {
          // Do things to `addedNode` here
          if (removedNode?.media?.classList?.contains('remote-video')) {
            that.videoexists = false;
          }
        });
      });
    });

    let node = document.getElementById('stream');

    observer.observe(node, { childList: true, subtree: false });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.streamHelper.stopBothVideoAndAudio();
  }

  // /**
  //  * @description a test method to check if chromecast is getting the data
  //  */
  // sendEventData() {
  //   this.streamCastHelper.sendEventData(this.eventData, this.user, this.streamId);
  // }

  async manualCheck() {
    await NativeSettings.open({
      optionAndroid: AndroidSettings.ApplicationDetails,
      optionIOS: IOSSettings.App
    });

    await this.checkPermissions();
  }

  /**
   * @description this chacks both camera and mic permissions before getting the event data and starting the web rtc streaming
   */
  async checkPermissions() {
    if (!this.cameraAllowed) {
      await this.checkCameraPermissions();
    }
    if (!this.micAllowed) {
      await this.checkMicPermissions();
    }
    if (this.cameraAllowed && this.micAllowed) {
      await this.getEvent();
    }
  }

  /**
   * @description checks if the device is native and then if camera permissions have been granted. If not it prompts the user to grant permissions.
   */
  async checkCameraPermissions() {
    if (this.isNative) {
      let state = await Camera.checkPermissions();

      if (state.camera !== 'granted') {
        let requestResult = await Camera.requestPermissions();
        if (requestResult.camera === 'granted') {
          this.cameraAllowed = true;
        }
        console.log('camera request: ', requestResult);
      } else {
        this.cameraAllowed = true;
      }
    } else {
      this.cameraAllowed = true;
    }
  }

  /**
   * @description checks if the device is native and then if microphone permissions have been granted. If not it prompts the user to grant permissions.
   */
  async checkMicPermissions() {
    if (this.isNative) {
      let state = await Microphone.checkPermissions();

      if (state.microphone !== 'granted') {
        let requestResult = await Microphone.requestPermissions();
        if (requestResult.microphone === 'granted') {
          this.micAllowed = true;
        }
        console.log('microphone request: ', requestResult);
      } else {
        this.micAllowed = true;
      }
    } else {
      this.micAllowed = true;
    }
  }

  /**
   * @description gets the event from the database by the passed in event id
   */
  private async getEvent() {
    // get the authenticated users profile data
    this.subscriptions.add(
      this.eventService.getEvent(this.eventId, this.user.id).pipe(first()).subscribe(
        {
          next: async (res) => {
            console.log(res);
            if (!res) return; // stop if there is no result
            this.eventData = res.data; // set raw profile data
            let startTimeStamp = moment(res.data.dateTimeStart).valueOf(); //convert to unix time stamp
            let endTimeStamp = moment(res.data.dateTimeEnd).valueOf(); //convert to unix time stamp
            this.countdown(res.data.dateTimeEnd);
            this.params = {
              open: 'false',
              sessionid: `${res.data.id}${startTimeStamp}${endTimeStamp}`,
              publicRoomIdentifier: `${res.data.id}${startTimeStamp}${endTimeStamp}`,
              userFullName: this.user.displayName,
              wssPostUrl: ''
            };

            this.isArtist = (res.data.bookedUserId === this.user.id);

            await this.getMediaDevices();

            navigator.mediaDevices.addEventListener("devicechange", async (event) => {
              this.micList = [];
              this.cameraList = [];
              this.speakerList = [];
              await this.getMediaDevices();
            });

            this.streamHelper.params = this.params;
            this.streamHelper.streamComponent = this;
            this.streamHelper.doc = document;
            this.streamHelper.isArtist = this.isArtist;

            await this.streamHelper.mrtcReady();
          },
          error: (e) => {
            console.error(e);
            // hide the loader
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description set the venmo QR code width bsed on screen size
   * @param width { number } the current screen width in pixels
   */
  private setQrWidth(width: number) {
    if (width >= 1080) {
      this.qrCodeWidth = 100;
    } else {
      this.qrCodeWidth = 50;
    }
  }

  /**
     * @description calculates the remaining time in a event based off the enddatetime and now
     * @param endtime { Date } the event end date time
     */
  private countdown(endtime: Date) {

    let intervalSeconds = 1000;
    this.subscriptions.add(
      interval(intervalSeconds).subscribe(() => {

        let eventEndTime = moment(endtime); // end time in milliseconds
        let currentTime = moment(); // now in milliseconds        
        let duration = eventEndTime.diff(currentTime); // in milliseconds
        // check to see if the event is over and display 0 if it is
        if (eventEndTime > currentTime) {
          this.timer = moment.utc(duration).format('hh:mm:ss'); // must be converted to utc to not be offset
        } else {
          this.timer = '00:00:00';
        }
      })
    );
  }

  back() {
    this.loaderService.loaderSubject$.next(true);
    this.router.navigateByUrl(this.backRoute);
  }

  /**
   * @description the open fullscreen method that opens the event stream in fullscreen
   */
  openFullscreen() {

    this.streamControlsHelper.enterHandler(document);

    if (!this.isNative) {
      this.streamControlsHelper.toggleFullScreen(document);
    }
  }

  /**
   * @description the close fullscreen event for the button click
   */
  closeFullscreen() {
    this.streamControlsHelper.exitHandler(document, this.isNative);

    if (!this.isNative) {
      this.streamControlsHelper.toggleFullScreen(document);
    }
  }

  /**
   * @description this starts the chromecast
   */
  async startCast() {
    await this.streamCastHelper.initiateCast();
  };

  /**
   * @description opens the changeCameraMic dialog and sets up the close event
   * @param changeCameraMicTemplateRef { any }
   */
  mediaSettingsDialog(mediaSettingsTemplateRef: any): void {
    this.mediaSettingsDialogRef = this.dialog.open(mediaSettingsTemplateRef);

    this.subscriptions.add(
      this.mediaSettingsDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  private async getMediaDevices() {

    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {

      //Pass parameter to kill the stream immediately after we get it so we don't block future requests to the camera
      await this.streamHelper.safeGetUserMedia({ audio: true, video: true }, true);

      // List cameras and microphones.
      let devices = await navigator.mediaDevices.enumerateDevices();

      if (devices && devices.length > 0) {
        for (let device of devices) {
          console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
          if (!this.isNative) {
            if (device.kind === 'videoinput' && this.cameraList.filter(x => x.label === device.label).length === 0) {
              this.cameraList.push(device);
            }
          }
          if (device.kind === 'audioinput' && this.micList.filter(x => x.label === device.label).length === 0) {
            this.micList.push(device);
          }
          if (device.kind === 'audiooutput' && this.speakerList.filter(x => x.label === device.label).length === 0) {
            this.speakerList.push(device);

          }
        }

        if (this.isNative) {
          this.cameraList.push({ deviceId: 'user', label: 'Front Camera', groupId: 'user', kind: 'videoinput' });
          this.cameraList.push({ deviceId: 'environment', label: 'Rear Camera', groupId: 'environment', kind: 'videoinput' });
          // this.micList.push({ deviceId: 'default', label: 'Device Mic', groupId: 'default', kind: 'audioinput' });
        }

        let currentCamera = localStorage.getItem('camera');
        let currentMic = localStorage.getItem('mic');
        let currentSpeaker = localStorage.getItem('speaker');

        await this.checkMedia('camera', this.cameraList, this.cameraSelected, currentCamera, this.streamHelper.cameraSubject$, false);
        await this.checkMedia('mic', this.micList, this.micSelected, currentMic, this.streamHelper.micSubject$);
        await this.checkMedia('speaker', this.speakerList, this.speakerSelected, currentSpeaker, this.streamHelper.speakerSubject$);
      }
    }
  }

  muteSpeakers(muted: boolean) {
    let remoteVideos: any = document.getElementsByClassName('remote-video');
    let muteSpeakersElement = document.getElementById(`muteSpeakers`);
    let unmuteSpeakersElement = document.getElementById(`unmuteSpeakers`);

    if (remoteVideos.length > 0) {
      for (let video of remoteVideos) {
        video.muted = muted;
      }
    }

    switch (muted) {
      case true:
        muteSpeakersElement.classList.add('hidden');
        unmuteSpeakersElement.classList.remove('hidden');
        break;
      default:
        unmuteSpeakersElement.classList.add('hidden');
        muteSpeakersElement.classList.remove('hidden');
    }

  }

  changeMedia(event: any, type: string, runChangeMic: boolean = true) {
    console.log(event);
    switch (type) {
      case 'camera':
        localStorage.setItem('camera', event.value.label);
        this.streamHelper.cameraSubject$.next(event.value);
        if (runChangeMic) this.streamHelper.changeMicAndCameraDuringActiveSession();
        break;
      case 'mic':
        localStorage.setItem('mic', event.value.label);
        this.streamHelper.micSubject$.next(event.value);
        this.streamHelper.changeMicAndCameraDuringActiveSession();
        break;
      default:
        localStorage.setItem('speaker', event.value.label);
        this.streamHelper.speakerSubject$.next(event.value)
        // Attach audio output device to video element using device/sink ID.
        let remoteVideo: any = document.getElementsByClassName('remote-video');
        if (remoteVideo && remoteVideo.length > 0 && typeof remoteVideo[0].sinkId !== 'undefined') {
          remoteVideo[0].setSinkId(event.value.deviceId)
            .then(() => {
              console.log(`Success, audio output device attached: ${event.value.label}`);
            })
            .catch(error => {
              let errorMessage = error;
              if (error.name === 'SecurityError') {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
              }
              console.error(errorMessage);
              // Jump back to first output device in the list as it's the default.
              // audioOutputSelect.selectedIndex = 0;
            });
        } else {
          console.warn('Output device selection not supported.');
        }
    }
  }

  private async checkMedia(type: string, deviceList: Array<any>, deviceSelected: any, currentDevice: any, subject: BehaviorSubject<any>, runChangeMic: boolean = true) {
    if (deviceList.length > 0) {
      if (!deviceSelected && deviceList.filter(x => x.label === currentDevice).length > 0) {
        let device = deviceList.find(x => { if (x.label === currentDevice) return x });
        subject.next(device);
      } else {
        if (deviceList.filter(x => x.label === deviceSelected?.label).length === 0) {
          let firstDevice = deviceList[0];

          this.changeMedia({ value: firstDevice }, type, runChangeMic);
        } else if (deviceSelected) {
          let selectedDevice = deviceList.find(x => { if (x.label === deviceSelected?.label) return x });
          this.changeMedia({ value: selectedDevice }, type, runChangeMic);
        }
      }
    }
  }

}
