import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, first, Observable } from 'rxjs';

// environments
import { environment } from 'src/environments/environment';

// services
import { HttpBaseService } from '../http-base/http-base.service';
import { TokenService } from 'src/app/auth/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class SpotifyService extends HttpBaseService<any> {

  private genresInstance: string[] = []; // genres string array property
  private genresSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]); // subject that contains the genres string array
  private genresObservable: Observable<string[]> = this.genresSubject.asObservable(); // genres subject observable

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/`, _http, _tokenService);
  }

  ngOnDestroy(): void {
    // unsubscribes all base service subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description genres pull only pulls once
   * @returns { Observable<string[]> } of genres
   */
  genres(): Observable<string[]> {
    if (!this.genresInstance || this.genresInstance.length < 1) {
      this.subscriptions.add(
        this.get('spotify/genres')
          .pipe(first())
          .subscribe(response => {
            this.genresInstance = response.genres;
            this.genresSubject.next(this.genresInstance);
          })
      );
    }
    return this.genresObservable;
  }

  /**
   * @description gets the search result from the spotify API call
   * @param q { string }
   * @param types { string }
   * @param options { any }
   * @returns { Observable<any> } of search results
   */
  search(q: string, types: string, options?: any): Observable<any> {
    return this.get('spotify/search', { q: q, types: types, options: options });
  }

  /**
   * @description gets the search artist result from the spotify API call
   * @param artist { string }
   * @returns { Observable<any> } of artist search results
   */
  searchArtists(artist: string): Observable<any> {
    return this.search(artist, 'artist');
  }

  /**
   * @description gets the search track result from the spotify API call
   * @param track { string }
   * @returns { Observable<any> } of track search results
   */
  searchTracks(track: string): Observable<any> {
    return this.search(track, 'track');
  }

}
