// modules
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatestWith, map, Observable } from 'rxjs';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class NotAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private location: Location,
    private router: Router
  ) { }

  /**
   * @description checks if user is NOT authenticated before allowing them to navigate to the requested route
   * @param route { ActivatedRouteSnapshot }
   * @param state { RouterStateSnapshot }
   * @returns { Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree }
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.authCheck().pipe(
      combineLatestWith(this.authService.authenticated$),
      map(([check, auth]) => {
        let returnValue: boolean = true;
        if (auth && this.location.path() === '/sign-in') {
          returnValue = false;
          this.router.navigate(['/']);
        }

        return returnValue;
      })
    )

  }

}
