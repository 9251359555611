import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loaderSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); // subject with boolean value
  loader$: Observable<boolean> = this.loaderSubject$.asObservable(); // the loader subject observable

  themeSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('dark'); // subject with string value
  theme$: Observable<string> = this.themeSubject$.asObservable(); // the theme subject observable

  constructor() { 
  }
}
