<h2 mat-dialog-title>Search</h2>
<mat-dialog-content class="mat-typography no-scroll">
    <mat-form-field appearance="outline" color="primary">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Rock" type="search" [value]="filterObject.searchText"
            (keyup)="search($event.target.value)">
        <mat-icon matSuffix>search</mat-icon>
        <mat-hint>Type in a keyword.</mat-hint>
    </mat-form-field>
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
        <div fxFlex.lt-sm="0 1 100%" fxFlex="0 1 50%">
            <br />
            <mat-label class="check-list-title">Roles</mat-label>
            <section class="roles-section">
                <span class="roles-list-section">
                    <ul class="no-bullets">
                        <li *ngFor="let role of roleNames">
                            <mat-checkbox color="primary" [checked]="filterObject.roles.includes(role)" (change)="roles($event, role)">
                                {{role}}
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
        </div>
        <div fxFlex.lt-sm="0 1 100%" fxFlex="0 1 50%">
            <br />
            <mat-label class="check-list-title">Status</mat-label>
            <section class="roles-section">
                <span class="roles-list-section">
                    <ul class="no-bullets">
                        <li>
                            <mat-checkbox color="primary" [checked]="filterObject.status.includes('Active')" (change)="status($event, 'Active')">
                                Active
                            </mat-checkbox>
                        </li>
                        <li>
                            <mat-checkbox color="primary" [checked]="filterObject.status.includes('Inactive')" (change)="status($event, 'Inactive')">
                                Suspended
                            </mat-checkbox>
                        </li>
                    </ul>
                </span>
            </section>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="icon-button" mat-button mat-dialog-close (click)="close()"><mat-icon>close</mat-icon> Cancel</button>
    <button class="icon-button" mat-button [mat-dialog-close]="true" cdkFocusInitial
        (click)="filter()"><mat-icon>search</mat-icon> Search</button>
</mat-dialog-actions>