<h2 mat-dialog-title>Upload {{data.mode}}</h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="isNative; else desktop">
        <div class="file-input-button">
            <button class="icon-button full" mat-raised-button color="primary" (click)="openCamera()"><mat-icon>photo_camera</mat-icon> Camera</button>            
            <input class="hidden" id="cameraInput" type="file" (change)="fileChangeEvent($event)" accept="image/*" capture="camera" />            
        </div>
        <div class="file-input-button">
            <button class="icon-button full" mat-raised-button color="accent" (click)="openGallery()"><mat-icon>collections</mat-icon> Choose Image</button>
            <input class="hidden" id="galleryInput" type="file" (change)="fileChangeEvent($event)" accept="image/*" />
        </div>
    </ng-container>
    <ng-template #desktop>
        <input type="file" (change)="fileChangeEvent($event)" />
    </ng-template>
    
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="data.aspectRatio" [resizeToWidth]="data.width" [resizeToHeight]="data.height" [imageQuality]="50"
        format="png" output="base64" (imageCropped)="imageCropped($event)" (cropperReady)="cropperReady()" (imageLoaded)="imageLoaded($event)"
        (loadImageFailed)="loadImageFailed()">
    </image-cropper>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="icon-button" mat-button mat-dialog-close (click)="close()"><mat-icon>close</mat-icon> Cancel</button>
    <button class="icon-button" mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="done()" disabled="{{ croppedImage !== '' ? false : true }}"><mat-icon>done</mat-icon> Done</button>
</mat-dialog-actions>
