// modules
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

// services
import { AuthService } from '../../services/auth/auth.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { PlatformMediaService } from 'src/app/admin/services/platform-media/platform-media.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';
import { SuccessMessageComponent } from 'src/app/global/components/success-message/success-message.component';

// environments
import { GlobalConstants } from 'src/app/global/constants/global';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  imageBaseUrl: string; // the base url for the images
  forgotPasswordImage: string; // object with the default forgot password image
  forgotPasswordLogo: string; // object with the default sign in logo

  hide: boolean = true; // flag to tell input to show or hide password
  forgotPasswordSaving: boolean = false; // flag that disables save button while saving to prevent multiple clicks

  // creates the form group
  forgotPasswordForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]]
  });

  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template
  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private platformMediaService: PlatformMediaService,
    private loaderService: LoaderService,
    private snackBar: MatSnackBar
  ) {

    this.globalConstants = GlobalConstants;
    this.imageBaseUrl = environment.imageBaseUrl;

    let theme = localStorage.getItem('theme');

    this.subscriptions.add(
      this.platformMediaService.platformMediaDefault$.subscribe(defaults => {
        if (defaults && defaults && defaults.length > 0) {

          let image = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.resetPasswordImageType.id);
          let logo = defaults.filter(x => x.mediaTypeLookupId === this.globalConstants.mainLogoType.id);

          if (image && image.length > 0 && image[0].mediaUrl && this.imageBaseUrl) {
            this.forgotPasswordImage = this.imageBaseUrl + image[0].mediaUrl;
          } else {
            this.forgotPasswordImage = "assets/default-images/forgot-password.webp";
          }

          if (logo && logo.length > 0 && logo[0].mediaUrl && this.imageBaseUrl) {
            this.forgotPasswordLogo = this.imageBaseUrl + logo[0].mediaUrl;
          } else {
            if (theme === 'light') {
              this.forgotPasswordLogo = "assets/logos/toonballoon_logo_dark.webp";
            } else {
              this.forgotPasswordLogo = "assets/logos/toonballoon_logo_light.webp";
            }
          }
        } else {
          this.forgotPasswordImage = "assets/default-images/forgot-password.webp";
          if (theme === 'light') {
            this.forgotPasswordLogo = "assets/logos/toonballoon_logo_dark.webp";
          } else {
            this.forgotPasswordLogo = "assets/logos/toonballoon_logo_light.webp";
          }
        }
      })
    );

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description requests the verification code to reset the password be sent to the accounts email address
   */
  requestVerificationCode() {
    this.forgotPasswordSaving = true;
    this.loaderService.loaderSubject$.next(true);
    let email = this.forgotPasswordForm.controls['email'].value;

    this.authService.forgotPassword(email).then(result => {
      console.log('requestVerificationCode: ', result);
      this.router.navigate([`../reset-password`], { queryParams: { email: email }, relativeTo: this.route });
      this.loaderService.loaderSubject$.next(false);
      this.snackBar.openFromComponent(SuccessMessageComponent, {
        data: `Verification code sent to ${email}.`,
        duration: 10000,
        panelClass: ['success-snackbar']
      });
    }).catch(reason => {
      console.log('Failed: ', reason);
      this.forgotPasswordSaving = false;
      this.loaderService.loaderSubject$.next(false);
      this.snackBar.openFromComponent(ErrorMessageComponent, {
        data: 'Failed to send verification code!',
        duration: 10000,
        panelClass: ['error-snackbar']
      });
    });

  }

}
