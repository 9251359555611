import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from "@angular/common";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subscription, first } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';

// capacitor
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { StatusBar, Style, Animation, BackgroundColorOptions } from '@capacitor/status-bar';

// services
import { CheckForUpdateService } from './global/services/check-for-update/check-for-update.service';
import { AuthService } from './auth/services/auth/auth.service';
import { LoaderService } from './global/services/loader/loader.service';
import { StripeService } from './global/services/stripe/stripe.service';
import { RoutingStateService } from './global/services/routing-state/routing-state.service';

// components
import { CompleteProfilePromptComponent } from 'src/app/global/components/complete-profile-prompt/complete-profile-prompt.component';
import { CompleteStripeAccountPromptComponent } from './global/components/complete-stripe-account-prompt/complete-stripe-account-prompt.component';
import { PlatformMediaService } from './admin/services/platform-media/platform-media.service';

// environments
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  path: string;
  statusBarBackgroundColor: string = '#EDEDED';
  title = 'Toon Balloon';
  isNative: boolean = false; // flag to tell if native app
  isAuthenticated = false; // flag to tell user is authenticated
  user: any; // the authenticated user
  isExpanded = false; // flag to tell if the side menu is expanded
  expandedWidth = 0; // the width of the expanded menu
  routeLinks: any[] = []; // the route links for the menu items
  noUserDataPages: string[] = ['/sign-in', '/create-account', '/forgot-password', '/reset-password', '/browse', '/artist']; // pages that do not require user data to load

  private noPromptPages = ['/sign-in', '/create-account', '/forgot-password', '/reset-password', '/register', '/edit-profile']; // pages where the user sign up status promp should NOT be shown
  private roles: string[] = []; // the roles of the authenticated user
  private subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private checkForUpdateService: CheckForUpdateService,
    private swUpdate: SwUpdate,
    private router: Router,
    public location: Location,
    private overlayContainer: OverlayContainer,
    public authService: AuthService,
    private platformMediaServcie: PlatformMediaService,
    public loaderService: LoaderService,
    private snackBar: MatSnackBar,
    public stripeService: StripeService,
    public routingState: RoutingStateService
  ) {

    this.path = location.path();

    // shows the loader on navigation
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    })

    // disable console on production build
    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
    }

    // loads the routing state to keep track of route history
    routingState.loadRouting();

    // overrides the default hardware back button
    CapacitorApp.addListener('backButton', () => {
      window.history.back();
    });

    this.isNative = Capacitor.isNativePlatform(); // sets the native flag based on if a native app or not

    // these values are also set at 'android\app\src\main\res\values\styles.xml' for the newer devices.
    const setStatusBarStyleDark = async () => {
      if (this.isNative) {
        // Display content under transparent status bar (Android only)
        await StatusBar.setOverlaysWebView({ overlay: false });
        await StatusBar.setStyle({ style: Style.Light });
        await StatusBar.setBackgroundColor({color: this.statusBarBackgroundColor});
        await StatusBar.show({ animation: Animation.None });
      }
    };

    this.platformMediaServcie.getDefaultPlatformMedia();
  }

  async ngOnInit() {

    let that = this;
    this.loaderService.loaderSubject$.next(true);

    if (this.swUpdate.isEnabled) { // PWA check for updates.
      this.checkForUpdateService.startUpdateCheck();
      this.swUpdate.versionUpdates.subscribe(async versionUpdate => {
        if (versionUpdate.type === 'VERSION_READY') {
          this.snackBar.open("A new version of the app is available!", 'Update', {
            horizontalPosition: 'center',
            verticalPosition: 'top'
          }).afterDismissed().subscribe(() => {
            document.location.reload();
          });
        }
      });
    }

    if (!localStorage.getItem('theme')) {
      localStorage.setItem('theme', 'dark');
    }
    this.loaderService.themeSubject$.next(localStorage.getItem('theme'));

    this.loaderService.theme$.subscribe(theme => {
      if (theme === 'light') {
        that.setActiveTheme('theme-alternate'); // set the material theme
      } else {
        that.setActiveTheme('normal'); // set the material theme 
      }
    });
    // this.setActiveTheme('theme-alternate'); // set the material theme to dark    
    this.checkRoles();

    // sets the authenticated flag
    this.subscriptions.add(
      this.authService.authenticated$.subscribe(auth => {
        that.isAuthenticated = auth;
        that.calculateExpandedWidth();
      })
    );

    // sets the authenticated user
    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe(user => {
        that.user = user;
      })
    );

    // triggers a calculate of the menu width when a route changes
    this.subscriptions.add(
      this.router.events.subscribe(() => {
        that.calculateExpandedWidth();
      })
    );

  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description toggles the menu based on its previous value
   */
  toggleMenu() {
    this.isExpanded = !this.isExpanded;
    this.calculateExpandedWidth();
  }

  /**
   * @description sets all the navigation links for the menu
   */
  private setRouteLinks() {
    this.routeLinks = [
      { link: "/edit-profile", name: "Edit Profile", icon: "account_circle" },
      { link: "/bookings", name: "Bookings", icon: "event" },
      { link: "/shows", name: "Shows", icon: "music_note", hide: !this.roles?.includes('Artist') },
      { link: "/browse", name: "Browse", icon: "apps" },
      { link: "/users", name: "Users", icon: "people", hide: !this.roles?.includes('Admin') },
      { link: "/tags", name: "Tags", icon: "style", hide: !this.roles?.includes('Admin') },
      { link: "/media", name: "Media", icon: "collections", hide: !this.roles?.includes('Admin') },
      { link: "/settings", name: "Settings", icon: "settings", hide: !this.roles?.includes('Admin') },
    ];
  }

  /**
   * @description check what user roles they are in
   */
  private checkRoles() {
    let that = this;
    this.subscriptions.add(
      this.authService.authenticatedUserRole$.subscribe(roles => {
        if (roles && roles.length > 0) {
          that.roles = roles;
          that.setRouteLinks();

          if (that.roles && that.roles.includes('Artist')) {
            that.showRegisterPrompt();
          }
        }
      })
    );
  }

  /**
   * @description get the users sign up status and show the prompt to complete their profile if needed
   */
  private showRegisterPrompt() {
    let that = this;
    this.authService.signUpStatus$.pipe(first()).subscribe(data => {
      if (!that.noPromptPages.includes(that.location.path()) && data) {
        console.log('signupstatus: ', data);
        if (data.showSignUpPrompt && data.detailsComplete && (!data.availabilityComplete || !data.pricingComplete || !data.songlistComplete)) {
          that.snackBar.openFromComponent(CompleteProfilePromptComponent, {
            data: data,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['info-snackbar', 'complete-action-prompt']
          });
        } else {
          that.showStripeAccountStatusPrompt();
        }
      }
    });
  }

  /**
   * @description get the users stripe account status and show the prompt to complete their stripe account if needed
   */
  private showStripeAccountStatusPrompt() {
    let that = this;
    this.stripeService.stripeAccountStatus$.pipe(first()).subscribe(data => {
      if (!that.noPromptPages.includes(that.location.path()) && data) {
        console.log('stripe account status: ', data);
        if ((that.roles && that.roles.includes('Artist')) && !data.charges || !data.verify) {
          that.snackBar.openFromComponent(CompleteStripeAccountPromptComponent, {
            data: { stripeStatus: data, stripeAccountId: that.user.stripeAccountId },
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['info-snackbar', 'complete-action-prompt']
          });
        }
      }
    });
  }

  /**
   * @description calculates the menu width based on if the user is authenticated what page the user is on and if it is expanded or not
   */
  private calculateExpandedWidth() {
    if (this.isNative || (!this.isAuthenticated || this.location.path() === '/register')) {
      this.expandedWidth = 0;
    } else {
      if (!this.isExpanded) {
        this.expandedWidth = 80;
      } else {
        this.expandedWidth = 250;
      }
    }
  }

  /**
   * @description sets the theme based of the provided theme name
   * @param theme { string }
   */
  private setActiveTheme(theme: string) {

    let classList = this.overlayContainer.getContainerElement().classList;

    classList.remove('normal', 'theme-alternate');
    classList.add(theme);

    document.body.classList.remove('normal', 'theme-alternate');
    document.body.classList.add(theme);
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {

      this.loaderService.loaderSubject$.next(true);

    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => {
        this.loaderService.loaderSubject$.next(false);
      }, 2000);
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => {
        this.loaderService.loaderSubject$.next(true);
      }, 2000);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => {
        this.loaderService.loaderSubject$.next(true);
      }, 2000);
    }

  }

}
