//modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialComponentsModule } from 'src/app/material-components/material-components.module';
import { HttpClientModule } from '@angular/common/http';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

//components
import { NavbarComponent } from 'src/app/global/components/navbar/navbar.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { SuccessMessageComponent } from './components/success-message/success-message.component';
import { CompleteProfilePromptComponent } from './components/complete-profile-prompt/complete-profile-prompt.component';
import { CompleteStripeAccountPromptComponent } from './components/complete-stripe-account-prompt/complete-stripe-account-prompt.component';

// pipes
import { TimePipe } from './pipes/time/time.pipe';

// directives
import { StripeConnectButtonDirective } from './directives/stripe-connect-button/stripe-connect-button.directive';
import { StripeCheckoutButtonDirective } from './directives/stripe-checkout-button/stripe-checkout-button.directive';

//services
import { HttpBaseService } from './services/http-base/http-base.service';
import { CheckForUpdateService } from './services/check-for-update/check-for-update.service';
import { ImageService } from './services/image/image.service';
import { SpotifyService } from './services/spotify/spotify.service';
import { TagLookupService } from './services/tag-lookup/tag-lookup.service';
import { LoaderService } from './services/loader/loader.service';
import { StripeService } from './services/stripe/stripe.service';

// validators
import { StripeCouponValidator } from './validators/stripe-coupon';
import { RoutingStateService } from './services/routing-state/routing-state.service';
import { DeleteAccountWarningComponent } from './components/delete-account-warning/delete-account-warning.component';

@NgModule({
  declarations: [
    NavbarComponent,
    ImageCropperComponent,
    VideoPlayerComponent,
    ErrorMessageComponent,
    SuccessMessageComponent,
    CompleteProfilePromptComponent,
    CompleteStripeAccountPromptComponent,
    StripeConnectButtonDirective,
    StripeCheckoutButtonDirective,
    TimePipe,
    DeleteAccountWarningComponent        
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ImageCropperModule,
    MaterialComponentsModule,
    HttpClientModule
  ],
  exports: [
    NavbarComponent,
    ImageCropperComponent,
    StripeConnectButtonDirective,
    StripeCheckoutButtonDirective,
    TimePipe
  ],
  providers: [    
    HttpBaseService,
    CheckForUpdateService,
    LoaderService,
    ImageService,
    SpotifyService,
    TagLookupService,
    StripeService,
    RoutingStateService,
    TimePipe,
    StripeCouponValidator,
    InAppBrowser
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class GlobalModule { }
