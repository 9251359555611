import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

// capacitor
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { UserService } from 'src/app/profile/services/user/user.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { AvailabilityService } from 'src/app/profile/services/availability/availability.service';
import { ShowRequirementService } from 'src/app/profile/services/show-requirement/show-requirement.service';
import { PlatformDefaultsService } from 'src/app/admin/services/platform-defaults/platform-defaults.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';
import { SuccessMessageComponent } from 'src/app/global/components/success-message/success-message.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {

  isNative: boolean = false; // flag to tell if device is native
  keyboardShowing: boolean = false; // this tells if the keyboard is showing on mobile devices
  isIos: boolean = false // flag to tell if device is ios;
  userId: number; // the user id passed in from the route
  userProfile: boolean = true; // flag to determine if not in role of artist 
  modifiedBy: string; // the logged in users email
  days: any; // the processed availability data

  profileSaving: boolean = false; // flag to tell if in the act of saving profile data
  availabilitySaving: boolean = false; // flag to tell if in the act of saving availability data
  showRequirementsSaving: boolean = false; // flag to tell if in the act of saving show requirement data

  profileData: any; // the raw logged in user profile data
  availabilityData: any; // the raw logged in user availability data
  showRequirementsData: any; // the raw logged in user show requirements data
  userSongListData: any; // the raw logged in user song list data
  platformDefaults: any; // object with the current platform defaults

  profileFormValid: boolean = false; // flag to tell if in the profile form is valid
  availabilityFormValid: boolean = false; // flag to tell if in the availability form is valid
  showRequirementsFormValid: boolean = false; // flag to tell if in the show requirements form is valid

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private loaderService: LoaderService,
    private availabilityService: AvailabilityService,
    private showRequirementService: ShowRequirementService,
    private platformDefaultsService: PlatformDefaultsService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    // check if device is ios and set flag
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.userId = this.route.snapshot.params['id'];

    this.isNative = Capacitor.isNativePlatform(); // check if device is native and set flag 

    // if native add keyboard event listeners
    if (this.isNative) {
      Keyboard.addListener('keyboardWillShow', info => {
        console.log('keyboard will show:', info);
        this.keyboardShowing = true;
      });

      Keyboard.addListener('keyboardWillHide', () => {
        console.log('keyboard will hide');
        this.keyboardShowing = false;
      });
    }
  }

  async ngOnInit() {

    // show the loader
    this.loaderService.loaderSubject$.next(true);

    // set the modifiedBy var to the authenticated users email
    this.subscriptions.add(
      this.authService.authenticatedUser$.subscribe(user => {
        if (user) {
          this.modifiedBy = user.find(res => {
            return res.Name === 'email';
          }).Value;
        }
      })
    );

    //Get platform defaults
    this.subscriptions.add(
      this.platformDefaultsService.getPlatformDefaults()
        .subscribe(platformDefaults => {
          this.platformDefaults = platformDefaults.data;
        })
    );

    if (this.userId) {
      // get user data for id passed in params
      this.profileData = (await this.userService.getUser(this.userId)).data;
      this.setUpProfileData();
      console.log(this.profileData);
    } else {
      // get the authenticated users profile data
      this.subscriptions.add(
        this.authService.authenticatedUserData$.subscribe(
          {
            next: (res) => {
              console.log(res);
              if (!res) return; // stop if there is no result

              this.profileData = res; // set raw profile data
              this.setUpProfileData();

            },
            error: (e) => {
              console.error(e);
              // hide the loader
              this.loaderService.loaderSubject$.next(false);
              this.snackBar.openFromComponent(ErrorMessageComponent, {
                data: e,
                duration: 10000,
                panelClass: ['error-snackbar']
              });
            },
            complete: () => console.info('complete')
          }
        )
      );
    }
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  //#region profile details

  /**
   * @description sets up the rest of the profile data based on the original pull
   */
  private setUpProfileData() {
    // set the user profile flag based on its roles
    this.userProfile = !this.profileData.roleNames.includes('Artist'); // set the user profile flag based off roles

    // process raw availability data and set days var
    this.days = this.availabilityService.populateAvailabilityData(this.profileData.userDayOfTheWeekArray);

    // check if show requirements exists and set var
    if (this.profileData.userShowRequirement) {
      this.showRequirementsData = this.profileData.userShowRequirement;
    } else {
      this.showRequirementsData = { userId: this.profileData.id };
    }

    // check if songs exists and set var
    if (this.profileData.songs) {
      this.userSongListData = { userId: this.profileData.id, songList: this.profileData.songs };
    } else {
      this.userSongListData = { userId: this.profileData.id };
    }

    // hide the loader
    this.loaderService.loaderSubject$.next(false);
  }

  /**
   * @description sets the validation flag returned from the profile form component
   * @param valid { boolean }
   */
  setProfileFormValid(valid: boolean) {
    this.profileFormValid = valid;
  }

  /**
   * @description sets the data returned from the profile form component 
   * @param data { any }
   */
  setProfileData(data: any) {
    if (data) {
      this.profileData = data;
    }
  }

  /**
   * @description updates the profile data returned from the child component
   */
  updateProfile() {
    this.profileSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('update-profile', this.profileData);
    this.subscriptions.add(
      this.userService.updateUser(this.profileData).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Profile details saved!',
              duration: 5000,
              panelClass: ['success-snackbar']
            });
            this.authService.getUserData();
          },
          error: (e) => {
            console.error(e);
            this.profileSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => {
            console.info('complete');
            this.profileSaving = false;
            this.loaderService.loaderSubject$.next(false);
          }
        })
    );

  }

  //#endregion profile details

  //#region availability

  /**
  * @description sets the validation flag returned from the availability form component
  * @param valid { boolean }
  */
  setAvailabilityFormValid(valid: boolean) {
    this.availabilityFormValid = valid;
  }

  /**
   * @description sets the data returned from the availability form component 
   * @param data { any }
   */
  setAvailabilityData(data: any) {
    if (data) {
      this.availabilityData = data;
    }
  }

  /**
   * @description updates the availability data returned from the child component
   */
  updateAvailability() {
    this.availabilitySaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('update-availability', this.availabilityData);
    let formattedAvailabilityData = this.availabilityService.formatAvailabilityData(this.availabilityData, this.profileData.id)
    this.subscriptions.add(
      this.availabilityService.createAvailabilties(formattedAvailabilityData).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Availability saved!',
              duration: 5000,
              panelClass: ['success-snackbar']
            });
            this.authService.getUserData(); // this updates the user data subject so that the most recent data is always displayed
          },
          error: (e) => {
            console.error(e);
            this.availabilitySaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => {
            console.info('complete');
            this.availabilitySaving = false;
            this.loaderService.loaderSubject$.next(false);
          }
        })
    );

  }

  //#endregion availablity

  //#region show requirements

  /**
   * @description sets the validation flag returned from the show settings form component
   * @param valid { boolean }
   */
  setShowRequirementsFormValid(valid: boolean) {
    this.showRequirementsFormValid = valid;
  }

  /**
   * @description sets the data returned from the show settings form component 
   * @param data { any }
   */
  setShowRequirementsData(data: any) {
    if (data) {
      this.showRequirementsData = data;
    }
  }

  /**
   * @description updates the show requirements data returned from the child component
   */
  updateShowRequirements() {
    this.showRequirementsSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('update-show-requirements', this.showRequirementsData);
    this.subscriptions.add(
      this.showRequirementService.createShowRequirements(this.showRequirementsData).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Show settings saved!',
              duration: 5000,
              panelClass: ['success-snackbar']
            });
            this.authService.getUserData(); // this updates the user data subject so that the most recent data is always displayed
          },
          error: (e) => {
            console.error(e);
            this.showRequirementsSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => {
            console.info('complete');
            this.showRequirementsSaving = false;
            this.loaderService.loaderSubject$.next(false);
          }
        }
      )
    );

  }

  //#endregion show requirements

  //#region song list

  /**
   * @description sets the data returned from the song list component 
   * @param data { any }
   */
  setSongListData(data: any) {
    if (data) {
      this.userSongListData = data;
    }
  }

  //#endregion song list

}
