import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
export class DateValidator {

    /**
     * @description Validates US dates
     * @param control {AbstractControl}
     * @return { [key: string]: any } whether the form control has an error of 'date' or null
     */
    static usDate(control: AbstractControl): { [key: string]: any } {
        // tslint:disable-next-line:max-line-length
        let date = moment(control.value, 'MM/DD/yyyy', true);
        if (!date.isValid()) {
            return { 'usDate': true };
        }
        return null;
    }

    /**
     * @description checks if date is after minimum date provided
     * @param min { date | moment | string }
     * @returns { [key: string]: boolean } | null
     */
    static minDate(min: any) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            let minDate = moment(min, 'yyyy-MM-DD', true);
            let date = moment(control.value, 'MM/DD/yyyy', true);
            if (date.isValid() && minDate.isValid() && minDate.isAfter(date)) {
                return { 'minDate': true };
            }
            return null;
        }
    }

    /**
     * @description checks if date is before maximum date provided
     * @param max { date | moment | string }
     * @returns { [key: string]: boolean } | null
     */
    static maxDate(max: any) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            let maxDate = moment(max, 'yyyy-MM-DD', true);
            let date = moment(control.value, 'MM/DD/yyyy', true);
            if (date.isValid() && maxDate.isValid() && maxDate.isBefore(date)) {
                return { 'maxDate': true };
            }
            return null;
        }
    }

}