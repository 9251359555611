import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// modules
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from '../app-routing.module';
import { GlobalModule } from '../global/global.module';
import { MaterialComponentsModule } from '../material-components/material-components.module';

// components
import { UserListComponent } from './components/user-list/user-list.component';
import { TagListComponent } from './components/tag-list/tag-list.component';
import { PlatformDefaultsComponent } from './components/platform-defaults/platform-defaults.component';
import { ImageListComponent } from './components/image-list/image-list.component';
import { TagFormComponent } from './components/tag-form/tag-form.component';
import { PlatformMediaComponent } from './components/platform-media/platform-media.component';

// services
import { PlatformDefaultsService } from './services/platform-defaults/platform-defaults.service';
import { CustomTagService } from './services/custom-tag/custom-tag.service';
import { UserFilterComponent } from './components/user-filter/user-filter.component';


@NgModule({
  declarations: [
    UserListComponent,
    TagListComponent,
    PlatformDefaultsComponent,
    ImageListComponent,
    TagFormComponent,
    PlatformMediaComponent,
    UserFilterComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialComponentsModule,
    ReactiveFormsModule,
    NgxCurrencyModule, 
    NgxMaskModule.forRoot(),   
    GlobalModule
  ],
  providers: [
    PlatformDefaultsService,
    CustomTagService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AdminModule { }
