import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Subscription } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

// services
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { AvailabilityService } from 'src/app/profile/services/availability/availability.service';
import { UserService } from 'src/app/profile/services/user/user.service';
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { StripeService } from 'src/app/global/services/stripe/stripe.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

// environments
import { environment } from 'src/environments/environment';

// validators
import { StripeCouponValidator } from 'src/app/global/validators/stripe-coupon';

// helpers
import { ScheduleHelper } from '../../helpers/schedule.helper';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  today: Moment = moment(); // the date right now

  startTimeDisabled: boolean = true; // flag to disable start time ddl
  endTimeDisabled: boolean = true; // flag to disable end time ddl
  selected: Moment | null; // the selected date on the calendar
  selectedStartTime: Moment | null; // the selected start time for the event
  selectedEndTime: Moment | null; // the selected end time for the event
  eventCost: any = {
    length: 0,
    price: 0,
    discount: 0,
    discountedPrice: 0,
    cancelFee: 0
  }; // the event cost object

  isIos: boolean = false; // flag to tell if device is Ios

  userId: number; // the user id for the artist
  artistData: any; // the raw chosen in artists data
  availabilityData: any; // the raw logged in user availability data
  showRequirementsData: any; // the raw logged in user show requirements data
  days: any; // the processed availability data

  profileData: any; // the raw authenticated user data

  startTimes: Array<Moment> = []; // the start times array for the start time ddl
  endTimes: Array<Moment> = []; // the end times array for the end time ddl

  checkoutData: any; // the object that contains all the data for the stripe checkout process
  discountCode: FormControl; // the form control that the user enters the coupon code into

  checkoutSaving: boolean = false; // flag to tell if in the act of saving checkout data

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private userService: UserService,
    private authService: AuthService,
    private stripeService: StripeService,
    private availabilityService: AvailabilityService,
    private snackBar: MatSnackBar,
    private router: Router,
    private stripeCouponValidator: StripeCouponValidator,
    private scheduleHelper: ScheduleHelper
  ) {
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.userId = Number(this.route.snapshot.params['id']); // this is the userId of the artist that is being booked
    this.subscriptions.add(
      this.loaderService.loader$.subscribe(res => {
        if (res) {
          this.checkoutSaving = true;
        } else {
          this.checkoutSaving = false;
        }
      })
    );
  }

  ngOnInit(): void {
    this.getArtistProfileData(); // get the data of the artist being booked
    this.getAuthenticatedUserData(); // get the data for the user booking the artist

    this.discountCode = new FormControl('', [], [this.stripeCouponValidator.coupon(this.userId)]);
    this.discountCode.markAsTouched(); // this is so the async validation shows the correct status on the first entry in the input

  }

  /**
   * @description sets the checkout saving flag from the output event to disable the button
   * @param saving { boolean }
   */
  setCheckoutSaving(saving: boolean) {
    this.checkoutSaving = saving;
  }

  /**
   * @description gets the profile data for the chosen artist
   */
  getArtistProfileData() {
    this.loaderService.loaderSubject$.next(true);
    this.subscriptions.add(
      this.userService.getArtist(this.userId).subscribe(
        {
          next: (res) => {
            console.log(res);

            if (!res) return; // stop if there is no result

            this.artistData = res.data; // set raw profile data

            // process raw availability data and set days var
            this.days = this.availabilityService.populateAvailabilityData(this.artistData.userDayOfTheWeekArray, true);
            console.log('days: ', this.days);

            // check if show requirements exists and set var
            if (this.artistData.userShowRequirement) {
              this.showRequirementsData = this.artistData.userShowRequirement;
            } else {
              this.showRequirementsData = { userId: this.userId };
            }

            // checks for changes to the validation status of the coupon code control and updates the checkout data accordingly
            this.discountCode.statusChanges.subscribe(
              result => {
                console.log(result);
                this.buildCheckoutData();
              }
            );

            // hide the loader
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            // hide the loader
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description gets the data for the loggen in user
   */
  getAuthenticatedUserData() {
    this.loaderService.loaderSubject$.next(true);
    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe(
        {
          next: (res) => {
            console.log(res);
            if (!res) return; // stop if there is no result

            this.profileData = res; // set raw profile data

            // hide the loader
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            // hide the loader
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description this checks all the dates on the current calendar and sets them as enabled based on availability
   * @param date { Moment } the date it is checking
   * @returns { boolean } flag of enabled
   */
  dateFilter: (date: Moment | null) => boolean =
    (date: Moment | null) => {

      let enabled = false;

      if (date) {
        // check to see if the user is available on that day of the week
        enabled = this.scheduleHelper.isDayAvailable(date, this.days, this.artistData.events, this.showRequirementsData);
      }

      return enabled;
    };

  /**
   * @description takes the date selected on the calendar and populates the availible start times ddl for that date
   * @param selectedDay { Moment } the date selected
   */
  dateSelected(selectedDay: Moment) {
    console.log(selectedDay);

    if (!this.selected || !this.selected.isSame(selectedDay)) {

      // resetting all the values
      this.startTimes = [];
      this.endTimes = [];
      this.selectedStartTime = null;
      this.selectedEndTime = null;
      this.startTimeDisabled = true;
      this.endTimeDisabled = true;
      this.checkoutData = null;

      this.selected = selectedDay.clone();

      this.startTimes = this.scheduleHelper.availableStartTimes(this.selected, this.days, this.artistData.events, this.showRequirementsData);

      this.startTimeDisabled = false;

    }
  }

  /**
   * @description takes the date seleted and the start time selected and populates the end times ddl based on availability and show requirements
   */
  selectStartTime() {

    // resetting all the values
    this.endTimes = [];
    this.selectedEndTime = null;
    this.checkoutData = null;

    this.endTimes = this.scheduleHelper.availableEndTimes(this.selected, this.selectedStartTime, this.days, this.artistData.events, this.showRequirementsData);

    this.endTimeDisabled = false;
    console.log('selected start time: ', this.selectedStartTime);

  }

  /**
   * @description takes the date selected, start and end times and populates the checkout data
   */
  selectEndTime() {
    console.log('selected end time: ', this.selectedEndTime);
    this.buildCheckoutData();
  }

  /**
   * @description this builds the checkout data object to send to stripe besed on the selected inputs
   */
  buildCheckoutData() {

    this.eventCost = this.scheduleHelper.calculateEventCost(this.selectedStartTime, this.selectedEndTime, this.showRequirementsData.pricePerHour, this.showRequirementsData.depositPercentage);

    this.checkoutData = {
      bookedUserId: this.artistData.id,
      bookingUserId: this.profileData.id,
      totalPrice: this.eventCost.price,
      discountedPrice: this.eventCost.discountedPrice,
      depositAmount: this.eventCost.cancelFee,
      eventStartTime: this.selectedStartTime,
      eventEndTime: this.selectedEndTime,
      dayOfTheWeekLookupId: (this.selectedStartTime.day() + 1),
      modifiedBy: this.profileData.email,
      customerId: this.profileData.stripeCustomerId,
      stripeAccountId: this.artistData.stripeAccountId,
      backUrl: environment.stripeReturnUrl + this.router.url,
      successUrl: environment.stripeReturnUrl + '/bookings'
    };

    // checks to see if there is a valid stripe coupon before adding it to the checkout data.
    if (this.discountCode.valid && this.discountCode.value !== '') {
      this.checkoutData.couponCode = this.discountCode.value

      if (this.checkoutData.couponCode) {
        this.stripeService.getStripeCoupon(this.checkoutData.couponCode).subscribe(res => {
          this.eventCost = this.scheduleHelper.calculateEventCost(this.selectedStartTime, this.selectedEndTime, this.showRequirementsData.pricePerHour, this.showRequirementsData.depositPercentage, res.percent_off);
          this.checkoutData.discountedPrice = this.eventCost.discountedPrice;
          this.checkoutData.depositAmount = this.eventCost.cancelFee;
        });
      }
    } else {
      delete this.checkoutData['couponCode'];
    }

    console.log('checkout data: ', this.checkoutData);

  }
}
