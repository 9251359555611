import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-complete-stripe-account-prompt',
  templateUrl: './complete-stripe-account-prompt.component.html',
  styleUrls: ['./complete-stripe-account-prompt.component.scss']
})
export class CompleteStripeAccountPromptComponent implements OnInit {

  constructor(
    public sbRef: MatSnackBarRef<CompleteStripeAccountPromptComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

}
