import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

// capacitor
import { Capacitor } from '@capacitor/core';

// services
import { UserService } from '../../services/user/user.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { AvailabilityService } from 'src/app/profile/services/availability/availability.service';
import { ImageService } from 'src/app/global/services/image/image.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

@Component({
  selector: 'app-artist-profile',
  templateUrl: './artist-profile.component.html',
  styleUrls: ['./artist-profile.component.scss']
})
export class ArtistProfileComponent implements OnInit {

  isIos: boolean = false; // flag to tell if device is ios;

  userId: number; // the user id for the artist
  modifiedBy: string; // the logged in users email
  days: any; // the processed availability data
  croppedImage: any = '';  // the base 64 cropped image

  profileData: any; // the raw logged in user profile data
  availabilityData: any; // the raw logged in user availability data
  showRequirementsData: any; // the raw logged in user show requirements data
  userSongListData: any; // the raw logged in user song list data

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private loaderService: LoaderService,
    private availabilityService: AvailabilityService,
    private imageService: ImageService,
    private snackBar: MatSnackBar
  ) { 
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.userId = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {

    this.loaderService.loaderSubject$.next(true);
    
    // get the authenticated users profile data
    this.subscriptions.add(
      this.userService.getArtist(this.userId).subscribe(
        {
          next: (res) => {
            console.log(res);
            if (!res) return; // stop if there is no result

            this.profileData = res.data; // set raw profile data

            this.croppedImage = this.profileData.profileImageUrl ? this.imageService.formatImage(this.profileData.profileImageUrl)['640w'] : '';

            // process raw availability data and set days var
            this.days = this.availabilityService.populateAvailabilityData(this.profileData.userDayOfTheWeekArray);

            // check if show requirements exists and set var
            if (this.profileData.userShowRequirement) {
              this.showRequirementsData = this.profileData.userShowRequirement;
            } else {
              this.showRequirementsData = { userId: this.userId };
            }

            // check if songs exists and set var
            if (this.profileData.songs) {
              this.userSongListData = { userId: this.userId, songList: this.profileData.songs };
            } else {
              this.userSongListData = { userId: this.userId };
            }

            // hide the loader
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            // hide the loader
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );

  }

  /**
   * @description navigates to the schedule event page
   * @param artistId { number } the artist id that the user is wanting to schedule
   */
   scheduleEvent(artistId: number) {
    console.log('schedule artist: ', artistId);
    this.router.navigate([`../../schedule`, artistId], { relativeTo: this.route });
  }

}
