<div class="container-authenticated container-savebar" [ngClass]="{'container-authenticated-ios': isIos}">
  <div class="edit-profile-tab-group">
    <h1>Edit Profile</h1>
    <mat-divider></mat-divider>
    <ng-container *ngIf="profileData">
      <ng-container *ngIf="userProfile; else artist">
        <br />
        <div class="edit-profile-tab-contents">
          <app-profile-form *ngIf="profileData && modifiedBy" [profileData]="profileData" [modifiedBy]="modifiedBy"
            [userProfile]="userProfile" (profileFormValidOutput)="setProfileFormValid($event)"
            (profileFormOutput)="setProfileData($event)">
          </app-profile-form>
          <div class="bottom-bar" [ngClass]="{'keyboard-open': keyboardShowing}">
            <mat-divider></mat-divider>
            <button class="icon-button" mat-raised-button color="primary" type="button"
              *ngIf="profileData && modifiedBy" [disabled]="!profileFormValid || profileSaving"
              (click)="updateProfile()">
              <mat-icon>save_alt</mat-icon> Save
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #artist>
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Profile" class="icon-tab-icon">manage_accounts</mat-icon>
              <span class="icon-tab-label">Profile</span>
            </ng-template>
            <br />
            <div class="edit-profile-tab-contents">
              <app-profile-form *ngIf="profileData && modifiedBy" [profileData]="profileData" [platformDefaults]="platformDefaults" [modifiedBy]="modifiedBy"
                [userProfile]="userProfile" (profileFormValidOutput)="setProfileFormValid($event)"
                (profileFormOutput)="setProfileData($event)"></app-profile-form>
              <div class="bottom-bar" [ngClass]="{'keyboard-open': keyboardShowing}">
                <mat-divider></mat-divider>
                <button class="icon-button" mat-raised-button color="primary" type="button"
                  *ngIf="profileData && modifiedBy" [disabled]="!profileFormValid || profileSaving"
                  (click)="updateProfile()">
                  <mat-icon>save_alt</mat-icon> Save
                </button>
                <!-- <button mat-fab class="fixed-save-button" mat-raised-button color="primary" type="button"
                *ngIf="profileData && modifiedBy" [disabled]="!profileFormValid || profileSaving"
                (click)="updateProfile()">
                <span class="fab-save-icon">
                  <mat-icon>save_alt</mat-icon><br />Save
                </span></button> -->
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Availability" class="icon-tab-icon">edit_calendar</mat-icon>
              <span class="icon-tab-label">Availability</span>
            </ng-template>
            <br />
            <div class="edit-profile-tab-contents">
              <app-availability-form *ngIf="days && modifiedBy" [days]="days" [modifiedBy]="modifiedBy" [availabilityNote]="profileData.availabilityNote"
                (availabilityFormValidOutput)="setAvailabilityFormValid($event)"
                (availabilityFormOutput)="setAvailabilityData($event)"></app-availability-form>
              <div class="bottom-bar" [ngClass]="{'keyboard-open': keyboardShowing}">
                <mat-divider></mat-divider>
                <button class="icon-button" mat-raised-button color="primary" type="button" *ngIf="days && modifiedBy"
                  [disabled]="!availabilityFormValid  || availabilitySaving" (click)="updateAvailability()">
                  <mat-icon>save_alt</mat-icon> Save
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Show Settings" class="icon-tab-icon">video_settings</mat-icon>
              <span class="icon-tab-label">Show Settings</span>
            </ng-template>
            <br />
            <div class="edit-profile-tab-contents">
              <app-show-settings-form *ngIf="showRequirementsData && modifiedBy"
                [showRequirements]="showRequirementsData" [platformDefaults]="platformDefaults" [modifiedBy]="modifiedBy" [userId]="profileData.id"
                (showRequirementsFormValidOutput)="setShowRequirementsFormValid($event)"
                (showRequirementsFormOutput)="setShowRequirementsData($event)"></app-show-settings-form>
              <div class="bottom-bar" [ngClass]="{'keyboard-open': keyboardShowing}">
                <mat-divider></mat-divider>
                <button class="icon-button" mat-raised-button color="primary" type="button"
                  *ngIf="showRequirementsData && modifiedBy"
                  [disabled]="!showRequirementsFormValid || showRequirementsSaving" (click)="updateShowRequirements()">
                  <mat-icon>save_alt</mat-icon> Save
                </button>
              </div>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Songlist" class="icon-tab-icon">library_music</mat-icon>
              <span class="icon-tab-label">Songlist</span>
            </ng-template>
            <br />
            <div class="edit-profile-tab-contents">
              <app-song-list *ngIf="userSongListData && modifiedBy" [userSongListData]="userSongListData"
                [modifiedBy]="modifiedBy" [editable]="true"></app-song-list>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Discount Codes" class="icon-tab-icon">discount</mat-icon>
              <span class="icon-tab-label">Discount Codes</span>
            </ng-template>
            <br />
            <div class="edit-profile-tab-contents">
              <app-discount-codes></app-discount-codes>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </ng-container>
  </div>
</div>