import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

// capacitor
import { Capacitor } from '@capacitor/core';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { Keyboard } from '@capacitor/keyboard';

// components
import { ImageCropperComponent } from 'src/app/global/components/image-cropper/image-cropper.component';

// validation
import { VenmoValidation } from '../../validators/venmo';

//Services
import { ImageService } from 'src/app/global/services/image/image.service';
import { SpotifyService } from 'src/app/global/services/spotify/spotify.service';
import { TagLookupService } from 'src/app/global/services/tag-lookup/tag-lookup.service';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {

  @ViewChild('venmoURL') venmoURL: ElementRef<HTMLInputElement>; // venmo url input element reference
  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>; // tags input element reference

  @Input() userProfile: boolean; // flag to tell if user or artist profile
  @Input() modifiedBy: string; // current authenticated user
  @Input() profileData: any; // current user profile data
  @Input() platformDefaults: any; // current platform defaults data

  @Output() profileFormOutput = new EventEmitter(); // emits the profile form data
  @Output() profileFormValidOutput = new EventEmitter(); // emits the profile form validation status

  isNative: boolean = false; // flag to tell if device is native
  croppedImage: any = '';  // the base 64 cropped image
  filteredTags: Observable<string[]>; // an observable that contains the string array of tag names
  tags: string[] = []; // the tags selected
  allTags: string[] = []; // all the tags that are available to select

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  // creates the profile form
  profileForm: FormGroup = this.formBuilder.group({
    id: [''],
    email: [''],
    profileImageUrl: [''],
    displayName: ['', [Validators.required]],
    tagNames: [[], [Validators.required]],
    zipCode: ['', [Validators.minLength(5), Validators.maxLength(5)]],
    modifiedBy: [''],
    tagsInput: ['']
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private imageService: ImageService,
    private spotifyService: SpotifyService,
    private tagLookupService: TagLookupService
  ) {

    this.isNative = Capacitor.isNativePlatform(); // check if device is native and set flag 

    // filters the values in the list of available tags based on what was typed in the input
    this.filteredTags = this.profileForm.controls['tagNames'].valueChanges.pipe(
      startWith(null),
      map((tags: [] | null) => (tags && tags.length > 0 ? this.filterTags(tags) : this.allTags.slice())),
    );

    //Get spotify genres    
    this.subscriptions.add(
      this.spotifyService.genres()
        .subscribe(genres => {
          this.addToTags(genres);
        })
    );

    //Get tagLookups
    this.subscriptions.add(
      this.tagLookupService.tagLookups()
        .subscribe(tagLookups => {
          this.addToTags(tagLookups.map((x: any) => { return x.name }));
        })
    );    

  }

  ngOnInit(): void {

    if (!this.userProfile) {
      this.profileForm.addControl('modifiedBy', new UntypedFormControl(''));
      this.profileForm.addControl('bio', new UntypedFormControl('', [Validators.required]));
      this.profileForm.addControl('venmoURL', new UntypedFormControl('', [VenmoValidation.venmoUrl]));
      this.profileForm.addControl('venmoQrCodeScreenPosition', new UntypedFormControl('bottomLeft'));
    }

    if (this.profileData) {
      this.croppedImage = this.profileData.profileImageUrl ? this.imageService.formatImage(this.profileData.profileImageUrl)['640w'] : '';

      this.profileForm.controls['id'].setValue(this.profileData.id ? this.profileData.id : '');
      this.profileForm.controls['email'].setValue(this.profileData.email ? this.profileData.email : '');
      this.profileForm.controls['profileImageUrl'].setValue(this.profileData.profileImageUrl);
      this.profileForm.controls['displayName'].setValue(this.profileData.displayName ? this.profileData.displayName : '');
      //Populate the tags object so previously selected tags show
      this.tags = this.profileData.tagNames ? this.profileData.tagNames : [];
      this.profileForm.controls['tagNames'].setValue(this.tags);
      this.profileForm.controls['zipCode'].setValue(this.profileData.zipCode ? this.profileData.zipCode : '');
      this.profileForm.controls['modifiedBy'].setValue(this.modifiedBy);

      if (!this.userProfile) {
        this.profileForm.controls['bio'].setValue(this.profileData.bio ? this.profileData.bio : '');
        this.profileForm.controls['venmoURL'].setValue(this.profileData.venmoURL ? this.profileData.venmoURL : '');
        this.profileForm.controls['venmoQrCodeScreenPosition'].setValue(this.profileData.venmoQrCodeScreenPosition ? this.profileData.venmoQrCodeScreenPosition : 'bottomLeft');
      }

    }

    this.subscriptions.add(
      this.profileForm.valueChanges.subscribe(
        (result) => {
          console.log(result);
          console.log(this.profileForm.status);
          this.profileFormValidOutput.emit(this.profileForm.status === 'VALID' ? true : false);
          if (this.profileForm.status === 'VALID') {
            this.profileFormOutput.emit(result);
          }
        }
      )
    );

    this.profileFormValidOutput.emit(this.profileForm.status === 'VALID' ? true : false);
  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
  * @description adds a selected tag to the list
  * @param array { string[] } the selected tags 
  */
  addToTags(array) {
    //Create a set to get unique values and then combine with previous tags
    this.allTags = Array.from(new Set(this.allTags.concat(array)));

    //Setup autocomplete filter
    if (array && array.length > 0) {
      this.filteredTags = this.profileForm.controls['tagsInput'].valueChanges.pipe(
        startWith(null),
        map((filter: string | null) => (filter && filter.length > 0 ? this.filterTags(this.tags, filter) : this.allTags.slice())),
      );
    }
  }

  /**
   * @description removes a selected tag from the list
   * @param tag { string }
   */
  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.profileForm.controls['tagNames'].setValue(this.tags);
    }
  }

  /**
   * @description set the selected tag
   * @param event { MatAutocompleteSelectedEvent }
   */
  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    //Need the control set value to allow filter to work correctly
    this.profileForm.controls['tagsInput'].setValue('');
    this.tagsInput.nativeElement.value = '';
    this.profileForm.controls['tagNames'].setValue(this.tags);
  }

  /**
   * @description filters tags based on the text in the input
   * @param tags { string[] }
   * @param value { string }
   * @returns { string[] } the filtered list of tags
   */
  private filterTags(tags: string[], value?: string): string[] {
    return this.allTags.filter(tag => !tags.includes(tag) && (!value || tag.toLowerCase().includes(value.toLowerCase())));
  }

  /**
   * @description opens the image cropper dialog popup
   */
  openDialog() {
    const dialogRef = this.dialog.open(ImageCropperComponent, { data : {mode: 'Photo', aspectRatio: (1.5 / 1), width: 640, height: 427 } });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result.event === 'done') {
          console.log(`Dialog result: ${result.data}`);
          this.croppedImage = result.data;
          this.profileForm.controls['profileImageUrl'].setValue(this.croppedImage);
        }
      })
    );
  }

  /**
   * @description sets the form value of the venmo QR screen position based on the value selected
   * @param position { string }
   */
  setvenmoQrCodeScreenPosition(position: string) {
    this.profileForm.controls['venmoQrCodeScreenPosition'].setValue(position);
  }

  /**
   * @description sets up the barcode scanner for native devices
   */
  async scanBarcode() {
    const status = await BarcodeScanner.checkPermission({ force: true });
    console.log('camera permission:', status);
    if (status.granted) {
      this.showBarcodeScanner();
      const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE] }); // start scanning and wait for a result

      // if the result has content
      if (result.hasContent) {
        console.log(result.content); // log the raw scanned content
        this.profileForm.controls['venmoURL'].setValue(result.content);
        this.hideBarcodeScanner();
      }
    }
  }

  /**
   * @description closes the barcode scanner for native devices
   * @param _this { this } passes the current scope to the method
   */
  stopScan(_this) {
    _this.hideBarcodeScanner();
    BarcodeScanner.stopScan();
  };

  /**
   * @description sets what elements are visible when the barcode scanner is opened
   */
  private showBarcodeScanner() {
    BarcodeScanner.hideBackground(); // make background of WebView transparent
    document.getElementById("app-body").style.backgroundColor = 'transparent';
    document.getElementById("app-root").style.display = 'none';
    document.getElementById("barcode-container").style.display = 'block';
    document.getElementById("close-scan").removeAllListeners();
    document.getElementById("close-scan").addEventListener('click', () => {
      this.stopScan(this);
    });
    if (this.isNative) {
      Keyboard.hide();
    }
  }

  /**
   * @description sets all elements back to normal when scanner is closed
   */
  private hideBarcodeScanner() {
    BarcodeScanner.showBackground();
    document.getElementById("app-body").style.backgroundColor = '#2a2b2a';
    document.getElementById("app-root").style.display = 'block';
    document.getElementById("barcode-container").style.display = 'none';
    this.venmoURL.nativeElement.focus();
  }

}
