<ng-container *ngIf="availabilityForm">
    <mat-label>Times Shown in {{timezoneString}} Timezone</mat-label>
    <form [formGroup]="availabilityForm">
        <div
            *ngIf="availabilityForm.controls && availabilityForm.controls['daysOfTheWeek'] && availabilityForm.controls['daysOfTheWeek'].controls && availabilityForm.controls['daysOfTheWeek'].controls.length > 0">
            <div *ngFor="let day of availabilityForm.controls['daysOfTheWeek'].controls; let i = index">
                <div [formGroup]="day">
                    <mat-checkbox #dayOption class="availability-checkbox" color="primary" formControlName="isSelected">
                        {{day.controls['dayOfTheWeek'].value.name}}</mat-checkbox>
                    <ng-container *ngIf="dayOption.checked">
                        <ng-container
                            *ngIf="day && day.controls && day.controls['times'] && day.controls['times'].controls && day.controls['times'].controls.length > 0">
                            <div *ngFor="let time of day.controls['times'].controls let ti = index">
                                <div [formGroup]="time" class="availability-times" fxLayout="row"
                                    fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                                    <input matInput [hidden]="true" formControlName="id">
                                    <div fxFlex="45%">
                                        <mat-form-field appearance="outline" color="primary" class="time-main">
                                            <mat-label>Start Time</mat-label>
                                            <input matInput placeholder="9:00 PM" formControlName="startTime"
                                                mask="0:00||00:00" [dropSpecialCharacters]="false"
                                                [showMaskTyped]="true" />
                                            <mat-hint>Available to book starting time.</mat-hint>
                                            <mat-error
                                                *ngIf="!time.controls['startTime'].valid && (time.controls['startTime'].dirty || time.controls['startTime'].touched)">
                                                <ng-container *ngIf="time.controls['startTime'].errors?.['required']">
                                                    Start Time is required.
                                                </ng-container>
                                                <ng-container *ngIf="time.controls['startTime'].errors?.['time']">
                                                    Please enter a valid time. Example 01:00 PM (minutes in intervals of
                                                    15)
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="time.controls['startTime'].errors?.['verifyRange']">
                                                    Start time must be before end time.
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="time.controls['startTime'].errors?.['verifyOverlap']">
                                                    Start time overlaps with another time slot.
                                                </ng-container>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" color="primary" class="time-suffix">
                                            <mat-select class="text-select" formControlName="startTimeDDL">
                                                <mat-option value="AM">
                                                    AM
                                                </mat-option>
                                                <mat-option value="PM">
                                                    PM
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="45%">
                                        <mat-form-field appearance="outline" color="primary" class="time-main">
                                            <mat-label>End Time</mat-label>
                                            <input matInput placeholder="11:00 PM" formControlName="endTime"
                                                mask="0:00||00:00" [dropSpecialCharacters]="false"
                                                [showMaskTyped]="true" />
                                            <mat-hint>Available to book end time.</mat-hint>
                                            <mat-error
                                                *ngIf="!time.controls['endTime'].valid && (time.controls['endTime'].dirty || time.controls['endTime'].touched)">
                                                <ng-container *ngIf="time.controls['endTime'].errors?.['required']">
                                                    End Time is required.
                                                </ng-container>
                                                <ng-container *ngIf="time.controls['endTime'].errors?.['time']">
                                                    Please enter a valid time. Example 03:00 PM (minutes in intervals of
                                                    15)
                                                </ng-container>
                                                <ng-container *ngIf="time.controls['endTime'].errors?.['verifyRange']">
                                                    End time must be after start time.
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="time.controls['endTime'].errors?.['verifyOverlap']">
                                                    End time overlaps with another time slot.
                                                </ng-container>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field appearance="outline" color="primary" class="time-suffix">
                                            <mat-select class="text-select" formControlName="endTimeDDL">
                                                <mat-option value="AM">
                                                    AM
                                                </mat-option>
                                                <mat-option value="PM">
                                                    PM
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="10%">
                                        <button mat-icon-button class="row-button"
                                            (click)="deleteTimeSlot(day.controls['times'], ti)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </ng-container>
                        <div *ngIf="day && day.controls" class="availability-times" fxLayout="row"
                            fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                            <div fxFlex="45%">
                                <mat-form-field *ngIf="day.controls['addStartTime']" appearance="outline" color="primary"
                                    class="time-main">
                                    <mat-label>Start Time</mat-label>
                                    <input matInput placeholder="09:00" formControlName="addStartTime"
                                        mask="0:00||00:00" [dropSpecialCharacters]="false" [showMaskTyped]="true" />
                                    <mat-hint>Available to book starting time.</mat-hint>
                                    <mat-error
                                        *ngIf="!day.controls['addStartTime'].valid && (day.controls['addStartTime'].dirty || day.controls['addStartTime'].touched)">
                                        <!-- <ng-container *ngIf="day.controls['addStartTime'].errors?.['required']">
                                            Start Time is required.
                                        </ng-container> -->
                                        <ng-container *ngIf="day.controls['addStartTime'].errors?.['time']">
                                            Please enter a valid time. Example 01:00 PM (minutes in intervals of 15)
                                        </ng-container>
                                        <ng-container *ngIf="day.controls['addStartTime'].errors?.['verifyRange']">
                                            Start time must be before end time.
                                        </ng-container>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="day.controls['addStartTimeDDL']" appearance="outline"
                                    color="primary" class="time-suffix">
                                    <mat-select class="text-select" formControlName="addStartTimeDDL">
                                        <mat-option value="AM">
                                            AM
                                        </mat-option>
                                        <mat-option value="PM">
                                            PM
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="45%">
                                <mat-form-field *ngIf="day.controls['addEndTime']" appearance="outline" color="primary"
                                    class="time-main">
                                    <mat-label>End Time</mat-label>
                                    <input matInput placeholder="11:00 PM" formControlName="addEndTime"
                                        mask="0:00||00:00" [dropSpecialCharacters]="false" [showMaskTyped]="true" />
                                    <mat-hint>Available to book end time.</mat-hint>
                                    <mat-error
                                        *ngIf="!day.controls['addEndTime'].valid  && (day.controls['addEndTime'].dirty || day.controls['addEndTime'].touched)">
                                        <!-- <ng-container *ngIf="day.controls['addEndTime'].errors?.['required']">
                                            End Time is required.
                                        </ng-container> -->
                                        <ng-container *ngIf="day.controls['addEndTime'].errors?.['time']">
                                            Please enter a valid time. Example 03:00 PM (minutes in intervals of 15)
                                        </ng-container>
                                        <ng-container *ngIf="day.controls['addEndTime'].errors?.['verifyRange']">
                                            End time must be after start time.
                                        </ng-container>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field *ngIf="day.controls['addEndTimeDDL']" appearance="outline"
                                    color="primary" class="time-suffix">
                                    <mat-select class="text-select" formControlName="addEndTimeDDL">
                                        <mat-option value="AM">
                                            AM
                                        </mat-option>
                                        <mat-option value="PM">
                                            PM
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%">
                                <button
                                    [disabled]="!day.controls['addStartTime'].valid || !day.controls['addEndTime'].valid || day.controls['addStartTime'].value === '' || day.controls['addEndTime'].value === ''"
                                    class="row-button" mat-raised-button mat-icon-button color="primary"
                                    (click)="addTimeSlot(day)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </div>
                        <br />
                    </ng-container>
                    <mat-divider></mat-divider>
                </div>
            </div>
        </div>
        <br />
        <mat-form-field appearance="outline" color="primary">
            <mat-label>Availability Note</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5" placeholder=""
                formControlName="availabilityNote" type="text"></textarea>
            <mat-hint>Additional info about your availability.</mat-hint>
        </mat-form-field>
        <br />
        <br />
    </form>
</ng-container>