import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';

// environment
import { environment } from 'src/environments/environment';

// firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

// custom modules
import { MaterialComponentsModule } from 'src/app/material-components/material-components.module';
import { GlobalModule } from 'src/app/global/global.module';
import { AuthModule } from './auth/auth.module';
import { EventModule } from './event/event.module';
import { ProfileModule } from './profile/profile.module';
import { AdminModule } from './admin/admin.module';

// guards
import { RegisterGuard } from './profile/guards/register/register.guard';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,    
    AppRoutingModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    MaterialComponentsModule,
    GlobalModule,
    AuthModule,
    EventModule,
    ProfileModule,
    AdminModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    CurrencyPipe,
    RegisterGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
