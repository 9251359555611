<div class="polaroid-image">
    <img *ngIf="croppedImage && croppedImage !== ''" [src]="croppedImage" />
    <h1 class="inline-header-name">{{ profileData.displayName }}</h1>
</div>

<p *ngIf="profileData.bio" class="event-item-content">{{ profileData.bio }}</p>

<mat-list *ngIf="profileData.tagNames">
    <div mat-subheader>
        <span>Tags</span>
    </div>
    <mat-divider></mat-divider>
    <div class="event-item-content">
        <span *ngFor="let tag of profileData.tagNames; let i = index">{{ i < profileData.tagNames.length - 1 ? tag
                + ', ' : tag }}</span>
    </div>
</mat-list>