import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Capacitor } from '@capacitor/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

// services
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { UserService } from 'src/app/profile/services/user/user.service';
import { EventService } from '../../services/event/event.service';
@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  bookings: Array<any> = [];
  bookingsSorted: Array<any> = [];
  status: string = 'upcoming';
  isIos: boolean = false;
  defaultScrollParams: any = {
    limitLength: 20,
    offsetLength: 0,
    bottomReached: false
  };   
  scrollParams: any;

  private scrollEventsSubject: BehaviorSubject<any> = new BehaviorSubject(this.defaultScrollParams);

  constructor(
    private loaderService: LoaderService,
    private userService: UserService,
    private eventService: EventService,
    private afd: AngularFireDatabase
  ) {
    this.isIos = Capacitor.getPlatform() === 'ios';
  }

  async ngOnInit() {
    this.scrollEventsSubject.subscribe(async res => {
      await this.getEvents(this.status, res);
    });
  }

  /**
   * @description toggle the event status to filter by
   * @param status { any }
   */
  async changeStatus(status: any) {
    this.bookings = [];
    this.bookingsSorted = [];
    this.status = status;
    this.scrollEventsSubject.next(this.defaultScrollParams);
  }

  /**
   * @description append next set of events pulled to list when scrolled
   * @param scrollOutput { any }
   */
  async appendEvents(scrollOutput: any) {
    this.scrollEventsSubject.next(scrollOutput);
  }

  /**
   * @description gets the bookings from either firebase or mysql depending on event status selected
   * @param status { string } history || upcoming
   * @param scrollParams { any }
   */
  async getEvents(status: string, scrollParams: any) {
    this.status = status;
    this.scrollParams = _.clone(scrollParams);
    this.loaderService.loaderSubject$.next(true);

    let userId: number = await this.userService.getUserId();

    if (this.status === 'history') {

      this.eventService.getBookings(userId, scrollParams.limitLength, scrollParams.offsetLength).subscribe(events => {
        if (events && events.data && events.data.length > 0) {
          console.log('bookings: ', events);
          this.bookingsSorted = this.bookingsSorted.concat(events.data);
          this.loaderService.loaderSubject$.next(false);
          console.log('bookings: ', this.bookingsSorted);
          // this.sortBookings(this.bookings);
        } else {
          this.loaderService.loaderSubject$.next(false);
          this.scrollParams.bottomReached = true;
        }
      });

    } else {

      this.scrollParams.bottomReached = true;
      this.afd.list('events').valueChanges().subscribe((res: Array<any>) => {
        console.log('bookings: ', res);
        if (res) {
          this.bookings = res.filter(event => {
            return event.bookingUserId === Number(userId);
          });
          this.sortBookings(this.bookings);
        }
        this.loaderService.loaderSubject$.next(false);
      });

    }

  }

  /**
   * @description sort the firebase booking list 
   * @param bookings { Array<any> }
   */
  private sortBookings(bookings: Array<any>) {
    this.bookingsSorted = bookings.sort((a: any, b: any) => {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      let start1: any = new Date(b.dateTimeStart).getTime();
      let start2: any = new Date(a.dateTimeStart).getTime();
      return start2 - start1;
    });
  }

}
