<div class="container-authenticated" [ngClass]="{'container-authenticated-ios': isIos}">
    <h1 class="inline-header">Manage Custom Tags</h1>
    <span class="spacer"></span>
    <div class="actions">
        <button *ngIf="tagListData && tagListData.length > 0" class="icon-button" mat-raised-button color="primary"
            (click)="openTagDialog('add')"><mat-icon>new_label</mat-icon> Add</button>
    </div>
    <mat-divider></mat-divider>
    <mat-list class="tag-list">
        <ng-container *ngIf="tagListData && tagListData.length > 0; else notags">
            <ng-container *ngFor="let tag of tagListData">
                <mat-list-item>
                    <mat-icon mat-list-icon>local_offer</mat-icon>
                    <div mat-line [matBadge]="tag.statusLookup.status" matBadgeOverlap="false"
                        matBadgeColor="{{ tag.statusLookup.status === 'Active' ? 'accent' : 'warn' }}">{{ tag.name }}
                    </div>
                    <div class="desktop-buttons">
                        <button class="icon-button" mat-button
                            (click)="openTagDialog('edit', tag)"><mat-icon>edit</mat-icon> Edit</button>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="tagOptions" matTooltip="Options">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #tagOptions class="matMenu">
                        <div class="mobile-buttons">
                            <button class="menuButtonToggle" mat-menu-item (click)="openTagDialog('edit', tag)">
                                <mat-icon>edit</mat-icon>
                                <span>Edit</span>
                            </button>
                        </div>
                        <button *ngIf="tag.statusLookupId === globalConstants.activeStatus.id" class="menuButtonToggle"
                            mat-menu-item (click)="deactivateTagDialog(deactivateTemplate)">
                            <mat-icon>lock</mat-icon>
                            <span>Deactivate</span>
                        </button>
                        <button *ngIf="tag.statusLookupId === globalConstants.inactiveStatus.id"
                            class="menuButtonToggle" mat-menu-item (click)="reactivateTagDialog(reactivateTemplate)">
                            <mat-icon>lock_open</mat-icon>
                            <span>Reactivate</span>
                        </button>
                        <button class="menuButtonToggle" mat-menu-item (click)="deleteTagDialog(deleteTemplate)">
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>

                    <ng-template #deleteTemplate>
                        <h1 mat-dialog-title>Delete Tag</h1>
                        <div mat-dialog-content>
                            <p>You are about to delete the tag "{{ tag.name }}".</p>
                            <span>Are you sure?</span>
                        </div>
                        <div mat-dialog-actions>
                            <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                                Cancel</button>
                            <button class="icon-button" mat-button (click)="deleteTag(tag)" cdkFocusInitial
                                [disabled]="deleteTagSaving"><mat-icon>delete</mat-icon>
                                Delete</button>
                        </div>
                    </ng-template>

                    <ng-template #deactivateTemplate>
                        <h1 mat-dialog-title>Deactivate Tag</h1>
                        <div mat-dialog-content>
                            <p>You are about to deactivate the tag "{{ tag.name }}".</p>
                            <span>Are you sure?</span>
                        </div>
                        <div mat-dialog-actions>
                            <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                                Cancel</button>
                            <button class="icon-button" mat-button (click)="deactivateTag(tag)" cdkFocusInitial
                                [disabled]="deactivateTagSaving"><mat-icon>lock</mat-icon>
                                Deactivate</button>
                        </div>
                    </ng-template>

                    <ng-template #reactivateTemplate>
                        <h1 mat-dialog-title>Reactivate Tag</h1>
                        <div mat-dialog-content>
                            <p>You are about to reactivate the tag "{{ tag.name }}".</p>
                            <span>Are you sure?</span>
                        </div>
                        <div mat-dialog-actions>
                            <button class="icon-button" mat-button mat-dialog-close><mat-icon>close</mat-icon>
                                Cancel</button>
                            <button class="icon-button" mat-button (click)="reactivateTag(tag)" cdkFocusInitial
                                [disabled]="reactivateTagSaving"><mat-icon>lock_open</mat-icon>
                                Reactivate</button>
                        </div>
                    </ng-template>

                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </ng-container>
        <ng-template #notags>
            <div class="no-tags">
                <span class="center-align">
                    <mat-icon mat-list-icon class="empty-tag-list">label_off</mat-icon>
                    <h2>There are no custom tags in the system!</h2>
                    <button class="icon-button" mat-raised-button color="primary"
                        (click)="openTagDialog('add')"><mat-icon>new_label</mat-icon> Add</button>
                </span>
            </div>
        </ng-template>
    </mat-list>
</div>