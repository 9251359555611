import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';
import { SuccessMessageComponent } from 'src/app/global/components/success-message/success-message.component';

// constants
import { GlobalConstants } from 'src/app/global/constants/global';

// services
import { ImageService } from 'src/app/global/services/image/image.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { EventService } from '../../services/event/event.service';
import { AuthService } from 'src/app/auth/services/auth/auth.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit, OnDestroy {

  isIos: boolean = false; // flag to tell if device is ios;

  user: any// the authenticated user
  eventId: number; // the event id
  modifiedBy: string // the authenticated user email 
  showLength: any; // the processed show length data
  croppedImage: any = '';  // the base 64 cropped image

  eventStatusData: any; // the raw event status data from firebase
  eventData: any; // the raw event data
  artistSongListData: any; // the raw logged in user song list data

  cancelEventDialogRef: any; // element reference to cancel event dialog
  cancelEventSaving: boolean = false; // flag to tell if in the act of deleting an event

  confirmEventDialogRef: any; // element reference to confirm event dialog
  confirmEventSaving: boolean = false; // flag to tell if in the act of confirming an event

  startEventDialogRef: any; // element reference to start event dialog
  startEventSaving: boolean = false; // flag to tell if in the act of starting an event

  completeEventDialogRef: any; // element reference to complete event dialog
  completeEventSaving: boolean = false; // flag to tell if in the act of completing an event

  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template

  canStartEvent: boolean = false; // tells if the event can start
  canStartEventStartTime: Moment; // the start time of the event that can start
  canStartEventEndTime: Moment; // the end time of the event that can start

  // empty stripe data object
  stripeData: any = {
    payment: 0,
    refund: 0
  };

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private eventService: EventService,
    private loaderService: LoaderService,
    private imageService: ImageService,
    private snackBar: MatSnackBar
  ) {
    this.globalConstants = GlobalConstants;
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.eventId = this.route.snapshot.params['id'];
    this.getEventStatus();
  }

  async ngOnInit() {

    this.loaderService.loaderSubject$.next(true);

    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe({
        next: (res) => {
          if (res) {
            this.user = res;
            this.getEvent();
          }
        },
        error: (e) => {
          console.error(e);
          // hide the loader
          this.loaderService.loaderSubject$.next(false);
          this.snackBar.openFromComponent(ErrorMessageComponent, {
            data: e,
            duration: 10000,
            panelClass: ['error-snackbar']
          });
        },
        complete: () => console.info('complete')
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description gets the event status from firebase
   */
  private getEventStatus() {
    this.subscriptions.add(
      this.eventService.getEventStatus(this.eventId).subscribe(status => {
        console.log('firebase event status: ', status)
        this.eventStatusData = status;
        if(!status){
          this.getEvent();
        }
      })
    );
  }

  /**
   * @description gets the event from the database by the passed in event id
   */
  private getEvent() {
    // get the authenticated users profile data
    this.subscriptions.add(
      this.eventService.getEvent(this.eventId, this.user.id).subscribe(
        {
          next: (res) => {
            console.log(res);
            if (!res) return; // stop if there is no result

            this.eventData = res.data; // set raw profile data

            let start = moment(this.eventData.dateTimeStart);
            let end = moment(this.eventData.dateTimeEnd);
            this.showLength = ((end.diff(start) / 1000) / 60);

            if (this.user.id == this.eventData.__bookedUser__.id) {
              this.modifiedBy = this.eventData.__bookingUser__.email;
              this.croppedImage = this.eventData.__bookingUser__.profileImageUrl ? this.imageService.formatImage(this.eventData.__bookingUser__.profileImageUrl)['640w'] : '';
            } else {
              this.modifiedBy = this.eventData.__bookedUser__.email;
              this.croppedImage = this.eventData.__bookedUser__.profileImageUrl ? this.imageService.formatImage(this.eventData.__bookedUser__.profileImageUrl)['640w'] : '';
            }

            // check if songs exists and set var
            if (this.eventData.__bookedUser__.__userSongs__) {
              this.artistSongListData = { userId: this.eventData.__bookedUser__.id, songList: this.eventData.__bookedUser__.__userSongs__ };
            } else {
              this.artistSongListData = { userId: this.eventData.__bookedUser__.id };
            }

            this.parseStripeData(this.eventData.__eventTransactionHistory__);

            this.canStartEventStartTime = start.clone().subtract(5, 'minutes');
            this.canStartEventEndTime = end.clone().add(5, 'minutes');
            // this will enable the button when the time reaches the window to start the event.
            setInterval(() => {
              let today = moment();
              if (today.isBetween(this.canStartEventStartTime, this.canStartEventEndTime, undefined, '[]')) {
                this.canStartEvent = true;
              } else {
                this.canStartEvent = false;
              }
            }, 1000);

            // hide the loader
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            // hide the loader
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description opens the confirm event dialog and sets up the close event
   * @param confirmEventTemplateRef { any }
   */
  confirmEventDialog(confirmEventTemplateRef: any): void {
    this.confirmEventDialogRef = this.dialog.open(confirmEventTemplateRef);

    this.subscriptions.add(
      this.confirmEventDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description confirms the event
   * @param eventId { number } the event id that the user is wanting to confirm
   */
  confirmEvent(eventId: number) {
    console.log('confrim event: ', eventId);

    this.confirmEventSaving = true;
    this.loaderService.loaderSubject$.next(true);

    let eventStatusHistoryData = {
      userId: this.user.id,
      statusLookupId: GlobalConstants.activeStatus.id,
      modifiedBy: this.eventData.__bookedUser__.email,
      eventStatusLookupId: GlobalConstants.confirmedEventStatus.id,
      eventId: eventId
    };

    this.eventService.createEventStatusHistory(eventStatusHistoryData).subscribe(
      {
        next: (res: any) => {
          console.log(res);
          this.snackBar.openFromComponent(SuccessMessageComponent, {
            data: 'Event Confirmed!',
            duration: 5000,
            panelClass: ['success-snackbar']
          });
          this.confirmEventDialogRef.close({ event: 'close' });          
          this.getEvent();          
          this.confirmEventSaving = false;
        },
        error: (e) => {
          this.confirmEventSaving = false;
          console.error(e);
          this.snackBar.openFromComponent(ErrorMessageComponent, {
            data: e,
            duration: 10000,
            panelClass: ['error-snackbar']
          });
        },
        complete: () => console.info('Confirm event complete.')
      }
    );
  }

  /**
   * @description opens the cancel event dialog and sets up the close event
   * @param cancelEventTemplateRef { any }
   */
  cancelEventDialog(cancelEventTemplateRef: any): void {
    this.cancelEventDialogRef = this.dialog.open(cancelEventTemplateRef);

    this.subscriptions.add(
      this.cancelEventDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description cancels the event
   * @param eventId { number } the event id that the user is wanting to cancel
   */
  cancelEvent(eventId: number) {
    console.log('confrim event: ', eventId);
    this.cancelEventSaving = true;
    this.loaderService.loaderSubject$.next(true);

    this.eventService.cancelEvent(this.user.id, eventId).subscribe({
      next: (res: any) => {
        console.log(res);
        this.snackBar.openFromComponent(SuccessMessageComponent, {
          data: 'You have successfuly canceled the event.',
          duration: 5000,
          panelClass: ['success-snackbar']
        });
        this.cancelEventDialogRef.close({ event: 'close' });
        this.getEvent();
        this.cancelEventSaving = false;
      },
      error: (e) => {
        this.cancelEventSaving = false;
        this.loaderService.loaderSubject$.next(false);
        console.error(e);
        this.snackBar.openFromComponent(ErrorMessageComponent, {
          data: e,
          duration: 10000,
          panelClass: ['error-snackbar']
        });
      },
      complete: () => console.info('Event canceled complete')
    });
  }

  /**
   * @description opens the start event dialog and sets up the close event
   * @param startEventTemplateRef { any }
   */
  startEventDialog(startEventTemplateRef: any): void {
    this.startEventDialogRef = this.dialog.open(startEventTemplateRef);

    this.subscriptions.add(
      this.startEventDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description starts the event
   * @param eventId { number } the event id that the user is wanting to start
   */
  startEvent(eventId: number) {
    console.log('start event: ', eventId);

    this.startEventSaving = true;
    this.loaderService.loaderSubject$.next(true);

    let eventStatusHistoryData = {
      userId: this.user.id,
      statusLookupId: GlobalConstants.activeStatus.id,
      modifiedBy: this.eventData.__bookedUser__.email,
      eventStatusLookupId: GlobalConstants.liveEventStatus.id,
      eventId: eventId
    };

    this.eventService.createEventStatusHistory(eventStatusHistoryData).subscribe(
      {
        next: (res: any) => {
          console.log(res);
          this.snackBar.openFromComponent(SuccessMessageComponent, {
            data: 'Event Started!',
            duration: 5000,
            panelClass: ['success-snackbar']
          });
          this.startEventDialogRef.close({ event: 'close' });
          this.joinEvent(eventId);
          this.startEventSaving = false;
        },
        error: (e) => {
          this.startEventSaving = false;
          console.error(e);
          this.snackBar.openFromComponent(ErrorMessageComponent, {
            data: e,
            duration: 10000,
            panelClass: ['error-snackbar']
          });
        },
        complete: () => console.info('Start event complete.')
      }
    );
  }

  /**
   * @description join the event
   * @param eventId { number } the event id that the user is wanting to join
   */
  joinEvent(eventId: number) {
    this.loaderService.loaderSubject$.next(true);
    console.log('join event: ', eventId);
    this.router.navigate([`stream`], { relativeTo: this.route });
  }

  /**
   * @description copys the email address to the clipboard
   * @param val { string } the value to copy to clipboard
   */
  copyEmail(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.snackBar.openFromComponent(SuccessMessageComponent, {
      data: val + ' copied to your clipboard.',
      duration: 5000,
      panelClass: ['success-snackbar']
    });
  }

  /**
   * @description opens the complete event dialog and sets up the close event
   * @param completeEventTemplateRef { any }
   */
  completeEventDialog(completeEventTemplateRef: any): void {
    this.completeEventDialogRef = this.dialog.open(completeEventTemplateRef);

    this.subscriptions.add(
      this.completeEventDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description completes the event
   * @param eventId { number } the event id that the user is wanting to complete
   */
  completeEvent(eventId: number) {
    console.log('complete event: ', eventId);

    this.completeEventSaving = true;
    this.loaderService.loaderSubject$.next(true);

    this.eventService.completeEvent(this.user.id, eventId).subscribe(
      {
        next: (res: any) => {
          console.log(res);
          this.snackBar.openFromComponent(SuccessMessageComponent, {
            data: 'Event Complete!',
            duration: 5000,
            panelClass: ['success-snackbar']
          });
          this.completeEventDialogRef.close({ event: 'close' });
          this.getEvent();
          this.completeEventSaving = false;
          this.loaderService.loaderSubject$.next(false);
        },
        error: (e) => {
          this.completeEventSaving = false;
          this.loaderService.loaderSubject$.next(false);
          console.error(e);
          this.snackBar.openFromComponent(ErrorMessageComponent, {
            data: e,
            duration: 10000,
            panelClass: ['error-snackbar']
          });
        },
        complete: () => console.info('Complete event complete.')
      }
    );
  }

  /**
   * @description check if the event is incomplete based on the start and end times
   * @param endDateTime { any }
   * @returns boolean
   */
  incompleteEvent(endDateTime: any) {
    let now = moment();
    let endtime = moment(endDateTime);
    return now.isBefore(endtime);
  }

  /**
   * @description gets the data string from stripe and parse it into a json object
   * @param data { Array<any> }
   */
  private parseStripeData(data: Array<any>) {

    if (data && data.length > 0) {
      for (let transaction of data) {

        let raw: any = JSON.parse(transaction.stripeObject);

        switch (transaction.stripeEventType) {
          case 'checkout.session.completed':
            this.stripeData.payment = this.stripeData.payment + raw.data.object.amount_total;
            break;
          case 'refund':
            this.stripeData.refund = this.stripeData.refund + raw.amount;
            break;
        }
      }
    }
  }

}
