import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingStateService {

  history = []; // a list of routes visited

  constructor(
    private router: Router
  ) { }

  /**
   * @description sets up the way the router events work
   */
  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        if (urlAfterRedirects === '/' || urlAfterRedirects === '/browse' || urlAfterRedirects === '/edit-profile' || urlAfterRedirects === '/shows' || urlAfterRedirects === '/bookings') {
          this.history = [urlAfterRedirects];
        } else {
          this.history = [...this.history, urlAfterRedirects];
        }
      });
  }

  /**
   * @description wires up the back route based on history
   */
  public back(): void {

    this.history.pop();
    let previousUrl = this.history[this.history.length - 1];
    if (previousUrl) {
      this.router.navigateByUrl(previousUrl);
      this.history.pop();
    }

  }
}
