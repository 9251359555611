<div *ngIf="profileData" class="container-authenticated" [ngClass]="{'container-authenticated-ios': isIos}">
    <h1 class="inline-header">Artist</h1>
    <button class="icon-button right-group float-right" mat-raised-button color="primary" type="button" (click)="scheduleEvent(profileData.id)">
        <mat-icon>today</mat-icon> Schedule Event
    </button>
    <br class="clear" />
    <mat-divider></mat-divider>
    <br/>
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
        <div fxFlex="0 1 45%" fxFlex.lt-md="100%">            
            <app-profile-info [profileData]="profileData"></app-profile-info>       

            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                    <mat-list>
                        <div mat-subheader>
                            <span>Event Info</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="event-item-content">
                            <span>Min Length: {{showRequirementsData.minimumShowLength | time}}</span><br />
                            <span>Max Length: {{showRequirementsData.maximumShowLength | time}}</span><br />
                            <span>Price: {{showRequirementsData.pricePerHour | currency}}/hr</span><br />
                            <span>Deposit: {{showRequirementsData.depositPercentage}}% of total</span><br />
                        </div>
                    </mat-list>
                </div>
                <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                    <mat-list>
                        <div mat-subheader>
                            <span>Availability</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="event-item-content">
                            <ng-container *ngFor="let day of days">
                                <div *ngIf="day.times && day.times.length > 0">
                                    {{day.name}} <br />
                                    <span *ngFor="let time of day.times" class="times-available">
                                        {{time.startTime | date: 'shortTime'}} - {{time.endTime | date:
                                        'shortTime'}}<br />
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                    </mat-list>
                    <p *ngIf="profileData.availabilityNote">{{ profileData.availabilityNote }}</p>
                </div>
            </div>
        </div>
        <div fxFlex="0 1 30%" fxFlex.lt-md="100%">
            <app-song-list *ngIf="userSongListData" [userSongListData]="userSongListData"></app-song-list>
        </div>
        <div fxFlex="0 1 25%" fxFlex.lt-md="100%">
            <app-comments [comments]="profileData.comments"></app-comments>
        </div>
    </div>
</div>