import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class CheckForUpdateService {

  private appIsStable$: any;
  private everyHour$: any;
  private everyHourOnceAppIsStable$: any;

  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    this.appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    this.everyHour$ = interval(1 * 60 * 60 * 1000);
    this.everyHourOnceAppIsStable$ = concat(this.appIsStable$, this.everyHour$);
  }

  startUpdateCheck() {
    this.everyHourOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await this.updates.checkForUpdate();
        console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }
}
