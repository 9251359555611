import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// services
import { CognitoService } from '../cognito/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  tokenSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(''); // AWS JWT subject with token string value
  token$: Observable<string> = this.tokenSubject$.asObservable(); // the token subject observable

  validSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // subject with boolean to tell if token is valid
  valid$: Observable<boolean> = this.validSubject$.asObservable(); // valid subject observable

  constructor(
    private cognitoService: CognitoService
  ) { }

  /**
   * @description check the current token to see if it is valid.
   */
  async checkToken() {
    // console.log('Checking Token ...');
    await this.cognitoService.getCognitoUser().then(async cognitoUser => {
      if (cognitoUser) {
        // console.log('Getsession for user: ', cognitoUser);
        await this.cognitoService.getCognitoUserSession(cognitoUser).then(async session => {
          if (session) {
            // console.log('session: ', session);
            await this.cognitoService.getCognitoSessionValid(session).then(async isValid => {

              this.validSubject$.next(isValid);
              let now = new Date();
              let timeToRenew = new Date(session.accessToken.payload.exp * 1000);
              // console.log('is valid session: ', isValid);
              // console.log('now: ', now);
              // console.log('timeToRenew: ', timeToRenew);
              // console.log('renewing token: ', isValid && (now >= timeToRenew));

              await this.cognitoService.getCognitoUserJwtToken(session).then(token => {
                if (isValid && this.tokenSubject$.value !== token) {
                  this.tokenSubject$.next(token);
                }
              }).catch(err => {
                if (err) {
                  console.log('jwt token error: ', err);
                }
              });
            }).catch(err => {
              if (err) {
                console.log('session valid error: ', err);
              }
            });
          }
        }).catch(err => {
          if (err) {
            console.log('session error: ', err);
          }
        });
      }
    }).catch(err => {
      if (err) {
        console.log('cognitoUser error: ', err);
      }
    });

  }

}
