import { Injectable } from "@angular/core";

@Injectable()
export class StreamControlsHelper {

    constructor(
    ) {
    }

    /**
     * @description this handles when the full screen enters
     */
    enterHandler(page: any) {
        let navbar: any = page.getElementsByTagName('app-navbar');
        let sidenav: any = page.getElementsByTagName('mat-sidenav');
        let sidenavContainer: any = page.getElementsByTagName('mat-sidenav-container');
        let sidenavContent: any = page.getElementsByTagName('mat-sidenav-content');
        let streamContainer: any = page.getElementById('stream');
        // set the video height to expand to screen height
        let remoteVideo: any = page.getElementsByClassName('remote-video');

        streamContainer.classList.add('fullscreen');

        for (let bar of navbar) {
            bar.classList.add('hidden');
        }
        for (let nav of sidenav) {
            nav.classList.add('full');
        }
        for (let navConatiner of sidenavContainer) {
            navConatiner.classList.add('mat-drawer-container-fullscreen');
        }
        for (let navcont of sidenavContent) {
            navcont.classList.add('full');
        }
        for (let video of remoteVideo) {
            video.classList.add('fullscreen');
        }

        // set visibitity of fullscreen button controls 
        let openFullScreenButton: any = page.getElementById('fullScreenOpenVideo');
        openFullScreenButton.classList.add('hidden');

        let closeFullScreenButton: any = page.getElementById('fullScreenCloseVideo');
        closeFullScreenButton.classList.remove('hidden');
    }

    /**
     * @description this handles when the full screen exits
     */
    exitHandler(page: any, isNative: boolean) {
        let navbar: any = page.getElementsByTagName('app-navbar');
        let sidenav: any = page.getElementsByTagName('mat-sidenav');
        let sidenavContainer: any = page.getElementsByTagName('mat-sidenav-container');
        let sidenavContent: any = page.getElementsByTagName('mat-sidenav-content');
        let streamContainer: any = page.getElementById('stream');
        let remoteVideo: any = page.getElementsByClassName('remote-video');

        if (!isNative) {
            if (!page.webkitIsFullScreen && !page.mozFullScreen && !page.msFullscreenElement) {

                streamContainer.classList.remove('fullscreen');

                for (let bar of navbar) {
                    bar.classList.remove('hidden');
                }
                for (let nav of sidenav) {
                    nav.classList.remove('full');
                }
                for (let navConatiner of sidenavContainer) {
                    navConatiner.classList.remove('mat-drawer-container-fullscreen');
                }
                for (let navcont of sidenavContent) {
                    navcont.classList.remove('full');
                }
                for (let video of remoteVideo) {
                    video.classList.remove('fullscreen');
                }

                // set visibility of full screen buttons
                let openFullScreenButton: any = page.getElementById('fullScreenOpenVideo');
                openFullScreenButton.classList.remove('hidden');

                let closeFullScreenButton: any = page.getElementById('fullScreenCloseVideo');
                closeFullScreenButton.classList.add('hidden');
            }
        } else {

            streamContainer.classList.remove('fullscreen');

            for (let bar of navbar) {
                bar.classList.remove('hidden');
            }
            for (let nav of sidenav) {
                nav.classList.remove('full');
            }
            for (let navConatiner of sidenavContainer) {
                navConatiner.classList.remove('mat-drawer-container-fullscreen');
            }
            for (let navcont of sidenavContent) {
                navcont.classList.remove('full');
            }
            for (let video of remoteVideo) {
                video.classList.remove('fullscreen');
            }

            // set visibility of full screen buttons
            let openFullScreenButton: any = page.getElementById('fullScreenOpenVideo');
            openFullScreenButton.classList.remove('hidden');

            let closeFullScreenButton: any = page.getElementById('fullScreenCloseVideo');
            closeFullScreenButton.classList.add('hidden');
        }
    }

    muteSpeakers(muted: boolean) {
        let remoteVideos: any = document.getElementsByClassName('remote-video');
        let muteSpeakersElement = document.getElementById(`muteSpeakers`);
        let unmuteSpeakersElement = document.getElementById(`unmuteSpeakers`);

        if (remoteVideos.length > 0) {
            for (let video of remoteVideos) {
                video.muted = muted;
            }
        }

        switch (muted) {
            case true:
                muteSpeakersElement.classList.add('hidden');
                unmuteSpeakersElement.classList.remove('hidden');
                break;
            default:
                unmuteSpeakersElement.classList.add('hidden');
                muteSpeakersElement.classList.remove('hidden');
        }
    }

    /**
     * @description change the controls to be fullscreen when casting
     */
    controlBarCast(connected: boolean, controlBarElement: any) {

        let timer = document.getElementById('timer');
        let buttonContainer = document.getElementById('buttonContainer');
        let castButton = document.getElementById('castbutton');
        let buttons = controlBarElement.querySelectorAll('button');

        let remoteVideo = document.getElementsByClassName('remote-video');
        let localVideo = document.getElementsByClassName('local-video');

        if (connected) {
            this.muteSpeakers(true);
            // controlBarElement.classList.add('castingControlsBackground'); //This was the old remote when casting
            
            // new casting view on device to show local video
            if (remoteVideo && remoteVideo.length > 0) {
                remoteVideo[0].classList.add('hidden');
            }
            if (localVideo && localVideo.length > 0) {
                localVideo[0].classList.add('full');
            }
            
            // timer.classList.add('casting'); //This was the old remote when casting
            // buttonContainer.classList.add('casting'); //This was the old remote when casting

            castButton.classList.remove('mat-primary');
            castButton.classList.add('mat-accent');

            for (let button of buttons) {
                if (button.id === 'muteSpeakers' || button.id === 'unmuteSpeakers') {
                    button.classList.add('castingButtonHide');
                } else {
                    // button.classList.remove('mat-mini-fab'); //This was the old remote when casting
                    // button.classList.add('mat-fab'); //This was the old remote when casting
                    button.classList.add('casting');
                }
            }
        } else {
            this.muteSpeakers(false);
            // controlBarElement.classList.remove('castingControlsBackground'); //This was the old remote when casting

            // new casting view on device to show local video
            if (remoteVideo && remoteVideo.length > 0) {
                remoteVideo[0].classList.remove('hidden');
            }
            if (localVideo && localVideo.length > 0) {
                localVideo[0].classList.remove('full');
            }
            // timer.classList.remove('casting'); //This was the old remote when casting
            // buttonContainer.classList.remove('casting'); //This was the old remote when casting

            castButton.classList.add('mat-primary');
            castButton.classList.remove('mat-accent');

            for (let button of buttons) {
                if (button.id === 'muteSpeakers' || button.id === 'unmuteSpeakers') {
                    button.classList.remove('castingButtonHide');
                } else {
                    // button.classList.add('mat-mini-fab'); //This was the old remote when casting
                    // button.classList.remove('mat-fab'); //This was the old remote when casting
                    button.classList.remove('casting');
                }
            }
        }
    }

    /**
     * @description handles the full screen toggle based on device/web
     */
    toggleFullScreen(page: any) {

        if ((page.fullScreenElement && page.fullScreenElement !== null) || (!page.mozFullScreen && !page.webkitIsFullScreen)) {
            if (page.documentElement.requestFullScreen) {
                page.documentElement.requestFullScreen();
            }
            else if (page.documentElement.mozRequestFullScreen) { /* Firefox */
                page.documentElement.mozRequestFullScreen();
            }
            else if (page.documentElement.webkitRequestFullScreen) {   /* Chrome, Safari & Opera */
                //@ts-ignore
                page.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
            else if (page.msRequestFullscreen) { /* IE/Edge */
                page.documentElement.msRequestFullscreen();
            }
        }
        else {
            if (page.cancelFullScreen) {
                page.cancelFullScreen();
            }
            else if (page.mozCancelFullScreen) { /* Firefox */
                page.mozCancelFullScreen();
            }
            else if (page.webkitCancelFullScreen) {   /* Chrome, Safari and Opera */
                page.webkitCancelFullScreen();
            }
            else if (page.msExitFullscreen) { /* IE/Edge */
                page.msExitFullscreen();
            }
        }
    }

}