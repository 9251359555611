<div class="container-authenticated" [ngClass]="{'container-authenticated-ios': isIos}">
    <div class="register-stepper">
        <h1>Register Profile</h1>
        <mat-divider></mat-divider>
        <ng-container *ngIf="profileData">
            <br />
            <div class="horizontal-center-form">
                <ng-container *ngIf="userProfile; else artist">
                    <app-profile-form *ngIf="profileData && modifiedBy" [profileData]="profileData"
                        [modifiedBy]="modifiedBy" [userProfile]="userProfile"
                        (profileFormValidOutput)="setProfileFormValid($event)"
                        (profileFormOutput)="setProfileData($event)">
                    </app-profile-form>
                    <div>
                        <br />
                        <br />
                        <button class="icon-button right-button" mat-raised-button color="primary" type="button" *ngIf="profileData && modifiedBy"
                            [disabled]="!profileFormValid || profileSaving" (click)="updateProfile()"><mat-icon>done</mat-icon> Done</button>
                    </div>
                </ng-container>
                <ng-template #artist>
                    <mat-stepper labelPosition="bottom" #registerStepper linear>
                        <mat-step stepControl="stepOne">
                            <ng-template matStepLabel>Details</ng-template>
                            <app-profile-form *ngIf="profileData && modifiedBy" [profileData]="profileData"
                                [modifiedBy]="modifiedBy" [userProfile]="userProfile"
                                (profileFormValidOutput)="setProfileFormValid($event)"
                                (profileFormOutput)="setProfileData($event)"></app-profile-form>
                            <div>
                                <button class="icon-button right-button" mat-button type="button" *ngIf="profileData && modifiedBy"
                                    [disabled]="!profileFormValid || profileSaving" (click)="updateProfile()">Next <mat-icon>navigate_next</mat-icon></button>
                            </div>
                        </mat-step>
                        <mat-step stepControl="stepTwo" optional>
                            <ng-template matStepLabel>Availability</ng-template>
                            <app-availability-form *ngIf="days && modifiedBy" [days]="days" [modifiedBy]="modifiedBy"
                                (availabilityFormValidOutput)="setAvailabilityFormValid($event)"
                                (availabilityFormOutput)="setAvailabilityData($event)"></app-availability-form>
                            <div>
                                <button class="icon-button" mat-button matStepperPrevious><mat-icon>navigate_before</mat-icon> Back</button>
                                <button class="icon-button right-button" mat-button *ngIf="days && modifiedBy" [disabled]="!availabilityFormValid || availabilitySaving"
                                    type="button" (click)="updateAvailability()">Next <mat-icon>navigate_next</mat-icon></button>
                                <button class="icon-button right-button" mat-button matStepperNext type="button">Skip <mat-icon>keyboard_double_arrow_right</mat-icon></button>
                            </div>
                        </mat-step>
                        <mat-step stepControl="stepThree" optional>
                            <ng-template matStepLabel>Show Settings</ng-template>
                            <app-show-settings-form *ngIf="showRequirementsData && modifiedBy"
                                [showRequirements]="showRequirementsData" [modifiedBy]="modifiedBy" [userId]="profileData.id"
                                (showRequirementsFormValidOutput)="setShowRequirementsFormValid($event)"
                                (showRequirementsFormOutput)="setShowRequirementsData($event)"></app-show-settings-form>
                            <div>
                                <button class="icon-button" mat-button matStepperPrevious><mat-icon>navigate_before</mat-icon> Back</button>
                                <button class="icon-button right-button" mat-button *ngIf="showRequirementsData && modifiedBy"
                                    [disabled]="!showRequirementsFormValid || showRequirementsSaving" type="button"
                                    (click)="updateShowRequirements()">Next <mat-icon>navigate_next</mat-icon></button>
                                <button class="icon-button right-button" mat-button matStepperNext type="button">Skip <mat-icon>keyboard_double_arrow_right</mat-icon></button>
                            </div>
                        </mat-step>
                        <mat-step stepControl="stepFour" optional>
                            <ng-template matStepLabel>Songlist</ng-template>
                            <app-song-list *ngIf="userSongListData && modifiedBy" [userSongListData]="userSongListData"
                                [modifiedBy]="modifiedBy" editable="true"></app-song-list>
                            <div>
                                <button class="icon-button" mat-button matStepperPrevious><mat-icon>navigate_before</mat-icon> Back</button>
                                <button class="icon-button right-button" mat-button [stripeConnectButton]="{ stripeAccountId: profileData.stripeAccountId, linkType: 'account_onboarding' }" *ngIf="stripeAccountStatusData && (!stripeAccountStatusData.setup)"><mat-icon>payments</mat-icon> Setup Payments</button>
                                <button class="icon-button right-button" mat-button *ngIf="userSongListData && modifiedBy" type="button"
                                    (click)="complete()"><mat-icon>done</mat-icon> Done</button>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </ng-template>
            </div>
        </ng-container>
    </div>
</div>