import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

// environments
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  private poolData: any; // AWS cognito pool data config object
  private userPool: any; // AWS cognito user pool

  constructor() {
    // sets the pool data config
    this.poolData = {
      UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
      ClientId: environment.cognitoAppClientId // Your client id here
    };

    // gets and sets the cognito user pool
    this.userPool = new CognitoUserPool(this.poolData);
  }

  /**
   * @description converts the callback of the cognito user into a promise
   * @returns Promise<any> with the calback values
   */
  public getCognitoUser(email?: string) {
    return new Promise<any>((resolve, reject) => {
      if (email) {
        let userData = {
          Username: email,
          Pool: this.userPool
        };
        let user = new CognitoUser(userData);
        resolve(user)
      } else {
        resolve(this.userPool.getCurrentUser());
      }
    });
  }

  /**
   * @description converts the callback of the cognito users jwt token into a promise
   * @param session { any } the actual cognito user session
   * @returns Promise<any> with the calback values
   */
  public getCognitoUserJwtToken(session: any) {
    return new Promise<any>((resolve, reject) => {
      resolve(session.idToken.getJwtToken());
    });
  }

  /**
   * @description converts the callback of the cognito session valid into a promise
   * @param session { any } the actual cognito user session
   * @returns Promise<any> with the calback values
   */
  public getCognitoSessionValid(session: any) {
    return new Promise<any>((resolve, reject) => {
      resolve(session.isValid());
    });
  }

  /**
   * @description converts the callback of the cognito user session into a promise
   * @param cognitoUser { any } the actual cognito user object
   * @returns Promise<any> with the calback values
   */
  public getCognitoUserSession(cognitoUser: any) {
    return new Promise<any>((resolve, reject) => {
      cognitoUser.getSession((err: any, session: any) => {
        resolve(session);
        reject(err);
      });
    });
  }

  /**
   * @description converts the callvack of the cognito user attributes into a promise
   * @param cognitoUser { any } the actual cognito user object
   * @returns Promise<any> with the calback values
   */
  public getCognitoUserAttributes(cognitoUser: any) {
    return new Promise<any>((resolve, reject) => {
      cognitoUser.getUserAttributes((err: any, user: any) => {
        resolve(user);
        reject(err);
      });
    });
  }

  /**
   * @description converts the callback of the cognito user auth attributes into a promise
   * @param email { string } the actual cognito user email
   * @param email { password } the actual cognito user password
   * @returns Promise<any> with the calback values
   */
  public setCognitoSetAuthAttributes(email: string, password: string) {
    return new Promise((resolve, reject) => {
      let authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      let userData = {
        Username: email,
        Pool: this.userPool
      };
      let cognitoUser = new CognitoUser(userData);

      if (!cognitoUser) {
        reject('No user!');
      } else {
        resolve({ cognitoUser: cognitoUser, authenticationDetails: authenticationDetails });
      }      
    });
  }

  /**
   * @description converts the callback of the cognito user auth into a promise
   * @param cognitoUser { any } the actual cognito user object
   * @param authenticationDetails { any } the actual cognito authentication details object
   * @returns Promise<any> with the calback values
   */
  public authCognitoUser(cognitoUser: any, authenticationDetails: any) {
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {          
          // console.log('call result: ', result);
          resolve(result);
        },
        onFailure: (err) => {          
          console.log('error: ', err);
          reject(err);
        },
      });
    });
  }

  /**
   * @description converts the callback of the cognito user forgot password into a promise
   * @param cognitoUser { any } the actual cognito user object
   * @returns Promise<any> with the calback values
   */
  public forgotPasswordCognitoUser(cognitoUser: any) {
    return new Promise((resolve, reject) => {

      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          // console.log('call result: ', result);
          resolve(result);
        },
        onFailure: function (err) {
          console.log('error: ', err);
          reject(err);
        }
      });
    });
  }

  /**
   * @description converts the callback of the cognito user confirm password into a promise
   * @param cognitoUser { any } the actual cognito user object
   * @param verificationCode { any } the string from the verification email
   * @param newPassword { any } the new password the user inputs
   * @returns Promise<any> with the calback values
   */
  public confirmPasswordCognitoUser(cognitoUser: any, verificationCode: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: function (result) {
          // console.log('call result: ', result);
          resolve(result);
        },
        onFailure: function (err) {
          console.log('error: ', err);
          reject(err);
        }
      });
    });
  }

}
