<div class="container-authenticated" [ngClass]="{'container-authenticated-ios': isIos}">
    <h1 class="inline-header" *ngIf="eventData">{{user.id == eventData.__bookedUser__.id ?
        eventData.__bookingUser__.displayName : eventData.__bookedUser__.displayName }}</h1>
    <div class="float-right">
        <div class="desktop-menu" [ngClass]="{ 'hidden' : isNative }">
            <button class="icon-button right-group" mat-raised-button color="primary" type="button"
                (click)="mediaSettingsDialog(mediaSettingsTemplate)">
                <mat-icon>settings_input_component</mat-icon> Media Settings
            </button>
            <button class="icon-button right-group" mat-raised-button color="warn" type="button"
                (click)="back()">
                <mat-icon>exit_to_app</mat-icon> Leave Event
            </button>
        </div>
        <div class="mobile-menu" [ngClass]="{ 'show' : isNative }">
            <button mat-icon-button [matMenuTriggerFor]="streamMenu" matTooltip="Options">
                <mat-icon>tune</mat-icon>
            </button>
            <mat-menu #streamMenu class="matMenu">
                <button mat-menu-item (click)="mediaSettingsDialog(mediaSettingsTemplate)">
                    <mat-icon>settings_input_component</mat-icon>
                    <span>Media Settings</span>
                </button>
                <button mat-menu-item (click)="back()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Leave Event</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <br class="clear" />
    <div #stream id="stream" class="stream-container">
        <div id="waiting" class="waiting-for-stream" *ngIf="!videoexists">
            <img src="assets/logos/toonballoon_logo_notext_light.webp">
            <h3>Get ready to release your Toon Balloon!</h3>
            <div *ngIf="!cameraAllowed || !micAllowed">
                <h4>Camera and microphone access is required to stream event!</h4>
                <button class="icon-button" mat-raised-button color="primary" type="button" (click)="manualCheck()">
                    <mat-icon>add</mat-icon> Grant Access
                </button>
                <br />
            </div>
            <mat-progress-bar mode="buffer" color="primary"></mat-progress-bar>
        </div>
        <div *ngIf="eventData && eventData.__bookedUser__.venmoURL" class="venmo-container"
            [ngClass]="eventData.__bookedUser__.venmoQrCodeScreenPosition ? eventData.__bookedUser__.venmoQrCodeScreenPosition : 'bottomRight'">
            <div class="venmo-label">TIP<br />{{ eventData.__bookedUser__.displayName }}</div>
            <!-- <br class="clear-both" /> -->
            <qrcode [qrdata]="eventData.__bookedUser__.venmoURL" [width]="qrCodeWidth" [errorCorrectionLevel]="'M'"
                [margin]="0" class="venmo-qr-code"></qrcode>
            <img class="venmo-logo" src="assets/logos/Venmo_Logo_Blue.png" />
        </div>
        <div id="controlBar" class="control-bar">
            <div id="buttonContainer" class="right-group-container">
                <button id="fullScreenOpenVideo" matTooltip="Enter Fullscreen" class="icon-button right-group-button" mat-mini-fab disabled
                    color="primary" type="button" (click)="openFullscreen()">
                    <mat-icon>fullscreen</mat-icon>
                </button>
                <button id="fullScreenCloseVideo" matTooltip="Exit Fullscreen" class="icon-button right-group-button hidden" mat-mini-fab
                    color="primary" type="button" (click)="closeFullscreen()">
                    <mat-icon>fullscreen_exit</mat-icon>
                </button>
                <button id="unmuteSpeakers" matTooltip="Unmute Speakers" (click)="muteSpeakers(false)" class="icon-button right-group-button hidden" mat-mini-fab color="warn"
                    type="button">
                    <mat-icon>volume_off</mat-icon>
                </button>
                <button id="muteSpeakers" matTooltip="Mute Speakers" (click)="muteSpeakers(true)" class="icon-button right-group-button" disabled mat-mini-fab color="primary"
                    type="button">
                    <mat-icon>volume_up</mat-icon>
                </button>
                <button id="unmuteVideo" matTooltip="Turn Camera On" class="icon-button right-group-button hidden" mat-mini-fab color="warn"
                    type="button">
                    <mat-icon>videocam_off</mat-icon>
                </button>
                <button id="muteVideo" matTooltip="Turn Camera Off" class="icon-button right-group-button" disabled mat-mini-fab color="primary"
                    type="button">
                    <mat-icon>videocam</mat-icon>
                </button>
                <button id="unmuteAudio" matTooltip="Turn Mic On" class="icon-button right-group-button hidden" mat-mini-fab color="warn"
                    type="button">
                    <mat-icon>mic_off</mat-icon>
                </button>
                <button id="muteAudio" matTooltip="Turn Mic Off" class="icon-button right-group-button" disabled mat-mini-fab color="primary"
                    type="button">
                    <mat-icon>mic_none</mat-icon>
                </button>
                <button *ngIf="!isIos" matTooltip="Chromecast" id="castbutton" class="icon-button right-group-button" [disabled]="!streamId"
                    mat-mini-fab color="primary" type="button" (click)="startCast()">
                    <mat-icon>cast</mat-icon>
                </button>
                <!-- <button id="eventId" class="icon-button right-group-button" mat-mini-fab color="primary" type="button" (click)="sendEventData()">
                    <mat-icon>send</mat-icon>
                </button> -->
                <br class="clear" />
                <div id="timer" class="timer">Time Remaining: {{ timer }}</div>
            </div>
        </div>
    </div>
    <!-- <ng-template #videoTemplate>
        <video #video></video>
    </ng-template> -->
    <ng-template #mediaSettingsTemplate>
        <h1 mat-dialog-title>Media Settings</h1>
        <div mat-dialog-content>
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Camera</mat-label>
                <mat-select [value]="cameraSelected" (selectionChange)="changeMedia($event, 'camera')">
                    <mat-option *ngFor="let camera of cameraList" [value]="camera">{{camera.label === '' ? 'Device Camera' : camera.label}}</mat-option>
                </mat-select>
                <mat-icon matPrefix>videocam</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Microphone</mat-label>
                <mat-select [value]="micSelected" (selectionChange)="changeMedia($event, 'mic')">
                    <mat-option *ngFor="let mic of micList" [value]="mic">{{mic.label === '' ? 'Device Microphone' :
                        mic.label}}</mat-option>
                </mat-select>
                <mat-icon matPrefix>mic_none</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" color="primary" *ngIf="speakerList.length > 0">
                <mat-label>Speakers</mat-label>
                <mat-select [value]="speakerSelected" (selectionChange)="changeMedia($event, 'speaker')">
                    <mat-option *ngFor="let speaker of speakerList" [value]="speaker">{{speaker.label === '' ? 'Device Speakers' : speaker.label}}</mat-option>
                </mat-select>
                <mat-icon matPrefix>speaker_group</mat-icon>
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button class="icon-button" mat-button mat-dialog-close><mat-icon>check</mat-icon> Done</button>
        </div>
    </ng-template>
</div>