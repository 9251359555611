import { Component, OnInit } from '@angular/core';

// capacitor
import { Capacitor } from '@capacitor/core';
import { LoaderService } from 'src/app/global/services/loader/loader.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  isIos: boolean = false // flag to tell if device is ios;

  constructor(
    private loaderService: LoaderService
  ) { 
    // check if device is ios and set flag
    this.isIos = Capacitor.getPlatform() === 'ios';
  }

  ngOnInit(): void {
    this.loaderService.loaderSubject$.next(false);
  }

}
