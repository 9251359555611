import { Injectable } from '@angular/core';

// environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  // preset sizes that an image can be set to have
  private sizes: string[] = [
    "80x80", // small existing thumb
    "150x150", // new thumb size?
    "200x125", // existing thumb
    "360w", // thumb without height restriction
    "360x270", // larger thumb restricted
    "480w",
    "640w",
    "1280w", // largest web image
  ];

  constructor(
  ) { }

  /**
   * @description formats the image pat 
   * @param imagePath { string }
   * @returns { any } the formatted image path object
   */
  formatImage(imagePath: string): any {
    let formattedPaths = {};
    for (let size of this.sizes) {
      formattedPaths[size] = environment.imageBaseUrl + this.insertSizeIntoString(imagePath, size);
    }
    return formattedPaths;
  }

  /**
   * @description inserts the requested image size into the image path string
   * @param imagePath { string }
   * @param size { string }
   * @returns combined image path and size string
   */
  insertSizeIntoString(imagePath: string, size: string) {
    //Split it into it's path segments
    let imageParts = imagePath.split('/');
    //Append the size prior to the last segment
    imageParts[imageParts.length - 1] = size + '/' + imageParts[imageParts.length - 1];
    //Rejoin the string
    return imageParts.join('/');
  }

}
