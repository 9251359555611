import { Injectable, OnDestroy } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable, Subscription } from 'rxjs';

// services
import { TokenService } from 'src/app/auth/services/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class HttpBaseService<T> implements OnDestroy {

  private token: string; // JWT authentication token property

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private url: string,
    private http: HttpClient,
    private tokenService?: TokenService
  ) {
    // subscribe to the token subject and set token property
    if (this.tokenService.token$) {
      this.subscriptions.add(
        this.tokenService.token$.subscribe(token => {
          this.token = token;
        })
      );
    }
  } // end constructor

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description base http get method for api calls
   * @param method { any }
   * @param params { any }
   * @returns { Observable<any> } result of the api call
   */
  get(method: any, params?: any): Observable<any> {

    const httpOptions = this._setRequestOptions(params);

    return this.http.get(this.url + method, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError),
      );
  } // end get

  /**
   * @description base http post method for api calls
   * @param resource { any }
   * @param method { any }
   * @param params { any }
   * @returns { Observable<any> } result of the api call
   */
  post(resource: any, method: any = null, params?: any): Observable<any> {

    const httpOptions = this._setRequestOptions(params);

    return this.http.post(this.url + (method ? method : ''), resource, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  } // end post

  /**
   * @description base http patch method for api calls
   * @param id { any }
   * @param resource { any }
   * @param method { any }
   * @param params { any }
   * @returns { Observable<any> } result of the api call
   */
  patch(id: any, resource: any, method: any = null, params?: any): Observable<any> {

    const httpOptions = this._setRequestOptions(params);

    return this.http.patch(this.url + (method ? method : '') + id, resource, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  } // end patch

  /**
   * @description base http put method for api calls
   * @param resource { any }
   * @param method { any }
   * @param params { any }
   * @returns { Observable<any> } result of the api call
   */
  put(resource: any, method: any = null, params?: any): Observable<any> {

    const httpOptions = this._setRequestOptions(params);

    return this.http.put(this.url + (method ? method : ''), resource, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  } // end put

  /**
   * @description base http delete method for api calls
   * @param method { any }
   * @param id { any }
   * @param params { any }
   * @returns { Observable<any> } result of the api call
   */
  delete(method: any, id: any, params?: any): Observable<any> {

    const httpOptions = this._setRequestOptions(params);

    return this.http.delete(this.url + method + '/' + id, httpOptions)
      .pipe(
        map(response => response),
        catchError(this.handleError)
      );
  } // end delete

  /**
   * @description global error handler for base http calls
   * @param error { any }
   * @returns { Observable<never> } error observable with message
   */
  private handleError(error: any): Observable<never> {
    if (error.status === 400) {
      // Need to update errors
      // return ErrorObservable.create(new BadInput(error.json()));
    }

    if (error.status === 404) {
      // Need to update errors
      // return ErrorObservable.create(new NotFoundError());
    }

    if (error.status === 422) {
      // Need to update errors
      let errorObject: any = {};
      Object.assign(errorObject, error);
      if (errorObject.error && errorObject.error.error && errorObject.error.error.message) {

        if (errorObject.error.error.details.messages.email) {
          let errorDetails: string = '';

          errorObject.error.error.details.messages.email.forEach((error) => {
            errorDetails = errorDetails + '\r\n' + error;
          });

          console.log('Error Status:', error.status);
          return throwError(() => new Error(errorDetails));
        }
        console.log('Error Status:', error.status);
        return throwError(() => new Error(errorObject.error.error.message));
      }
    }

    if (error.status === 500) {
      // Need to update errors
      // return ErrorObservable.create(new NotFoundError());
      let errorObject: any = {};
      Object.assign(errorObject, error);
      if (errorObject.error && errorObject.error.error && errorObject.error.error.message) {
        console.log('Error Status:', error.status);
        return throwError(() => new Error(errorObject.error.error.message));
      }
    }

    //Stripe error of some kind. Handle messages    
    if (error.status === 402) {
      let errorObject: any = {};
      Object.assign(errorObject, error);
      if (errorObject.error && errorObject.error.error && errorObject.error.error.message) {
        console.log('Error Status:', error.status);
        console.log('Error Message:', errorObject.error.error.message);
        return throwError(() => new Error(errorObject.error.error.message));
      }
    }

    console.log('Error Status:', error.status);
    return throwError(() => new Error(error.error.shortMessage));
  } // end handleError

  /**
   * @description sets the request options and headers
   * @param params { any }
   * @returns { any } the generated options object
   */
  private _setRequestOptions(params?: any): any {

    this.tokenService.checkToken();

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params ? Object.assign({}, params) : {}
    };
    if (this.token) {
      options.headers = options.headers.append('Authorization', this.token);
    }
    return options;
  } // end _setRequestOptions

} // end HttpBaseService
// -----------------------------------------------------------------/------------------------------------------------------------------/
