<div *ngIf="eventData && user" class="container-authenticated" [ngClass]="{'container-authenticated-ios': isIos}">
    <div class="fixed-header">
        <h1 class="inline-header">{{ user.id == eventData.__bookedUser__.id ? 'Show' : 'Booked' }}</h1>

        <div class="event-actions">
            <button
                *ngIf="user.id === eventData.__bookedUser__.id && (eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) === globalConstants.unconfirmedEventStatus.id"
                class="icon-button right-group" mat-raised-button type="button" color="primary"
                (click)="confirmEventDialog(confirmEventTemplate)">
                <mat-icon>event_available</mat-icon> Confirm Event
            </button>
            <div class="tooltip-disabled" [matTooltipDisabled]="canStartEvent"
                matTooltip="{{ 'Event cannot be started until ' + (canStartEventStartTime | date: 'short') +'.' }}">
                <button *ngIf="user.id === eventData.__bookedUser__.id && 
               (eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) === globalConstants.confirmedEventStatus.id"
                    class="icon-button right-group" mat-raised-button type="button" color="primary"
                    (click)="startEventDialog(startEventTemplate)" [disabled]="!canStartEvent">
                    <mat-icon>ondemand_video</mat-icon> Start Event
                </button>
            </div>
            <button
                *ngIf="(eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) === globalConstants.liveEventStatus.id"
                class="icon-button right-group" mat-raised-button type="button" color="primary" (click)="joinEvent(eventData.id)">
                <mat-icon>camera_indoor</mat-icon> Join Event
            </button>
            <!-- <button class="icon-button right-group" mat-raised-button type="button" (click)="joinEvent(eventData.id)">
                <mat-icon>camera_indoor</mat-icon> Join Event
            </button> -->
            
            <button *ngIf="((eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId :eventData.__eventStatusHistory__[0].eventStatusLookupId) !== globalConstants.canceledEventStatus.id && 
            (eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) !== globalConstants.completeEventStatus.id &&
            (eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) !== globalConstants.liveEventStatus.id) || (
                user.id === eventData.__bookedUser__.id && (eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) === globalConstants.liveEventStatus.id
            )" mat-icon-button class="right-group"
                [matMenuTriggerFor]="eventMenu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #eventMenu="matMenu">
                <button *ngIf="(eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId :eventData.__eventStatusHistory__[0].eventStatusLookupId) !== globalConstants.canceledEventStatus.id && 
                (eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) !== globalConstants.completeEventStatus.id &&
                (eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) !== globalConstants.liveEventStatus.id"
                    class="icon-button" mat-menu-item color="warn" type="button"
                    (click)="cancelEventDialog(cancelEventTemplate)">
                    <mat-icon>event_busy</mat-icon> Cancel Event
                </button>
                <button
                *ngIf="user.id === eventData.__bookedUser__.id && (eventStatusData ? eventStatusData.__eventStatusHistory__[0].eventStatusLookupId : eventData.__eventStatusHistory__[0].eventStatusLookupId) === globalConstants.liveEventStatus.id"
                class="icon-button" mat-menu-item type="button"
                (click)="completeEventDialog(completeEventTemplate)">
                <mat-icon>done</mat-icon> Complete Event
            </button>
            </mat-menu>
        </div>
        <br class="clear" />
        <mat-divider></mat-divider>
    </div>
    <br />
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
        <div fxFlex="0 1 45%" fxFlex.lt-md="100%">
            <app-profile-info
                [profileData]="user.id == eventData.__bookedUser__.id ? eventData.__bookingUser__ : eventData.__bookedUser__">
            </app-profile-info>

            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
                <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                    <mat-list>
                        <div mat-subheader>
                            <span>Scheduled Event Info</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="event-item-content">
                            <span>Status:
                                {{ eventStatusData ? eventStatusData.__eventStatusHistory__[0].__eventStatusLookup__.eventStatus : eventData.__eventStatusHistory__[0].__eventStatusLookup__.eventStatus }}</span><br />
                            <span>Starts: {{ eventData.dateTimeStart | date: 'short' }}</span><br />
                            <span>Ends: {{ eventData.dateTimeEnd | date: 'short' }}</span><br />
                            <span>Show Length: {{ showLength | time }}</span><br />
                        </div>
                    </mat-list>
                </div>
                <div fxFlex="0 1 50%" fxFlex.lt-sm="100%">
                    <mat-list>
                        <div mat-subheader>
                            <span>Scheduled Event Cost</span>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="event-item-content">
                            <span>Total: {{ eventData.totalPrice | currency }}</span><br />
                            <span>Cancel Fee: {{ eventData.depositAmount | currency }}</span><br />
                            <ng-container *ngIf="stripeData">
                                <span>Paid: {{ stripeData.payment / 100 | currency }}</span><br />
                                <span>Refunded: {{ stripeData.refund / 100 | currency }}</span><br />
                            </ng-container>
                        </div>
                    </mat-list>
                </div>
                <div fxFlex="0 1 100%">
                    <div class="event-item-content">
                        <button class="icon-button" mat-raised-button color="primary" type="button"
                            [matMenuTriggerFor]="contactMenu">
                            <mat-icon>contact_support</mat-icon> Contact {{ user.id == eventData.__bookedUser__.id ?
                            eventData.__bookingUser__.displayName : eventData.__bookedUser__.displayName }}
                        </button>
                        <mat-menu #contactMenu="matMenu">
                            <a target="_blank" mat-menu-item type="button"
                                href="mailto:{{ eventData.__emailThreads__[0].name }}@relay.toonballoon.com">
                                Open Default Email Application
                            </a>
                            <button mat-menu-item
                                (click)="copyEmail(eventData.__emailThreads__[0].name + '@relay.toonballoon.com')">Copy
                                Email Address to Clipboard</button>
                        </mat-menu>
                        <p class="email-instructions">Use the email account you signed up with to send your message.</p>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="0 1 30%" fxFlex.lt-md="100%">
            <app-song-list *ngIf="artistSongListData" [userSongListData]="artistSongListData">
            </app-song-list>
        </div>
        <div fxFlex="0 1 25%" fxFlex.lt-md="100%">
            <app-comments [comments]="eventData.__eventComments__" [eventId]="eventId" [user]="user"
                [modifiedBy]="modifiedBy"
                [eventStatusLookupId]="eventData.__eventStatusHistory__[0].eventStatusLookupId"></app-comments>
        </div>
    </div>
    <ng-template #cancelEventTemplate>
        <h1 mat-dialog-title>Cancel Event</h1>
        <div mat-dialog-content>
            <p>You are about to cancel "{{ user.id == eventData.__bookedUser__.id ?
                eventData.__bookingUser__.displayName
                : eventData.__bookedUser__.displayName }}" starting on {{ eventData.dateTimeStart | date: 'short' }} and
                ending on {{ eventData.dateTimeEnd | date: 'short' }}.</p>
            <p
                *ngIf="eventData.bookingUserId === user.id && eventData.__eventStatusHistory__[0].eventStatusLookupId === globalConstants.confirmedEventStatus.id">
                Because this event has already been confirmed by the artist the cancel fee will not be refunded.</p>
            <span>Are you sure?</span>
        </div>
        <div mat-dialog-actions>
            <button class="icon-button" mat-button mat-dialog-close>
                <mat-icon>close</mat-icon> No, Close
            </button>
            <button class="icon-button" mat-button (click)="cancelEvent(eventData.id)" cdkFocusInitial
                [disabled]="cancelEventSaving">
                <mat-icon>free_cancellation</mat-icon> Yes, Cancel
            </button>
        </div>
    </ng-template>
    <ng-template #confirmEventTemplate>
        <h1 mat-dialog-title>Confirm Event</h1>
        <div mat-dialog-content>
            <p>You are about to confirm "{{ user.id == eventData.__bookedUser__.id ?
                eventData.__bookingUser__.displayName
                : eventData.__bookedUser__.displayName }}" starting on {{ eventData.dateTimeStart | date: 'short' }} and
                ending on {{ eventData.dateTimeEnd | date: 'short' }}.</p>
            <span>Are you sure?</span>
        </div>
        <div mat-dialog-actions>
            <button class="icon-button" mat-button mat-dialog-close>
                <mat-icon>close</mat-icon> No, Close
            </button>
            <button class="icon-button" mat-button (click)="confirmEvent(eventData.id)" cdkFocusInitial
                [disabled]="confirmEventSaving">
                <mat-icon>event_available</mat-icon> Yes, Confirm
            </button>
        </div>
    </ng-template>
    <ng-template #startEventTemplate>
        <h1 mat-dialog-title>Start Event</h1>
        <div mat-dialog-content>
            <p>You are about to start "{{ user.id == eventData.__bookedUser__.id ?
                eventData.__bookingUser__.displayName
                : eventData.__bookedUser__.displayName }}" starting on {{ eventData.dateTimeStart | date: 'short' }} and
                ending on {{ eventData.dateTimeEnd | date: 'short' }}.</p>
            <span>Are you sure?</span>
        </div>
        <div mat-dialog-actions>
            <button class="icon-button" mat-button mat-dialog-close>
                <mat-icon>close</mat-icon> No, Close
            </button>
            <button class="icon-button" mat-button (click)="startEvent(eventData.id)" cdkFocusInitial
                [disabled]="startEventSaving">
                <mat-icon>ondemand_video</mat-icon> Yes, Start
            </button>
        </div>
    </ng-template>
    <ng-template #completeEventTemplate>
        <h1 mat-dialog-title>Complete Event</h1>
        <div mat-dialog-content>
            <p>You are about to complete "{{ user.id == eventData.__bookedUser__.id ?
                eventData.__bookingUser__.displayName
                : eventData.__bookedUser__.displayName }}" starting on {{ eventData.dateTimeStart | date: 'short' }} and
                ending on {{ eventData.dateTimeEnd | date: 'short' }}.</p>
            <p *ngIf="incompleteEvent(eventData.dateTimeEnd)">
                This event still has time left. If you complete the event before the agreed end time you will be in
                breach of the agreement and the event will be refunded in full.
            </p>
            <span>Are you sure?</span>
        </div>
        <div mat-dialog-actions>
            <button class="icon-button" mat-button mat-dialog-close>
                <mat-icon>close</mat-icon> No, Close
            </button>
            <button class="icon-button" mat-button (click)="completeEvent(eventData.id)" cdkFocusInitial
                [disabled]="completeEventSaving">
                <mat-icon>done</mat-icon> Yes, Complete
            </button>
        </div>
    </ng-template>
</div>