<mat-list class="song-list">
    <cdk-virtual-scroll-viewport #scroller [itemSize]="88" class="virtual-scroll-viewport"
        [ngClass]="{'hidden':!events || events.length < 1}">
        <ng-container *cdkVirtualFor="let event of events">
            <mat-divider></mat-divider>
            <mat-list-item [routerLink]="'/event/' + event.id">
                <img matListAvatar
                    [src]="getProfileImage(type === 'shows' ? event.__bookingUser__.profileImageUrl : event.__bookedUser__.profileImageUrl)"
                    alt="profile image">
                <h2 mat-line>{{ type === 'shows' ? event.__bookingUser__.displayName :
                    event.__bookedUser__.displayName}}</h2>
                <div mat-line class="small-text">Starts {{event.dateTimeStart | date: 'short' }}</div>
                <div mat-line class="small-text">Ends {{event.dateTimeEnd | date: 'short' }}</div>
                <div>
                    <mat-chip-list aria-label="Event Status">
                        <mat-chip color="primary" selected>
                            {{event.__eventStatusHistory__[0].__eventStatusLookup__.eventStatus}}</mat-chip>
                    </mat-chip-list>
                </div>
            </mat-list-item>
        </ng-container>
    </cdk-virtual-scroll-viewport>
    <div [ngClass]="{'hidden': events && events.length > 0 }">
        <mat-divider></mat-divider>
        <div class="no-events">
            <span class="center-align">
                <mat-icon mat-list-icon class="empty-events">event_busy</mat-icon>
                <ng-container *ngIf="type === 'shows'; else nobookings">
                    <h2>{{ status === 'upcoming' ? 'No one has booked you for any upcoming shows!' : 'You have no
                        history of booked shows!'}}</h2>
                </ng-container>
                <ng-template #nobookings>
                    <h2>{{ status === 'upcoming' ? 'You have no upcoming events booked!' : 'You have no history of
                        booked events!'}}</h2>
                    <button class="icon-button" mat-raised-button color="primary" [routerLink]="'/browse'">
                        <mat-icon>apps</mat-icon> Browse Artists
                    </button>
                </ng-template>
            </span>
        </div>
    </div>
</mat-list>