// modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialComponentsModule } from 'src/app/material-components/material-components.module';
import { GlobalModule } from '../global/global.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule, IConfig  } from 'ngx-mask';

// services
import { UserService } from '../profile/services/user/user.service';
import { AvailabilityService } from '../profile/services/availability/availability.service';
import { ShowRequirementService } from './services/show-requirement/show-requirement.service';
import { SongService } from './services/song/song.service';

//components
import { RegisterComponent } from './components/register/register.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { SongListComponent } from './components/song-list/song-list.component';
import { SongFormComponent } from './components/song-form/song-form.component';
import { AvailabilityFormComponent } from './components/availability-form/availability-form.component';
import { ShowSettingsFormComponent } from './components/show-settings-form/show-settings-form.component';
import { DiscountCodesComponent } from './components/discount-codes/discount-codes.component';
import { DiscountCodeFormComponent } from './components/discount-code-form/discount-code-form.component';
import { ArtistProfileComponent } from './components/artist-profile/artist-profile.component';
import { CommentsComponent } from './components/comments/comments.component';
import { ProfileInfoComponent } from './components/profile-info/profile-info.component';

export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    RegisterComponent,
    EditProfileComponent,
    ProfileFormComponent,
    SongListComponent,
    SongFormComponent,
    AvailabilityFormComponent,
    ShowSettingsFormComponent,
    DiscountCodesComponent,
    DiscountCodeFormComponent,
    ArtistProfileComponent,
    CommentsComponent,
    ProfileInfoComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialComponentsModule,
    ReactiveFormsModule,
    NgxCurrencyModule, 
    NgxMaskModule.forRoot(),   
    GlobalModule
  ],
  exports: [
    EditProfileComponent,
    SongListComponent,
    CommentsComponent,
    ProfileInfoComponent
  ],
  providers: [
    UserService,
    AvailabilityService,
    ShowRequirementService,
    SongService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class ProfileModule { }
