import { Injectable } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";
import { StreamCastHelper } from "./streamCast.helper";
import { Microphone, AudioRecording } from '@mozartec/capacitor-microphone';

// RTCMultiConnection
declare let RTCMultiConnection: any;
// getHTMLMediaElement
declare let getHTMLMediaElement: any;
// DetectRTC
declare let DetectRTC: any;

@Injectable()
export class StreamHelper {

    private connection: any = null; // meeting connection
    private localTracks: any; // meeting array of all the local tracks
    private remoteTracks: any; // meeting array of all the remote tracks
    isIos: boolean = false; // flag to tell if device is Ios
    isNative: boolean = false; // flag to tell if device is native
    private localVideo: any; // reference to the local video element
    private mediaStreams: any = []; // references to any media stream so we can kill them all
    private cameraMuted: boolean = false;
    private micMuted: boolean = false;
    private audioConstraints: any;
    recording: AudioRecording;

    // mrtc params start
    params: any;
    streamComponent: any;
    doc: Document;
    isArtist: boolean = false;
    // mrtc params end

    cameraSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // subject with camera selected object values
    camera$: Observable<any> = this.cameraSubject$.asObservable(); // the camera subject observable

    micSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // subject with mic selected object values
    mic$: Observable<any> = this.micSubject$.asObservable(); // the mic subject observable

    speakerSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null); // subject with speaker selected object values
    speaker$: Observable<any> = this.speakerSubject$.asObservable(); // the speaker subject observable
    castInitialized: boolean = false
    constructor(
        private streamCastHelper: StreamCastHelper
    ) {
        this.isIos = Capacitor.getPlatform() === 'ios';
        this.isNative = Capacitor.isNativePlatform(); // sets the native flag based on if a native app or not        
    }

    /**
    * @description when RTCMultiConnection is ready to run
    */
    async mrtcReady() {
        // ......................................................
        // ..................RTCMultiConnection Code.............
        // ......................................................
        this.streamComponent.loaderService.loaderSubject$.next(false);
        if (!this.isIos) {
            this.castInitialized || await this.streamCastHelper.initializeCastApi(this.streamComponent.controlBarElement);
            this.castInitialized = true;
        }
        let videoConstraints: any;
        let audioConstraints: any;

        let camera = await firstValueFrom(this.camera$);
        if (!camera) {
            return;
        } else {
            videoConstraints = this.videoConstraintsBuilder(camera);
        }

        let mic = await firstValueFrom(this.mic$);
        if (!mic) {
            return;
        } else if (!this.audioConstraints) {
            this.audioConstraints = this.audioConstraintsBuilder(mic);
        }

        //If not the artist restrict some of the audio and video to optimize experience. This will be merged in the RTCMultiConnection js and properties here will overwrite defaults
        const mediaConstraintsOverrides = {
            audio: this.audioConstraints,
            video: videoConstraints
        };

        this.connection = new RTCMultiConnection(this.params.publicRoomIdentifier, null, mediaConstraintsOverrides, this.safeGetUserMedia);
        if (this.connection.bandwidth) this.connection.bandwidth.audio = this.isArtist ? 62 : 1; // change to set the max bitrate max value possible is 62
        this.connection.autoCloseEntireSession = false;
        this.connection.publicRoomIdentifier = this.params.publicRoomIdentifier;

        // comment-out below line if you do not have your own socket.io server
        this.connection.socketURL = 'https://server-dev.toonballoon.com/';

        this.connection.socketMessageEvent = this.params.publicRoomIdentifier;

        this.connection.session = {
            audio: true,
            video: true,
            data: true
        };

        this.connection.sdpConstraints.mandatory = {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true
        };

        this.connection.iceServers = [
            {
                urls: [
                    "stun:35.160.155.206:80",
                    "stun:35.160.155.206:443",
                    "turn:35.160.155.206:80",
                    "turn:35.160.155.206:80?transport=tcp",
                    "turn:35.160.155.206:443",
                    "turn:35.160.155.206:443?transport=tcp",
                ],
                username: "tba",
                credential: "tba20230427!",
            }
        ];

        this.connection.videosContainer = this.doc.getElementById('stream');
        this.connection.onstream = async (event) => {
            let existing = this.doc.getElementById(event.streamid);
            if (existing && existing.parentNode) {
                existing.parentNode.removeChild(existing);
            }

            event.mediaElement.removeAttribute('src');
            event.mediaElement.removeAttribute('srcObject');
            event.mediaElement.muted = true;
            event.mediaElement.volume = 0;

            let video = this.doc.createElement('video');
            try {
                video.setAttributeNode(this.doc.createAttribute('autoplay'));
                video.setAttributeNode(this.doc.createAttribute('playsinline'));
            } catch (e) {
                video.setAttribute('autoplay', 'true');
                video.setAttribute('playsinline', 'true');
            }
            //event.stream = await this.rtcLoopback(event.stream);
            if (event.type === 'local') {
                // for (let track of event.stream?.getTracks()) {
                //     track?.stop();
                // }
                //return;
                this.streamComponent.streamId = event.streamid;
                if (!this.isIos) {
                    await this.streamCastHelper.setCastHelperData(this.streamComponent.eventData, this.streamComponent.user, this.streamComponent.streamId);
                    await this.streamCastHelper.setupChromeCastListeners(this.streamComponent.controlBarElement);
                }
                video.volume = 0;
                try {
                    video.setAttributeNode(this.doc.createAttribute('muted'));
                } catch (e) {
                    video.setAttribute('muted', 'true');
                }
                video.classList.add('local-video');

                // check to see if event data is populated and if so set the position of the local track element based on the position of the QR code to prevent overlap
                if (this.streamComponent.eventData) {
                    switch (this.streamComponent.eventData.__bookedUser__.venmoQrCodeScreenPosition) {
                        case 'topRight':
                            video.classList.add('left');
                            break;
                        case 'topLeft':
                            video.classList.add('right');
                            break;
                        default:
                            video.classList.add('right');
                    }
                }

                this.localTracks = event.stream; // sets to let so that they can be stopped on exit.                

                // find the video mute button and set up the click events and visibility
                let localVideoMuteElement = this.doc.getElementById(`muteVideo`);
                let localVideoUnmuteElement = this.doc.getElementById(`unmuteVideo`);

                localVideoMuteElement.onclick = () => {
                    this.cameraMuted = true;
                    this?.connection?.getAllParticipants()?.forEach(pid => {
                        this?.connection?.peers[pid].peer?.getSenders()?.forEach(sender => {
                            if (sender?.track?.kind == 'video') {
                                sender.track.enabled = !this.cameraMuted;
                            }
                        });
                    });
                    localVideoUnmuteElement.classList.remove('hidden');
                    localVideoMuteElement.classList.add('hidden');
                    this.localTracks.getVideoTracks()[0].enabled = !this.cameraMuted;
                };

                // find the video unmute button and set up the click events and visibility     
                localVideoUnmuteElement.onclick = () => {
                    this.cameraMuted = false;
                    this?.connection?.getAllParticipants()?.forEach(pid => {
                        this?.connection?.peers[pid].peer?.getSenders()?.forEach(sender => {
                            if (sender?.track?.kind == 'video') {
                                sender.track.enabled = !this.cameraMuted;
                            }
                        });
                    });
                    localVideoUnmuteElement.classList.add('hidden');
                    localVideoMuteElement.classList.remove('hidden');
                    this.localTracks.getVideoTracks()[0].enabled = !this.cameraMuted;
                };

                localVideoMuteElement.removeAttribute('disabled');
                localVideoMuteElement.classList.remove('mat-button-disabled');

                // find the audio mute button and set up the click events and visibility
                let localAudioMuteElement = this.doc.getElementById(`muteAudio`);
                let localAudioUnmuteElement = this.doc.getElementById(`unmuteAudio`);

                localAudioMuteElement.onclick = () => {
                    this.micMuted = true;
                    this?.connection?.getAllParticipants()?.forEach(pid => {
                        this?.connection?.peers[pid].peer?.getSenders()?.forEach(sender => {
                            if (sender?.track?.kind == 'audio') {
                                sender.track.enabled = !this.micMuted;
                            }
                        });
                    });
                    localAudioUnmuteElement.classList.remove('hidden');
                    localAudioMuteElement.classList.add('hidden');
                    this.localTracks.getAudioTracks()[0].enabled = !this.micMuted;
                };

                // find the video unmute button and set up the click events and visibility     
                localAudioUnmuteElement.onclick = () => {
                    this.micMuted = false;
                    this?.connection?.getAllParticipants()?.forEach(pid => {
                        this?.connection?.peers[pid].peer?.getSenders()?.forEach(sender => {
                            if (sender?.track?.kind == 'audio') {
                                sender.track.enabled = !this.micMuted;
                            }
                        });
                    });
                    localAudioUnmuteElement.classList.add('hidden');
                    localAudioMuteElement.classList.remove('hidden');
                    this.localTracks.getAudioTracks()[0].enabled = !this.micMuted;
                };

                localAudioMuteElement.removeAttribute('disabled');
                localAudioMuteElement.classList.remove('mat-button-disabled');
                this.localVideo = video;
                // this.localTracks.getVideoTracks()[0].enabled = !this.cameraMuted;                           
                // this.localTracks.getAudioTracks()[0].enabled = !this.micMuted;
            }
            else {

                let castButtonElement = this.doc.getElementById(`castbutton`);
                castButtonElement?.removeAttribute('disabled');
                castButtonElement?.classList?.remove('mat-button-disabled');

                let videoFullscreenElement = this.doc.getElementById(`fullScreenOpenVideo`);
                videoFullscreenElement?.removeAttribute('disabled');
                videoFullscreenElement?.classList?.remove('mat-button-disabled');

                let unmuteSpeakersElement = this.doc.getElementById(`unmuteSpeakers`);
                unmuteSpeakersElement?.classList?.add('hidden');

                let muteSpeakersElement = this.doc.getElementById(`muteSpeakers`);
                muteSpeakersElement?.removeAttribute('disabled');
                muteSpeakersElement?.classList?.remove('hidden');
                muteSpeakersElement?.classList?.remove('mat-button-disabled');

                video?.classList?.add('remote-video');
                this.remoteTracks = event.stream; // sets to let so that they can be stopped on exit.                
                //Once we've added the remote video setup a listener for message events
                // this?.connection?.getAllParticipants()?.forEach(pid => {
                //     if (this?.connection?.peers[pid].channels && this?.connection?.peers[pid].channels.length) {
                //         this?.connection?.peers[pid].channels[0].addEventListener('message', (msg) => {
                //             let messageData = JSON.parse(msg.data);
                //             console.log('Got a webRTC message: ', messageData.message);
                //             if (messageData.message === 'reload') {
                //                 this.stopBothVideoAndAudio();
                //                 this.mrtcReady();
                //             }
                //         });
                //     }
                // });
            }
            video.srcObject = event.stream;
            let mediaElement1 = getHTMLMediaElement(video, {
                title: event.userid,
                buttons: [],
                showOnMouseEnter: false
            });

            this.connection.videosContainer.appendChild(mediaElement1);

            mediaElement1.media.play();

            mediaElement1.id = event.streamid;
            this?.connection?.getAllParticipants()?.forEach(pid => {
                this?.connection?.peers[pid].peer?.getSenders()?.forEach(sender => {
                    if (sender?.track?.kind == 'audio') {
                        sender.track.enabled = !this.micMuted;
                    }
                    if (sender?.track?.kind == 'video') {
                        sender.track.enabled = !this.cameraMuted;
                    }
                });
            });
        };

        this.connection.onstreamended = (event) => {
            let mediaElement2 = this.doc.getElementById(event.streamid);
            if (mediaElement2) {
                mediaElement2.parentNode.removeChild(mediaElement2);
            }

            if (event.type === 'remote') {
                // let castButtonElement = this.doc.getElementById(`castbutton`);
                // castButtonElement.setAttribute('disabled', 'disabled');
                // castButtonElement.classList.add('mat-button-disabled');

                let videoFullscreenCloseElement = this.doc.getElementById(`fullScreenCloseVideo`);
                let videoFullscreenElement = this.doc.getElementById(`fullScreenOpenVideo`);
                if (videoFullscreenElement?.classList?.contains("hidden")) {
                    videoFullscreenCloseElement?.click();
                }
                videoFullscreenElement?.setAttribute('disabled', 'disabled');
                videoFullscreenElement?.classList?.add('mat-button-disabled');

                let unmuteSpeakersElement = this.doc.getElementById(`unmuteSpeakers`);
                unmuteSpeakersElement?.classList?.add('hidden');

                let muteSpeakersElement = this.doc.getElementById(`muteSpeakers`);
                muteSpeakersElement?.setAttribute('disabled', 'disabled');;
                muteSpeakersElement?.classList?.remove('hidden');
                muteSpeakersElement?.classList?.add('mat-button-disabled');
            }
        };

        this.connection.onMediaError = (e) => {
            if (e.message === 'Concurrent mic process limit.') {
                if (DetectRTC.audioInputDevices.length <= 1) {
                    console.warn('Please select external microphone. Check github issue number 483.');
                    return;
                }

                let secondaryMic = DetectRTC.audioInputDevices[1].deviceId;
                this.connection.mediaConstraints.audio = {
                    deviceId: secondaryMic
                };

                this.connection.openOrJoin(this.connection.sessionid);
            }
        };

        //Handle incoming messages
        this.connection.onmessage = (event) => {
            this.streamComponent.messageHandler(event);
        };

        if (!!this.params.password) {
            this.connection.password = this.params.password;
        }

        if (this.params.open === true || this.params.open === 'true') {
            this.connection.openOrJoin(this.params.sessionid, (isRoomOpened, roomid, error) => {
                if (error) {
                    if (error === this.connection.errors.ROOM_NOT_AVAILABLE) {
                        console.warn('Someone already created this room. Please either join or create a separate room.');
                        return;
                    }
                    console.warn(error);
                }
            });
        } else {
            this.connection.openOrJoin(this.params.sessionid, (isRoomJoined, roomid, error) => {
                if (error) {
                    if (error === this.connection.errors.ROOM_NOT_AVAILABLE) {
                        console.warn('This room does not exist. Attempting open.');
                        this.connection.openOrJoin(this.params.sessionid, (isRoomOpened, roomid, error) => {
                            if (error) {
                                if (error === this.connection.errors.ROOM_NOT_AVAILABLE) {
                                    console.warn('Someone already created this room. Please either join or create a separate room.');
                                    return;
                                }
                                console.warn(error);
                            }
                        });
                        return;
                    }
                    if (error === this.connection.errors.ROOM_FULL) {
                        console.warn('Room is full.');
                        return;
                    }
                    if (error === this.connection.errors.INVALID_PASSWORD) {
                        this.connection.password = prompt('Please enter room password.') || '';
                        if (!this.connection.password.length) {
                            console.warn('Invalid password.');
                            return;
                        }
                        this.connection.join(this.params.sessionid, (isRoomJoined, roomid, error) => {
                            if (error) {
                                console.warn(error);
                            }
                        });
                        return;
                    }
                    console.warn(error);
                }
            });
        }

        // detect 2G
        // if (navigator.connection &&
        //   navigator.this.connection.type === 'cellular' &&
        //   navigator.this.connection.downlinkMax <= 0.115) {
        //   console.warn('2G is not supported. Please use a better internet service.');
        // }
    }

    /**
    * @description stop both mic and camera
    */
    stopBothVideoAndAudio() {
        if (this.localTracks && this.localTracks.getTracks().length > 0) {
            this.localTracks.getTracks().forEach(track => {
                track.stop();
            });
        }
        if (this.remoteTracks && this.remoteTracks.getTracks().length > 0) {
            this.remoteTracks.getTracks().forEach(track => {
                track.stop();
            });
        }
        if (this?.localVideo && this?.localVideo?.srcObject && this?.localVideo?.srcObject?.getTracks().length) {
            for (let track of this.localVideo.srcObject.getTracks()) {
                track.stop();
            }
            delete this?.localVideo?.srcObject;
            delete this?.localVideo;
        }
        if (this?.mediaStreams && this.mediaStreams.length) {
            for (let mediaStream of this.mediaStreams) {
                for (let track of mediaStream.getTracks()) {
                    track.stop();
                }
            }
        }
        this.mediaStreams = [];
        let videos = document.getElementsByTagName('video');
        [].forEach.call(videos, (video) => {
            if (video && video.srcObject) {
                for (let track of video.srcObject.getTracks()) {
                    track.stop();
                }
                delete video?.srcObject;
                video.remove();
            }
        });

        this?.connection?.disconnect();
        this?.connection?.closeSocket();
        if (this?.connection?.currentUserMediaRequest && this?.connection?.currentUserMediaRequest?.streams && this?.connection?.currentUserMediaRequest?.streams.length) {
            for (let mediaStream of this?.connection?.currentUserMediaRequest?.streams) {
                for (let track of mediaStream.getTracks()) {
                    track.stop();
                }
            }
        }
        this.cameraMuted = false;
        this.micMuted = false;
    }

    async changeMicAndCameraDuringActiveSession() {
        // we need to stop previous video tracks because
        // at the same time
        let selectedCamera = await firstValueFrom(this.camera$);
        let selectedMic = await firstValueFrom(this.mic$);

        if (selectedCamera && selectedMic) {

            var mediaConstraints = this?.connection?.mediaConstraints || { audio: true, video: true };

            this.audioConstraints = mediaConstraints.audio = this.audioConstraintsBuilder(selectedMic);
            mediaConstraints.video = this.videoConstraintsBuilder(selectedCamera);

            await this.safeGetUserMedia(mediaConstraints)?.then((stream: MediaStream) => {
                let cameraTrack = stream.getVideoTracks()[0];
                let micTrack = stream.getAudioTracks()[0];

                if (this.localVideo && this.localVideo.srcObject.getTracks().length > 0) {
                    for (let localTrack of this.localVideo.srcObject.getVideoTracks()) {
                        localTrack.stop();
                        this.localVideo.srcObject.removeTrack(localTrack);
                    }
                    this.localVideo.srcObject.addTrack(cameraTrack);
                }
                if (this.connection?.peers?.selectFirst()) {
                    this?.connection?.replaceTrack(cameraTrack, this.connection?.peers?.selectFirst().userid, true);
                    this?.connection?.replaceTrack(micTrack, this.connection?.peers?.selectFirst().userid, false);

                    cameraTrack.enabled = !this.cameraMuted;
                    micTrack.enabled = !this.micMuted;
                }
            }).catch(res => {
                console.log('audio or video error: ', res);
                this.deviceErrorFallback();
            });

        }

    }

    private deviceErrorFallback() {
        localStorage.removeItem('camera');
        localStorage.removeItem('mic');
        localStorage.removeItem('speaker');

        // if (this?.connection?.getAllParticipants()?.length > 0) {
        //     this?.connection?.getAllParticipants()?.forEach(pid => {
        //         this?.connection?.peers[pid].channels[0].send(JSON.stringify({ message: 'reload' }));
        //     });
        //     this.stopBothVideoAndAudio();
        //     this.mrtcReady();
        // } else {
        //     location.reload();
        // }
    }

    private videoConstraintsBuilder(selectedCamera: any) {

        let video: any = {
            bandwidth: 128 * 8 * 1024,
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            frameRate: { ideal: 60 },
            echoCancellation: true,
            volume: 0
        };

        if (!this.isNative) {
            video.deviceId = {
                exact: selectedCamera.deviceId
            }
        } else {
            video.facingMode = selectedCamera.deviceId;
        }

        return video;
    }

    private audioConstraintsBuilder(selectedMic: any) {

        let audio: any = {
            googAutoGainControl: false,
            autoGainControl: false,
            channelCount: 2,
            latency: 0,
            sampleRate: 48000,
            sampleSize: 16,
            volume: 1.0,
            echoCancellation: true,
            noiseSuppression: true,
            deviceId: {
                exact: selectedMic.deviceId
            }
        };

        if (this.isArtist) {
            audio.echoCancellation = false,
            audio.noiseSuppression = false;
        }

        return audio;
    }

    safeGetUserMedia(mediaConstraints, killStreamImmediately: boolean = false) {
        return new Promise(async (res: any, rej: any) => {

            if (this?.mediaStreams && this.mediaStreams.length) {
                for (let mediaStream of this.mediaStreams) {
                    for (let track of mediaStream.getTracks()) {
                        track.stop();
                        let localTrack = this?.localTracks?.getTracks().find(x => x.id === track.id);
                        if (localTrack) this?.localTracks?.removeTrack(localTrack);
                        let remoteTrack = this?.remoteTracks?.getTracks().find(x => x.id === track.id);
                        if (remoteTrack) this?.remoteTracks?.removeTrack(remoteTrack);
                        mediaStream.removeTrack(track);
                    }
                }
            }
            //Kill the video for the element in the dom before trying to switch camera
            if (this?.localVideo && this?.localVideo?.srcObject?.getTracks().length > 0) {
                for (let localTrack of this.localVideo?.srcObject?.getVideoTracks()) {
                    localTrack.stop();
                    this?.localVideo?.srcObject?.removeTrack(localTrack);
                }
            }
            this.mediaStreams = [];
            //await Microphone.startRecording();
            navigator?.mediaDevices?.getUserMedia(mediaConstraints).then(async (stream) => {
                this.mediaStreams.push(stream);
                //In some cases we run this to just get labels
                if (killStreamImmediately) {
                    //Kill the stream we just got immediately
                    if (stream && stream.getTracks().length > 0) {
                        for (let track of stream.getTracks()) {
                            track.stop();
                            stream.removeTrack(track);
                        }
                    }
                }
                res(stream);
                //await Microphone.stopRecording();
            }).catch((err) => {
                rej(err);
            });
        });
    }


    async rtcLoopback(stream) {
        let rtcConnection = null;
        let rtcLoopbackConnection = null;
        let loopbackStream = new MediaStream(); // this is the stream you will read from for actual audio output

        const offerOptions = {
            offerVideo: true,
            offerAudio: true,
            offerToReceiveAudio: false,
            offerToReceiveVideo: false,
        };

        let offer, answer;

        // initialize the RTC connections

        rtcConnection = new RTCPeerConnection();
        rtcLoopbackConnection = new RTCPeerConnection();

        rtcConnection.onicecandidate = e =>
            e.candidate && rtcLoopbackConnection.addIceCandidate(new RTCIceCandidate(e.candidate));
        rtcLoopbackConnection.onicecandidate = e =>
            e.candidate && rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

        rtcLoopbackConnection.ontrack = e =>
            e.streams[0].getTracks().forEach(track => loopbackStream.addTrack(track));


        // setup the loopback
        rtcConnection.addStream(stream); // this stream would be the processed stream coming out of Web Audio API destination node

        offer = await rtcConnection.createOffer(offerOptions);
        await rtcConnection.setLocalDescription(offer);

        await rtcLoopbackConnection.setRemoteDescription(offer);
        answer = await rtcLoopbackConnection.createAnswer();
        await rtcLoopbackConnection.setLocalDescription(answer);

        await rtcConnection.setRemoteDescription(answer);
        return loopbackStream;
    }
}