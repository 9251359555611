<form *ngIf="showSettingsForm && platformDefaults" [formGroup]="showSettingsForm">
    <input type="hidden" formControlName="userId" />
    <mat-label
        [ngClass]="{'form-group-error-label': showSettingsForm.controls['leadBookingTime'].invalid && (showSettingsForm.controls['leadBookingTime'].dirty || showSettingsForm.controls['leadBookingTime'].touched)}">
        Lead Booking Time</mat-label>
    <input type="hidden" formControlName="leadBookingTime" />
    <div fxLayout="row" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
        <div fxFlex="33.333%">
            <mat-form-field class="no-wrapper"
                [ngClass]="{'ng-invalid mat-form-field-invalid': showSettingsForm.controls['leadBookingTime'].invalid && (showSettingsForm.controls['leadBookingTime'].dirty || showSettingsForm.controls['leadBookingTime'].touched)}"
                appearance="outline" color="primary">
                <mat-label>Day(s)</mat-label>
                <mat-select [(value)]="leadBookingTimeDay" (selectionChange)="updateLeadBookingTimeInput()">
                    <mat-option
                        *ngFor="let day of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]"
                        [value]="day">
                        {{day}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="33.333%">
            <mat-form-field class="no-wrapper"
                [ngClass]="{'ng-invalid mat-form-field-invalid': showSettingsForm.controls['leadBookingTime'].invalid && (showSettingsForm.controls['leadBookingTime'].dirty || showSettingsForm.controls['leadBookingTime'].touched)}"
                appearance="outline" color="primary">
                <mat-label>Hour(s)</mat-label>
                <mat-select [(value)]="leadBookingTimeHour" (selectionChange)="updateLeadBookingTimeInput()">
                    <mat-option *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
                        [value]="hour">
                        {{hour}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="33.333%">
            <mat-form-field class="no-wrapper"
                [ngClass]="{'ng-invalid mat-form-field-invalid': showSettingsForm.controls['leadBookingTime'].invalid && (showSettingsForm.controls['leadBookingTime'].dirty || showSettingsForm.controls['leadBookingTime'].touched)}"
                appearance="outline" color="primary">
                <mat-label>Minutes</mat-label>
                <mat-select [(value)]="leadBookingTimeMinute" (selectionChange)="updateLeadBookingTimeInput()">
                    <mat-option *ngFor="let minute of [0,15,30,45]" [value]="minute">
                        {{minute}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-error class="form-group-error"
        *ngIf="showSettingsForm.controls['leadBookingTime'].invalid && (showSettingsForm.controls['leadBookingTime'].dirty || showSettingsForm.controls['leadBookingTime'].touched)">
        <ng-container *ngIf="showSettingsForm.controls['leadBookingTime'].errors?.['required']">
            Lead Booking Time is required.
        </ng-container>
        <ng-container *ngIf="showSettingsForm.controls['leadBookingTime'].errors?.['min']">
            Minimum Lead Booking Time is 15 minutes.
        </ng-container>
    </mat-error>
    <br />
    <mat-label
        [ngClass]="{'form-group-error-label': showSettingsForm.controls['minimumShowLength'].invalid && (showSettingsForm.controls['minimumShowLength'].dirty || showSettingsForm.controls['minimumShowLength'].touched)}">
        Minimum Show Length</mat-label>
    <input type="hidden" formControlName="minimumShowLength" />
    <div fxLayout="row" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
        <div fxFlex="50%">
            <mat-form-field class="no-wrapper"
                [ngClass]="{'ng-invalid mat-form-field-invalid': showSettingsForm.controls['minimumShowLength'].invalid && (showSettingsForm.controls['minimumShowLength'].dirty || showSettingsForm.controls['minimumShowLength'].touched)}"
                appearance="outline" color="primary">
                <mat-label>Hour(s)</mat-label>
                <mat-select [(value)]="minimumShowLengthHour" (selectionChange)="updateMinimumShowLengthInput()">
                    <mat-option *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
                        [value]="hour">
                        {{hour}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="50%">
            <mat-form-field class="no-wrapper"
                [ngClass]="{'ng-invalid mat-form-field-invalid': showSettingsForm.controls['minimumShowLength'].invalid && (showSettingsForm.controls['minimumShowLength'].dirty || showSettingsForm.controls['minimumShowLength'].touched)}"
                appearance="outline" color="primary">
                <mat-label>Minutes</mat-label>
                <mat-select [(value)]="minimumShowLengthMinute" (selectionChange)="updateMinimumShowLengthInput()">
                    <mat-option *ngFor="let minute of [0,15,30,45]" [value]="minute">
                        {{minute}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-error class="form-group-error"
        *ngIf="showSettingsForm.controls['minimumShowLength'].invalid && (showSettingsForm.controls['minimumShowLength'].dirty || showSettingsForm.controls['minimumShowLength'].touched)">
        <ng-container *ngIf="showSettingsForm.controls['minimumShowLength'].errors?.['required']">
            Minimum Show Length is required.
        </ng-container>
        <ng-container *ngIf="showSettingsForm.controls['minimumShowLength'].errors?.['min']">
            Minimum Show Length is {{ platformDefaults.showLengthMinutesMinimum | time }}.
        </ng-container>
        <ng-container *ngIf="showSettingsForm.controls['minimumShowLength'].errors?.['max']">
            Maximum Show Length minimum is {{ platformDefaults.showLengthMinutesMaximum | time }}.
        </ng-container>
    </mat-error>
    <br />
    <mat-label
        [ngClass]="{'form-group-error-label': showSettingsForm.controls['maximumShowLength'].invalid && (showSettingsForm.controls['maximumShowLength'].dirty || showSettingsForm.controls['maximumShowLength'].touched)}">
        Maximum Show Length</mat-label>
    <input type="hidden" formControlName="maximumShowLength" />
    <div fxLayout="row" fxLayoutGap="16px grid" fxLayoutAlign="flex-start">
        <div fxFlex="50%">
            <mat-form-field class="no-wrapper"
                [ngClass]="{'ng-invalid mat-form-field-invalid': showSettingsForm.controls['maximumShowLength'].invalid && (showSettingsForm.controls['maximumShowLength'].dirty || showSettingsForm.controls['maximumShowLength'].touched)}"
                appearance="outline" color="primary">
                <mat-label>Hour(s)</mat-label>
                <mat-select [(value)]="maximumShowLengthHour" (selectionChange)="updateMaximumShowLengthInput()">
                    <mat-option *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]"
                        [value]="hour">
                        {{hour}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="50%">
            <mat-form-field class="no-wrapper"
                [ngClass]="{'ng-invalid mat-form-field-invalid': showSettingsForm.controls['maximumShowLength'].invalid && (showSettingsForm.controls['maximumShowLength'].dirty || showSettingsForm.controls['maximumShowLength'].touched)}"
                appearance="outline" color="primary">
                <mat-label>Minutes</mat-label>
                <mat-select [(value)]="maximumShowLengthMinute" (selectionChange)="updateMaximumShowLengthInput()">
                    <mat-option *ngFor="let minute of [0,15,30,45]" [value]="minute">
                        {{minute}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-error class="form-group-error"
        *ngIf="showSettingsForm.controls['maximumShowLength'].invalid && (showSettingsForm.controls['maximumShowLength'].dirty || showSettingsForm.controls['maximumShowLength'].touched)">
        <ng-container *ngIf="showSettingsForm.controls['maximumShowLength'].errors?.['required']">
            Maximum Show Length is required.
        </ng-container>
        <ng-container *ngIf="showSettingsForm.controls['maximumShowLength'].errors?.['min']">
            Minimum Show Length is {{ platformDefaults.showLengthMinutesMinimum | time }}.
        </ng-container>
        <ng-container *ngIf="showSettingsForm.controls['maximumShowLength'].errors?.['max']">
            Maximum Show Length minimum is {{ platformDefaults.showLengthMinutesMaximum | time }}.
        </ng-container>
    </mat-error>
    <br />
    <mat-label>Rate</mat-label>
    <mat-form-field appearance="outline" color="primary">
        <mat-label>Price Per Hour</mat-label>
        <input matInput currencyMask [options]="{ align: 'left' }" placeholder="$20.00"
            formControlName="pricePerHour" />
        <mat-hint>The price you charge per hour.</mat-hint>
        <mat-error
            *ngIf="showSettingsForm.controls['pricePerHour'].invalid && (showSettingsForm.controls['pricePerHour'].dirty || showSettingsForm.controls['pricePerHour'].touched)">
            <ng-container *ngIf="showSettingsForm.controls['pricePerHour'].errors?.['required']">Price per hour is
                required.
            </ng-container>
            <ng-container *ngIf="showSettingsForm.controls['pricePerHour'].errors?.['min']">
                Minimum price per hour is is 5 dollars.
            </ng-container>
        </mat-error>
    </mat-form-field>
    <br />
    <br />
    <mat-form-field appearance="outline" color="primary">
        <mat-label>Deposit Percentage</mat-label>
        <input matInput placeholder="Rockstars" formControlName="depositPercentage" placeholder="35" currencyMask
            [options]="{ precision : 0, align: 'left', prefix: '', suffix: ' %' , inputMode: 'NATURAL'}" />
        <mat-hint>The percentage of the total show cost you require as a security deposit.</mat-hint>
        <mat-error
            *ngIf="showSettingsForm.controls['depositPercentage'].invalid && (showSettingsForm.controls['depositPercentage'].dirty || showSettingsForm.controls['depositPercentage'].touched)">
            <ng-container *ngIf="showSettingsForm.controls['depositPercentage'].errors?.['required']">Deposit Percentage
                is
                required.
            </ng-container>
            <ng-container *ngIf="showSettingsForm.controls['depositPercentage'].errors?.['min']">
                Minimum Deposit Percentage is {{ platformDefaults.depositMinimumPercentage }} %.
            </ng-container>
            <ng-container *ngIf="showSettingsForm.controls['depositPercentage'].errors?.['max']">
                Maximum Deposit Percentage is {{ platformDefaults.depositMaximumPercentage }} %.
            </ng-container>
        </mat-error>
    </mat-form-field>
    <br />
    <br />
</form>