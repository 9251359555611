// modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialComponentsModule } from 'src/app/material-components/material-components.module';
import { QRCodeModule } from 'angularx-qrcode';
import { GlobalModule } from '../global/global.module';
import { ProfileModule } from '../profile/profile.module';

// components
import { BrowseComponent } from './components/browse/browse.component';
import { BookingsComponent } from './components/bookings/bookings.component';
import { ShowsComponent } from './components/shows/shows.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { FilterComponent } from './components/filter/filter.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { EventComponent } from './components/event/event.component';
import { StreamComponent } from './components/stream/stream.component';

// helpers
import { ScheduleHelper } from './helpers/schedule.helper';
import { StreamHelper } from './helpers/stream.helper';
import { StreamControlsHelper } from './helpers/streamControls.helper';
import { StreamCastHelper } from './helpers/streamCast.helper';

@NgModule({
  declarations: [
    BrowseComponent,
    BookingsComponent,
    ShowsComponent,
    ScheduleComponent,
    FilterComponent,
    EventListComponent,
    EventComponent,
    StreamComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialComponentsModule,
    QRCodeModule,
    ProfileModule,
    GlobalModule
  ],
  exports: [
    BrowseComponent,
    BookingsComponent,
    ShowsComponent,
    ScheduleComponent,
  ],
  providers: [
    ScheduleHelper,
    StreamHelper,
    StreamControlsHelper,
    StreamCastHelper
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class EventModule { }
