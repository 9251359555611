import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';

// environment
import { environment } from 'src/environments/environment';
import { GlobalConstants } from '../../../global/constants/global';

// services
import { HttpBaseService } from '../../../global/services/http-base/http-base.service';
import { TokenService } from 'src/app/auth/services/token/token.service';
import * as _ from 'lodash';
import { CheckboxControlValueAccessor } from '@angular/forms';
import { time } from 'console';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService extends HttpBaseService<any> {

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/availability/`, _http, _tokenService);
  }

  /**
   * @description creates a users availability in the database
   * @param availabilityData { any }
   * @returns { Observable<any> } users availability data
   */
  createAvailabilties(availabilityData: any): Observable<any> {
    return this.post(availabilityData);
  }

  /**
   * @description updates the users availability data
   * @param availabilityData { any }
   * @returns { Observable<any> } users availability data
   */
  updateAvailabilties(availabilityData: any): Observable<any> {
    return this.put(availabilityData);
  }

  /**
   * @description formats the users availability data to display it
   * @param availabilityData { any }
   * @param userId { number }
   * @returns { any } object with formatted data
   */
  formatAvailabilityData(availabilityData: any, userId: number): any {
    let availabilityDataDto: any = {};
    if (availabilityData && availabilityData.daysOfTheWeek) {
      //Make sure a user id is set
      availabilityDataDto.userId = userId;
      availabilityDataDto.availabilityNote = availabilityData.availabilityNote;
      availabilityDataDto.daysOfTheWeek = [];
      availabilityData.daysOfTheWeek.forEach(dayOfTheWeek => {
        let dayOfTheWeekDto: any = {};
        let day = GlobalConstants.days.find(x => x.name === dayOfTheWeek.dayOfTheWeek.name);
        //Moment day numbers are 1 less than our db entries
        let momentDayNumber = day.id - 1 > -1 ? day.id - 1 : 6;
        dayOfTheWeekDto.day = dayOfTheWeek.dayOfTheWeek.name;
        dayOfTheWeekDto.userDaysOfTheWeek = [];
        dayOfTheWeek.times.forEach(time => {
          let userDayOfTheWeekDto: any = {};
          //If we already had an id make sure to send it in to avoid duplication
          userDayOfTheWeekDto.id = time.id;
          userDayOfTheWeekDto.userId = userId;
          userDayOfTheWeekDto.modifiedBy = time.modifiedBy;
          //Set the day to the correct day of the week so they are sequential
          let startTime = moment('2022-01-01 ' + time.startTime + ' ' + time.startTimeDDL, 'YYYY-MM-DD HH:mm a').day(momentDayNumber);
          let endTime = (time.endTime + ' ' + time.endTimeDDL) === '12:00 AM' ? moment('2022-01-01 ' + time.endTime + ' ' + time.endTimeDDL, 'YYYY-MM-DD HH:mm a').day(momentDayNumber).add(1, 'days') : moment('2022-01-01 ' + time.endTime + ' ' + time.endTimeDDL, 'YYYY-MM-DD HH:mm a').day(momentDayNumber);
          //Parse to date object for now. Might do this better in the future
          userDayOfTheWeekDto.startTime = startTime.toDate();
          userDayOfTheWeekDto.endTime = endTime.toDate();
          dayOfTheWeekDto.userDaysOfTheWeek.push(userDayOfTheWeekDto);
        });
        dayOfTheWeekDto.isSelected = dayOfTheWeek.isSelected;
        //dayOfTheWeekDto.isSelected = dayOfTheWeekDto.userDaysOfTheWeek.length > 0 ? true : false;
        availabilityDataDto.daysOfTheWeek.push(dayOfTheWeekDto);
      });
    }
    return availabilityDataDto;
  }

  /**
   * @description populate availability days of the week
   * @param userDaysOfTheWeek { any }
   * @returns { any[] } array with days of the week data
   */
  populateAvailabilityData(userDaysOfTheWeek: any, isBooking: boolean = false): any[] {
    let days = GlobalConstants.days;
    if (userDaysOfTheWeek && userDaysOfTheWeek.length > 0) {
      userDaysOfTheWeek.forEach(userDayOfTheWeek => {
        let day = days.find(d => d.id === userDayOfTheWeek.dayOfTheWeekLookupId);
        if (day) {
          //Make sure array is initialized
          day.times = day.times ?? [];
          //Setup time string we can use

          userDayOfTheWeek.originalStartTime = _.clone(userDayOfTheWeek.startTime);
          userDayOfTheWeek.originalEndTime = _.clone(userDayOfTheWeek.endTime);

          let localStartTime = moment(userDayOfTheWeek.startTime);
          let localEndTime = moment(userDayOfTheWeek.endTime);

          userDayOfTheWeek.startTimeString = localStartTime.format('LT');
          userDayOfTheWeek.endTimeString = localEndTime.format('LT');
         
          //Moment day numbers are 1 less than our db entries
          let momentDayNumber = userDayOfTheWeek.dayOfTheWeekLookupId - 1;
          // If both the start and end time are on the wrong localized day. Move it to the correct day
          if (isBooking && momentDayNumber !== localStartTime.day() && momentDayNumber !== localEndTime.day()) {
            //Should only ever be +1 or -1
            let dayDiff = localStartTime.day() - momentDayNumber;
            //Shift to 7 if we go prior to 1 shift to 1 if we go past 7
            let newDayId = day.id + dayDiff > 0 ? (day.id + dayDiff > 7 ? 1 : day.id + dayDiff) : 7;
            //Change the day here to the correct day
            day = days.find(d => d.id === newDayId);
            //Make sure array is initialized
            day.times = day.times ?? [];
            //Mark the day as true so it shows up
            day.isSelected = true;
          }

          //Push the record into it
          day.times.push(userDayOfTheWeek);
        }
        day.isSelected = day.times.filter(x => x.statusLookupId === GlobalConstants.activeStatus.id).length > 0 ? true : false;
      });
    }

    //Set which days are selected after localization
    this.setSelectedDays(days, isBooking);
    return days;
  }


  /**
* @description set the selected values for days that are populated after localization
* @param days { array }
*/
  private setSelectedDays(days: any, isBooking: boolean = false) {
    //Set the is selected value based upon whether a time range goes into the actual day or not
    for (let i = 0; i < 7; i++) {
      if (days[i].isSelected) continue;
      days.forEach(x => {
        x.times = x.times || [];
        x.times.forEach(y => {
          if (moment(y.startTime).day() === i || moment(y.endTime).day() === i) {
            days[i].isSelected = true;
          }
        });
      });
    }
  }

}
