import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

// capacitor
import { Capacitor } from '@capacitor/core';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { CustomTagService } from '../../services/custom-tag/custom-tag.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';
import { SuccessMessageComponent } from 'src/app/global/components/success-message/success-message.component';
import { TagFormComponent } from '../tag-form/tag-form.component';

// constants
import { GlobalConstants } from 'src/app/global/constants/global';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit, OnDestroy {

  isIos: boolean = false // flag to tell if device is ios;
  tagListData: any; // tag list
  user: any; // the authenticated user

  deleteTagDialogRef: any; // element reference to delete tag dialog
  deleteTagSaving: boolean = false; // flag to tell if in the act of deleting tag data

  deactivateTagDialogRef: any; // element reference to deactivate tag dialog
  deactivateTagSaving: boolean = false; // flag to tell if in the act of deactivateing tag data

  reactivateTagDialogRef: any; // element reference to reactivate tag dialog
  reactivateTagSaving: boolean = false; // flag to tell if in the act of restoring tag data

  tagDialogRef: any; // element reference to reset password dialog
  tagSaving: boolean = false; // flag to tell if in the act of reseting password data

  globalConstants: any; // stores the global constants in a local param so that they can be used on the html template

  // creates the form group
  tagForm: FormGroup = this.formBuilder.group({
    tag: ['', [Validators.required]]
  });

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private authService: AuthService,
    private customTagService: CustomTagService,
    private loaderService: LoaderService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { 
    // check if device is ios and set flag
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.globalConstants = GlobalConstants;
  }

  ngOnInit(): void {

    // show the loader
    this.loaderService.loaderSubject$.next(true);

    // set the modifiedBy var to the authenticated users email
    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe((user: any) => {
        if (user) {
          this.user = user;
          this.getTagList();
        }
      })
    );   
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  /**
   * @description gets a list of all tags from the database
   */
  getTagList(){
    // get the authenticated tags profile data
    this.subscriptions.add(
      this.customTagService.getTagList().subscribe(
        {
          next: (res) => {
            console.log(res);
            if (!res) return; // stop if there is no result

            this.tagListData = res.data; // set raw tag list data

            // hide the loader
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            // hide the loader
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description opens the tag dialog with tag data based on mode and set up the close event
   * @param mode { string }
   * @param tag { any }
   */
  openTagDialog(mode: string, tag?: any): void {

    let tagData: any;

    if (tag) {
      tagData = { mode: mode, modifiedBy: this.user.email, tag: tag }
    } else {
      tagData = { mode: mode, modifiedBy: this.user.email, tag: { tagId: this.tagListData.tagId } }
    }

    const tagFormDialogRef = this.dialog.open(TagFormComponent, {
      width: '500px',
      data: tagData,
    });

    this.subscriptions.add(
      tagFormDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
        //If the result is nothing they click off the dialog and we have no save or edit
        if (!result) {
          return;
        }
        else if (result.event === 'add') {
          this.tagListData.push(result.data);
        } else if (result.event === 'edit') {
          let tagToReplace = this.tagListData.find(tag => tag.id === result.data.id);
          Object.assign(tagToReplace, result.data);
        }
      })
    );
  }

   /**
   * @description opens the delete tag dialog and sets up the close event
   * @param deleteTemplateRef { any }
   */
   deleteTagDialog(deleteTemplateRef: any): void {
    this.deleteTagDialogRef = this.dialog.open(deleteTemplateRef);

    this.subscriptions.add(
      this.deleteTagDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description opens the deactivate tag dialog and sets up the close event
   * @param deactivateTemplateRef { any }
   */
  deactivateTagDialog(deactivateTagTemplateRef: any): void {
    this.deactivateTagDialogRef = this.dialog.open(deactivateTagTemplateRef);

    this.subscriptions.add(
      this.deactivateTagDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description opens the reactivate tag dialog and sets up the close event
   * @param reactivateTemplateRef { any }
   */
  reactivateTagDialog(reactivateTemplateRef: any): void {
    this.reactivateTagDialogRef = this.dialog.open(reactivateTemplateRef);

    this.subscriptions.add(
      this.reactivateTagDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed:', result);
      })
    );
  }

  /**
   * @description marks a tag as deleted in the database
   * @param deletetag { any }
   */
  deleteTag(deletetag: any) {
    this.deleteTagSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('deleted tag:', deletetag);

    this.subscriptions.add(
      this.customTagService.deleteTag(deletetag, this.user.email).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.tagListData = this.tagListData.filter(tag => tag.id !== deletetag.id);
            this.deleteTagDialogRef.close({ event: 'delete', data: res });
            this.deleteTagDialogRef = null;
            this.deleteTagSaving = false;
            //this.authService.getTagData(); // this updates the tag data subject so that the most recent data is always displayed
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Tag has been deleted!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.deleteTagSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description marks a tag as deactivateed in the database
   * @param deactivateTag { any }
   */
  deactivateTag(deactivatetag: any) {
    this.deactivateTagSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('deactivate tag:', deactivatetag);

    this.subscriptions.add(
      this.customTagService.deactivateTag(deactivatetag, this.user.email).subscribe(
        {
          next: (res) => {
            console.log(res);
            // update the tag record in the list with the updated data
            this.tagListData = this.tagListData.map(u => u.id !== res.data.id ? u : res.data);
            this.deactivateTagDialogRef.close({ event: 'deactivate', data: res });
            this.deactivateTagDialogRef = null;
            this.deactivateTagSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Tag has been deactivateed!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.deactivateTagSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description marks a tag as reactivated in the database
   * @param reactivatetag { any }
   */
  reactivateTag(reactivatetag: any) {
    this.reactivateTagSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('reactivate tag:', reactivatetag);

    this.subscriptions.add(
      this.customTagService.reactivateTag(reactivatetag, this.user.email).subscribe(
        {
          next: (res) => {
            console.log(res);
            // update the tag record in the list with the updated data
            this.tagListData = this.tagListData.map(u => u.id !== res.data.id ? u : res.data);
            this.reactivateTagDialogRef.close({ event: 'reactivate', data: res });
            this.reactivateTagDialogRef = null;
            this.reactivateTagSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(SuccessMessageComponent, {
              data: 'Tag has been reactivated!',
              duration: 10000,
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            console.error(e);
            this.reactivateTagSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

}
