import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

// environment
import { environment } from 'src/environments/environment';

// services
import { HttpBaseService } from '../../../global/services/http-base/http-base.service';
import { TokenService } from 'src/app/auth/services/token/token.service';
import { GlobalConstants } from 'src/app/global/constants/global';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpBaseService<any> {

  filterSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({ searchText: '', roles: [], status: [] }); // subject with filter object values
  filter$: Observable<any> = this.filterSubject$.asObservable(); // the filter subject observable

  private poolData: any; // AWS cognito pool data config object
  private userPool: any; // AWS cognito user pool

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/user/`, _http, _tokenService);

    // sets the pool data config
    this.poolData = {
      UserPoolId: environment.cognitoUserPoolId, // Your user pool id here
      ClientId: environment.cognitoAppClientId // Your client id here
    };

    // gets and sets the cognito user pool
    this.userPool = new CognitoUserPool(this.poolData);
  }

  /**
   * @description gets the authenticated AWS cognito users id
   * @returns { Promise<number> } containing userId
   */
  async getUserId(): Promise<number> {
    let cognitoUser = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      if (cognitoUser) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.log('session error: ', err);
            reject('No session!');
          }
          if (session) {
            cognitoUser.getUserAttributes((err, user) => {
              if (err) {
                console.log('user error: ', err);
                reject('No user!');
              }
              if (user) {
                console.log(user);
                let userId = user.find(res => {
                  return res.Name === 'custom:userId';
                }).Value;

                resolve(userId);
              }
            });
          }
        });
      } else {
        resolve(-1) // not logged in
      }
    });
  }

  /**
   * @description gets the first user returned based on the AWS cognito userId
   * @returns { Promise<any> } user
   */
  async getUser(id?: number): Promise<any> {
    let userId = await this.getUserId();
    // if the id is passed in overwrite the default id
    if (id) {
      userId = id;
    }
    return await firstValueFrom(this.get('', { id: userId }));
  }

  /**
   * @description gets the user list
   * @returns { Observable<any> } user list
   */
  getUserList(filters: any): Observable<any> {
    return this.post({ filters: filters }, 'list');
  }

  /**
   * @description gets the artist returned based on sent in user id
   * @param id { number }
   * @returns { Observable<any> } user
   */
  getArtist(id: number): Observable<any> {
    return this.get('artist', { id: id });
  }

  /**
   * @description gets the users sign up status from the returned AWS cognito userId
   * @returns { Promise<any> } the users sign up status object
   */
  async getSignUpStatus(): Promise<any> {
    let userId = await this.getUserId();
    return await firstValueFrom(this.get('signupstatus', { id: userId }));
  }

  /**
   * @description updates the users profile in the database
   * @param account { any }
   * @returns { Observable<any> } profile update result
   */
  updateUser(account: any): Observable<any> {
    return this.put(account);
  }

  /**
   * @description deletes the users profile in the database AWS cognito and cancels all upcoming shows
   * @param account { any }
   * @returns { Observable<any> } profile delete result
   */
  // deleteUser(account: any, modifiedBy): Observable<any> {
  deleteUser(account: any): Observable<any> {
    return this.post(account, 'deleteaccount');
    // return this.patch(account.id, { statusLookupId: GlobalConstants.deletedStatus.id, modifiedBy: modifiedBy });
  }

  /**
   * @description suspends the users profile in the database
   * @param account { any }
   * @returns { Observable<any> } profile suspend result
   */
  suspendUser(account: any, modifiedBy): Observable<any> {
    return this.patch(account.id, { statusLookupId: GlobalConstants.inactiveStatus.id, modifiedBy: modifiedBy });
  }

  /**
   * @description restores the users profile in the database
   * @param account { any }
   * @returns { Observable<any> } profile restore result
   */
  restoreUser(account: any, modifiedBy): Observable<any> {
    return this.patch(account.id, { statusLookupId: GlobalConstants.activeStatus.id, modifiedBy: modifiedBy });
  }

  /**
   * @description resets the users password in the database
   * @param data { any }
   * @returns { Observable<any> } password reset result
   */
  resetPassword(data: any): Observable<any> {
    return this.post(data, 'resetpassword');
  }

  /**
   * @description updates the users roles in the database
   * @param data { any }
   * @returns { Observable<any> } roles update result
   */
  updateRoles(data: any): Observable<any> {
    return this.post(data, 'updateroles');
  }

}
