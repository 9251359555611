// modules
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

// services
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { CustomTagService } from '../../services/custom-tag/custom-tag.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

@Component({
  selector: 'app-tag-form',
  templateUrl: './tag-form.component.html',
  styleUrls: ['./tag-form.component.scss']
})
export class TagFormComponent implements OnInit {

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  isLoading: boolean = false; // flag to show the loader
  tagSaving: boolean = false; // flag to disable save button to prevent multiple clicks

  // creates the tag form
  tagForm = this.formBuilder.group({
    id: [''],
    name: ['', [Validators.required]],
    modifiedBy: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<TagFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private customTagService: CustomTagService,
    private snackBar: MatSnackBar,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    
    //Populate the form with existing data if we have it
    if (this.data) {
      this.tagForm.controls['id'].setValue(this.data.tag.id ? this.data.tag.id : '');
      this.tagForm.controls['name'].setValue(this.data.tag.name ? this.data.tag.name : '');
      this.tagForm.controls['modifiedBy'].setValue(this.data.modifiedBy ? this.data.modifiedBy : 'unknown');
    }
  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description saves the tag data for the artist
   * @returns { void }
   */
  saveTag() {
    this.tagSaving = true;
    this.loaderService.loaderSubject$.next(true);

    console.log('add-tag', this.tagForm.value);
    
    this.subscriptions.add(
      this.customTagService.createTag(this.tagForm.value).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.dialogRef.close({ event: this.data.mode, data: res.data });
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            this.tagSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description the event that closes the tag form dialog
   */
  onCancelClick(): void {
    this.dialogRef.close({ event: 'close' });
  }

}
