import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// capacitor
import { Capacitor } from '@capacitor/core';

// services
import { AuthService } from 'src/app/auth/services/auth/auth.service';
import { UserService } from 'src/app/profile/services/user/user.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';
import { AvailabilityService } from 'src/app/profile/services/availability/availability.service';
import { ShowRequirementService } from 'src/app/profile/services/show-requirement/show-requirement.service';
import { StripeService } from 'src/app/global/services/stripe/stripe.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChild('registerStepper') registerStepper: MatStepper; // the register stepper element

  isIos: boolean = false; // flag to tell if device is ios
  stepperLoaded: boolean = false; // tells if the stepper has been rendered
  userProfile: boolean = true; // flag to determine if not in role of artist 
  modifiedBy: string; // the logged in users email

  days: any; // the processed availability data

  profileSaving: boolean = false; // flag to tell if in the act of saving profile data
  availabilitySaving: boolean = false; // flag to tell if in the act of saving availability data
  showRequirementsSaving: boolean = false; // flag to tell if in the act of saving show requirement data

  profileData: any; // the raw logged in user profile data
  availabilityData: any;  // the raw logged in user availability data
  showRequirementsData: any; // the raw logged in user show requirements data
  userSongListData: any; // the raw logged in user song list data
  stripeAccountStatusData: any; // the raw logged in user stripe account status data

  profileFormValid: boolean = false; // flag to tell if in the profile form is valid
  availabilityFormValid: boolean = false; // flag to tell if in the availability form is valid
  showRequirementsFormValid: boolean = false; // flag to tell if in the show requirements form is valid

  private subscriptions: Subscription = new Subscription();  // parent subscription list that contains all subscriptions to make cleanup easy

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private loaderService: LoaderService,
    private stripeService: StripeService,
    private availabilityService: AvailabilityService,
    private showRequirementService: ShowRequirementService,
    private snackBar: MatSnackBar
  ) {
    // checks if device is ios and set flag
    this.isIos = Capacitor.getPlatform() === 'ios';
  }

  async ngOnInit() {

    // show the loader
    this.loaderService.loaderSubject$.next(true);

    // set the modifiedBy var to the authenticated users email
    this.subscriptions.add(
      this.authService.authenticatedUser$.subscribe(user => {
        if (user) {
          this.modifiedBy = user.find(res => {
            return res.Name === 'email';
          }).Value;
        }
      })
    );

    // get the authenticated users profile data
    this.subscriptions.add(
      this.authService.authenticatedUserData$.subscribe(
        {
          next: (res) => {
            console.log(res);
            if (!res) return; // stop if there is no result

            this.profileData = res; // set raw profile data
            this.userProfile = !this.profileData.roleNames.includes('Artist'); // set the user profile flag based off roles

            // process raw availability data and set days var
            this.days = this.availabilityService.populateAvailabilityData(this.profileData.userDayOfTheWeekArray);

            // check if show requirements exists and set var
            if (this.profileData.userShowRequirement) {
              this.showRequirementsData = this.profileData.userShowRequirement;
            } else {
              this.showRequirementsData = { userId: this.profileData.id };
            }

            // check if songs exists and set var
            if (this.profileData.songs) {
              this.userSongListData = { userId: this.profileData.id, songList: this.profileData.songs };
            } else {
              this.userSongListData = { userId: this.profileData.id, songList: [] };
            }

            // hide the loader
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            // hide the loader
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );

    this.subscriptions.add(
      this.stripeService.stripeAccountStatus$.subscribe(accountStatus => {
        this.stripeAccountStatusData = accountStatus;
      })
    );
  }

  ngAfterViewChecked() {
    // checks to make sure all the data and html is rndered before trying to set the active step
    if (!this.userProfile && this.registerStepper && !this.stepperLoaded) {
      this.authService.signUpStatus$.subscribe(data => {
        if (data) {
          // this sets the active step based off the first step with incomplete status
          if (data.detailsComplete) {
            this.registerStepper.selected.completed = true;
            this.registerStepper.next();
            if (data.availabilityComplete) {
              this.registerStepper.selected.completed = true;
              this.registerStepper.next();
              if (data.pricingComplete) {
                this.registerStepper.selected.completed = true;
                this.registerStepper.next();
                if (data.songlistComplete) {
                  this.registerStepper.selected.completed = true;
                  this.registerStepper.next();
                }
              }
            }
          }
        }
      }).unsubscribe();
      this.stepperLoaded = true; // stop runnning this once it completes
    }
  }

  ngOnDestroy(): void {
    // unsubscribe from all active subscriptions
    this.subscriptions.unsubscribe();
  }

  //#region profile details

  /**
   * @description sets the validation flag returned from the profile form component
   * @param valid { boolean }
   */
  setProfileFormValid(valid: boolean) {
    this.profileFormValid = valid;
  }

  /**
   * @description sets the data returned from the profile form component 
   * @param data { any }
   */
  setProfileData(data: any) {
    if (data) {
      this.profileData = data;
    }
  }

  /**
   * @description updates the profile data returned from the child component
   */
  updateProfile() {
    this.profileSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('update-profile', this.profileData);
    this.subscriptions.add(
      this.userService.updateUser(this.profileData).subscribe(
        {
          next: async (res) => {
            console.log(res);
            await this.authService.getUserData(); // this updates the user data subject so that the most recent data is always displayed
            // set navigation action based off user roles
            if (this.userProfile) {
              this.router.navigate([`/browse`], { relativeTo: this.route });
            } else {
              this.registerStepper.selected.completed = true;
              this.registerStepper.next();
              this.profileSaving = false;
              this.loaderService.loaderSubject$.next(false);
            }
          },
          error: (e) => {
            console.error(e);
            this.profileSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );

  }

  //#endregion profile details

  //#region availability

  /**
   * @description sets the validation flag returned from the availability form component
   * @param valid { boolean }
   */
  setAvailabilityFormValid(valid: boolean) {
    this.availabilityFormValid = valid;
  }

  /**
   * @description sets the data returned from the availability form component 
   * @param data { any }
   */
  setAvailabilityData(data: any) {
    if (data) {
      this.availabilityData = data;
    }
  }

  /**
   * @description updates the availability data returned from the child component
   */
  updateAvailability() {
    this.availabilitySaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('update-availability', this.availabilityData);
    this.subscriptions.add(
      this.availabilityService.createAvailabilties(this.availabilityService.formatAvailabilityData(this.availabilityData, this.profileData.id)).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.registerStepper.selected.completed = true;
            this.registerStepper.next();
            this.availabilitySaving = false;
            this.authService.getUserData(); // this updates the user data subject so that the most recent data is always displayed
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            this.availabilitySaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );

  }

  //#endregion availablity

  //#region show requirements

  /**
   * @description sets the validation flag returned from the show settings form component
   * @param valid { boolean }
   */
  setShowRequirementsFormValid(valid: boolean) {
    this.showRequirementsFormValid = valid;
  }

  /**
   * @description sets the data returned from the show settings form component 
   * @param data { any }
   */
  setShowRequirementsData(data: any) {
    if (data) {
      this.showRequirementsData = data;
    }
  }

  /**
   * @description updates the show requirements data returned from the child component
   */
  updateShowRequirements() {
    this.showRequirementsSaving = true;
    this.loaderService.loaderSubject$.next(true);
    console.log('update-show-requirements', this.showRequirementsData);
    this.subscriptions.add(
      this.showRequirementService.createShowRequirements(this.showRequirementsData).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.registerStepper.selected.completed = true;
            this.registerStepper.next();
            this.showRequirementsSaving = false;
            this.authService.getUserData(); // this updates the user data subject so that the most recent data is always displayed
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            this.showRequirementsSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );

  }

  //#endregion show requirements

  //#region song list

  /**
   * @description sets the data returned from the song list component 
   * @param data { any }
   */
  setSongListData(data: any) {
    if (data) {
      this.userSongListData = data;
    }
  }

  /**
   * @description navigates to the users home browse page on complete
   */
  complete() {
    console.log('update-song-list', this.userSongListData);
    this.router.navigate([`../browse`], { relativeTo: this.route });
  }

  //#endregion song list
}
