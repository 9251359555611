import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialComponentsModule } from 'src/app/material-components/material-components.module';
import { GlobalModule } from '../global/global.module';

// components
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';

// services
import { AuthService } from './services/auth/auth.service';
import { TokenService } from './services/token/token.service';
import { CognitoService } from './services/cognito/cognito.service';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { DeleteAccountComponent } from './components/delete-account/delete-account.component';


@NgModule({
  declarations: [
    SignInComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CreateAccountComponent,
    AccessDeniedComponent,
    DeleteAccountComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialComponentsModule,
    ReactiveFormsModule,
    GlobalModule
  ],
  exports: [
    SignInComponent
  ],
  providers: [
    AuthService,
    TokenService,
    CognitoService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AuthModule { }
