import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// servcies
import { TokenService } from 'src/app/auth/services/token/token.service';
import { HttpBaseService } from 'src/app/global/services/http-base/http-base.service';

// environments
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformDefaultsService extends HttpBaseService<any> {

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) { 
    super(`${environment.nodeServerUrl}/platformdefaults/`, _http, _tokenService);
  }

  /**
   * @description gets the active platform defaults data
   * @returns { Observable<any> } with platform defaults
   */
  getPlatformDefaults(): Observable<any> {

    let platformDefaultsResult = this.get('');
    console.log('get single active platform defaults object:', platformDefaultsResult);
    return platformDefaultsResult;

  }

  /**
    * @description creates a new platform defaults object in the database
    * @param platformDefaultsData { any }
    * @returns { Observable<any> } platform defaults data return object
    */
  createPlatformDefaults(platformDefaultsData: any): Observable<any> {

    let result = this.post(platformDefaultsData, 'create');
    console.log('create platform default result:', result);
    return result;

  }

}
