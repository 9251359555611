import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//Services
import { EmbedService } from '../../services/embed/embed.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {

  public embedIframe: HTMLElement; // the embedded iframe element

  constructor(
    public dialogRef: MatDialogRef<VideoPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private embedService: EmbedService
  ) {

  }

  ngOnInit(): void {
    // set the embedded iframe element with the src contents
    this.embedIframe = this.embedService.embed(this.data.mediaUrl);
  }
}

