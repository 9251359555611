<div class="mat-simple-snackbar">
    <div class="mat-simple-snack-bar-content">
        <h3>
            <mat-icon class="message-icon">rule</mat-icon> Profile Incomplete
        </h3>
        <p>You must complete your profile for others to find you.</p>
        <mat-stepper class="completeProfilePromptStepper" labelPosition="bottom" #completeProfilePromptStepper>
            <mat-step stepControl="stepOne" [editable]="!this.data.detailsComplete" [completed]="this.data.detailsComplete">
                <ng-template matStepLabel>Details</ng-template>
            </mat-step>
            <mat-step stepControl="stepTwo" [editable]="!this.data.availabilityComplete" [completed]="this.data.availabilityComplete">
                <ng-template matStepLabel>Availability</ng-template>
            </mat-step>
            <mat-step stepControl="stepThree" [editable]="!this.data.pricingComplete" [completed]="this.data.pricingComplete">
                <ng-template matStepLabel>Show Settings</ng-template>
            </mat-step>
            <mat-step stepControl="stepFour" optional [editable]="!this.data.songlistComplete" [completed]="this.data.songlistComplete">
                <ng-template matStepLabel>Songlist</ng-template>
            </mat-step>
        </mat-stepper>
    </div>
    <div class="mat-simple-snackbar-action">
        <button class="icon-button" mat-button (click)="continue()">
            <mat-icon>navigate_next</mat-icon> Continue
        </button>
        <button class="icon-button" mat-button (click)="sbRef.dismiss()">
            <mat-icon>close</mat-icon> Dismiss
        </button>
        <button class="icon-button" mat-button (click)="dontShowAgain()">
            <mat-icon>delete</mat-icon> Don't Show Again
        </button>
    </div>
</div>


