// modules
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, finalize, map, Observable, startWith, Subscription } from 'rxjs';

// services
import { SongService } from '../../services/song/song.service';
import { EmbedService } from 'src/app/global/services/embed/embed.service';
import { SpotifyService } from 'src/app/global/services/spotify/spotify.service';
import { LoaderService } from 'src/app/global/services/loader/loader.service';

// components
import { ErrorMessageComponent } from 'src/app/global/components/error-message/error-message.component';

@Component({
  selector: 'app-song-form',
  templateUrl: './song-form.component.html',
  styleUrls: ['./song-form.component.scss']
})
export class SongFormComponent implements OnInit {

  spotifyGenres: string[] = []; // list of spotify genres
  spotifyArtists: string[] = []; // list of spotify artists
  spotifyTracks: string[] = []; // list of spotify tracks
  filteredGenres: Observable<string[]>; // observable with filtered list of spotify genres 
  filteredArtists: string[] = []; // filtered list of spotify artists
  filteredTracks: string[] = []; // filtered list of spotify tracks

  subscriptions: Subscription = new Subscription(); // parent subscription list that contains all subscriptions to make cleanup easy

  isLoading: boolean = false; // flag to show the loader
  songSaving: boolean = false; // flag to disable save button to prevent multiple clicks

  
  // creates the song form
  songForm = this.formBuilder.group({
    id: [''],
    userId: [''],
    title: ['', [Validators.required]],
    artist: ['', [Validators.required]],
    genre: ['', [Validators.required]],
    mediaUrl: ['', []],
    modifiedBy: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<SongFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private songService: SongService,
    private snackBar: MatSnackBar,
    private embedService: EmbedService,
    private spotifyService: SpotifyService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    //Get spotify genres
    if (this.spotifyGenres.length < 1) {
      this.subscriptions.add(
        this.spotifyService.genres().subscribe(genres => {
          this.spotifyGenres = genres;
        })
      );
    }
    //Populate the form with existing data if we have it
    if (this.data) {
      this.songForm.controls['id'].setValue(this.data.song.id ? this.data.song.id : '');
      this.songForm.controls['userId'].setValue(this.data.song.userId ? this.data.song.userId : '');
      this.songForm.controls['title'].setValue(this.data.song.title ? this.data.song.title : '');
      this.songForm.controls['artist'].setValue(this.data.song.artist ? this.data.song.artist : '');
      this.songForm.controls['genre'].setValue(this.data.song.genre ? this.data.song.genre : '');
      this.songForm.controls['mediaUrl'].setValue(this.data.song.mediaUrl ? this.data.song.mediaUrl : '');
      this.songForm.controls['modifiedBy'].setValue(this.data.modifiedBy ? this.data.modifiedBy : 'unknown');
    }

    //Setup autocomplete filter for genres. Static genre list from prior spotify service get 
    this.filteredGenres = this.songForm.controls['genre'].valueChanges.pipe(
      startWith(''),
      map(value => this.filterGenres(value))
    );

    //Setup autocomplete filter for artists. Will hit our api which in turn fetches from spotify api
    this.subscriptions.add(
      this.songForm.controls['artist'].valueChanges.pipe(
        debounceTime(1000),
        startWith(''),
        map(value => this.filterArtists(value))
      ).subscribe()
    );

    //Setup autocomplete filter for tracks. Will hit our api which in turn fetches from spotify api
    this.subscriptions.add(
      this.songForm.controls['title'].valueChanges.pipe(
        debounceTime(1000),
        startWith(''),
        map(value => this.filterTracks(value))
      ).subscribe()
    );
  }

  //Clean up subscriptions on exit
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * @description saves the song data for the artist
   * @returns { void }
   */
  saveSong() {
    this.songSaving = true;
    this.loaderService.loaderSubject$.next(true);

    console.log('add-song', this.songForm.value);
    if (this.songForm.value.mediaUrl && !this.isEmbedValid(this.songForm.value.mediaUrl)) {
      this.songSaving = false;
      this.loaderService.loaderSubject$.next(false);
      this.snackBar.openFromComponent(ErrorMessageComponent, {
        data: 'The Media URL you supplied is not valid.',
        duration: 10000,
        panelClass: ['error-snackbar']
      });
      return;
    }
    this.subscriptions.add(
      this.songService.createUserSong(this.songForm.value).subscribe(
        {
          next: (res) => {
            console.log(res);
            this.dialogRef.close({ event: this.data.mode, data: res.data });
            this.loaderService.loaderSubject$.next(false);
          },
          error: (e) => {
            console.error(e);
            this.songSaving = false;
            this.loaderService.loaderSubject$.next(false);
            this.snackBar.openFromComponent(ErrorMessageComponent, {
              data: e,
              duration: 10000,
              panelClass: ['error-snackbar']
            });
          },
          complete: () => console.info('complete')
        }
      )
    );
  }

  /**
   * @description the event that closes the song form dialog
   */
  onCancelClick(): void {
    this.dialogRef.close({ event: 'close' });
  }

  /**
   * @description checks if the embed url is valid
   * @param mediaUrl { string }
   * @returns { boolean }
   */
  isEmbedValid(mediaUrl: string) {
    return this.embedService.detect(mediaUrl);
  }

  /**
   * @description method to filters the list of genres based on the value in the input
   * @param value { any } the value to filter by
   * @returns { any[] } the list of filtered values
   */
  filterGenres(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.spotifyGenres.filter(option => option.toString().toLowerCase().includes(filterValue));
  }

  /**
   * @description filters the list of artists based on the value in the input
   * @param value { any } the value to fiter by
   * @returns { any } the list of filtered values
   */
  filterArtists(value: any): any {
    //If we pass in an empty value or a value from the current autocomplete we can ignore the rest of the autocomplete process
    if (!value || value.length < 1 || (this.filteredArtists && this.filteredArtists.find((x: any) => x.name === value))) return null;
    //Could potentially fill out genre here if artist is known
    //Show a loading item until we populate a list from the api
    this.isLoading = true;
    const filterValue = value.toLowerCase();
    //Run the fetch from our spotify api
    return this.spotifyService.searchArtists(filterValue)
      .pipe(
        finalize(() => {
          //Hide the loading indicator
          this.isLoading = false
        }),
      )
      .subscribe(data => {
        if (!data || !data.artists || !data.artists.items || data.artists.items.length < 1) {
          //If no data clear the autocomplete list
          this.filteredArtists = null;
        } else {
          //Populate the list for autocomplete
          this.filteredArtists = data.artists.items;
        }
      });
  }

   /**
   * @description filters the list of tracks based on the value in the input
   * @param value { any } the value to fiter by
   * @returns { any } the list of filtered values
   */
  filterTracks(value: any): any {
    //If we pass in an empty value or a value from the current autocomplete we can ignore the rest of the autocomplete process
    if (!value || value.length < 1 || (this.filteredTracks && this.filteredTracks.find((x: any) => x.name === value))) return null;
    //Could potentially fill out both artist and genre here if known
    //Show a loading item until we populate a list from the api
    this.isLoading = true;
    const filterValue = value.toLowerCase();
    //Run the fetch from our spotify api
    return this.spotifyService.searchTracks(filterValue)
      .pipe(
        finalize(() => {
          //Hide the loading indicator
          this.isLoading = false
        }),
      )
      .subscribe(data => {
        if (!data || !data.tracks || !data.tracks.items || data.tracks.items.length < 1) {
          //If no data clear the autocomplete list
          this.filteredTracks = null;
        } else {
          //Populate the list for autocomplete
          this.filteredTracks = data.tracks.items;
        }
      });
  }
}
