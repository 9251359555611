import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, switchMap, Observable, first } from 'rxjs';

// services
import { TokenService } from 'src/app/auth/services/token/token.service';
import { HttpBaseService } from 'src/app/global/services/http-base/http-base.service';

// environments
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrowseService extends HttpBaseService<any> {

  filterSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({ searchText: '', dateFilter: '', price: '', distance: '' }); // subject with filter object values
  filter$: Observable<any> = this.filterSubject$.asObservable(); // the filter subject observable

  constructor(
    private _http: HttpClient,
    private _tokenService: TokenService
  ) {
    super(`${environment.nodeServerUrl}/event/`, _http, _tokenService);
  }

  /**
   * @description gets the list of available artists
   * @param userId { string } 
   * @returns { Observable<any> } with artists that are set up to play a show
   */
  getBrowseArtists(userId: number, limitLength: number, offsetLength: number): Observable<any> {
    return this.filter$.pipe(
      first(),
      switchMap((filter: { searchText?: string, dateFilter?: string, price?: string, distance?: string }) => {
        let artists = this.get('browse',{ userId: userId, searchText: filter.searchText, dateFilter: filter.dateFilter, price: filter.price, distance: filter.distance, limitLength: limitLength,  offsetLength: offsetLength });
        console.log('artists result:', artists);
        return artists;
      })
    );

  }
}
